import React from "react";
import { Col, InputGroup, Row } from "react-bootstrap";
import { useLanguage } from "hooks";
import {
  GridList,
  InputField,
  Layout,
  ProfileLayout,
  SelectField,
  Typography,
} from "components";
import { OrderItem } from "./components";
import withContainer from "./Container";
import { useSelector } from "react-redux";

function OrderListPage({
  loading,
  toggleFilter,
  debouncedSearchKeyChange,
  handleResetFilters,
  data,
  meta,
}) {
  const translation = useLanguage();
  const { order_status_types } = useSelector(
    (state) => state.common.websiteSettings
  );
  const filters = order_status_types;
  return (
    <Layout footerMargin>
      <div id="EditProfilePage">
        <ProfileLayout title={translation.myAccount}>
          <div className="bg-gray3 px-2 py-2 rounded-xs border border-gray">
            <Row>
              <Col xs={12} sm={7} xl={3} className="order-xl-1 order-lg-1">
                <div className="bg-white d-flex align-items-center rounded-sm">
                  <InputField
                    type="text"
                    onChange={(e) => debouncedSearchKeyChange(e.target.value)}
                    icon="search"
                    color="gray7"
                    iconColor="gray7"
                  />
                </div>
              </Col>
              <Col
                xs={12}
                lg={12}
                xl={6}
                className="order-xl-2 order-sm-3 mt-2 mt-xl-0"
              >
                <InputGroup>
                  <InputField
                    type="date"
                    onChange={(e) => toggleFilter("from_date", e.target.value)}
                    label={translation.from}
                    labelType="group"
                    className="border-0"
                    labelClassName="bg-white fw-bolder border-0"
                    labelColor="gray7"
                  />
                  <InputField
                    type="date"
                    onChange={(e) => toggleFilter("to_date", e.target.value)}
                    label={translation.to}
                    labelType="group"
                    className="border-0"
                    labelClassName="bg-white fw-bolder border-0"
                    labelColor="gray7"
                  />
                </InputGroup>
              </Col>
              <Col
                xs={12}
                sm={5}
                xl={3}
                className="order-xl-3 order-sm-2 mt-2 mt-sm-0"
              >
                <Row className="align-items-center">
                  <Col xs={2}>
                    <Typography
                      variant="body2"
                      className="fw-bolder"
                      color="gray7"
                      text={translation.filterBy}
                    />
                    <span className="text-gray7 fw-bolder"></span>
                  </Col>
                  <Col xs={10}>
                    <SelectField
                      className="border-0"
                      onChange={(e) =>
                        toggleFilter("status", e.target.value, false)
                      }
                    >
                      {filters.map((item, idx) => (
                        <option key={idx} value={item.value}>
                          {item.name}
                        </option>
                      ))}
                    </SelectField>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div className="mt-4">
            <GridList
              as={OrderItem}
              items={data && data.length > 0 ? data : []}
              meta={meta}
              loading={loading}
              noDataImage="/images/no-orders.png"
              noDataText={translation.noOrders}
              skeletonHeight={160 + 16}
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
            />
          </div>
        </ProfileLayout>
      </div>
    </Layout>
  );
}

export default withContainer(React.memo(OrderListPage));

import { useAlert } from "context";
import { useForm, useLanguage } from "hooks";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { register } from "store/auth";

export default function withContainer(WrappedComponent) {
  return (props) => {
    const dispatch = useDispatch();
    const language = useLanguage();
    const [_, dispatchAlert] = useAlert();
    const { loading } = useSelector((state) => state.auth);

    const [form, setForm] = useForm({
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      placeId: "",
      password: "",
      password_confirmation: "",
    });
    const [errors, setErrors] = React.useState(null);

    const handleRegister = (e) => {
      e.preventDefault();
      if (loading) return;
      dispatch(
        register({
          body: form,
          onSuccess: (err) => {
            dispatchAlert({
              type: "OPEN",
              payload: {
                icon: "success",
                title: language.success,
                text: language.registerSuccess,
                canDismiss: true,
              },
            });
            setErrors(null);
          },
          onError: (err) => setErrors(err),
        })
      );
    };
    return (
      <WrappedComponent
        form={form}
        loading={loading}
        errors={errors}
        setForm={setForm}
        handleRegister={handleRegister}
      />
    );
  };
}

import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { useLanguage } from "hooks";
import { Skeleton, Typography } from "components";
import { FactCard } from "components/cards";
import "./style.scss";

function OurFacts({ loading, facts, categories }) {
  const language = useLanguage();

  return !loading ? (
    <div id="our-facts" className="py-3 py-lg-3">
      <Container>
        <Row>
          <Col xs={12}>
            <Typography
              tag="h2"
              variant="h1"
              weight="bolder"
              color="white"
              align="center"
            >
              {language.ourFacts}
            </Typography>
            <Typography
              tag="p"
              variant="body2"
              weight="normal"
              align="center"
              color="white"
              // text="Over 200,000 downloadable resources, with commercial license. Unlimited Downloads and more."
            />
          </Col>
          <Col xs={12} className="mt-0">
            <Row className="g-2 g-lg-3">
              {facts &&
                facts.length > 0 &&
                facts.map(({ key, ...item }, idx) => (
                  <Col key={item.id} xs={6} lg={3}>
                    <FactCard title={key} {...item} />
                  </Col>
                ))}
            </Row>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col xs={12}>
            <Typography
              tag="h2"
              variant="h1"
              weight="bolder"
              align="center"
              color="white"
              text={language.chooseAServices}
            />
          </Col>
          <Col xs={12} className="">
            <ul className="d-flex flex-wrap justify-content-center">
              {categories.map((item, idx) => (
                <li key={item.id} className="bg-transparent m-0 p-0 border-0">
                  <Link
                    className="facts-service-link"
                    to={`/services/${item.machine_name}`}
                  >
                    <Typography
                      tag="span"
                      variant="body2"
                      weight="bolder"
                      className="text-capitalize"
                    >
                      {item.name}
                    </Typography>
                  </Link>
                  {idx < categories.length - 1 && (
                    <Typography
                      tag="span"
                      variant="body2"
                      weight="bolder"
                      color="white"
                      className="mx-2"
                    >
                      -
                    </Typography>
                  )}
                </li>
              ))}
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  ) : (
    <Skeleton
      xs={{
        width: "100%",
        height: 200,
      }}
      lg={{
        width: "100%",
        height: 420,
      }}
    />
  );
}

export default React.memo(OurFacts);

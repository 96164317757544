import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { API_ROUTES } from "constants";
import { checkLogin } from "store/auth";
import { useFetch } from "hooks";

function withContainer(WrappedCompoent) {
  return (props) => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const [notificationsOn, setNotificationsOn] = React.useState(
      Boolean(user.email_notifications) || false
    );

    const [{ loading }, request] = useFetch(
      {
        initialLoadingState: true,
        method: "POST",
        endpoint: API_ROUTES.editProfile,
        body: {
          ...user,
          place_id: user.place.id,
          email_notifications: notificationsOn ? 1 : 0,
        },
        onSuccess: () => {
          dispatch(checkLogin());
        },
      },
      [notificationsOn],
      false
    );

    const handleTurnOnOffNotifications = () => {
      setNotificationsOn(!notificationsOn);
    };

    return (
      <WrappedCompoent
        {...props}
        loading={loading}
        notificationsOn={notificationsOn}
        handleTurnOnOffNotifications={handleTurnOnOffNotifications}
      />
    );
  };
}
export default withContainer;

import useDeepCompareEffect from "use-deep-compare-effect";
import React, { useCallback, useEffect } from "react";
import _ from "lodash";
import classNames from "classnames";
import { DEFAULT_LATLNG, DEFAULT_MAPS_STYLES } from "constants";
import { Autocomplete, InputField } from "components";
import { useLanguage } from "hooks";
import "./style.scss";

function Map({
  onClick,
  onIdle,
  children,
  style,
  height,
  marker,
  center,
  centerOnUserLocation,
  onCenterChanged,
  getCenterDetails,
  onSuggestionChosen,
  autoComplete,
  ...options
}) {
  const translation = useLanguage();
  const ref = React.useRef(null);
  const [map, setMap] = React.useState(null);
  const [placesService, setPlacesService] = React.useState(null);
  const [geoCoder, setGeoCoder] = React.useState(null);
  const [currentCoords, setCurrentCoords] = React.useState({
    lat: DEFAULT_LATLNG.lat,
    lng: DEFAULT_LATLNG.lng,
  });

  useEffect(() => {
    console.log("currentCoords", currentCoords);
  }, [currentCoords]);

  React.useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}));
    }
    setPlacesService(
      new window.google.maps.places.PlacesService(document.createElement("div"))
    );
    setGeoCoder(new window.google.maps.Geocoder());
  }, [ref, map]);

  useDeepCompareEffect(() => {
    if (map) {
      map.setOptions({
        mapTypeId: window.google.maps.MapTypeId.ROADMAP,
        mapTypeControl: false,
        panControl: false,
        zoomControl: false,
        fullscreenControl: false,
        streetViewControl: false,
        styles: DEFAULT_MAPS_STYLES,
        zoom: 16,
        ...options,
        center: new window.google.maps.LatLng({
          lat: currentCoords.lat,
          lng: currentCoords.lng,
        }),
      });
    }
  }, [map, options]);

  React.useEffect(() => {
    if (map) {
      if (
        centerOnUserLocation &&
        navigator &&
        navigator.geolocation &&
        !center.lat
      ) {
        navigator.geolocation.getCurrentPosition((location) => {
          map.panTo(
            new window.google.maps.LatLng({
              lat: location.coords.latitude,
              lng: location.coords.longitude,
            })
          );
        });
      }
    }
  }, [map, center, centerOnUserLocation]);

  const handleCenterChanged = useCallback(async () => {
    const mapCenter = map.getCenter();
    if (onCenterChanged) {
      let formatted_address = "";
      if (getCenterDetails && mapCenter.lat() && mapCenter.lng()) {
        await geoCoder.geocode(
          {
            latLng: { lat: mapCenter.lat(), lng: mapCenter.lng() },
          },
          (res) => {
            if (res) {
              if (res[0].formatted_address) {
                formatted_address = res[0].formatted_address;
              }
            }
          }
        );
      }
      onCenterChanged({
        latlng: { lat: mapCenter.lat(), lng: mapCenter.lng() },
        formatted_address,
      });
    }
  }, [map, geoCoder, onCenterChanged, getCenterDetails]);

  const debouncedHandleCenterChanged = _.debounce(handleCenterChanged, 1000);

  React.useEffect(() => {
    if (map) {
      map.addListener("center_changed", debouncedHandleCenterChanged);

      return () => {
        new window.google.maps.event.clearListeners(map, "center_changed");
      };
    }
  }, [map, debouncedHandleCenterChanged]);

  React.useEffect(() => {
    if (center.lat) {
      setCurrentCoords({
        lat: parseFloat(center.lat),
        lng: parseFloat(center.lng),
      });
    }
  }, [center.lat, center.lng]);

  return (
    <div className="map-component">
      <div className="map-component__header">
        {autoComplete && (
          <Autocomplete
            as={InputField}
            placeholder={translation.searchForPlaces}
            suggestionBoxHeight={200}
            fetcher={async (val, cb) => {
              var data = [];
              var request = {
                radius: 5000,
                location: center,
                query: val,
                language: "ar",
              };
              await placesService.textSearch(request, (res) => {
                // console.log("res :>> ", res);
                if (res.length > 0) {
                  cb(res);
                }
              });
            }}
            onSuggestionClick={(suggestion) => {
              if (suggestion.place_id) {
                placesService.getDetails(
                  {
                    placeId: suggestion.place_id,
                    fields: ["name", "formatted_address", "geometry"],
                    language: "ar",
                  },
                  (res) => {
                    //  map animate to latlng
                    if (res.geometry.location) {
                      map.panTo(
                        new window.google.maps.LatLng({
                          lat: parseFloat(res.geometry.location.lat()),
                          lng: parseFloat(res.geometry.location.lng()),
                        })
                      );
                      if (onSuggestionChosen) {
                        onSuggestionChosen(res);
                      }
                    }
                  }
                );
              }
            }}
            labelSelector="name"
          />
        )}
      </div>
      <div
        ref={ref}
        id="map"
        className={classNames("map-component__map", {
          "map-component__map--with-marker": marker,
        })}
        style={{
          ...style,
          height: height || 300,
          width: "100%",
        }}
      >
        {React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            // set the map prop on the child component
            return React.cloneElement(child, { map });
          }
        })}
      </div>
    </div>
  );
}

export default React.memo(Map);

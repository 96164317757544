import React from "react";

function Marker({ onDragend, ...options }) {
  const [marker, setMarker] = React.useState(null);

  React.useEffect(() => {
    if (!marker) {
      setMarker(new window.google.maps.Marker());
    }

    // On unmount, we must remove marker from map
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  React.useEffect(() => {
    if (marker && onDragend) {
      var dragEndListener = marker.addListener("dragend", (e) => {
        return onDragend({
          lat: e.latLng.lat(),
          lng: e.latLng.lng(),
        });
      });
    }
    return () => {
      if (marker && onDragend) {
        window.google.maps.event.removeListener(dragEndListener);
      }
    };
  }, [marker, onDragend]);

  React.useEffect(() => {
    if (marker) {
      marker.setOptions({
        ...options,
        ...(options.position && {
          position: new window.google.maps.LatLng({
            lat: parseFloat(options.position.lat),
            lng: parseFloat(options.position.lng),
          }),
        }),
      });
    }
  }, [marker, options]);

  return null;
}

export default React.memo(Marker);

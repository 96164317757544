import React from "react";
import classNames from "classnames";
import {
  CopyrightSection,
  DetailsSection,
  SubscribeSection,
} from "./components";
import withContainer from "./Container";
import "./style.scss";

function Footer({
  footerMargin = true,
  websiteInformation,
  websiteSettings,
  socialMediaIcons,
  publicPages,
  paymentMethods,
  loading,
  registerField,
  handleSubmit,
  errors,
}) {
  return (
    <footer
      id="footer"
      className={classNames("footer d-none d-lg-block", {
        "mt-5": footerMargin,
      })}
    >
      {/* <div className="floating-actions">
        <div
          className="floating-actions__item"
          onClick={() => {
            if (window.Tawk_API) {
              window.Tawk_API.toggle();
            }
          }}
        >
          <Image src="/images/chat.png" />
        </div>
      </div> */}
      <SubscribeSection
        loading={loading}
        registerField={registerField}
        handleSubmit={handleSubmit}
        errors={errors}
      />
      <DetailsSection
        websiteInformation={websiteInformation}
        websiteSettings={websiteSettings}
        socialMediaIcons={socialMediaIcons}
        paymentMethods={paymentMethods}
      />
      <CopyrightSection
        websiteSettings={websiteSettings}
        publicPages={publicPages}
      />
    </footer>
  );
}

export default withContainer(React.memo(Footer));

export default function getAncestors(
  target,
  children,
  childrenSelector = "children",
  ancestors = []
) {
  for (let node of children) {
    if (node.id === target) {
      return ancestors.concat(node.id);
    }
    const found = getAncestors(
      target,
      node[childrenSelector],
      childrenSelector,
      ancestors.concat(node.id)
    );
    if (found) {
      return found;
    }
  }
  return undefined;
}

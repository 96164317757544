import { Button, InputField, Typography } from "components";
import { useLanguage } from "hooks";
import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import "./style.scss";

function StepTwo({ handleNextStep }) {
  const langunge = useLanguage();
  const [otp, setOTP] = React.useState("");

  const otpInput1 = React.useRef(null);
  const otpInput2 = React.useRef(null);
  const otpInput3 = React.useRef(null);
  const otpInput4 = React.useRef(null);

  const handleSetOtp = (val) => {
    if (val.length > 1 || isNaN(Number(val))) return;
    if (val === "") {
      if (otp.length === 4 && document.activeElement === otpInput4.current) {
        otpInput3.current.focus();
      }
      if (otp.length === 3 && document.activeElement === otpInput3.current) {
        otpInput2.current.focus();
      }
      if (otp.length === 2 && document.activeElement === otpInput2.current) {
        otpInput1.current.focus();
      }
      return setOTP(
        (oldValue) => `${oldValue.substring(0, oldValue.length - 1)}`
      );
    }
    if (otp.length === 0 && document.activeElement === otpInput1.current) {
      otpInput2.current.focus();
    }
    if (otp.length === 1 && document.activeElement === otpInput2.current) {
      otpInput3.current.focus();
    }
    if (otp.length === 2 && document.activeElement === otpInput3.current) {
      otpInput4.current.focus();
    }
    setOTP((oldValue) => `${oldValue}${val}`);
  };

  React.useEffect(() => {
    otpInput1.current.focus();
  }, []);

  return (
    <div className="step-one">
      <Row className="justify-content-center">
        <Col xs={12} xl={10}>
          <Typography
            tag="h1"
            className="fs-2"
            color="black"
            text={langunge.forgotPassword1}
          />
        </Col>
        <Col xs={12} xl={10}>
          <Typography
            tag="p"
            variant="body1"
            color="gray7"
            text={langunge.pleaseEnterCodeReceivedOnEmail}
          />
        </Col>
      </Row>
      <Row className="justify-content-center mt-2">
        <Col className="mt-4" xs={12} xl={10}>
          <Form>
            <Row>
              <Col xs={12}>
                <Typography
                  tag="label"
                  className="fs-6"
                  color="gray7"
                  text={langunge.otp}
                />
              </Col>
              <Col xs={12} className="mt-3">
                <div dir="ltr">
                  <Row>
                    <Col xs={3} dir="rtl">
                      <input
                        ref={otpInput1}
                        className="otp-input rounded-sm"
                        type="text"
                        min={0}
                        step={1}
                        onChange={(e) => handleSetOtp(e.target.value)}
                        value={otp.substring(0, 1)}
                        dir="ltr"
                      />
                    </Col>
                    <Col xs={3} dir="rtl">
                      <input
                        ref={otpInput2}
                        className="otp-input rounded-sm"
                        type="text"
                        min={0}
                        step={1}
                        onChange={(e) => handleSetOtp(e.target.value)}
                        value={otp.substring(1, 2)}
                        dir="ltr"
                      />
                    </Col>
                    <Col xs={3} dir="rtl">
                      <input
                        ref={otpInput3}
                        className="otp-input rounded-sm"
                        type="text"
                        min={0}
                        step={1}
                        onChange={(e) => handleSetOtp(e.target.value)}
                        value={otp.substring(2, 3)}
                        dir="ltr"
                      />
                    </Col>
                    <Col xs={3} dir="rtl">
                      <input
                        ref={otpInput4}
                        className="otp-input rounded-sm"
                        type="text"
                        min={0}
                        max={0}
                        step={1}
                        onChange={(e) => handleSetOtp(e.target.value)}
                        value={otp.substring(3, 4)}
                        dir="ltr"
                      />
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col xs={12} xl={10} className="mt-4">
          <Row className="justify-content-center">
            <Col xs={12} lg={6}>
              <Button
                type="submit"
                className="text-white py-3 w-100"
                variant="primary"
                onClick={handleNextStep}
                text={langunge.verify}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default StepTwo;

import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLanguage } from "hooks";
import { SkeletonGroup } from "components";
import { PackageCard } from "components/cards";
import Title from "components/Title";

function Packages({ loading, packages }) {
  const language = useLanguage();

  return (
    <div id="packages">
      <Container>
        {packages.length > 0 && (
          <Row>
            <Col xs={12}>
              <Title
                title={language.maintenancePackages}
                // description="Over 200,000 downloadable resources, with commercial license. Unlimited Downloads and more."
                color="black"
                lastColor="secondary"
                link="/packages"
              />
            </Col>
          </Row>
        )}
        <Row className="mt-4">
          {!loading ? (
            <Row className="g-3">
              {packages.map((item, idx) => (
                <Col key={idx} xs={6} sm={6} md={4} lg={4} xl={3}>
                  <PackageCard {...item} />
                </Col>
              ))}
            </Row>
          ) : (
            <Col xs={12}>
              <SkeletonGroup
                rows={1}
                width={"100%"}
                xs={{
                  count: 2,
                  height: 250,
                }}
                lg={{
                  count: 4,
                  height: 320,
                }}
              />
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
}

export default React.memo(Packages);

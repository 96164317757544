import React from "react";

const initialState = {
  visible: false,
  variant: "",
  canDismiss: () => {},
  onDismiss: () => {},
  icon: "",
  title: "",
  text: "",
  buttons: [],
};

const AlertContext = React.createContext();

export const AlertProvider = ({ children }) => {
  const [state, setState] = React.useState(initialState);

  const open = (variant, canDismiss, onDismiss, icon, title, text, buttons) => {
    setState({
      visible: true,
      canDismiss,
      onDismiss,
      variant,
      icon,
      title,
      text,
      buttons,
    });
  };

  const close = () => {
    setState(initialState);
  };

  return React.useMemo(() => {
    return (
      <AlertContext.Provider value={{ state, open, close }}>
        {children}
      </AlertContext.Provider>
    );
  }, [state, children]);
};

export function useAlert() {
  const { state, open, close } = React.useContext(AlertContext);

  const dispatch = (action) => {
    switch (action.type) {
      case "OPEN":
        open(
          action.payload.variant,
          action.payload.canDismiss,
          action.payload.onDismiss,
          action.payload.icon,
          action.payload.title,
          action.payload.text,
          action.payload.buttons
        );
        break;
      case "CLOSE":
        close();
        break;
      default:
        break;
    }
  };

  return [state, dispatch];
}

import { createSlice } from "@reduxjs/toolkit";
import {
  generateGuestId,
  checkLogin,
  basicLogin,
  register,
  verify,
  editProfile,
} from "./actions";

const initialState = {
  auth_checked: false,
  isLoggedIn: false,
  guestId: null,
  accessToken: null,
  user: null,
  loading: false,
};

const appSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.isLoggedIn = false;
      state.accessToken = "";
      state.user = null;
    },
  },
  extraReducers: {
    // Login Actions
    [generateGuestId.fulfilled]: (state, action) => {
      state.guestId = action.payload;
    },
    [basicLogin.pending]: (state) => {
      state.loading = true;
    },
    [basicLogin.fulfilled]: (state, action) => {
      state.user = action.payload.user;
      state.accessToken = action.payload.access_token;
      state.isLoggedIn = true;
      state.loading = false;
    },
    [basicLogin.rejected]: (state) => {
      state.loading = false;
    },
    [editProfile.pending]: (state) => {
      state.loading = true;
    },
    [editProfile.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [editProfile.rejected]: (state) => {
      state.loading = false;
    },
    // Check Login Actions
    [checkLogin.pending]: (state) => {
      // state.loading = true;
    },
    [checkLogin.fulfilled]: (state, action) => {
      state.user = action.payload;
      state.isLoggedIn = true;
      state.auth_checked = true;
      // state.loading = false;
    },
    [checkLogin.rejected]: (state) => {
      state.auth_checked = true;
      // state.loading = false;
    },

    // Check Register Actions
    [register.pending]: (state) => {
      state.loading = true;
    },
    [register.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
      state.accessToken = action.payload.access_token;
      state.loading = false;
    },
    [register.rejected]: (state) => {
      state.loading = false;
    },

    // verify
    [verify.pending]: (state) => {
      state.loading = true;
    },
    [verify.fulfilled]: (state, action) => {
      if (action.payload.user.verify) {
        state.isLoggedIn = true;
      }
      state.user = action.payload.user;
      state.loading = false;
    },
    [verify.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export {
  generateGuestId,
  basicLogin,
  checkLogin,
  register,
  verify,
  editProfile,
};
export const { logout } = appSlice.actions;
export default appSlice.reducer;

import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Heading, Layout, Typography, AddressForm } from "components";
import { useLanguage } from "hooks";

function CheckoutGuest(props) {
  const language = useLanguage();
  const navigate = useNavigate();

  return (
    <Layout footerMargin>
      <div id="cart-page" className="page_container">
        <section id="cart" className="mt-4">
          <Container>
            <Row className="mb-4">
              <Col xs={12}>
                <Heading title={language.myCart} />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Typography
                  tag="h1"
                  variant="h2"
                  color="black"
                  text={language.continueAsGuest}
                />
              </Col>
            </Row>
            <div className="mt-4">
              <AddressForm
                createAccountOption
                onAddressCreated={() => {
                  if (props.type === "subscription") {
                    return navigate("/checkout-subscription");
                  }
                  navigate("/checkout");
                }}
              />
            </div>
          </Container>
        </section>
      </div>
    </Layout>
  );
}
export default React.memo(CheckoutGuest);

import React from "react";
import { API_ROUTES } from "constants";
import { useFetch, useLanguage, usePagination } from "hooks";
import { useAlert } from "context";

function withContainer(WrappedCompoent) {
  return ({ fetchReviews, serviceId, ...props }) => {
    const [rate, setRate] = React.useState(20);
    const [comment, setComment] = React.useState("");
    const language = useLanguage();
    const { handleSetPage } = usePagination();
    const [_, dispatchAlert] = useAlert();

    const [{ loading }, request] = useFetch(
      {
        method: "POST",
        endpoint: API_ROUTES.addServiceReview,
        body: {
          product_id: serviceId,
          rate: rate / 20,
          comment: comment,
        },
        onSuccess: () => {
          setRate(20);
          setComment("");
          dispatchAlert({
            type: "OPEN",
            payload: {
              icon: "success",
              title: language.success,
              text: language.reviewSubmissionSuccess,
              canDismiss: true,
            },
          });
          fetchReviews();
          handleSetPage(1);
        },
      },
      [serviceId],
      false
    );

    const handleSetRate = (rate) => {
      setRate(rate);
    };

    const handleSetComment = (comment) => {
      setComment(comment);
    };

    const handleSubmit = () => {
      request();
    };

    return (
      <WrappedCompoent
        {...props}
        rate={rate}
        comment={comment}
        loading={loading}
        handleSetRate={handleSetRate}
        handleSetComment={handleSetComment}
        handleSubmit={handleSubmit}
      />
    );
  };
}
export default withContainer;

import React from "react";
import { Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useLanguage } from "hooks";
import { Button, InputField, Typography } from "components";
import "./style.scss";

function SubscribeSection({ loading, registerField, handleSubmit, errors }) {
  const language = useLanguage();
  const { serverTranslations } = useSelector((state) => state.common);

  return (
    <div className="footer-subscribe">
      <div className="content py-5">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} lg={8} xl={6}>
              <Typography
                tag="h2"
                variant="display1"
                weight="bold"
                align="center"
                color="white"
                className="text-capitalize"
              >
                {language.subscriptionViaEmail}
              </Typography>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs={12} lg={8} xl={6}>
              <Typography
                tag="p"
                variant="body2"
                weight="normal"
                align="center"
                color="white"
                overflow={2}
              >
                {serverTranslations.text_in_email_subscription}
              </Typography>
            </Col>
          </Row>
          <Row className="justify-content-center mt-4">
            <Col xs={12} lg={8} xl={6}>
              <Form onSubmit={handleSubmit}>
                <InputGroup className="subscription-form input-group ">
                  <InputField
                    type="email"
                    placeholder={language.emailAddress}
                    className="py-3"
                    {...registerField("email")}
                  />
                  <Button
                    type="submit"
                    variant="primary"
                    text={language.subscribe}
                    className="py-3"
                  />
                </InputGroup>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default React.memo(SubscribeSection);

const API_ROUTES = {
  // Common
  getFirstLevelPlaces: "getPlaces?parent_id=0",
  getHelpCenter: "getHelpCenter",
  getWebsiteSettings: "getSettings",
  getWebsiteInformation: "getCompanyLocationByPlace",
  getSocialMediaIcons: "getSocialMediaLinks",
  getPaymentMethodsByPlaceID: "getPaymentMethodsByPlaceID",
  getPublicPages: "getPublicPages",
  getOrderComplainReasons: "getOrderComplainReasons",
  getHearAboutUS: "getHearAboutUS",
  getTranslations: "getFrontendTranslations",
  getGroupAdvertisementsByMachineName: "getGroupAds",

  // Auth
  generateGuestId: "generateGuestID",
  basicLogin: "basicLogin",
  getUserInformation: "getUserInformation",
  register: "registerUser",
  verify: "verify",
  resend: "resend",
  editProfile: "editUserInformation",
  editUserPassword: "editUserPassword",
  addLocation: "addUserLocation",
  markUserLocationAsDefault: "markUserLocationAsDefault",
  deleteUserLocation: "deleteUserLocation",
  editUserLocation: "editUserLocation",
  getUserLocations: "getUserLocations",
  getUserLocationDetails: "getUserLocationDetails",
  getOrders: "getMyOrders",
  getOrderDetails: "getMyOrderDetails",
  getSubscriptionsOrders: "getSubscriptionsOrders",
  getSubscriptionOrderDetails: "getSubscriptionOrderDetails",
  getUserNotifications: "getNotifications",

  // Wishlist
  addRemoveWishlist: "addRemoveFavorites",
  getWishList: "getFavorites",
  getWishlistIds: "getFavoritesIDs",

  // Cart
  addRemoveCart: "addUpdateRemoveCart",
  getCart: "getCart",
  getCartIds: "getCartIDs",
  getCheckoutAmounts: "getCheckoutAmounts",
  getSubscriptionCheckoutAmounts: "getSubscriptionCheckoutAmounts",
  checkCoupon: "checkCouponIfValid",
  createOrder: "createOrderAsSeparated",
  createSubscriptionOrder: "createSubscriptionOrder",

  // Home
  getHomeSlides: "getSliderData",
  getCompanyServices: "getCompanyFeatures",
  getHomeVideo: "getVideos?show_one_only=1",
  getHomeCategories: "getCategories?limit=0&parent_id=0&is_featured=0",
  getHomeTopRatedServices: "getHomeTDRDeals?type=2",
  getHomeFacts: "getFacts",
  getHomePackages: "getSubscriptions",
  getHomeLatestOffers: "getHomeTDRDeals?type=3",
  getHomeTestimonials: "getHomeProductsReviews",
  getHomeAdvices: "getAdvices",

  // Inner Requests
  getPlaces: "getPlaces",
  getPublicPageDetails: "getPublicPageDetails",
  getCategories: "getCategories",
  getCategoryInfo: "getSingleCategoryInfoAndFilters",
  getCategoryServices: "getSingleCategoryProducts",
  getServiceDetails: "getProductDetails",
  getServiceReviews: "getProductReviews",
  getServiceRelatedProducts: "getProductRelated",
  addServiceReview: "addProductReview",
  getSearchResults: "getSearchProducts",
  getArticles: "getArticles",
  getArticleDetails: "getArticleDetails",
  getAdvicesWithPagination: "getAdvicesWithPagination",
  getAdvicesDetails: "getAdvicesDetails",
  submitContactForm: "submitContactForm",
  getSubscriptions: "getSubscriptions",
  getVideos: "getVideos",
  submitNewsletterEmail: "submitNewsletterEmail",
  getWalletPackages: "getWalletPackages",
  chargeUserWallet: "chargeUserWallet",
  uploadFile: "uploadFile",
  sendOrderComplain: "sendOrderComplain",
  getSubscriptionDetails: "getSubscriptionDetails",
  sendCallout: "sendCallout",
  getSubscriptionOrderHistory: "getSubscriptionOrderHistory",
  getTimeslotsByDayDate: "getTimeslotsByDayDate",
  getPlaceUserLocationFields: "getPlaceUserLocationFields",
  getUserOrderedProducts: "getUserOrderedProducts",

  // General
  getCalloutsTypes: "getCalloutsTypes",
  submitReturnRequest: "submitReturnRequest",
  submitCancelSubscriptionOrder: "submitCancelSubscriptionOrder",
  convertMyPointsToWallet: "convertMyPointsToWallet",
  getPlacesWithSubLevels: "getPlacesWithSubLevels",
};

export default API_ROUTES;

import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import classNames from "classnames";
import { useLanguage, useSkipFirstRender, useUpload } from "hooks";
import { Button, Typography } from "components";
import "./style.scss";
import { API_ROUTES } from "constants";

function DropZoneField({ label, onChange, error, ...props }) {
  const language = useLanguage();
  const [uploadedFiles, setUploadedFiles] = React.useState([]);
  const [file, setFile] = React.useState(null);
  const [isDragging, setIsDragging] = React.useState(false);
  const dropAreaRef = React.useRef(null);
  const fileInputRef = React.useRef(null);
  const [_, upload] = useUpload({
    endpoint: API_ROUTES.uploadFile,
  });

  const onAttachClick = () => {
    fileInputRef.current.click();
  };

  const onFileAttached = (e) => {
    if (!e.target.files || e.target.files.length === 0) return;
    setFile(e.target.files[0]);
    upload({
      body: {
        file: e.target.files[0],
      },
      onSuccess: (response) => {
        setUploadedFiles((prevState) => [...prevState, response.data]);
      },
      onError: () => {},
    });
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dropAreaRef.current.classList.add("dragging");
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const { files } = e.dataTransfer;

    if (!files || files.length === 0) return;
    setFile(files[0]);
  };

  React.useEffect(() => {
    let dropAreaRefValue = dropAreaRef.current; // <-- save ref value

    dropAreaRefValue.addEventListener("dragover", handleDragOver);
    dropAreaRefValue.addEventListener("drop", handleDrop);
    dropAreaRefValue.addEventListener("dragenter", handleDragEnter);
    dropAreaRefValue.addEventListener("dragleave", handleDragLeave);

    return () => {
      dropAreaRefValue.removeEventListener("dragover", handleDragOver);
      dropAreaRefValue.removeEventListener("drop", handleDrop);
      dropAreaRefValue.removeEventListener("dragenter", handleDragEnter);
      dropAreaRefValue.removeEventListener("dragleave", handleDragLeave);
    };
  }, []);

  useSkipFirstRender(() => {
    const handleOnChange = () => {
      if (onChange) onChange(uploadedFiles);
    };
    handleOnChange();
  }, [onChange, uploadedFiles]);

  return (
    <div className="drop-zone">
      <Row>
        <Col xs={12}>
          <Typography
            htmlFor="accomodation"
            tag="label"
            className={classNames("form-label text-capitalize")}
            variant="body2"
            color="gray7"
            text={label}
          />
        </Col>
        <Col xs={12}>
          <div
            ref={dropAreaRef}
            className={classNames("drop-zone-area form-control p-4", {
              "border-danger": error,
              "bg-gray4": isDragging,
            })}
          >
            <Row>
              <Col xs={12}>
                <div className="d-flex align-items-center justify-content-center">
                  <Typography
                    tag="span"
                    className="me-2"
                    text={language.dragDocuments}
                  />
                  <input
                    ref={fileInputRef}
                    type="file"
                    onChangeCapture={onFileAttached}
                    className="d-none"
                  />
                  <Button
                    onClick={onAttachClick}
                    variant="primary"
                    text={language.browseFiles}
                    style={{
                      zIndex: 100,
                    }}
                  />
                </div>
              </Col>
              {file && (
                <Col xs={12} className="text-center mt-2">
                  {file.name}
                </Col>
              )}
            </Row>
          </div>
        </Col>
        <Col xs={12}>
          {error && <Form.Text className="text-danger">{error}</Form.Text>}
        </Col>
      </Row>
    </div>
  );
}

export default React.memo(DropZoneField);

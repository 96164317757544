import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { Image, Typography } from "components";
import "./style.scss";
import { useLanguage } from "hooks";

function Card({ id, image_thumb, title, brief }) {
  const language = useLanguage();

  return (
    <div className="blog-card p-3 bg-gray3 rounded-xs border border-gray">
      <Row className="justify-content-center">
        <Col xs={12}>
          <Link to={`/article/${id}`}>
            <Image
              src={image_thumb}
              alt={title}
              className="w-100 rounded-xs"
              fromApi
            />
          </Link>
        </Col>
        <Col xs={12} className="mt-3">
          <Row className="align-items-center">
            <Col xs={12}>
              <Link to={`/article/${id}`}>
                <Typography
                  tag="h1"
                  variant="h4"
                  weight="bold"
                  color="secondary"
                  overflow={1}
                >
                  {title}
                </Typography>
              </Link>
            </Col>
            <Col xs={12}>
              {brief && (
                <Typography
                  tag="p"
                  variant="body2"
                  weight="normal"
                  overflow={5}
                  color="black"
                >
                  {brief.replace(/(<([^>]+)>)/gi, "")}
                </Typography>
              )}
            </Col>
            <Col xs={12} className="mt-2 text-end">
              <Link to={`/article/${id}`}>
                <Typography
                  tag="span"
                  variant="body2"
                  weight="bold"
                  color="primary"
                >
                  {language.readMore}
                </Typography>
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default React.memo(Card);

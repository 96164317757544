import React, { Fragment } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Heading, Layout, Skeleton, SkeletonGroup } from "components";
import { useEffect } from "react";

function ChatPage() {
  return (
    <Layout>
      <div id="CustomPage">
        <div id="chat-section"></div>
      </div>
    </Layout>
  );
}

export default React.memo(ChatPage);

import React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import classNames from "classnames";
import { Icon, Typography, Button } from "components";
import "./style.scss";

function StandardPrompt({
  canDismiss,
  visible,
  icon,
  title,
  text,
  buttons,
  handleButtonClick,
  handleClose,
}) {
  if (!visible) return null;
  return (
    <Modal
      show={visible}
      {...(canDismiss && { onHide: handleClose })}
      centered
      contentClassName="prompt-modal-standard"
    >
      <Modal.Header closeButton className="border-0 pb-0" />
      <Modal.Body className="pt-0">
        <Row>
          <Col xs={12} className="text-center">
            <Icon name={icon} className="prompt-icon text-primary" />
          </Col>
          <Col xs={12}>
            <Typography
              tag="h1"
              variant="h2"
              weight="bold"
              align="center"
              color="primary"
            >
              {title}
            </Typography>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Typography
              tag="p"
              variant="body1"
              weight="bold"
              align="center"
              color="black"
            >
              {text}
            </Typography>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="d-block border-0">
        <ul className="list-unstyled d-flex align-items-center justify-content-center">
          {buttons &&
            buttons.length > 0 &&
            buttons.map(({ variant, text, onClick }, idx) => (
              <li
                key={idx}
                className={classNames({
                  "ms-3": idx > 0,
                })}
              >
                <Button
                  onClick={() => handleButtonClick(onClick)}
                  variant={variant}
                  text={text}
                />
              </li>
            ))}
        </ul>
      </Modal.Footer>
    </Modal>
  );
}

export default React.memo(StandardPrompt);

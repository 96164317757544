import { usePagination, useSkipFirstRender } from "hooks";
import React from "react";

function withContainer(WrappedCompoent) {
  return ({
    as,
    items,
    loading,
    meta,
    horizontal,
    appliedFilters,
    ...props
  }) => {
    const { handleSetPage, onNext } = usePagination();
    const observer = React.useRef();
    const [savedData, setSavedData] = React.useState([]);
    const { page } = usePagination();

    const lastItemElement = React.useCallback(
      (node) => {
        if (loading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver((entries) => {
          let hasMore =
            +savedData.length < +meta.total && +meta.last_page > +page;
          if (entries[0].isIntersecting && hasMore) {
            onNext();
          }
        });
        if (node) observer.current.observe(node);
      },
      [loading, meta, savedData, onNext]
    );

    useSkipFirstRender(() => {
      if (+page > 1) {
        setSavedData((prevState) => [...prevState, ...items]);
      }
    }, [setSavedData, items]);

    React.useEffect(() => {
      if (+page === 1) {
        setSavedData(items);
      }
    }, [setSavedData, items]);

    useSkipFirstRender(() => {
      setSavedData([]);
    }, [appliedFilters]);

    React.useEffect(() => {
      if (page > 1) {
        handleSetPage(1);
      }
    }, [handleSetPage]);

    return (
      <WrappedCompoent
        {...{
          componentToRender: as,
          items: savedData,
          pagination: {
            count: meta && meta.total,
            perPage: meta && meta.per_page,
          },
          horizontal,
          lastItemElement,
          loading,
          ...props,
        }}
      />
    );
  };
}
export default withContainer;

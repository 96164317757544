import React, { Suspense } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import {
  getHelpCenter,
  getOrderComplainReasons,
  getHearAboutUS,
  getPaymentMethodsByPlaceID,
  getPublicPages,
  getSocialMediaIcons,
  getWebsiteInformation,
  getWebsiteSettings,
  getCities,
} from "store/common";
import { getFirstLevelPlaces, setAppLoading } from "store/app";
import { generateGuestId, checkLogin } from "store/auth";
import { getWishlistIds } from "store/wishlist";
import { getCartIds } from "store/cart";
import {
  useLanguage,
  useLanguageEffect,
  useMediaQuery,
  useSkipFirstRender,
} from "./hooks";
import {
  Footer,
  Header,
  Image,
  Layout,
  MobileBottomNav,
  Prompt,
  TopProgressBar,
} from "components";
import { Account } from "pages";
import "./assets/styles/App.scss";
import "react-loading-skeleton/dist/skeleton.css";
import "react-modal-video/scss/modal-video.scss";
import "nprogress/nprogress.css";
import { setHomeLoaded } from "store/home";
import PackageDetailsPage from "pages/Package/Details";
import { useEffect } from "react";
import {
  getCalloutsTypes,
  getPlacesWithSubLevels,
  getTranslations,
  getAdvertisements,
} from "store/common/actions";

const Home = React.lazy(() => import("pages/Home"));
const Chat = React.lazy(() => import("pages/Chat"));
const Custom = React.lazy(() => import("pages/Custom"));
const NotFound = React.lazy(() => import("pages/NotFound"));
const About = React.lazy(() => import("pages/About"));
const ContactUs = React.lazy(() => import("pages/ContactUs"));
const BlogList = React.lazy(() => import("pages/Blog/List"));
const BlogDetails = React.lazy(() => import("pages/Blog/Details"));
const AdviceList = React.lazy(() => import("pages/Advice/List"));
const AdviceDetails = React.lazy(() => import("pages/Advice/Details"));
const Videos = React.lazy(() => import("pages/Videos"));

const Register = React.lazy(() => import("pages/Register"));
const Login = React.lazy(() => import("pages/Login"));
const ForgotPassword = React.lazy(() => import("pages/ForgotPassword"));
const Wishlist = React.lazy(() => import("pages/Wishlist"));
const Cart = React.lazy(() => import("pages/Cart"));
const CheckoutGuest = React.lazy(() => import("pages/CheckoutGuest"));
const Checkout = React.lazy(() => import("pages/Checkout"));

const LatestOffers = React.lazy(() => import("pages/LatestOffers"));
const OurServices = React.lazy(() => import("pages/OurServices"));
const Categories = React.lazy(() => import("pages/Categories"));
const Search = React.lazy(() => import("pages/Search"));

const ServiceDetails = React.lazy(() => import("pages/Service/Details"));
const ServiceList = React.lazy(() => import("pages/Service/List"));
const TopRatedServicesPage = React.lazy(() => import("pages/Service/TopRated"));
const PackageList = React.lazy(() => import("pages/Package/List"));

function App() {
  const { loading: appLoading } = useSelector((state) => state.app);
  const { auth_checked, isLoggedIn, guestId, accessToken } = useSelector(
    (state) => state.auth
  );
  const { subscriptionId } = useSelector((state) => state.cart);
  const cartIds = useSelector((state) => state.cart.ids);
  const { pathname, ...rest } = useLocation();
  const language = useLanguage();
  const dispatch = useDispatch();
  const matchesMobile = useMediaQuery("(max-width:992px)");

  useEffect(() => {
    if (matchesMobile) {
      if (window.Tawk_API) {
        window.Tawk_API.onLoad = function () {
          window.Tawk_API.hideWidget();
        };
      }
    }
  }, [matchesMobile]);

  useEffect(() => {
    const reachFooterListener = () => {
      // if footer is visible
      // get footer height
      const footer = document.getElementById("footer");
      const footerHeight = footer.getBoundingClientRect().height;
      if (
        document.body.scrollHeight -
          window.innerHeight -
          document.documentElement.scrollTop <
        footerHeight
      ) {
        if (window.Tawk_API) {
          window.Tawk_API.hideWidget();
        }
      } else {
        if (window.Tawk_API && !pathname.startsWith("/service/")) {
          window.Tawk_API.showWidget();
        }
      }
    };

    window.addEventListener("scroll", reachFooterListener);
    return () => {
      window.removeEventListener("scroll", reachFooterListener);
    };
  }, []);

  React.useLayoutEffect(() => {
    document.documentElement.setAttribute("lang", language.direction);
    document.documentElement.setAttribute("dir", language.direction);
  }, [language]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useLanguageEffect(() => {
    dispatch(setAppLoading(true));
    dispatch(setHomeLoaded(false));
    dispatch(checkLogin());
    if (!accessToken && !guestId) {
      dispatch(generateGuestId());
    }

    Promise.all([dispatch(getFirstLevelPlaces())]).then(() => {
      Promise.all([
        dispatch(getTranslations()),
        dispatch(getCities()),
        dispatch(getWishlistIds()),
        dispatch(getCartIds()),
        dispatch(getWebsiteInformation()),
        dispatch(getCalloutsTypes()),
        dispatch(getWebsiteSettings()),
        dispatch(getHelpCenter()),
        dispatch(getSocialMediaIcons()),
        dispatch(getPaymentMethodsByPlaceID()),
        dispatch(getPublicPages()),
        dispatch(getOrderComplainReasons()),
        dispatch(getHearAboutUS()),
        dispatch(getPlacesWithSubLevels()),
        dispatch(getAdvertisements()),
      ]).then(() => {
        dispatch(setAppLoading(false));
      });
    });
  }, [dispatch, accessToken, guestId]);

  if (appLoading)
    return (
      <div className="d-flex flex-column bg-light3 min-vh-100 align-items-center justify-content-center">
        <div>
          <Image src="/images/website-logo.svg" />
        </div>
        <div className="d-flex align-items-center justify-content-center mt-4">
          <Spinner animation="grow" className="bg-primary me-2" />
          <Spinner animation="grow" className="bg-secondary me-2" />
          <Spinner animation="grow" className="bg-secondary" />
        </div>
      </div>
    );

  return (
    <div id="app">
      <Prompt />
      <Suspense
        fallback={
          <React.Fragment>
            <TopProgressBar />
          </React.Fragment>
        }
      >
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/chat" element={<Chat />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/packages" element={<PackageList />} />
          <Route path="/packages/:packageId" element={<PackageDetailsPage />} />
          <Route path="/latest-offers" element={<LatestOffers />} />
          <Route path="/our-services" element={<OurServices />} />
          <Route path="/category/:id" element={<Categories />} />
          <Route path="/services/:machineName" element={<ServiceList />} />
          <Route
            path="/services/top-rated"
            element={<TopRatedServicesPage />}
          />
          <Route path="/service/:machineName" element={<ServiceDetails />} />
          <Route path="/articles" element={<BlogList />} />
          <Route path="/article/:articleId" element={<BlogDetails />} />
          <Route path="/advices" element={<AdviceList />} />
          <Route path="/advices/:id" element={<AdviceDetails />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/videos" element={<Videos />} />
          <Route path="/favourite" element={<Wishlist />} />
          <Route path="/page/:machineName" element={<Custom />} />
          <Route path="/search/:keyword" element={<Search />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />

          <Route path="/account/mobile" element={<Account.Mobile />} />

          <Route
            path="/account"
            element={
              auth_checked &&
              (isLoggedIn ? (
                <Navigate to="/account/edit" />
              ) : (
                <Navigate to="/" />
              ))
            }
          />

          <Route
            path="/account/edit"
            element={
              auth_checked &&
              (isLoggedIn ? <Account.EditProfile /> : <Navigate to="/" />)
            }
          />
          <Route
            path="/account/orders"
            element={
              auth_checked &&
              (isLoggedIn ? <Account.OrderList /> : <Navigate to="/" />)
            }
          />
          <Route
            path="/account/order/:orderReference"
            element={
              auth_checked &&
              (isLoggedIn ? <Account.Order /> : <Navigate to="/" />)
            }
          />
          <Route
            path="/account/subscriptions"
            element={
              auth_checked &&
              (isLoggedIn ? <Account.Subscription.List /> : <Navigate to="/" />)
            }
          />
          <Route
            path="/account/subscription/:orderReference"
            element={
              auth_checked &&
              (isLoggedIn ? (
                <Account.Subscription.Details />
              ) : (
                <Navigate to="/" />
              ))
            }
          />
          <Route
            path="/account/subscription/request/:orderReference"
            element={
              auth_checked &&
              (isLoggedIn ? (
                <Account.Subscription.Request />
              ) : (
                <Navigate to="/" />
              ))
            }
          />
          <Route
            path="/account/subscription/history/:orderReference"
            element={
              auth_checked &&
              (isLoggedIn ? (
                <Account.Subscription.History />
              ) : (
                <Navigate to="/" />
              ))
            }
          />
          <Route
            path="/account/notifications"
            element={
              auth_checked &&
              (isLoggedIn ? <Account.Notifications /> : <Navigate to="/" />)
            }
          />
          <Route
            path="/account/change-password"
            element={
              auth_checked &&
              (isLoggedIn ? <Account.ChangePassword /> : <Navigate to="/" />)
            }
          />
          <Route
            path="/account/addresses"
            element={
              auth_checked &&
              (isLoggedIn ? <Account.Address.List /> : <Navigate to="/" />)
            }
          />
          <Route
            path="/account/address/create"
            element={
              auth_checked &&
              (isLoggedIn ? <Account.Address.Create /> : <Navigate to="/" />)
            }
          />
          <Route
            path="/account/address/edit/:addressId"
            element={
              auth_checked &&
              (isLoggedIn ? <Account.Address.Edit /> : <Navigate to="/" />)
            }
          />
          <Route
            path="/account/points"
            element={
              auth_checked &&
              (isLoggedIn ? <Account.Points /> : <Navigate to="/" />)
            }
          />
          <Route
            path="/account/wallet"
            element={
              auth_checked &&
              (isLoggedIn ? <Account.Wallet /> : <Navigate to="/" />)
            }
          />

          <Route
            path="/login"
            element={
              auth_checked && (isLoggedIn ? <Navigate to="/" /> : <Login />)
            }
          />
          <Route
            path="/register"
            element={
              auth_checked && (isLoggedIn ? <Navigate to="/" /> : <Register />)
            }
          />
          <Route
            path="/pre-checkout"
            element={
              cartIds.length === 0 ? (
                <Navigate to="/" />
              ) : (
                <CheckoutGuest type="cart" />
              )
            }
          />

          <Route
            path="/pre-checkout-subscription"
            element={
              subscriptionId === null ? (
                <Navigate to="/" />
              ) : (
                <CheckoutGuest type="subscription" />
              )
            }
          />
          <Route
            path="/checkout"
            element={
              cartIds.length === 0 ? (
                <Navigate to="/" />
              ) : (
                <Checkout type="cart" />
              )
            }
          />

          <Route
            path="/checkout-subscription"
            element={
              subscriptionId === null ? (
                <Navigate to="/" />
              ) : (
                <Checkout type="subscription" />
              )
            }
          />

          <Route path="*" element={<NotFound />} />
        </Routes>
        <MobileBottomNav />
        <Footer />
      </Suspense>
    </div>
  );
}

export default App;

import React from "react";
import debounce from "lodash.debounce";
import { API_ROUTES } from "constants";
import { useFetch, useFilter, usePagination, useSkipFirstRender } from "hooks";
import { useEffect } from "react";

function withContainer(WrappedCompoent) {
  return (props) => {
    const { page, handleSetPage } = usePagination();
    const { applyFilters, selectedFilters, toggleFilter, resetFilters } =
      useFilter({
        status: "",
        from_date: "",
        to_date: "",
        search_key: "",
      });
    const [{ loading, data, meta }, request] = useFetch(
      {
        initialLoadingState: true,
        method: "POST",
        endpoint: API_ROUTES.getOrders,
        params: `?page=${page}`,
        body: {
          status: selectedFilters["status"],
          from_date: selectedFilters["from_date"],
          to_date: selectedFilters["to_date"],
          search_key: selectedFilters["search_key"],
          isActive: true,
          isHistory: false,
        },
      },
      [page],
      false
    );

    const debouncedSearchKeyChange = React.useCallback(
      debounce((val) => toggleFilter("search_key", val, false), 2000),
      []
    );

    useSkipFirstRender(() => {
      handleSetPage(1);
    }, [selectedFilters]);

    useEffect(() => {
      request();
    }, [selectedFilters]);

    const handleResetFilters = () => {
      handleSetPage(1);
      resetFilters();
    };

    return (
      <WrappedCompoent
        loading={loading}
        debouncedSearchKeyChange={debouncedSearchKeyChange}
        toggleFilter={toggleFilter}
        handleResetFilters={handleResetFilters}
        data={data}
        meta={meta}
      />
    );
  };
}
export default withContainer;

import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useLanguage } from "hooks";
import { Typography, Button, Image } from "components";
import "./style.scss";

function OfferCard({
  machine_name,
  title,
  subtitle,
  price: oldPrice,
  price_after_discount: price,
  discount_percentage: discount,
  type,
  main_image,
  currency,
}) {
  const language = useLanguage();

  return (
    <div className="offer-card p-1 p-md-2 bg-gray3 rounded-xs border border-secondary">
      <div className="bg-secondary rounded-xs overflow-hidden h-100">
        <Row className="h-100">
          <Col xs={6}>
            <div className="py-3 px-2 h-100">
              <Row className="h-100">
                <Col xs={12}>
                  <Row>
                    <Col xs={12}>
                      <Typography
                        tag="h3"
                        variant="h2"
                        weight="bolder"
                        align="center"
                        color="white"
                        overflow={1}
                      >
                        <Link to={`/service/${machine_name}`}>{title}</Link>
                      </Typography>
                    </Col>
                    <Col xs={12} className="mt-2">
                      <Typography
                        tag="h3"
                        variant="h1"
                        weight="bolder"
                        align="center"
                        color="primary"
                      >
                        {discount}% {language.off}
                      </Typography>
                    </Col>
                    {/* <Col xs={12} className="mt-2">
                      <Typography
                        tag="h3"
                        variant="h4"
                        weight="bold"
                        align="center"
                        color="white"
                        overflow={1}
                      >
                        {type}
                      </Typography>
                    </Col> */}
                    <Col xs={12} className="mt-2">
                      <div className="d-block d-md-flex justify-content-center align-items-center text-center">
                        {price && (
                          <Typography
                            tag="h3"
                            variant="h4"
                            weight="bolder"
                            align="center"
                            color="primary"
                            className="me-2"
                          >
                            {price} {language.website_currency}
                          </Typography>
                        )}

                        {oldPrice && (
                          <Typography
                            tag="span"
                            variant="h6"
                            weight="nomral"
                            color="gray5"
                            className="strikethrough"
                          >
                            {oldPrice} {language.website_currency}
                          </Typography>
                        )}
                      </div>
                    </Col>
                    <Col xs={12} className="mt-2">
                      <Typography
                        tag="p"
                        variant="body2"
                        weight="normal"
                        align="center"
                        color="white"
                        overflow={2}
                      >
                        {subtitle}
                      </Typography>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} className="align-self-end">
                  <div className="px-3 px-lg-4">
                    <Button
                      as={Link}
                      to={`/service/${machine_name}`}
                      variant="primary"
                      className={`w-100 rounded-md`}
                      text={language.views}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col xs={6} className="h-100">
            <Link to={`/service/${machine_name}`}>
              <Image src={main_image} alt="offer" fromApi />
            </Link>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default React.memo(OfferCard);

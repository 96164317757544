import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import classNames from "classnames";
import { Typography } from "components";
import { useLanguage } from "hooks";

const SelectFieldWithQtyAsSelect = React.forwardRef(
  (
    {
      id,
      name,
      label,
      labelType = "formLabel",
      selectLabel,
      qtyLabel,
      labelClassName,
      className,
      placeholder,
      value,
      onChange,
      defaultValue,
      error,
      labelColor = "gray7",
      iconColor,
      icon,
      children,
      min,
      max,
      ...props
    },
    ref
  ) => {
    const translation = useLanguage();
    const quantityChangeHandler = (val) => {
      if (value.value) {
        onChange({
          value: `${value.value}`,
          qty: val,
        });
      }
    };

    const onChangeHandler = (e) => {
      onChange({
        value: `${e.target.value}`,
        qty: value.qty,
      });
    };

    return (
      <Row>
        <Col xs={12}>
          <Typography
            htmlFor={id}
            tag="label"
            variant="body2"
            className={classNames("form-label text-capitalize", {
              [`${labelClassName}`]: labelClassName,
              [`text-${labelColor}`]: labelColor,
            })}
            text={label}
          />
        </Col>
        <Col xs={12}>
          <div className="">
            <table
              className="table table-bordered m-0"
              style={{
                tableLayout: "fixed",
              }}
            >
              <tbody
                style={{
                  borderTopWidth: 0,
                }}
              >
                <tr>
                  <td
                    className="p-0 m-0 h-100"
                    style={{
                      width: "60%",
                      minWidth: "60%",
                      verticalAlign: "middle",
                    }}
                  >
                    <div className="d-flex align-items-center h-100 bg-primaryx">
                      <Typography
                        variant="body2"
                        weight="bold"
                        color="black"
                        text={selectLabel}
                      />
                    </div>
                  </td>
                  <td
                    style={{
                      width: "40%",
                      minWidth: "40%",
                    }}
                  >
                    <Form.Select
                      ref={ref}
                      id={id}
                      name={name}
                      onChange={onChangeHandler}
                      {...(placeholder && {
                        defaultValue: "",
                      })}
                      placeholder={placeholder}
                      defaultValue={placeholder ? "" : defaultValue}
                      className={classNames("", {
                        [`${className}`]: className,
                        "is-invalid": error,
                      })}
                      {...props}
                    >
                      <option value="">{translation.pleaseSelect}</option>
                      {children}
                    </Form.Select>
                  </td>
                </tr>
                <tr>
                  <td
                    className="p-0 m-0 h-100"
                    style={{
                      width: "60%",
                      minWidth: "60%",
                      verticalAlign: "middle",
                    }}
                  >
                    <div className="d-flex align-items-center h-100 bg-primaryx">
                      <Typography
                        variant="body2"
                        weight="bold"
                        color="black"
                        text={qtyLabel}
                      />
                    </div>
                  </td>
                  <td
                    style={{
                      width: "40%",
                      minWidth: "40%",
                    }}
                  >
                    <Form.Select
                      ref={ref}
                      id={id}
                      name={name}
                      onChange={(e) => quantityChangeHandler(e.target.value)}
                      defaultValue=""
                      value={value.qty}
                      disabled={!value.value}
                      className={classNames("", {
                        [`${className}`]: className,
                        "is-invalid": error,
                      })}
                      {...props}
                    >
                      <option value="">{translation.pleaseSelect}</option>
                      {Array.from(
                        { length: max - min + 1 },
                        (_, i) => i + min
                      ).map((item) => (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      ))}
                    </Form.Select>
                    {/* <QuantityField
                      onChange={quantityChangeHandler}
                      value={value.qty}
                      min={min}
                      max={max}
                      disabled={!value.value}
                    /> */}
                    {error && (
                      <Form.Text className="text-danger">{error}</Form.Text>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Col>
        <Col xs={12}>
          {error && (
            <Form.Text className="text-danger">{error.message}</Form.Text>
          )}
        </Col>
      </Row>
    );
  }
);
export default React.memo(SelectFieldWithQtyAsSelect);

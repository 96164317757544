import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { API_ROUTES } from "constants";
import { checkLogin } from "store/auth";
import { useAlert } from "context";
import { useFetch, useLanguage } from "hooks";

function withContainer(WrappedCompoent) {
  return (props) => {
    const language = useLanguage();
    const dispatch = useDispatch();
    const [_, dispatchAlert] = useAlert();
    const { user } = useSelector((state) => state.auth);

    const [{ loading, data }] = useFetch(
      {
        initialLoadingState: true,
        endpoint: API_ROUTES.getWalletPackages,
      },
      []
    );

    const schema = yup.object().shape(
      {
        charge_amount: yup.string().required(language.chargeAmountRequired),
      },
      []
    );
    const {
      register: registerField,
      handleSubmit,
      formState: { errors },
      watch,
      setValue,
      reset,
    } = useForm({
      defaultValues: {
        package_id: "",
      },
      resolver: yupResolver(schema),
    });
    const values = watch();

    const [{ loading: loadingChargeWallet }, requestChargeWallet] = useFetch(
      {
        method: "POST",
        endpoint: API_ROUTES.chargeUserWallet,
        body: {
          charge_amount: values.charge_amount,
        },
        onSuccess: () => {
          reset();
          dispatch(checkLogin());
          dispatchAlert({
            type: "OPEN",
            payload: {
              icon: "success",
              title: language.success,
              text: language.userWalletChargedSuccess,
              canDismiss: true,
            },
          });
        },
        onError: (err) => {
          dispatchAlert({
            type: "OPEN",
            payload: {
              icon: "attention",
              title: language.error,
              text: err,
              canDismiss: true,
            },
          });
        },
      },
      [],
      false
    );

    const [
      { loading: loadingChargeWalletPackage },
      requestChargeWalletPackage,
    ] = useFetch(
      {
        method: "POST",
        endpoint: API_ROUTES.chargeUserWallet,
        body: {
          package_id: values.package_id,
          charge_amount:
            data && data.length > 0 && values.package_id
              ? data.find((item) => item.id === values.package_id).charge_amount
              : null,
        },
        onSuccess: () => {
          reset();
          dispatch(checkLogin());
          dispatchAlert({
            type: "OPEN",
            payload: {
              icon: "success",
              title: language.success,
              text: language.userWalletChargedSuccess,
              canDismiss: true,
            },
          });
        },
      },
      [values.package_id],
      false
    );

    const handleSubmitData = () => {
      requestChargeWallet();
    };

    return (
      <WrappedCompoent
        loading={loading}
        data={data}
        user={user}
        registerField={registerField}
        setValue={setValue}
        errors={errors}
        loadingChargeWallet={loadingChargeWallet}
        handleSubmit={handleSubmit(handleSubmitData)}
        requestChargeWalletPackage={requestChargeWalletPackage}
      />
    );
  };
}
export default withContainer;

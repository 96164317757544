import React from "react";
import { API_ROUTES } from "constants";
import { useFetch } from "hooks";

function withContainer(WrappedComponent) {
  return (props) => {
    const [{ data, loading }] = useFetch(
      {
        endpoint: API_ROUTES.getPublicPageDetails,
        params: `?machine_name=about_us`,
      },
      [],
      true
    );

    return (
      <WrappedComponent
        loading={loading}
        name={!loading && data && data.name}
        text={!loading && data && data.text}
      />
    );
  };
}
export default withContainer;

import React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import classNames from "classnames";
import { Icon, Typography, Button } from "components";
import "./style.scss";

function FullScreenPrompt({
  canDismiss,
  visible,
  icon,
  title,
  text,
  buttons,
  handleClose,
  handleButtonClick,
}) {
  if (!visible) return null;
  return (
    <Modal
      show={visible}
      {...(canDismiss && { onHide: handleClose })}
      contentClassName="prompt-modal-fullscreen bg-transparent border-0 my-0"
      backdropClassName="prompt-modal-fullscreen__backdrop my-0"
      dialogClassName="my-0"
      className="my-0"
      centered
    >
      <Modal.Body>
        <Row>
          <Col xs={12} className="text-center">
            <Icon name={icon} className="prompt-icon text-primary" />
          </Col>
          <Col xs={12} className="mt-4">
            <Typography
              tag="h1"
              variant="h2"
              weight="bold"
              align="center"
              color="secondary"
            >
              {title}
            </Typography>
          </Col>
        </Row>
        <Row className="justify-content-center mt-4">
          <Col xs={8}>
            <Typography
              tag="p"
              variant="body1"
              weight="bold"
              align="center"
              color="black"
            >
              {text}
            </Typography>
          </Col>
          {buttons &&
            buttons.length > 0 &&
            buttons.map(({ variant, text, onClick }, idx) => (
              <Col key={idx} xs={8} className={classNames("mt-4", {})}>
                <Button
                  onClick={() => handleButtonClick(onClick)}
                  variant={variant}
                  className="w-100 py-3"
                  text={text}
                />
              </Col>
            ))}
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default React.memo(FullScreenPrompt);

import SubscriptionListPage from "./List";
import SubscriptionDetailsPage from "./Details";
import SubscriptionRequesCalloutPage from "./Request";
import SubscriptionHistoryPage from "./History";

const Subscription = {
  List: SubscriptionListPage,
  Details: SubscriptionDetailsPage,
  Request: SubscriptionRequesCalloutPage,
  History: SubscriptionHistoryPage,
};

export default Subscription;

import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Col, Row } from "react-bootstrap";
import { Typography } from "components";
import "./style.scss";
import moment from "moment";
import { useLanguage } from "hooks";

const ScrollableList = ({ values, onChange }) => {
  const [activeIdx, setActiveIdx] = React.useState(0);

  React.useEffect(() => {
    if (values.length > 0 && onChange) {
      onChange(values[activeIdx]);
    }
  }, [activeIdx]);

  return (
    <Swiper
      direction={"vertical"}
      loop={true}
      slidesPerView={3}
      centeredSlides={true}
      className="mySwiper"
      onSlideChange={({ realIndex }) => setActiveIdx(Number(realIndex))}
      grabCursor={true}
    >
      <hr style={{ height: "2px" }} className="text-gray5" />

      {values.map((item, idx) => (
        <SwiperSlide key={item.id}>
          <Typography
            tag="span"
            variant={activeIdx === idx ? "h4" : "h6"}
            color={activeIdx === idx ? "secondary" : "gray5"}
            text={`${moment(item.from_time, "HH:mm").format("HH:mm")}`}
            // text={`${moment(item.from_time, "HH:mm").format(
            //   "HH:mm"
            // )} - ${moment(item.to_time, "HH:mm").format("HH:mm")}`}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

function TimePicker({ availableHours, onChange }) {
  const translation = useLanguage();

  return (
    <div className="time-picker border border-gray4 py-2 px-5">
      <Row>
        <Col xs={12}>
          <Typography
            tag="h6"
            variant="h6"
            weight="bold"
            className="text-center"
            text={translation.chooseTime}
          />
        </Col>
      </Row>
      <hr style={{ height: "2px" }} className="text-gray6 mt-1" />

      <Row className="justify-content-center">
        <Col xs={7} sm={10} md={7} lg={10} xl={7}>
          <Row className="">
            <Col xs={12} className="float-end px-0">
              <ScrollableList values={availableHours} onChange={onChange} />
            </Col>
            {/* <Col xs={6} className="float-start px-0">
              <ScrollableList
                values={[
                  "01",
                  "02",
                  "03",
                  "04",
                  "05",
                  "06",
                  "07",
                  "08",
                  "09",
                  "10",
                ]}
              />
            </Col> */}
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default React.memo(TimePicker);

import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useLanguage } from "hooks";
import { Image, Typography } from "components";
import "./style.scss";

function AdviceCard({ id, title, text, image }) {
  const language = useLanguage();

  return (
    <div className="advice-card bg-gray3 rounded-xs h-100">
      <Row>
        <Col xs={12} lg={6}>
          <div className="img_container">
            <Image src={image} alt={title} fromApi />
          </div>
        </Col>
        <Col xs={12} lg={6}>
          <div className="py-3 px-3 px-lg-0">
            <Row className="align-items-center px-1 px-lg-2">
              <Col xs={12} className="mb-2">
                <Typography
                  tag="h3"
                  variant="h4"
                  weight="bolder"
                  align="center"
                  color="secondary"
                  overflow={2}
                >
                  <Link to={`/advices/${id}`}>{title}</Link>
                </Typography>
              </Col>
              <Col xs={12}>
                <Typography
                  tag="p"
                  variant="body1"
                  weight="normal"
                  color="gray7"
                  overflow={8}
                >
                  {text}..{" "}
                  <Link to={`/advices/${id}`}>
                    <Typography
                      tag="span"
                      variant="body2"
                      weight="bold"
                      color="primary"
                    >
                      {language.readMore}
                    </Typography>
                  </Link>
                </Typography>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default React.memo(AdviceCard);

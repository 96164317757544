import React from "react";
import {
  Button,
  DropZoneField,
  InputField,
  SelectField,
  TextAreaField,
  Typography,
} from "components";
import { useLanguage } from "hooks";
import { Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import withContainer from "./Container";
import "./style.scss";

function CancelSubscriptionOrderModal({
  visible,
  handleClose,
  loading,
  registerField,
  control,
  errors,
  handleSubmit,
  cancelComment = "",
}) {
  const translation = useLanguage();
  const { calloutsTypes: types } = useSelector((state) => state.common);

  return (
    <div>
      <Modal
        show={visible}
        onHide={handleClose}
        dialogClassName="request-callout-modal"
        contentClassName="w-100 bg-gray border-0 rounded-xs"
      >
        <Modal.Header className="border-0" closeButton />
        <Modal.Body className="show-grid pt-0 px-4">
          <Row>
            <Col xs={12}>
              <Typography
                tag="h1"
                color="gray7"
                className="fs-5 fw-bold"
                text={translation.requestCallout}
              />
            </Col>
          </Row>

          <Form onSubmit={handleSubmit} className="mt-4">
           
            <Row className="mt-3">
              <Col xs={12} md={12} lg={12}>
                <Controller
                  control={control}
                  name="cancel_comment"
                  defaultValue={cancelComment}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <TextAreaField
                      rows={4}
                      label={`${translation.cancelComment}`}
                      placeholder={translation.cancelSubscriptionComment}
                      error={error && error.message}
                      onChange={onChange}
                      value={value}
                      disabled={cancelComment !== ""}
                    />
                  )}
                />
              </Col>
            </Row>


            {/* Form check */}
            <Row className="mt-3">
              <Col xs={12}>
                <Controller
                  control={control}
                  name="agree"
                  defaultValue={false}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <React.Fragment>
                      <Form.Check
                        id="termsAndConditions"
                        type="checkbox"
                        onChange={onChange}
                        label={
                          <Typography
                            tag="span"
                            variant="body1"
                            color="gray7"
                            text={translation.cancelSubscriptionConfirmationText2}
                          />
                        }
                        className="d-inline-block me-1"
                      />
                      {errors && errors.agree && (
                        <Form.Text className="d-block text-danger">
                          {errors.agree.message}
                        </Form.Text>
                      )}
                    </React.Fragment>
                  )}
                />
              </Col>
            </Row>

            <Row className="justify-content-center mt-4">
              <Col xs={6}>
                <Button
                  type="submit"
                  className="w-100"
                  variant="primary"
                  textVariant="buttonText3"
                  text={
                    loading ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      translation.send
                    )
                  }
                />
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default withContainer(React.memo(CancelSubscriptionOrderModal));

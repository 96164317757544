import React from "react";
import { API_ROUTES } from "constants";
import {
  useFetch,
  useFilter,
  useLanguage,
  usePagination,
  useSkipFirstRender,
} from "hooks";

function withContainer(WrappedCompoent) {
  return (props) => {
    const language = useLanguage();
    const { page, handleSetPage } = usePagination();
    const [mobileFilterVisible, setMobileFilterVisible] = React.useState(false);
    const [{ loading: infoAndFiltersLoading, data: infoAndFilters }] = useFetch(
      {
        initialLoadingState: true,
        endpoint: API_ROUTES.getCategoryInfo,
        params: `?machine_name=all`,
      }
    );
    const {
      applyFilters,
      appliedFilters,
      selectedFilters,
      toggleFilter,
      resetFilters,
    } = useFilter({
      options_values: [],
      min_max: {
        min: infoAndFilters ? infoAndFilters.prices.min : null,
        max: infoAndFilters ? infoAndFilters.prices.max : null,
      },
    });
    const [{ loading, data, meta }] = useFetch(
      {
        initialLoadingState: true,
        method: "POST",
        endpoint: API_ROUTES.getCategoryServices,
        params: `?page=${page}`,
        body: {
          machine_name: "all",
          rate: [3, 4, 5],
          free_shipping: false,
          new_arrivals: false,
          has_discount: false,
          price_from: appliedFilters["min_max"].min,
          price_to: appliedFilters["min_max"].max,
          brands: [],
          options_values: appliedFilters["options_values"],
          sort: "",
          display: 15,
        },
      },
      [appliedFilters, page],
      true
    );

    const handleApplyFilters = () => {
      handleCloseMobileFilter();
      handleSetPage(1);
      applyFilters();
    };

    const handleResetFilters = () => {
      handleCloseMobileFilter();
      handleSetPage(1);
      resetFilters();
    };

    const handleOpenMobileFilter = () => setMobileFilterVisible(true);
    const handleCloseMobileFilter = () => setMobileFilterVisible(false);

    useSkipFirstRender(() => {
      if (infoAndFilters.prices) {
        toggleFilter(
          "min_max",
          {
            min: infoAndFilters.prices.min,
            max: infoAndFilters.prices.max,
          },
          false
        );
      }
    }, [infoAndFilters]);

    React.useEffect(() => {
      var touches = [];
      var differenceThreshold = 40;
      var differenceThresholdLimit = 1000;

      if (language.direction === "rtl") {
        differenceThreshold = -40;
        differenceThresholdLimit = 0;
      }

      function detectTouchMove(e) {
        touches.push(e.touches[0]);
        let differenceX =
          touches[touches.length - 1].clientX - touches[0].clientX;
        let differenceY =
          touches[touches.length - 1].clientY - touches[0].clientY;

        if (
          differenceX > differenceThreshold &&
          differenceX < differenceThresholdLimit &&
          differenceY > -20 &&
          differenceY < 20
        ) {
          handleOpenMobileFilter();
        }
      }

      function resetTouchMove() {
        touches = [];
      }

      window.addEventListener("touchend", resetTouchMove);
      window.addEventListener("touchmove", detectTouchMove);
      return () => {
        window.removeEventListener("touchend", resetTouchMove);
        window.removeEventListener("touchmove", detectTouchMove);
      };
    }, [language]);

    return (
      <WrappedCompoent
        infoAndFiltersLoading={infoAndFiltersLoading}
        loading={loading || infoAndFiltersLoading}
        infoAndFilters={infoAndFilters}
        appliedFilters={appliedFilters}
        selectedFilters={selectedFilters}
        mobileFilterVisible={mobileFilterVisible}
        handleOpenMobileFilter={handleOpenMobileFilter}
        handleCloseMobileFilter={handleCloseMobileFilter}
        toggleFilter={toggleFilter}
        handleApplyFilters={handleApplyFilters}
        handleResetFilters={handleResetFilters}
        data={data}
        meta={meta}
      />
    );
  };
}
export default withContainer;

import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { IMAGES } from "constants";
import { Button, Image, Slider, Typography } from "components";
import "./style.scss";
import classNames from "classnames";

const escapeHtml = (text) => {
  // Remove any HTML from text
  return text.replace(/<\/?[^>]+(>|$)/g, "");
};

const HomeSlide = ({ title, text, image, alignments, button_text, link }) => {
  return (
    <div className="slide position-relative h-100">
      <Image
        className="slide_bg position-absolute w-100 h-100"
        src={image}
        preload={true}
        dimensions={{ width: "100%", height: "100%" }}
        alt="slide"
        fromApi
      />

      {title !== "" && title !== null && (
        <div
          className={classNames("slide_container d-inline-block h-100", {
            "content-left": alignments === "left",
            "content-center": alignments === "center",
            "content-right": alignments === "right",
          })}
        >
          <Image
            className="geometric_shapes h-100"
            src={IMAGES.bgLogo}
            alt="slide"
          />
          <div className="slide_content">
            <Typography
              tag="h3"
              color="white"
              align="center"
              className="subtitle"
              text={title}
              overflow={1}
            />
            <Typography
              tag="h3"
              color="primary"
              align="center"
              className="title"
              text={escapeHtml(text)}
              overflow={1}
            />
            {button_text && (
              <div className="d-flex justify-content-center">
                <Button
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                  variant="primary"
                  className="py-1 body2 text-overflow-1 text-nowrap"
                  text={button_text}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

function Hero({ slides, companyServices }) {
  return (
    <div id="home-hero">
      <div className="carousel">
        <Slider itemAs={HomeSlide} items={slides} autoplay loop />
      </div>
      <div className="home-info">
        <Container className="position-relative d-none d-lg-block">
          <div className="home-info_container bg-gray2 rounded-lg">
            <Row className="h-100">
              {companyServices &&
                companyServices.map((item) => (
                  <Col key={item.id} md={3} xs={6} className="h-100">
                    <Row className="h-100 align-items-center py-3">
                      <Col xs={12} className="text-center">
                        <Image src={item.icon} alt={item.title} fromApi />
                      </Col>
                      <Col xs={12} className="text-center">
                        <Typography
                          tag="h3"
                          variant="h4"
                          weight="bolder"
                          color="secondary"
                          overflow={1}
                          className="text-capitalize"
                        >
                          {item.text}
                        </Typography>
                      </Col>
                    </Row>
                  </Col>
                ))}
            </Row>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default React.memo(Hero);

import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { API_ROUTES } from "constants";
import { useFetch, useLanguage } from "hooks";
import { useEffect } from "react";
import {
  clearExtraFields,
  setExtraFields,
  setSubscriptionId,
  clearSubscriptionId,
} from "store/cart";
import { useDispatch, useSelector } from "react-redux";
import { navigate } from "helpers";

function withContainer(WrappedCompoent) {
  return (props) => {
    const language = useLanguage();
    const dispatch = useDispatch();
    const { packageId } = useParams();
    const { isLoggedIn } = useSelector((state) => state.auth);
    const [{ loading, data }] = useFetch(
      {
        endpoint: API_ROUTES.getSubscriptionDetails,
        params: `?subscription_id=${packageId}`,
        initialLoadingState: true,
      },
      [packageId],
      true
    );

    const schema = yup.lazy(() => {
      const shapes = {};
      const fields = data ? data.fields : [];
      if (data.fields.length > 0) {
        fields.forEach((field) => {
          shapes[field.id] = yup;

          if (field.type === 3) {
            shapes[field.id] = shapes[field.id].number();
          } else if (field.type === 4 || field.type === 5) {
            shapes[field.id] = shapes[field.id].array();
          } else {
            shapes[field.id] = shapes[field.id].string();
          }
          shapes[field.id] = shapes[field.id].label(field.label);
          if (field.is_required) {
            shapes[field.id] = shapes[field.id].required(
              `\${label} ${language.isRequired}`
            );
          }
          let itemTerm = "";
          switch (field.type) {
            case 1:
              itemTerm = language.characters;
              break;
            case 3:
              itemTerm = language.digits;
              break;
            case 5:
              itemTerm = language.items;
              break;
            default:
              itemTerm = language.characters;
          }
          if (field.min_length) {
            shapes[field.id] = shapes[field.id].min(
              field.min_length,
              `\${label} ${language.mustBeAtLeast} \${min} ${itemTerm}`
            );
          }

          if (field.max_length) {
            shapes[field.id] = shapes[field.id].max(
              field.max_length,
              `\${label} ${language.mustBeLessThan} \${max} ${itemTerm}`
            );
          }
        });
      }

      return yup.object().shape({
        ...shapes,
        agree: yup
          .boolean()
          .oneOf(
            [true],
            `${language.youMustAgreeToThe} ${language.termsAndConditions}`
          ),
      });
    });

    const {
      register: registerField,
      control,
      setValue: setFieldValue,
      handleSubmit,
      formState: { errors },
      watch,
    } = useForm({
      resolver: yupResolver(schema),
      reValidateMode: "onChange",
    });
    const values = watch();

    const getExtraPrices = useMemo(() => {
      let selectedExtraFields =
        Object.keys(values).length > 0 ? Object.entries(values) : [];
      let allFields = [];
      const extraFields = data ? data.fields : [];
      if (extraFields.length === 0) return 0;
      extraFields.forEach((field) => {
        if (field.type === 7) {
          field.group_fields.forEach((subField) => {
            allFields.push(subField);
          });
        } else {
          allFields.push(field);
        }
      });
      if (selectedExtraFields.length === 0) return 0;
      return [...selectedExtraFields]
        .filter((item) => {
          if (typeof item[1] === "string" && item[1] === "") {
            return false;
          } else if (item[0] === "agree") {
            return false;
          } else if (typeof item[1] === "object" && item[1].value === "") {
            return false;
          } else if (Array.isArray(item[1]) && item[1].length === 0) {
            return false;
          }
          return true;
        })
        .map((item) => {
          let fieldType = allFields.find(
            (field) => String(field.id) === String(item[0])
          ).type;

          if ([1, 4].includes(fieldType)) {
            return 0;
          }

          let extraFieldValues = allFields.find(
            (field) => String(field.id) === String(item[0])
          ).values;

          if (fieldType === 5) {
            let extraPrices = 0;
            item[1].forEach((subItem) => {
              extraPrices += +allFields
                .find((field) => String(field.id) === String(item[0]))
                .values.find((val) => String(val.id) === String(subItem.value))
                .price;
            });
            return extraPrices;
          }

          if ([9, 11].includes(fieldType)) {
            let extraFieldValuePrice = +extraFieldValues.find(
              (field) => String(field.id) === String(item[1].value)
            ).price;
            return extraFieldValuePrice * item[1].qty;
          }

          if (extraFieldValues.length > 0) {
            // find selected extra field value in extraFieldValues
            let extraFieldValuePrice = +extraFieldValues.find(
              (field) => String(field.id) === String(item[1])
            ).price;
            return extraFieldValuePrice;
          }

          return (
            allFields.find((field) => String(field.id) === String(item[0]))
              .price_per_unit * +item[1]
          );
        })
        .reduce((a, b) => a + +b, 0);
    }, [values, data]);

    const handleSubmitData = () => {
      let originalFields = data ? data.fields : [];
      let extraFields = [];
      let valuesToSend = values;
      delete valuesToSend.agree;
      if (originalFields.length > 0) {
        const valueArr = Object.entries(values)
          .filter((item) => {
            if (typeof item[1] === "string" && item[1] === "") {
              return false;
            } else if (typeof item[1] === "object" && item[1].value === "") {
              return false;
            } else if (Array.isArray(item[1]) && item[1].length === 0) {
              return false;
            }
            if (
              originalFields.findIndex(
                (x) => String(x.id) === String(item[0])
              ) < 0
            ) {
              return false;
            }
            return true;
          })
          .map((item) => ({
            id: item[0],
            value: item[1],
          }));
        valueArr.forEach((item, idx) => {
          let { type, values } = originalFields.find(
            (field) => String(field.id) === String(item.id)
          );

          let value;
          if ([2, 6].includes(type)) {
            value = values.find(
              (val) => String(val.id) === String(item.value)
            ).value;
          }

          extraFields.push({
            field_id: item.id,
            ...([1, 3, 8].includes(type) && {
              value_id: "",
              value_text: item.value,
            }),
            ...([2, 6, 10].includes(type) && {
              value_id: item.value,
              value_text: value,
            }),
            ...([4, 5].includes(type) && {
              value_id: type === 5 ? item.value.map((el) => el.value) : "",
              value_text:
                type === 5
                  ? item.value.map((el) => el.label).join(",")
                  : item.value.join(","),
            }),
            ...([9, 11].includes(type) && {
              value_id: item.value.value,
              value_text: `${item.value.value} - x${item.value.qty}`,
            }),
          });
        });
      }
      dispatch(setExtraFields(extraFields));
      dispatch(setSubscriptionId(packageId));
      if (!isLoggedIn) {
        return navigate("/pre-checkout-subscription");
      }
      navigate("/checkout-subscription");
    };

    useEffect(() => {
      clearExtraFields();
      clearSubscriptionId();
    }, []);

    return (
      <WrappedCompoent
        loading={loading}
        data={data}
        getExtraPrices={getExtraPrices}
        errors={errors}
        registerField={registerField}
        control={control}
        setFieldValue={setFieldValue}
        handleSubmit={handleSubmit(handleSubmitData)}
      />
    );
  };
}
export default withContainer;

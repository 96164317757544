import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLanguage } from "hooks";
import {
  BreadCrumb,
  Image,
  Layout,
  Skeleton,
  SkeletonGroup,
  Typography,
} from "components";
import withContainer from "./Container";
import "./style.scss";

function AdviceDetailsPage({ loading, data }) {
  const language = useLanguage();

  return (
    <Layout footerMargin>
      <div id="advice-details-page" className="page_container">
        {!loading && data && (
          <React.Fragment>
            <BreadCrumb
              title={language.advices}
              path={[
                { title: language.advices, link: "/advices" },
                { title: data.title },
              ]}
              src={data.image}
              titleClassName="text-white fs-1 fw-light"
              lastWordClassName="text-secondary fw-bolder"
              fromApi
            />
            <Container className="mt-5">
              <Row>
                <Col xs={12}>
                  <div className="blog-details p-3 bg-gray3 rounded-xs border border-gray">
                    <Row className="justify-content-center">
                      <Col xs={12}>
                        <Image
                          src={data.image}
                          alt={data.title}
                          className="w-100 rounded-xs"
                          fromApi
                        />
                      </Col>
                      <Col xs={12} className="mt-3">
                        <Row className="align-items-center">
                          <Col xs={12}>
                            <Typography
                              tag="h1"
                              variant="h4"
                              weight="bold"
                              color="secondary"
                              overflow={1}
                            >
                              {data.title}
                            </Typography>
                          </Col>
                          <Col xs={12} className="mt-4">
                            <div
                              dangerouslySetInnerHTML={{ __html: data.text }}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </React.Fragment>
        )}

        {loading && (
          <React.Fragment>
            <Skeleton height={320} />
            <Container className="mt-5">
              <Row>
                <Col xs={12}>
                  <Skeleton height={40} width="30%" />
                </Col>
              </Row>
              <Row className="mt-5">
                <Col xs={12}>
                  <SkeletonGroup height={40} rows={4} />
                </Col>
              </Row>
            </Container>
          </React.Fragment>
        )}
      </div>
    </Layout>
  );
}

export default withContainer(React.memo(AdviceDetailsPage));

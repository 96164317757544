import React from "react";
import { STORAGE_URL } from "constants";
import classNames from "classnames";
import { Skeleton } from "components";

function ImageComponent({
  src,
  alt,
  fromApi = false,
  className,
  preload = false,
  dimensions,
  ...props
}) {
  const [loading, setLoading] = React.useState(true);
  const imgSrc = fromApi ? `${STORAGE_URL}${src}` : src;

  const preLoadImg = React.useCallback(async () => {
    const img = new Image();
    img.src = imgSrc;
    img.onload = () => {
      setLoading(false);
    };
  }, [imgSrc]);

  React.useEffect(() => {
    if (preload) {
      preLoadImg();
    }
  }, [preload, preLoadImg]);

  return (
    <React.Fragment>
      {((preload && !loading) || !preload) && (
        <img
          src={imgSrc}
          alt={alt}
          className={classNames("", {
            [`${className}`]: className,
          })}
          {...props}
        />
      )}
      {preload && loading && (
        <Skeleton
          containerClassName="h-100"
          width={dimensions && dimensions.width}
          height={dimensions && dimensions.height}
        />
      )}
    </React.Fragment>
  );
}

export default React.memo(ImageComponent);

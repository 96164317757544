import React from "react";
import { Link } from "react-router-dom";
import {
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import classNames from "classnames";
import { useLanguage } from "hooks";
import {
  Button,
  Heading,
  Icon,
  Image,
  InputField,
  Layout,
  NoData,
  SelectField,
  Skeleton,
  SkeletonGroup,
  Typography,
} from "components";
import { LocationCard } from "components/cards";
import withContainer from "./Container";

function Checkout({
  type,
  data,
  loading,
  user,
  createOrderLoading,
  createSubscriptionLoading,
  amountsData,
  amountsLoading,
  subscriptionDetailsData,
  subscriptionDetailsLoading,
  extraFieldsData,
  locationsData,
  isLoggedIn,
  guestInfo,
  chosenPaymentMethod,
  paymentMethods,
  handleChoosePaymentMethod,
  handleApplyCoupon,
  handleRemoveCoupon,
  handleCreateOrder,
  hearAboutUsList,
  handleChooseHearAboutUsValue,
  handleMakeAddressDefault,
  useMyBalance,
  setUseMyBalance,
}) {
  const language = useLanguage();

  return (
    <Layout footerMargin>
      <div id="cart-page" className="page_container">
        <section id="cart" className="mt-4">
          <Container>
            <Row>
              <Col xs={12}>
                <Heading title={language.checkout} />
              </Col>
            </Row>
            {!loading ? (
              <Row className="mt-5">
                <Col xs={12} lg={9}>
                  <Row>
                    <Col xs={12}>
                      <Typography
                        tag="h3"
                        variant="h5"
                        weight="bold"
                        color="black"
                      >
                        {language.choosePaymentMethod}
                      </Typography>
                    </Col>
                    <Col xs={12} className="mt-2">
                      <Row className="gy-3">
                        {paymentMethods.map((item) => (
                          <Col key={item.id} xs={6} md={6} lg={4}>
                            <div
                              role="button"
                              onClick={() => handleChoosePaymentMethod(item.id)}
                              className={classNames(
                                "payment-method border border-gray5 p-3 rounded-xs",
                                {
                                  "bg-gray3": item.id !== chosenPaymentMethod,
                                  "bg-secondary":
                                    item.id === chosenPaymentMethod,
                                }
                              )}
                            >
                              <Row>
                                <Col xs={12} className="text-center">
                                  <Image
                                    src={item.image}
                                    style={{
                                      height: "150px",
                                      objecFit: "contain",
                                    }}
                                    fromApi
                                  />
                                </Col>
                                <Col xs={12} className="text-center mt-3">
                                  <Typography
                                    tag="span"
                                    variant="h5"
                                    color={
                                      item.id === chosenPaymentMethod
                                        ? "white"
                                        : "secondary"
                                    }
                                  >
                                    {item.name}
                                  </Typography>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </Col>
                  </Row>
                  {!isLoggedIn && guestInfo && (
                    <Row className="mt-4">
                      <Col xs={12}>
                        <Typography
                          tag="h3"
                          variant="h5"
                          weight="bold"
                          color="black"
                        >
                          {language.deliveryLocation}
                        </Typography>
                      </Col>
                      <Col xs={12} lg={8} xl={8} className="mt-2">
                        <LocationCard
                          is_selected={true}
                          nickname={language.deliveryLocation}
                          email={guestInfo.email}
                          phone={guestInfo.phone}
                          place={guestInfo?.place}
                          address={guestInfo.address}
                        />
                      </Col>
                    </Row>
                  )}

                  {isLoggedIn && (
                    <Row className="mt-4">
                      <Col xs={12}>
                        <Typography
                          tag="h3"
                          variant="h5"
                          weight="bold"
                          color="black"
                        >
                          {language.chooseLocation}
                        </Typography>
                      </Col>
                      <Col xs={12} className="mt-2">
                        {locationsData && (
                          <Row className="gy-3">
                            {locationsData.length > 0 &&
                              locationsData.map((item) => (
                                <Col key={item.id} xs={6}>
                                  <LocationCard
                                    nickname={item.nickname}
                                    email={item.email}
                                    phone={item.phone}
                                    place={item.place}
                                    address={item.address}
                                    is_selected={item.is_selected}
                                    handleMakeAddressDefault={() =>
                                      handleMakeAddressDefault(item.id)
                                    }
                                  />
                                </Col>
                              ))}
                            {locationsData.length === 0 && (
                              <Col xs={12}>
                                <NoData
                                  text={language.noAddresses}
                                  image="/images/no-addresses.png"
                                />
                              </Col>
                            )}
                          </Row>
                        )}
                      </Col>
                      <Col xs={12} className="mt-3 text-end">
                        <Button
                          as={Link}
                          to="/account/addresses"
                          text={language.manageAddresses}
                        />
                      </Col>
                    </Row>
                  )}
                </Col>
                {/* Cart Summary */}
                <Col xs={12} lg={3} className="mt-4 mt-lg-0">
                  <div className="bg-gray3 ounded-xs border border-gray px-3 py-2">
                    <Row>
                      <Col xs={12}>
                        <Typography
                          tag="h3"
                          variant="h5"
                          weight="bold"
                          color="secondary"
                          align="center"
                        >
                          {type === "subscription" && language.subscriptionSummary}
                          {type !== "subscription" && language.cartSummary}
                        </Typography>
                      </Col>
                    </Row>

                    {type === "subscription" && (
                      <Row className="mt-5">
                        <Col xs={12}>

                          {subscriptionDetailsData && (
                            <div
                              key={subscriptionDetailsData.information.id}
                              className={classNames(
                                "pb-2 border-bottom border-gray5 "
                              )}
                            >
                              <Row>
                                <Col xs={12}>
                                  <Typography
                                    tag="span"
                                    variant="body2"
                                    weight="normal"
                                    color="black"
                                  >
                                    {subscriptionDetailsData.information.name}
                                  </Typography>
                                </Col>
                           
                              </Row>
                              {
                                //display extra fields if any
                              }
                              {extraFieldsData && extraFieldsData.length > 0 && (
                                <Row >

                                  {extraFieldsData.map((item, idx) =>

                                    //split text value by comma and display each value in a new row
                                    item.value_text.split(',').map((item2, idx2) =>
                                    (
                                      <Col xs={12} key={idx2}>
                                        <Typography
                                          tag="span"
                                          variant="body2"
                                          weight="normal"
                                          color="black"
                                        >
                                          {item2}
                                        </Typography>
                                      </Col>
                                    )
                                    ))}
                                </Row>
                              )}

                            </div>

                          )}
                        </Col>
                      </Row>
                    )}

                    {type !== "subscription" && (
                      <Row className="mt-5">
                        <Col xs={12}>
                          {data &&
                            type !== "subscription" &&
                            data.map((item, idx) => (
                              <div
                                key={item.id}
                                className={classNames(
                                  "pb-2 border-bottom border-gray5 ",
                                  {
                                    "mt-2": idx !== 0,
                                  }
                                )}
                              >
                                <Row>
                                  <Col xs={10}>
                                    <Typography
                                      tag="span"
                                      variant="body2"
                                      weight="normal"
                                      color="black"
                                    >
                                      {item.product.title}
                                    </Typography>
                                  </Col>
                                  <Col xs={2}>
                                    <Typography
                                      tag="span"
                                      variant="body2"
                                      weight="normal"
                                      color="black"
                                    >
                                      x{item.qty}
                                    </Typography>
                                  </Col>
                                </Row>
                              </div>
                            ))}
                        </Col>
                      </Row>
                    )}

                    <Row className="mt-5">
                      {amountsLoading && (
                        <Col xs={12}>
                          <Skeleton height={40} />
                        </Col>
                      )}
                      {!amountsLoading &&
                        amountsData &&
                        amountsData.coupons.length > 0 && (
                          <Col xs={12} className="mt-2">
                            <Row className="align-items-center">
                              <Col xs={6}>
                                <Typography
                                  tag="span"
                                  variant="body2"
                                  weight="normal"
                                  color="black"
                                >
                                  {language.activeCoupon}:{" "}
                                  {amountsData.coupons[0]}
                                </Typography>
                              </Col>
                              <Col xs={6} className="text-end">
                                <Button
                                  onClick={handleRemoveCoupon}
                                  variant=""
                                  icon="trash"
                                  iconClassName="text-gray7"
                                />
                              </Col>
                            </Row>
                          </Col>
                        )}
                      {!amountsLoading &&
                        amountsData &&
                        amountsData.coupons.length === 0 && (
                          <React.Fragment>
                            <Col xs={12}>
                              <Typography
                                tag="label"
                                variant="h6"
                                weight="normal"
                                color="black"
                              >
                                {language.giftVoucher}
                              </Typography>
                            </Col>
                            <Col xs={12} className="mt-1">
                              <InputGroup>
                                <InputField
                                  id="coupon-voucher"
                                  type="text"
                                  placeholder={language.addCoupon}
                                />
                                <Button
                                  onClick={() =>
                                    handleApplyCoupon(
                                      document.getElementById("coupon-voucher")
                                        .value
                                    )
                                  }
                                  variant="primary"
                                  text={language.applyCoupon}
                                />
                              </InputGroup>
                            </Col>
                          </React.Fragment>
                        )}
                    </Row>
                    <Row className="mt-4">
                      <Col xs={12}>
                        {language.howDidYouHeatAboutUs}
                        <SelectField
                          className="border-secondary"
                          placeholder={language.howDidYouHeatAboutUs}
                          onChange={(e) =>
                            handleChooseHearAboutUsValue(e.target.value)
                          }
                        >
                          {hearAboutUsList &&
                            hearAboutUsList.length > 0 &&
                            hearAboutUsList.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.value}
                              </option>
                            ))}
                        </SelectField>
                      </Col>
                    </Row>

                    <Row className="mt-4">
                      {!amountsLoading ? (
                        <React.Fragment>
                          {amountsData.items_amount > 0 && (
                            <Col xs={12}>
                              <Row>
                                <Col xs={6}>
                                  <Typography
                                    tag="h6"
                                    variant="h6"
                                    weight="bolder"
                                    color="black"
                                  >
                                    {language.subTotal}
                                  </Typography>
                                </Col>
                                <Col xs={6}>
                                  <Typography
                                    tag="h6"
                                    variant="h6"
                                    weight="bolder"
                                    align="end"
                                    color="black"
                                  >
                                    {amountsData.items_amount}{" "}
                                    {language.website_currency}
                                  </Typography>
                                </Col>
                              </Row>
                            </Col>
                          )}
                          {amountsData.delivery_amount > 0 && (
                            <Col xs={12}>
                              <Row>
                                <Col xs={6}>
                                  <Typography
                                    tag="h6"
                                    variant="h6"
                                    weight="bolder"
                                    color="black"
                                  >
                                    {language.shippingFee}
                                  </Typography>
                                </Col>
                                <Col xs={6}>
                                  <Typography
                                    tag="h6"
                                    variant="h6"
                                    weight="bolder"
                                    align="end"
                                    color="black"
                                  >
                                    {amountsData.delivery_amount}{" "}
                                    {language.website_currency}
                                  </Typography>
                                </Col>
                              </Row>
                            </Col>
                          )}
                          {amountsData.discount_amount > 0 && (
                            <Col xs={12}>
                              <Row>
                                <Col xs={6}>
                                  <Typography
                                    tag="h6"
                                    variant="h6"
                                    weight="bolder"
                                    color="black"
                                  >
                                    {language.voucherDiscount}
                                  </Typography>
                                </Col>
                                <Col xs={6}>
                                  <Typography
                                    tag="h6"
                                    variant="h6"
                                    weight="bolder"
                                    align="end"
                                    color="black"
                                  >
                                    {amountsData.discount_amount}{" "}
                                    {language.website_currency}
                                  </Typography>
                                </Col>
                              </Row>
                            </Col>
                          )}
                          {amountsData.taxes_amount > 0 && (
                            <Col xs={12}>
                              <Row>
                                <Col xs={6}>
                                  <Typography
                                    tag="h6"
                                    variant="h6"
                                    weight="bolder"
                                    color="black"
                                  >
                                    {language.tax}
                                  </Typography>
                                </Col>
                                <Col xs={6}>
                                  <Typography
                                    tag="h6"
                                    variant="h6"
                                    weight="bolder"
                                    align="end"
                                    color="black"
                                  >
                                    {amountsData.taxes_amount}{" "}
                                    {language.website_currency}
                                  </Typography>
                                </Col>
                              </Row>
                            </Col>
                          )}
                          {amountsData.fees_amount > 0 && (
                            <Col xs={12}>
                              <Row>
                                <Col xs={6}>
                                  <Typography
                                    tag="h6"
                                    variant="h6"
                                    weight="bolder"
                                    color="black"
                                  >
                                    {language.fees}
                                  </Typography>
                                </Col>
                                <Col xs={6}>
                                  <Typography
                                    tag="h6"
                                    variant="h6"
                                    weight="bolder"
                                    align="end"
                                    color="black"
                                  >
                                    {amountsData.fees_amount}{" "}
                                    {language.website_currency}
                                  </Typography>
                                </Col>
                              </Row>
                            </Col>
                          )}
                        </React.Fragment>
                      ) : (
                        <Col xs={12}>
                          <SkeletonGroup rows={3} count={1} />
                        </Col>
                      )}
                    </Row>

                    <Row className="mt-4">
                      <Col xs={12}>
                        <Row>
                          <Col xs={6}>
                            <Typography
                              tag="h5"
                              variant="h4"
                              weight="bolder"
                              color="secondary"
                            >
                              {language.total}
                            </Typography>
                          </Col>
                          <Col xs={6}>

                            <Typography
                              tag="h5"
                              variant="h4"
                              weight="bolder"
                              align="end"
                              color="secondary"
                            >
                              {amountsData && amountsData.total_amount}{" "}
                              {language.website_currency}
                            </Typography>


                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    {user && user.wallet_balance > 0 && (
                      <Row className="mt-4">
                        <Col xs={12}>
                          <Form.Check
                            id="termsAndConditions"
                            type="checkbox"
                            onChange={() => {
                              setUseMyBalance((prev) => !prev);
                            }}
                            checked={useMyBalance}
                            label={
                              <Typography
                                tag="span"
                                variant="body1"
                                color="gray7"
                                text={language.useMyBalance}
                              />
                            }
                            className="d-inline-block me-1"
                          />
                        </Col>
                      </Row>
                    )}
                    <Row className="mt-5">
                      <Col lg={12} xs={6} className="text-center">
                        <Button
                          disabled={isLoggedIn && locationsData.length === 0}
                          onClick={handleCreateOrder}
                          variant="primary"
                          className="w-100 py-3"
                          {...(type !== "subscription" && {
                            text: createOrderLoading ? (
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            ) : (
                              language.completeCheckout
                            ),
                          })}
                          {...(type === "subscription" && {
                            text: createSubscriptionLoading ? (
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            ) : (
                              language.completeCheckout
                            ),
                          })}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            ) : (
              <Row className="mt-5">
                <Col xs={12} lg={9}>
                  <Row>
                    <Col xs={12}>
                      <Skeleton height={40} width="40%" />
                    </Col>
                    <Col xs={12} className="mt-3">
                      <SkeletonGroup
                        height={200}
                        xs={{
                          rows: 1,
                          count: 2,
                        }}
                        lg={{
                          rows: 1,
                          count: 3,
                        }}
                      />
                    </Col>
                  </Row>

                  <Row className="mt-4">
                    <Col xs={12}>
                      <Skeleton height={40} width="40%" />
                    </Col>
                    <Col xs={12} className="mt-3">
                      <SkeletonGroup
                        height={200}
                        xs={{
                          rows: 1,
                          count: 1,
                        }}
                        lg={{
                          rows: 1,
                          count: 2,
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
                {/* Cart Summary */}
                <Col xs={12} lg={3} className="mt-4 mt-lg-0">
                  <Skeleton height={450} />
                </Col>
              </Row>
            )}
          </Container>
        </section>
      </div>
    </Layout>
  );
}

export default withContainer(React.memo(Checkout));

import React from "react";
import { useSkipFirstRender } from "hooks";

export default function withContainer(WrappedComponent) {
  return ({ initialCount = 1, min = 1, max = Math.pow(10, 5), onChange }) => {
    const [count, setCount] = React.useState(initialCount || min);
    const inputRef = React.useRef(null);
    const handleIncrement = () => {
      setCount((prevState) => {
        if (prevState + 1 <= max) return prevState + 1;
        return prevState;
      });
    };

    const handleDecrement = () => {
      setCount((prevState) => {
        if (prevState - 1 >= min) return prevState - 1;
        return prevState;
      });
    };

    const handleOnInputBlur = React.useCallback(
      (value) => {
        let rounded = Math.round(value);
        inputRef.current.value = count;
        if (rounded === null || isNaN(rounded))
          return (inputRef.current.value = count);
        if (typeof rounded !== "number") return;
        if (rounded < min || rounded > max)
          return setCount(rounded < min ? min : max);
        setCount(rounded);
      },
      [count, max, min]
    );

    useSkipFirstRender(() => {
      if (onChange) {
        onChange(count);
      }
      handleOnInputBlur(count);
    }, [count]);

    React.useEffect(() => {
      inputRef.current.value = initialCount;
    }, [inputRef, initialCount]);

    return (
      <WrappedComponent
        handleDecrement={handleDecrement}
        inputRef={inputRef}
        handleOnInputBlur={handleOnInputBlur}
        handleIncrement={handleIncrement}
      />
    );
  };
}

import React from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "hooks";
import { Layout, ProfileLayout, AddressForm } from "components";

function AddressCreatePage() {
  const language = useLanguage();
  const navigate = useNavigate();

  return (
    <Layout footerMargin>
      <div id="EditProfilePage">
        <ProfileLayout title={language.myAccount}>
          <Row>
            <Col xs={12}>
              <AddressForm
                onAddressCreated={() => {
                  navigate("/account/addresses");
                }}
              />
            </Col>
          </Row>
        </ProfileLayout>
      </div>
    </Layout>
  );
}

export default React.memo(AddressCreatePage);

import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from "moment";
import { Image, RatingStars, Typography } from "components";
import "./style.scss";

function ReviewCard({
  comment,
  user: author,
  image,
  product: service,
  created_at: date,
  rate,
}) {
  return (
    <div className="review-card px-2 pt-2 bg-gray3 position-relative border border-gray5 rounded-xs">
      <Row className="gx-2 gx-sm-0 gx-md-2">
        <Col xs={4} sm={3} md={4} lg={5} xl={4}>
          <Row className="justify-content-center">
            {author !== null && (
              <React.Fragment>
                <Col xs={12} sm={12} className="">
                  <div className="user-image">
                    <Image
                      src="/images/user-default.webp"
                      alt={author.name}
                      className="rounded-circle"
                    />
                  </div>
                </Col>
                <Col xs={12} className="mt-3">
                  <Typography tag="h6" variant="h6" weight="bold" color="black">
                    {author.name}
                  </Typography>
                </Col>
                {service !== null && (
                  <Col xs={12} className="">
                    <Typography
                      tag="h6"
                      variant="body2"
                      weight="normal"
                      color="black"
                    >
                      <Link to={`/service/${service.machine_name}`}>
                        {service.title}
                      </Link>
                    </Typography>
                  </Col>
                )}
              </React.Fragment>
            )}
            <Col xs={12} className="">
              <RatingStars rate={rate} width={14} />
            </Col>
          </Row>
        </Col>
        <Col xs={8} sm={9} md={8} lg={7} xl={8}>
          <Row className="h-100 pt-3">
            <Col xs={12}>
              <Typography
                tag="p"
                variant="body3"
                weight="normal"
                color="black"
                overflow={8}
              >
                {comment}
              </Typography>
            </Col>
            <Col xs={12} className="align-self-end">
              <Typography
                tag="h6"
                variant="body3"
                weight="normal"
                align="end"
                color="secondary"
              >
                {moment(date).format("DD/MM/YYYY")}
              </Typography>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default React.memo(ReviewCard);

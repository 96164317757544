import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { addRemoveWishlist } from "store/wishlist";

function withContainer(WrappedCompoent) {
  return (props) => {
    const dispatch = useDispatch();
    const wishlistIds = useSelector((state) => state.wishlist.ids);
    const isInWishlist = wishlistIds.includes(props.id);

    // Dispatch add to wishlist by id
    const handleAddRemoveWishlist = (e) => {
      e.preventDefault();
      dispatch(addRemoveWishlist({ productId: props.id }));
    };

    return (
      <WrappedCompoent
        {...props}
        isFavourite={isInWishlist}
        handleAddRemoveWishlist={handleAddRemoveWishlist}
      />
    );
  };
}
export default withContainer;

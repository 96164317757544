import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useLanguage } from "hooks";
import { Layout, BreadCrumb, Typography, Skeleton } from "components";
import { ServiceCategoryCard } from "components/cards";
import withContainer from "./Container";
import "./style.scss";

function OurServices({ loading, data }) {
  const language = useLanguage();
  const { serverTranslations } = useSelector((state) => state.common);

  return (
    <Layout footerMargin>
      <div id="our-services-page" className="page_container">
        <BreadCrumb
          title={language.ourServices}
          path={[{ title: language.services }]}
          src="/images/our-services-bg.png"
          lastWordClassName="text-secondary fw-bolder"
          titleClassName="text-black fs-1 fw-light"
        />
        <Container className="mt-4">
          <Row>
            <Col xs={12}>
              <Row>
                <Col xs={12}>
                  <Typography
                    tag="h1"
                    variant="h2"
                    color="secondary"
                    weight="bold"
                    overflow={1}
                  >
                    {language.ourServices}
                  </Typography>
                </Col>
                <Col xs={12}>
                  <Typography
                    tag="p"
                    variant="body2"
                    color="black"
                    overflow={1}
                  >
                    {serverTranslations.text_in_our_services}
                  </Typography>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col xs={12}>
              {!loading && (
                <div className="grid pb-5">
                  {data &&
                    data.map((item) => (
                      <ServiceCategoryCard
                        key={item.id}
                        className="grid-item"
                        {...item}
                      />
                    ))}
                </div>
              )}
              {loading && (
                <React.Fragment>
                  <div className="d-lg-none">
                    <div className="grid">
                      {Array.from({ length: 6 }, (_, idx) => idx).map(
                        (_, idx) => (
                          <Skeleton
                            key={idx}
                            containerClassName="grid-item"
                            width={"100%"}
                            height={"100%"}
                            circle
                          />
                        )
                      )}
                    </div>
                  </div>

                  <div className="d-none d-lg-block">
                    <div className="grid">
                      {Array.from({ length: 8 }, (_, idx) => idx).map(
                        (_, idx) => (
                          <Skeleton
                            key={idx}
                            containerClassName="grid-item"
                            width={"100%"}
                            height={"100%"}
                            circle
                          />
                        )
                      )}
                    </div>
                  </div>
                </React.Fragment>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  );
}

export default withContainer(React.memo(OurServices));

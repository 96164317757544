import { API_ROUTES } from "constants";
import { useFetch, usePagination } from "hooks";
import React from "react";
import { useParams } from "react-router-dom";

function withContainer(WrappedCompoent) {
  return (props) => {
    const { machineName } = useParams();
    const { page } = usePagination();

    const [{ loading, data }] = useFetch(
      {
        endpoint: API_ROUTES.getServiceDetails,
        params: `?machine_name=${machineName}&page=${page}`,
      },
      [machineName],
      true
    );

    const [
      { loading: reviewsLoading, data: reviewsData, meta: reviewsMeta },
      fetchReviews,
    ] = useFetch(
      {
        endpoint: API_ROUTES.getServiceReviews,
        params: `?machine_name=${machineName}`,
      },
      [data, page],
      false
    );

    const [{ loading: relatedProductsLoading, data: relatedProductsData }] =
      useFetch(
        {
          endpoint: API_ROUTES.getServiceRelatedProducts,
          params: `?machine_name=${machineName}`,
        },
        [data],
        false
      );

    return (
      <WrappedCompoent
        loading={loading}
        data={data}
        reviewsLoading={loading || reviewsLoading}
        reviewsData={reviewsData}
        relatedProductsLoading={loading || relatedProductsLoading}
        relatedProductsData={relatedProductsData}
        fetchReviews={fetchReviews}
      />
    );
  };
}

export default withContainer;

import { Button, InputField, Typography } from "components";
import { useLanguage } from "hooks";
import React from "react";
import { Col, Form, Row } from "react-bootstrap";

function StepThree({ handleNextStep }) {
  const langunge = useLanguage();

  return (
    <div className="step-one">
      <Row>
        <Col xs={12}>
          <Typography
            tag="h1"
            color="black"
            className="fs-2 text-center"
            text={langunge.changePassword}
          />
        </Col>
      </Row>

      <Row className="mt-4">
        <Col xs={12}>
          <Form>
            <Row>
              <Col xs={12} md={6}>
                <InputField
                  type="text"
                  label={langunge.password}
                  labelClassName="fs-6"
                  placeholder={langunge.password}
                  className="py-3"
                  // error={errors && errors.username}
                />
              </Col>
              <Col xs={12} md={6} className="mt-4 mt-md-0">
                <InputField
                  type="text"
                  label={langunge.confirmPassword}
                  labelClassName="fs-6"
                  placeholder={langunge.confirmPassword}
                  className="py-3"
                  // error={errors && errors.username}
                />
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>

      <Row className="mt-2">
        <Col xs={12} className="mt-4">
          <Row className="justify-content-center">
            <Col xs={12} lg={6} xl={5}>
              <Button
                type="submit"
                className="text-white py-3 w-100"
                variant="primary"
                onClick={handleNextStep}
                text={langunge.changeMyPassword}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default StepThree;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_ROUTES } from "constants";
import { client } from "helpers";

export const getWebsiteInformation = createAsyncThunk(
  "common/getWebsiteInformation",
  async (_, thunkAPI) => {
    const { getState, rejectWithValue } = thunkAPI;
    const { language } = getState().app;
    try {
      const response = await client().get(
        `${API_ROUTES.getWebsiteInformation}`,
        {
          headers: {
            Language: language,
          },
        }
      );
      const data = response.data;
      if (data.status === 0) {
        return rejectWithValue(data.message);
      }
      return data;
    } catch (error) {
      const { response } = error;
      return rejectWithValue(response.data.message);
    }
  }
);

export const getWebsiteSettings = createAsyncThunk(
  "common/getWebsiteSettings",
  async (_, thunkAPI) => {
    const { getState, rejectWithValue } = thunkAPI;
    const { language } = getState().app;
    try {
      const response = await client().get(`${API_ROUTES.getWebsiteSettings}`, {
        headers: {
          Language: language,
        },
      });
      const data = response.data;
      if (data.status === 0) {
        return rejectWithValue(data.message);
      }
      return data;
    } catch (error) {
      const { response } = error;
      return rejectWithValue(response.data.message);
    }
  }
);

export const getHelpCenter = createAsyncThunk(
  "common/getHelpCenter",
  async (_, thunkAPI) => {
    const { getState, rejectWithValue } = thunkAPI;
    const { language, placeId } = getState().app;
    try {
      const response = await client().get(
        `${API_ROUTES.getHelpCenter}?place_id=${placeId}`,
        {
          headers: {
            Language: language,
          },
        }
      );
      const data = response.data;
      if (data.status === 0) {
        return rejectWithValue(data.message);
      }
      return data;
    } catch (error) {
      const { response } = error;
      return rejectWithValue(response.data.message);
    }
  }
);

export const getCalloutsTypes = createAsyncThunk(
  "common/getCalloutsTypes",
  async (_, thunkAPI) => {
    const { getState, rejectWithValue } = thunkAPI;
    const { language } = getState().app;
    try {
      const response = await client().get(`${API_ROUTES.getCalloutsTypes}`, {
        headers: {
          Language: language,
        },
      });
      const data = response.data;
      if (data.status === 0) {
        return rejectWithValue(data.message);
      }
      return data;
    } catch (error) {
      const { response } = error;
      return rejectWithValue(response.data.message);
    }
  }
);

export const getSocialMediaIcons = createAsyncThunk(
  "common/getSocialMediaIcons",
  async (_, thunkAPI) => {
    const { getState, rejectWithValue } = thunkAPI;
    const { language } = getState().app;
    try {
      const response = await client().get(`${API_ROUTES.getSocialMediaIcons}`, {
        headers: {
          Language: language,
        },
      });
      const data = response.data;
      if (data.status === 0) {
        return rejectWithValue(data.message);
      }
      return data;
    } catch (error) {
      const { response } = error;
      return rejectWithValue(response.data.message);
    }
  }
);

export const getPaymentMethodsByPlaceID = createAsyncThunk(
  "common/getPaymentMethodsByPlaceID",
  async (_, thunkAPI) => {
    const { getState, rejectWithValue } = thunkAPI;
    const { language, placeId } = getState().app;
    try {
      const response = await client().get(
        `${API_ROUTES.getPaymentMethodsByPlaceID}?place_id=${placeId}`,
        {
          headers: {
            Language: language,
          },
        }
      );
      const data = response.data;
      if (data.status === 0) {
        return rejectWithValue(data.message);
      }
      return data;
    } catch (error) {
      const { response } = error;
      return rejectWithValue(response.data.message);
    }
  }
);

export const getCities = createAsyncThunk(
  "common/getCities",
  async (_, thunkAPI) => {
    const { getState, rejectWithValue } = thunkAPI;
    const { language, countries } = getState().app;
    try {
      const parentId = countries.length > 0 ? countries[0].id : 0;
      const response = await client().get(
        `${API_ROUTES.getPlaces}?parent_id=${parentId}`,
        {
          headers: {
            Language: language,
          },
        }
      );
      const data = response.data;
      if (data.status === 0) {
        return rejectWithValue(data.message);
      }
      return data;
    } catch (error) {
      const { response } = error;
      return rejectWithValue(response.data.message);
    }
  }
);

export const getPublicPages = createAsyncThunk(
  "common/getPublicPages",
  async (_, thunkAPI) => {
    const { getState, rejectWithValue } = thunkAPI;
    const { language } = getState().app;
    try {
      const response = await client().get(`${API_ROUTES.getPublicPages}`, {
        headers: {
          Language: language,
        },
      });
      const data = response.data;
      if (data.status === 0) {
        return rejectWithValue(data.message);
      }
      return data;
    } catch (error) {
      const { response } = error;
      return rejectWithValue(response.data.message);
    }
  }
);

export const getOrderComplainReasons = createAsyncThunk(
  "common/getOrderComplainReasons",
  async (_, thunkAPI) => {
    const { getState, rejectWithValue } = thunkAPI;
    const { language } = getState().app;
    try {
      const response = await client().get(
        `${API_ROUTES.getOrderComplainReasons}`,
        {
          headers: {
            Language: language,
          },
        }
      );
      const data = response.data;
      if (data.status === 0) {
        return rejectWithValue(data.message);
      }
      return data;
    } catch (error) {
      const { response } = error;
      return rejectWithValue(response.data.message);
    }
  }
);

export const getHearAboutUS = createAsyncThunk(
  "common/getHearAboutUS",
  async (_, thunkAPI) => {
    const { getState, rejectWithValue } = thunkAPI;
    const { language } = getState().app;
    try {
      const response = await client().get(`${API_ROUTES.getHearAboutUS}`, {
        headers: {
          Language: language,
        },
      });
      const data = response.data;
      if (data.status === 0) {
        return rejectWithValue(data.message);
      }
      return data;
    } catch (error) {
      const { response } = error;
      return rejectWithValue(response.data.message);
    }
  }
);

export const getPlacesWithSubLevels = createAsyncThunk(
  "common/getPlacesWithSubLevels",
  async (_, thunkAPI) => {
    const { getState, rejectWithValue } = thunkAPI;
    const { language } = getState().app;
    try {
      const response = await client().get(
        `${API_ROUTES.getPlacesWithSubLevels}`,
        {
          headers: {
            Language: language,
          },
        }
      );
      const data = response.data;
      if (data.status === 0) {
        return rejectWithValue(data.message);
      }
      return data;
    } catch (error) {
      const { response } = error;
      return rejectWithValue(response.data.message);
    }
  }
);

export const getTranslations = createAsyncThunk(
  "common/getTranslations",
  async (_, thunkAPI) => {
    const { getState, rejectWithValue } = thunkAPI;
    const { language } = getState().app;
    try {
      const response = await client().get(
        `${API_ROUTES.getTranslations}?application_slug=customer-web-application`,
        {
          headers: {
            Language: language,
          },
        }
      );
      const data = response.data;
      if (data.status === 0) {
        return rejectWithValue(data.message);
      }
      return data;
    } catch (error) {
      const { response } = error;
      return rejectWithValue(response.data.message);
    }
  }
);

export const getAdvertisements = createAsyncThunk(
  "common/getAdvertisements",
  async (_, thunkAPI) => {
    const { getState, rejectWithValue } = thunkAPI;
    const { language, placeId } = getState().app;
    try {
      const response = await client().post(
        `${API_ROUTES.getGroupAdvertisementsByMachineName}`,
        {
          machine_names: ["home-bottom-image", "products-under-filter"],
          category_id: 0,
          place_id: placeId,
        },
        {
          headers: {
            Language: language,
          },
        }
      );
      const data = response.data;
      if (data.status === 0) {
        return rejectWithValue(data.message);
      }
      return data;
    } catch (error) {
      const { response } = error;
      return rejectWithValue(response.data.message);
    }
  }
);

import React from "react";
import { Form } from "react-bootstrap";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Typography } from "components";

const TextAreaField = React.forwardRef(
  (
    {
      id,
      name,
      label,
      labelType = "formLabel",
      labelClassName,
      className,
      placeholder,
      onChange,
      value,
      error,
      color,
      labelColor = "gray7",
      ...props
    },
    ref
  ) => {
    return (
      <Form.Group>
        {label && (
          <Typography
            htmlFor={id}
            tag="label"
            className={classNames("form-label text-capitalize", {
              [`${labelClassName}`]: labelClassName,
              [`text-${labelColor}`]: labelColor,
            })}
            variant="body2"
            text={label}
          />
        )}
        <Form.Control
          as="textarea"
          rows={3}
          ref={ref}
          id={id}
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          className={classNames("", {
            "is-invalid": error,
            [`${className}`]: className,
          })}
          {...props}
        />
        {error && <Form.Text className="text-danger">{error}</Form.Text>}
      </Form.Group>
    );
  }
);

TextAreaField.propTypes = {
  id: PropTypes.string,
  rows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  erorr: PropTypes.string,
};

export default React.memo(TextAreaField);

import React from "react";
import { Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useLanguage } from "hooks";
import {
  Button,
  Icons,
  Image,
  InputField,
  Layout,
  Map,
  TextAreaField,
  Typography,
} from "components";
import withContainer from "./Container";
import "./style.scss";
import classNames from "classnames";

function ContactUs({
  loading,
  socialMediaIcons,
  websiteInformation,
  errors,
  registerField,
  handleSubmitData,
}) {
  const language = useLanguage();

  return (
    <Layout footerMargin>
      <div id="contact-us-page" className="page_container">
        <Container>
          <Row>
            {typeof websiteInformation !== "undefined" &&
              websiteInformation !== null &&
              Object.keys(websiteInformation).indexOf("latitude") > -1 && (
                <Col xs={12} lg={6} className="order-2 order-lg-1">
                  <div className="left-side h-100">
                    <Map
                      center={{
                        lat: websiteInformation.latitude,
                        lng: websiteInformation.longitude,
                      }}
                      zoom={16}
                      draggable={false}
                      zoomControl={false}
                      scrollwheel={false}
                      disableDoubleClickZoom={true}
                      marker
                      height="100%"
                    />
                  </div>
                </Col>
              )}
            <Col
              xs={12}
              lg={
                typeof websiteInformation !== "undefined" &&
                websiteInformation !== null &&
                Object.keys(websiteInformation).indexOf("latitude") > -1
                  ? 6
                  : 12
              }
              className="order-1 order-lg-2 px-lg-3"
            >
              <div className="right-side py-4">
                <Row className="justify-content-center">
                  <Col xs={12} className="mb-4">
                    <ul className="list-unstyled m-0 p-0">
                      {websiteInformation &&
                        websiteInformation.company_phone && (
                          <li className="">
                            <Typography tag="h3" variant="h3">
                              <Typography
                                tag="span"
                                variant="body1"
                                weight="bold"
                                color="black"
                              >
                                {language.phone}:{" "}
                              </Typography>
                              <Typography
                                tag="a"
                                href={`tel://${websiteInformation.company_phone}`}
                                variant="body1"
                                weight="normal"
                                color="black"
                              >
                                {websiteInformation.company_phone}
                              </Typography>
                            </Typography>
                          </li>
                        )}
                      {websiteInformation &&
                        websiteInformation.company_email && (
                          <li className="">
                            <Typography tag="h3" variant="h3">
                              <Typography
                                tag="span"
                                variant="body1"
                                weight="bold"
                                color="black"
                              >
                                {language.email}:{" "}
                              </Typography>
                              <Typography
                                tag="a"
                                href={`mailto://${websiteInformation.company_email}`}
                                variant="body1"
                                weight="normal"
                                color="black"
                              >
                                {websiteInformation.company_email}
                              </Typography>
                            </Typography>
                          </li>
                        )}
                      {websiteInformation && websiteInformation.company_fax && (
                        <li className="">
                          <Typography tag="h3" variant="h3">
                            <Typography
                              tag="span"
                              variant="body1"
                              weight="bold"
                              color="black"
                            >
                              {language.fax}:{" "}
                            </Typography>
                            <Typography
                              tag="a"
                              href="fax:+1 444 547 9832"
                              variant="body1"
                              weight="normal"
                              color="black"
                            >
                              {websiteInformation.company_fax}
                            </Typography>
                          </Typography>
                        </li>
                      )}
                      {websiteInformation &&
                        websiteInformation.company_address && (
                          <li className="">
                            <div className="d-flex">
                              <Typography
                                tag="span"
                                variant="body1"
                                weight="bold"
                                color="black"
                              >
                                {language.address}:{" "}
                              </Typography>
                              <Typography
                                tag="p"
                                variant="body1"
                                weight="normal"
                                color="black"
                                className="ms-2"
                              >
                                {websiteInformation.company_address}
                              </Typography>
                            </div>
                          </li>
                        )}
                    </ul>
                  </Col>
                  <Col xs={4} className="">
                    <div className="w-100 border border-2 border-primary rounded-sm"></div>
                  </Col>
                  <Col xs={12} className="mt-4">
                    <Form onSubmit={handleSubmitData}>
                      <Row>
                        <Col xs={12}>
                          <InputField
                            type="text"
                            label={language.name}
                            placeholder={language.name}
                            error={errors.name && errors.name.message}
                            labelClassName="fw-bolder"
                            className="py-3"
                            {...registerField("name")}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col xs={12}>
                          <InputField
                            type="email"
                            label={language.emailAddress}
                            placeholder={language.emailAddress}
                            error={errors.email && errors.email.message}
                            labelClassName="fw-bolder"
                            className="py-3"
                            {...registerField("email")}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col xs={12}>
                          <InputField
                            type="tel"
                            label={language.mobile}
                            placeholder={language.mobile}
                            error={errors.phone && errors.phone.message}
                            labelClassName="fw-bolder"
                            className="py-3"
                            {...registerField("phone")}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col xs={12}>
                          <TextAreaField
                            rows={8}
                            label={language.message}
                            placeholder={language.message}
                            error={errors.message && errors.message.message}
                            labelClassName="fw-bolder"
                            {...registerField("message")}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col className="text-center" xs={12}>
                          <Button
                            type="submit"
                            variant="primary"
                            className="py-2 px-5"
                            textVariant="buttonText2"
                            text={
                              loading ? (
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                              ) : (
                                language.send
                              )
                            }
                          />
                        </Col>
                      </Row>
                    </Form>
                  </Col>

                  <Col xs={12} className="mt-5">
                    <ul className="m-0 d-flex flex-wrap justify-content-center">
                      {socialMediaIcons &&
                        socialMediaIcons.map((item, idx) => (
                          <li
                            key={item.id}
                            className={classNames("", {
                              "me-4": idx < socialMediaIcons.length - 1,
                            })}
                          >
                            <a
                              href={item.link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <Image
                                src={item.icon}
                                className="social-icon"
                                alt={item.title}
                                fromApi
                              />
                            </a>
                          </li>
                        ))}
                    </ul>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  );
}

export default withContainer(React.memo(ContactUs));

import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import classNames from "classnames";
import { Typography } from "components";

const CopyrightSection = ({ websiteSettings, publicPages }) => {
  return (
    <div className="footer-copyright bg-secondary py-2">
      <Container>
        <Row className="align-items-center">
          <Col xs={4}>
            {websiteSettings && (
              <Typography
                tag="h6"
                variant="body2"
                weight="bold"
                color="white"
                overflow={1}
                className="text-capitalize"
              >
                {websiteSettings.footer_text}
              </Typography>
            )}
          </Col>
          <Col xs={8} className="float-end">
            <ul className="m-0 d-flex flex-wrap justify-content-end">
              {publicPages &&
                publicPages.map((item, idx) => (
                  <li
                    key={item.id}
                    className={classNames({
                      "ms-4": idx !== 0,
                    })}
                  >
                    <Link to={`/page/${item.machine_name}`}>
                      <Typography
                        tag="span"
                        variant="h6"
                        weight="normal"
                        color="white"
                      >
                        {item.name}
                      </Typography>
                    </Link>
                  </li>
                ))}
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default React.memo(CopyrightSection);

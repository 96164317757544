import { API_ROUTES } from "constants";
import { useFetch, usePagination } from "hooks";
import React from "react";

function withContainer(WrappedCompoent) {
  return (props) => {
    const { page } = usePagination();
    const [{ loading, data, meta }] = useFetch(
      {
        initialLoadingState: true,
        endpoint: API_ROUTES.getArticles,
        params: `?page=${page}`,
      },
      [page],
      true
    );

    return <WrappedCompoent loading={loading} data={data} meta={meta} />;
  };
}
export default withContainer;

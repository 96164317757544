import React from "react";
import { Col, Row } from "react-bootstrap";
import { VideoImage, Typography } from "components";
import "./style.scss";

function Card({ image, video, video_provider, text }) {
  return (
    <div className="video-card">
      <Row className="justify-content-center">
        <Col xs={12}>
          <VideoImage
            video={video}
            video_provider={video_provider}
            image={image}
            className="rounded-xs"
          />
        </Col>
        <Col xs={12}>
          <Row className="align-items-center">
            <Col xs={12} className="mt-3">
              <Typography
                tag="h1"
                variant="h3"
                weight="bolder"
                overflow={1}
                color="secondary"
              >
                {text}
              </Typography>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default React.memo(Card);

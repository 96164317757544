import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import classNames from "classnames";
import { useLanguage } from "hooks";
import {
  Layout,
  ProfileLayout,
  Button,
  InputField,
  Typography,
  Image,
} from "components";
import withContainer from "./Container";

function MyWalletPage({
  loading,
  data,
  user,
  registerField,
  setValue,
  errors,
  loadingChargeWallet,
  requestChargeWalletPackage,
  handleSubmit,
}) {
  const translation = useLanguage();

  return (
    <Layout footerMargin>
      <div id="MyWalletPage">
        <ProfileLayout title={translation.myAccount}>
          <div className="items bg-gray3 px-2 py-5 rounded-xs border border-gray">
            <Row>
              <Col xs={12} className="text-center">
                <img src="/images/my-wallet.png" alt="points" />
              </Col>
              <Col xs={12} className="mt-3">
                <Typography
                  tag="h3"
                  color="secondary"
                  className="fw-bold text-center"
                  text={translation.totalBalance}
                />
              </Col>
              <Col xs={12} className="mt-2">
                <Typography
                  tag="h3"
                  color="secondary"
                  className="text-center"
                  text={`${user.wallet_balance} ${translation.website_currency}`}
                />
              </Col>
            </Row>

            <hr style={{ height: "2px" }} className="text-gray5" />

            {!loading &&
              data &&
              data.length > 0 &&
              data.map((item, idx) => (
                <Row
                  key={item.id}
                  className={classNames("justify-content-center", {
                    "mt-3": idx > 0,
                  })}
                >
                  <Col key={item.id} xs={12} md={8} lg={10} xl={6}>
                    <div className="charge-balance-item p-4 border border-secondary rounded-sm">
                      <Row className="align-items-center">
                        <Col xs={2}>
                          <Image
                            src="/images/dollar-sign.svg"
                            alt={item.name}
                          />
                        </Col>
                        <Col xs={10} md={6}>
                          <Row>
                            <Col xs={12}>
                              <Typography
                                tag="h4"
                                variant="h5"
                                color="secondary"
                                text={`${item.sell_amount} ${translation.website_currency}`}
                              />
                            </Col>
                            <Col xs={12}>
                              <Typography
                                tag="p"
                                variant="subtitle3"
                                color="gray7"
                                text={item.name}
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={12} md={4} className="mt-2 mt-md-0">
                          <Button
                            onClick={() =>
                              Promise.all([setValue("package_id", item.id)])
                            }
                            variant="outline-primary"
                            textVariant="buttonText3"
                            className="w-100"
                            text={translation.addCredit}
                          />
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              ))}

            <Row className="justify-content-center mt-4">
              <Col xs={12}>
                <Typography
                  tag="h4"
                  color="secondary"
                  className="text-center"
                  text={translation.addCustomBalance}
                />
              </Col>
              <Col xs={12} md={8} lg={10} xl={6} className="mt-2">
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                >
                  <Row className="align-items-center">
                    <Col xs={12} md={8}>
                      <InputField
                        type="text"
                        placeholder={translation.insertAmount}
                        error={
                          errors.charge_amount && errors.charge_amount.message
                        }
                        {...registerField("charge_amount")}
                      />
                    </Col>
                    <Col xs={12} md={4} className="mt-2 mt-md-0">
                      <Button
                        type="submit"
                        variant="primary"
                        className="w-100 py-1"
                        text={translation.addCredit}
                        textVariant="buttonText3"
                      />
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </div>
        </ProfileLayout>
      </div>
    </Layout>
  );
}

export default withContainer(React.memo(MyWalletPage));

import React from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFetch, useLanguage } from "hooks";
import { phoneNumberRegexp } from "constants";
import { useForm } from "react-hook-form";
import { API_ROUTES } from "constants";
import { useAlert } from "context";
import { useSelector } from "react-redux";

function withContainer(WrappedCompoent) {
  return (props) => {
    const language = useLanguage();
    const { websiteInformation, socialMediaIcons } = useSelector(
      (state) => state.common
    );
    const [_, dispatchAlert] = useAlert();
    const schema = yup.object().shape(
      {
        name: yup.string().required(language.nameRequired),
        phone: yup
          .string()
          .required(language.phoneNumberRequired)
          .matches(phoneNumberRegexp, language.phoneNumberInvalid),
        email: yup
          .string()
          .email(language.emailInvalid)
          .required(language.emailRequired),
        message: yup.string().required(language.messageRequired),
      },
      []
    );
    const {
      register: registerField,
      handleSubmit,
      reset,
      formState: { errors },
      watch,
    } = useForm({
      resolver: yupResolver(schema),
    });
    const values = watch();

    const [{ loading }, request] = useFetch(
      {
        method: "POST",
        endpoint: API_ROUTES.submitContactForm,
        body: {
          name: values.name,
          phone: values.phone,
          email: values.email,
          subject: values.name,
          message: values.message,
        },
        onSuccess: () => {
          reset();
          dispatchAlert({
            type: "OPEN",
            payload: {
              variant: "standard",
              icon: "success",
              title: language.success,
              text: language.contactUsSuccess,
              canDismiss: true,
            },
          });
        },
      },
      [],
      false
    );

    const handleSubmitData = () => {
      request();
    };

    return (
      <WrappedCompoent
        loading={loading}
        websiteInformation={websiteInformation}
        socialMediaIcons={socialMediaIcons}
        errors={errors}
        registerField={registerField}
        handleSubmitData={handleSubmit(handleSubmitData)}
      />
    );
  };
}
export default withContainer;

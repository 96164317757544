import React from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { API_ROUTES, phoneNumberRegexp } from "constants";
import { useAlert } from "context";
import { useFetch, useLanguage } from "hooks";
import { useSelector } from "react-redux";
import { useEffect } from "react";

function withContainer(WrappedCompoent) {
  return (props) => {
    const language = useLanguage();
    const { isLoggedIn, user } = useSelector((state) => state.auth);
    const [_, dispatchAlert] = useAlert();
    const schema = yup.object().shape(
      {
        name: yup.string().required(language.nameRequired),
        phone: yup
          .string()
          .required(language.phoneNumberRequired)
          .matches(phoneNumberRegexp, language.phoneNumberInvalid),
        email: yup
          .string()
          .email(language.emailInvalid)
          .required(language.emailRequired),
        description: yup
          .string()
          .required(language.descriptionRequired)
          .min(10, language.descriptionNotLessThan10Chars),
        type: yup.string().required(language.typeRequired),
        agree: yup
          .boolean()
          .oneOf(
            [true],
            `${language.youMustAgreeToThe} ${language.termsAndConditions}`
          ),
      },
      []
    );
    const {
      register: registerField,
      control,
      handleSubmit,
      formState: { errors },
      watch,
      reset,
    } = useForm({
      resolver: yupResolver(schema),
    });
    const values = watch();

    const [{ loading }, request] = useFetch(
      {
        method: "POST",
        endpoint: API_ROUTES.sendCallout,
        body: {
          name: values.name,
          phone: values.phone,
          email: values.email,
          message: values.description,
          type: values.type,
        },
        onSuccess: () => {
          reset();
          props.handleClose();
          dispatchAlert({
            type: "OPEN",
            payload: {
              icon: "success",
              title: language.success,
              text: language.calloutSendSuccess,
              canDismiss: true,
            },
          });
        },
      },
      [],
      false
    );

    const handleSubmitData = () => {
      request();
    };

    useEffect(() => {
      if (user && isLoggedIn) {
        reset({
          name: user.name,
          email: user.email,
          phone: user.phone,
        });
      }
    }, [user, isLoggedIn, reset]);

    return (
      <WrappedCompoent
        loading={loading}
        registerField={registerField}
        control={control}
        values={values}
        errors={errors}
        handleSubmit={handleSubmit(handleSubmitData)}
        {...props}
      />
    );
  };
}
export default withContainer;

import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLanguage } from "hooks";
import { ReadMore, SkeletonGroup, Slider } from "components";
import { ServiceCard } from "components/cards";
import Title from "components/Title";
import "./style.scss";

function TopRatedServices({ loading, services }) {
  const language = useLanguage();

  return (
    <div id="top-rated-services" className="top-rated-services">
      <Container>
        <Row>
          <Col xs={12}>
            <Title
              title={language.topRatedServices}
              // description="Over 200,000 downloadable resources, with commercial license. Unlimited Downloads and more."
              color="black"
              lastColor="secondary"
              link="/our-services"
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col xs={12}>
            {!loading ? (
              <Slider
                dir={language.direction}
                xs={{
                  slidesPerView: 2,
                }}
                md={{
                  slidesPerView: 3,
                }}
                lg={{
                  slidesPerView: 4,
                  pagination: { clickable: true },
                  grid: {
                    fill: "row",
                    rows: 2,
                  },
                }}
                items={services}
                itemAs={ServiceCard}
              />
            ) : (
              <SkeletonGroup
                xs={{
                  rows: 1,
                  count: 2,
                  width: "100%",
                  height: 250,
                }}
                lg={{
                  rows: 2,
                  count: 4,
                  width: "100%",
                  height: 320,
                }}
              />
            )}
          </Col>
        </Row>
        <Row className="mt-4 d-none d-lg-block">
          <Col xs={12}>
            <ReadMore link="/services/top-rated" />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default React.memo(TopRatedServices);

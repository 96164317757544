import { Icon } from "components";
import moment from "moment";
import React from "react";
import ReactCalendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./style.scss";

function DatePicker({ minDate, maxDate, value, onChange }) {
  const [currentVal, setCurrentVal] = React.useState(null);

  const handleOnChange = (val) => {
    setCurrentVal(val);
    onChange(val);
  };

  return (
    <div className="date-picker border border-gray4 py-2 px-2">
      <ReactCalendar
        minDate={minDate || new Date()}
        maxDate={maxDate || moment(new Date()).add(2, "weeks").toDate()}
        onChange={handleOnChange}
        value={value || currentVal}
        prevLabel={<Icon name="prev" area-label="prev" className="flip-x" />}
        nextLabel={<Icon name="next" area-label="next" className="flip-x" />}
      />
    </div>
  );
}

export default React.memo(DatePicker);

import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_ROUTES } from "constants";
import { client } from "helpers";

export const addRemoveCart = createAsyncThunk(
  "home/addRemoveCart",
  async (
    { optionId, qty, extraFields, timeslot, onSuccess, onError },
    thunkAPI
  ) => {
    const { dispatch, getState, rejectWithValue } = thunkAPI;
    const { language } = getState().app;
    const { guestId, accessToken } = getState().auth;
    const { ids } = getState().cart;

    try {
      const response = await client().post(
        API_ROUTES.addRemoveCart,
        {
          product_id: optionId,
          qty: qty,
          extra_fields: extraFields,
          from_cart: 0,
          ...(timeslot && {
            timeslot_id: timeslot.id,
            timeslot_date: timeslot.date,
          }),
        },
        {
          headers: {
            Language: language,
            guestid: guestId,
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.data.status === 0) {
        return rejectWithValue(response.data.message);
      }

      // let newIds = [...ids];
      // // find if product is in cart
      // const index = newIds.findIndex((id) => id === optionId);
      // // if it is, and qty is 0, remove it
      // if (index !== -1 && qty === 0) {
      //   newIds.splice(index, 1);
      // } else if (index === -1) {
      //   // if it is not, add it
      //   newIds.push(optionId);
      // }

      if (onSuccess) {
        onSuccess();
      }

      dispatch(getCartIds());

      return true;
    } catch (error) {
      const { response } = error;
      if (onError) {
        onError(response.data.message);
      }
      return rejectWithValue(response.data.message);
    }
  }
);

export const getCartIds = createAsyncThunk(
  "home/getCartIds",
  async (_, thunkAPI) => {
    const { getState, rejectWithValue } = thunkAPI;
    const { language } = getState().app;
    const { guestId, accessToken } = getState().auth;

    try {
      const response = await client().get(
        `${API_ROUTES.getCartIds}?show_cart_id=1`,
        {
          headers: {
            language: language,
            guestid: guestId,
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const data = response.data.data;
      if (data.status === 0) {
        return rejectWithValue(data.message);
      }
      return data;
    } catch (error) {
      const { response } = error;
      return rejectWithValue(response.data.message);
    }
  }
);

import React from "react";
import classNames from "classnames";
import { Icon } from "components";

function RatingStars({
  rate,
  showText,
  className,
  starsContainerClassName,
  starClassName,
  width = 22,
  ...props
}) {
  const renderRate = React.useMemo(() => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        i <= rate ? (
          <Icon
            key={i}
            name="star"
            className={classNames("text-star", {
              [`${starClassName}`]: starClassName,
              "me-1": i < 5,
            })}
            size={width}
          />
        ) : (
          <Icon
            key={i}
            name="starEmpty"
            className={classNames("text-star", {
              [`${starClassName}`]: starClassName,
              "me-1": i < 5,
            })}
            size={width}
          />
        )
      );
    }
    return stars;
  }, [rate, starClassName]);
  return (
    <div
      className={classNames("rating-stars", {
        [`${className}`]: className,
      })}
      {...props}
    >
      <span
        className={classNames({
          [`${starsContainerClassName}`]: starsContainerClassName,
          "me-2": showText,
        })}
      >
        {renderRate}
      </span>
      {showText && (
        <span className={classNames("text-secondary")}>
          {Number(rate).toFixed(1)}
        </span>
      )}
    </div>
  );
}

export default React.memo(RatingStars);

import React from "react";
import { SectionOne, SectionTwo, SectionThree } from "./components";
import withContainer from "./Container";
import "./style.scss";

function Header({
  headerRef,
  cities,
  websiteSettings,
  helpCenter,
  socialMediaIcons,
  isLoggedIn,
  user,
  wishlistCount,
  cartCount,
  handleLogout,
}) {
  return (
    <header ref={headerRef} id="header" className="w-100 website-header">
      <section>
        <SectionOne
          websiteSettings={websiteSettings}
          socialMediaIcons={socialMediaIcons}
          helpCenter={helpCenter}
        />
      </section>
      <section>
        <SectionTwo
          isLoggedIn={isLoggedIn}
          user={user}
          handleLogout={handleLogout}
        />
      </section>
      <section>
        <SectionThree
          cities={cities}
          wishlistCount={wishlistCount}
          cartCount={cartCount}
        />
      </section>
    </header>
  );
}

export default withContainer(React.memo(Header));

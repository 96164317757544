import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLanguage } from "hooks";
import { GridList, Layout, Typography } from "components";
import { ServiceCard } from "components/cards";
import withContainer from "./Container";

function SearchPage({ keyword, loading, data, meta }) {
  const language = useLanguage();

  return (
    <Layout footerMargin>
      <div id="ServiceListPage" className="page-container">
        <Container className="mt-4">
          <Row>
            <Col>
              <Typography
                tag="h1"
                variant="h1"
                weight="bold"
                color="black"
                overflow={1}
              >
                {language.searchResults}: {keyword}
              </Typography>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col xs={12}>
              <GridList
                as={ServiceCard}
                items={data && data.length > 0 ? data : []}
                meta={meta}
                loading={loading}
                noDataImage="/images/no-search.png"
                noDataText={language.noSearch}
                skeletonHeight={440}
                xs={6}
                sm={6}
                md={4}
                lg={3}
                xl={3}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  );
}

export default withContainer(React.memo(SearchPage));

import React from "react";
import { Form, FormControl, InputGroup } from "react-bootstrap";
import PropTypes from "prop-types";
import { Button, Icon, Typography } from "components";
import classNames from "classnames";

const InputField = React.forwardRef(
  (
    {
      id,
      name,
      type,
      label,
      labelType = "formLabel",
      labelClassName,
      className,
      placeholder,
      onChange,
      value,
      error,
      color,
      labelColor = "gray7",
      iconColor,
      onIconClick,
      icon,
      secure,
      ...props
    },
    ref
  ) => {
    const [showPassword, setShowPassword] = React.useState(false);

    const handleToggleShowPassword = () => setShowPassword(!showPassword);

    const ContainerElement =
      label && labelType !== "group" ? Form.Group : React.Fragment;

    return (
      <ContainerElement>
        {label && labelType === "group" && (
          <Typography
            htmlFor={id}
            tag="label"
            className={classNames("bg-white input-group-text text-capitalize", {
              [`${labelClassName}`]: labelClassName,
              [`text-${labelColor}`]: labelColor,
            })}
            variant="body2"
            text={label}
          />
        )}
        {label && labelType === "formLabel" && (
          <Typography
            htmlFor={id}
            tag="label"
            className={classNames("form-label text-capitalize", {
              [`${labelClassName}`]: labelClassName,
              [`text-${labelColor}`]: labelColor,
            })}
            variant="body2"
            text={label}
          />
        )}
        {icon && !secure && (
          <InputGroup className="h-100 bg-white rounded-sm">
            {!onIconClick && (
              <InputGroup.Text
                as={Form.Label}
                htmlFor={id}
                className={classNames("border-0 bg-transparent my-1 px-2")}
              >
                <Icon
                  name={icon}
                  className={classNames("", {
                    [`text-${iconColor}`]: iconColor,
                  })}
                />
              </InputGroup.Text>
            )}
            <FormControl
              ref={ref}
              id={id}
              name={name}
              type={type}
              placeholder={placeholder}
              onChange={onChange}
              value={value}
              className={classNames("border-0 border-start border-gray6", {
                "is-invalid": error,
                [`${className}`]: className,
              })}
              {...props}
            />
            {onIconClick && (
              <Button
                variant=""
                onClick={onIconClick}
                icon={icon}
                className={classNames("", {
                  [`text-${iconColor}`]: iconColor,
                })}
              />
            )}
          </InputGroup>
        )}
        {!icon && !secure && (
          <FormControl
            ref={ref}
            id={id}
            name={name}
            type={type}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            className={classNames("", {
              "is-invalid": error,
              [`${className}`]: className,
            })}
            {...props}
          />
        )}
        {icon && secure && (
          <InputGroup className="h-100 bg-white rounded-sm border border-gray6">
            <InputGroup.Text
              as={Form.Label}
              htmlFor={id}
              className={classNames("border-0 bg-transparent my-1 px-2")}
            >
              <Icon
                name={icon}
                className={classNames("", {
                  [`text-${iconColor}`]: iconColor,
                })}
              />
            </InputGroup.Text>
            <FormControl
              ref={ref}
              id={id}
              name={name}
              type={showPassword ? "text" : "password"}
              placeholder={placeholder}
              onChange={onChange}
              value={value}
              className={classNames(
                "border-0 border-end border-start border-gray6",
                {
                  "is-invalid": error,
                  [`${className}`]: className,
                }
              )}
              {...props}
            />
            <InputGroup.Text
              as={Button}
              variant=""
              onClick={handleToggleShowPassword}
              icon={!showPassword ? "eyeSlash" : "eye"}
              className={classNames("border-0 bg-transparent my-1 px-2")}
            />
          </InputGroup>
        )}
        {!icon && secure && (
          <InputGroup className="h-100 bg-white rounded-sm">
            <FormControl
              ref={ref}
              id={id}
              name={name}
              type={showPassword ? "text" : "password"}
              placeholder={placeholder}
              onChange={onChange}
              value={value}
              className={classNames("", {
                "is-invalid": error,
                [`${className}`]: className,
              })}
              {...props}
            />
            <Button
              variant=""
              onClick={handleToggleShowPassword}
              icon={!showPassword ? "eyeSlash" : "eye"}
              className="border border-gray6"
            />
          </InputGroup>
        )}
        {error && <Form.Text className="text-danger">{error}</Form.Text>}
      </ContainerElement>
    );
  }
);

InputField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.oneOf(["text", "number", "email", "tel", "date", "password"])
    .isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  erorr: PropTypes.string,
  icon: PropTypes.string,
};

export default React.memo(InputField);

import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { useLanguage } from "hooks";
import { Button, Dot, Typography } from "components";
import ComplainModal from "../ComplainModal";
import "./style.scss";
import moment from "moment";
import { useSelector } from "react-redux";
import RefundModal from "../RefundModal";

function OrderItem({
  id,
  order_reference: orderReference,
  name,
  subtitle,
  image,
  created_at: orderDate,
  total_amount,
  status = 0,
  statusText,
  statusColor,
}) {
  const language = useLanguage();
  const [refundModalShwon, setRefundModalShown] = React.useState(false);
  const [complainModalShown, setComplainModalShown] = React.useState(false);

  const toggleComplainModal = () => {
    setComplainModalShown(!complainModalShown);
  };

  const toggleRefundModal = () => {
    setRefundModalShown(!refundModalShwon);
  };

  return (
    <React.Fragment>
      <ComplainModal
        order_id={id}
        order_reference={orderReference}
        visible={complainModalShown}
        handleClose={toggleComplainModal}
      />
      {refundModalShwon && (
        <RefundModal
          order_id={id}
          order_reference={orderReference}
          visible={refundModalShwon}
          handleClose={toggleRefundModal}
        />
      )}
      <div className="order-item bg-gray3 px-2 py-2 rounded-xs border border-gray">
        <Row>
          {/* <Col xl={3} lg={4} md={12} sm={12} xs={12}>
            <Link to={`/account/order/${orderReference}`}>
              <div className="item_img">
                <img src={image} alt={name} className="rounded-sm" />
              </div>
            </Link>
          </Col> */}
          <Col xl={10} lg={8} md={12} sm={12} xs={12} className="mt-4 mt-lg-0">
            <Row>
              <Col xs={12}>
                <Link to={`/account/order/${orderReference}`}>
                  <Typography
                    tag="h3"
                    variant="h4"
                    color="secondary"
                    overflow={1}
                    text={orderReference}
                  />
                </Link>
              </Col>
              <Col xs={12}>
                <Typography
                  tag="h3"
                  variant="subtitle1"
                  color="gray7"
                  overflow={2}
                  text={subtitle}
                />
              </Col>
              <Col xs={12} className="mt-1">
                <Typography
                  tag="h3"
                  variant="h4"
                  color="gray7"
                  text={moment(orderDate).format("DD/MM/YYYY")}
                />
              </Col>
              <Col xs={12} className="mt-1">
                <Typography
                  tag="h3"
                  variant="h4"
                  color="gray7"
                  text={`${total_amount} ${language.website_currency}`}
                />
              </Col>
            </Row>
          </Col>
          <Col
            xl={2}
            lg={4}
            md={12}
            sm={12}
            xs={12}
            className="mt-4 mt-lg-0 text-end"
          >
            <Row className="justify-content-center align-items-end">
              <Col xs={12} className="text-start">
                <div>
                  <Dot color={statusColor} className="me-1" size={12} />
                  <Typography
                    tag="span"
                    variant="subtitle1"
                    style={{
                      color: statusColor,
                    }}
                    text={statusText}
                  />
                </div>
              </Col>
              <Col xs={12} className="text-end">
                <Row className="gy-2">
                  <Col xs={12}>
                    {status === 0 && (
                      <Button
                        variant="outline-danger"
                        className="w-100"
                        icon="cancel"
                        text={language.cancel}
                      />
                    )}
                  </Col>
                  <Col xs={12}>
                    {status === 1 && (
                      <Button
                        onClick={toggleComplainModal}
                        variant="outline-primary"
                        className="w-100"
                        icon="complain"
                        text={language.complain}
                      />
                    )}
                  </Col>
                  <Col xs={12}>
                    {status === 1 && (
                      <Button
                        onClick={toggleRefundModal}
                        variant="outline-primary"
                        className="w-100"
                        icon="refund"
                        text={language.refund}
                      />
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
}

export default React.memo(OrderItem);

import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { useLanguage } from "hooks";
import { Typography, Button } from "components";
import "./style.scss";

function PackageCard({
  id,
  name,
  slug,
  features,
  display_price,
  max_price,
  period,
  is_recommended: recommended,
}) {
  const language = useLanguage();

  return (
    <div
      className={classNames("package-card p-1 p-sm-2", {
        "bg-primary": recommended,
        "bg-secondary": !recommended,
      })}
    >
      <div className="border-white package-card-content border border-white p-2 p-sm-2 py-lg-4 px-lg-2">
        <Row className="justify-content-center">
          <div className="title">
            <Col xs={12}>
              <Typography
                tag="h3"
                variant="h4"
                weight="bolder"
                align="center"
                color="white"
                overflow={2}
                className="text-capitalize"
              >
                {name}
              </Typography>
            </Col>
          </div>
          <Col xs={12} className="mt-3 text-center">
            <img
              src={"/images/packk.png"}
              alt={name}
              className="w-100 package-img"
              height="87"
            />
          </Col>
          <Col xs={12} className="mt-3 d-none d-lg-block">
            <ul className="features list-unstyled px-1 px-lg-2 text-capitalize">
              {features &&
                features.length > 0 &&
                features.map((item) => (
                  <li key={item.id}>
                    <Typography
                      tag="p"
                      variant="body1"
                      weight="normal"
                      color="white"
                    >
                      - {item.text}
                    </Typography>
                  </li>
                ))}
            </ul>
          </Col>
          <Col xs={12} className="mt-3">
            <Typography
              tag="h4"
              variant="h4"
              weight="bold"
              align="center"
              color="white"
            >
              {language.startingAt}
            </Typography>
            <Typography
              tag="h4"
              variant="h4"
              weight="bold"
              align="center"
              color="white"
              overflow={1}
              text={
                max_price === 0 && display_price > 0
                  ? `${language.website_currency} ${display_price}`
                  : max_price > 0
                  ? `${language.website_currency} ${max_price}`
                  : language.basedOnSelection
              }
            />
          </Col>
          <Col xs={12} lg={8} className="mt-4">
            <Button
              as={Link}
              to={`/packages/${id}`}
              variant={!recommended ? "primary" : "secondary"}
              className={`rounded-md py-1 py-2 w-100`}
              text={language.selectPlan}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default React.memo(PackageCard);

import React from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useLanguage } from "hooks";
import { switchLanguage } from "store/app";
import InputField from "components/InputField";
import Dropdown from "components/Dropdown";
import Button from "components/Button";
import { RequestCalloutModal } from "./components";
import withContainer from "./Container";
import "./style.scss";

function SectionThree({
  cities,
  searchKey,
  keyword,
  wishlistCount,
  cartCount,
  requestModalShown,
  handleCloseRequestModal,
  handleOpenRequestModal,
  handleChangeSearchKey,
  handleSearch,
}) {
  const language = useLanguage();
  const dispatch = useDispatch();

  const handleSwitchLanguage = () => {
    if (language.lang === "ar") {
      return dispatch(switchLanguage("en"));
    }
    dispatch(switchLanguage("ar"));
  };

  return (
    <React.Fragment>
      <RequestCalloutModal
        visible={requestModalShown}
        handleClose={handleCloseRequestModal}
      />
      <div id="header-section-three" className="bg-secondary">
        <Container className="h-100">
          <Row className="align-items-center h-100">
            <Col xs={12} sm={12} lg={10}>
              <Row className="align-items-center">
                <Col xs={4} sm={3} xl={2}>
                  <Dropdown
                    icon="markerOutline"
                    label={language.selectCity}
                    options={cities.map((item) => ({
                      label: item.name,
                      value: item.coming_soon,
                    }))}
                  />
                </Col>
                <Col xs={8} sm={9} xl={10}>
                  <Row>
                    <Col xs={12} sm={12} md={8} lg={8} xl={9}>
                      <Form onSubmit={handleSearch}>
                        <InputField
                          id="header-search"
                          type="text"
                          onChange={(e) =>
                            handleChangeSearchKey(e.target.value)
                          }
                          placeholder={language.search}
                          value={
                            searchKey === null
                              ? keyword
                                ? keyword
                                : ""
                              : searchKey
                          }
                          icon="search"
                          onIconClick={handleSearch}
                          className="bg-white"
                        />
                      </Form>
                    </Col>
                    <Col
                      xs={3}
                      md={4}
                      lg={4}
                      xl={3}
                      className="d-none d-md-block text-end"
                    >
                      <Button
                        onClick={handleOpenRequestModal}
                        variant="primary"
                        className="w-100"
                        text={language.requestCallout}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col lg={2} className="d-none d-lg-block action-icons">
              <ul className="list-unstyled d-flex justify-content-end align-items-center m-0">
                <li className="me-3">
                  <Button
                    variant="transparent"
                    className="text-white p-0 border-0"
                    onClick={handleSwitchLanguage}
                    icon="translate"
                  />
                </li>
                <li className="position-relative me-3">
                  <Button
                    as={Link}
                    to="/favourite"
                    variant="transparent"
                    className="text-white p-0 border-0"
                    icon="favouriteOutline"
                  />
                  <span className="action-icons-count d-flex align-items-center justify-content-center">
                    {wishlistCount <= 9 ? wishlistCount : 9 + "+"}
                  </span>
                </li>
                <li className="position-relative">
                  <Button
                    as={Link}
                    to="/cart"
                    variant="transparent"
                    className="text-white p-0 border-0"
                    icon="cart"
                  />
                  <span className="action-icons-count d-flex align-items-center justify-content-center">
                    {cartCount <= 9 ? cartCount : 9 + "+"}
                  </span>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}
export default withContainer(React.memo(SectionThree));

import React from "react";
import { Col, Row } from "react-bootstrap";
import classNames from "classnames";
import { useLanguage } from "hooks";
import { Layout, NoData, ProfileLayout, SkeletonGroup } from "components";
import { NotificationItem } from "./components";
import withContainer from "./Container";

function NotificationsPage({ loading, data }) {
  const translation = useLanguage();

  return (
    <Layout footerMargin>
      <div id="EditProfilePage">
        <ProfileLayout title={translation.myAccount}>
          {!loading && data && data.length > 0 && (
            <div className="items bg-gray3 px-2 py-2 rounded-xs border border-gray">
              <Row>
                {data.map((item, idx) => (
                  <Col
                    key={item.id}
                    xs={12}
                    className={classNames({
                      "mt-3": idx !== 0,
                    })}
                  >
                    <NotificationItem {...item} />
                  </Col>
                ))}
              </Row>
            </div>
          )}
          {!loading && data && data.length === 0 && (
            <NoData
              text={translation.noNotifications}
              image="/images/no-notifications.png"
            />
          )}
          {loading && <SkeletonGroup rows={2} count={1} height={100} />}
        </ProfileLayout>
      </div>
    </Layout>
  );
}

export default withContainer(React.memo(NotificationsPage));

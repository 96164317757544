import React from "react";
import classNames from "classnames";
import { Col, Form, Row } from "react-bootstrap";
import { Typography } from "components";
import "./style.scss";

function HorizontalOptionsField({
  id,
  label,
  labelClassName,
  labelColor,
  options,
  error,
  onChange,
  value,
  disabled,
}) {
  return (
    <div className="horizontal-option-field">
      {!disabled && (
        <Row>
          <Col xs={12}>
            <Typography
              htmlFor={id}
              tag="label"
              variant="body2"
              className={classNames("form-label text-capitalize", {
                [`${labelClassName}`]: labelClassName,
                [`text-${labelColor}`]: labelColor,
              })}
              text={label}
            />
          </Col>
          <Col xs={12}>
            <Row className="g-3">
              {options.map((item, idx) => (
                <Col key={idx} xs={2} md={4}>
                  <div
                    role="button"
                    className={classNames("option-item py-2 px-1 text-center", {
                      chosen: value === item.id,
                    })}
                    onClick={() => onChange(item.id)}
                  >
                    <Typography
                      tag="span"
                      variant="body2"
                      color="black"
                      overflow={1}
                      className="text-capitalize"
                      text={item.value}
                    />
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
          <Col xs={12}>
            {error && <Form.Text className="text-danger">{error}</Form.Text>}
          </Col>
        </Row>
      )}
    </div>
  );
}

export default HorizontalOptionsField;

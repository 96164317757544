import React from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb as BBreadCrumb,
  Card,
  Col,
  Container,
  Row,
} from "react-bootstrap";
import classNames from "classnames";
import { STORAGE_URL } from "constants";
import { useLanguage } from "hooks";
import { Skeleton } from "components";
import "./style.scss";

function BreadCrumb({
  loading,
  path,
  title,
  titleClassName,
  lastWordClassName,
  lastWordNum = 1,
  src,
  fromApi,
}) {
  const language = useLanguage();
  const titleArray = title && title.split(" ");

  return (
    <div id="BreadCrumb" className="d-none d-lg-block">
      {!loading ? (
        <React.Fragment>
          {/* <Card className="image-container bg-dark text-white rounded-0 border-0">
            <Card.Img
              src={fromApi ? `${STORAGE_URL}${src}` : src}
              alt="Card image"
            />
            <Card.ImgOverlay className="p-0">
              <div className="h-100 d-flex align-items-center">
                <Container>
                  <Row>
                    <Col xs={12}>
                      <Card.Title>
                        <h1 className={classNames(titleClassName)}>
                          {titleArray && titleArray.length > 1 ? (
                            <React.Fragment>
                              {titleArray
                                .slice(0, -lastWordNum)
                                .map((item, idx) => (
                                  <React.Fragment key={idx}>
                                    {item}{" "}
                                  </React.Fragment>
                                ))}
                              {titleArray
                                .slice(-1 * lastWordNum)
                                .map((item, idx) => (
                                  <span
                                    key={idx}
                                    className={classNames({
                                      [`${lastWordClassName}`]:
                                        lastWordClassName,
                                    })}
                                  >
                                    {item}{" "}
                                  </span>
                                ))}
                            </React.Fragment>
                          ) : (
                            title
                          )}
                        </h1>
                      </Card.Title>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Card.ImgOverlay>
          </Card> */}
          <div className="bg-gray py-3">
            <Container>
              <Row>
                <Col xs={12}>
                  <BBreadCrumb>
                    <BBreadCrumb.Item
                      linkAs={() => <Link to="/">{language.home}</Link>}
                    />
                    {path &&
                      path.map((item, idx) => (
                        <BBreadCrumb.Item
                          key={idx}
                          active={idx === path.length - 1}
                          linkAs={() => (
                            <Link to={item.link}>{item.title}</Link>
                          )}
                        >
                          {item.title}
                        </BBreadCrumb.Item>
                      ))}
                  </BBreadCrumb>
                </Col>
              </Row>
            </Container>
          </div>
        </React.Fragment>
      ) : (
        <Skeleton height={190} />
      )}
    </div>
  );
}

export default React.memo(BreadCrumb);

import React from "react";
import { API_ROUTES } from "constants";
import { useFetch, useSkipFirstRender } from "hooks";

export default function withContainer(WrappedComponent) {
  return ({ initiaSelectedValue, ...props }) => {
    const [levels, setLevels] = React.useState([]);
    const [currentLevel, setCurrentLevel] = React.useState(0);
    const [currentParent, setCurrentParent] = React.useState(0);
    const [{ data, loading }] = useFetch(
      {
        endpoint: API_ROUTES.getPlaces,
        params: `?parent_id=${currentParent}`,
      },
      [currentParent],
      true
    );

    const handleSetLastSelectedValue = (val, currentLevelSub) => {
      setCurrentParent(val);
      setCurrentLevel(currentLevelSub);
    };
    const handleSetLevels = (val) => {
      let array = levels;
      array.splice(currentLevel + 1, levels.length);

      if (val && val.length > 0) {
        array.push({
          level: array.length,
          data: val,
        });
      }

      setLevels([...array]);
    };

    useSkipFirstRender(() => {
      handleSetLevels(data);
    }, [data]);

    return (
      <WrappedComponent
        levels={levels}
        handleSetLastSelectedValue={handleSetLastSelectedValue}
        handleSetLevels={handleSetLevels}
        loading={loading}
        {...props}
      />
    );
  };
}

import React from "react";
import { useLanguage } from "hooks";
import {
  BreadCrumb,
  Button,
  Image,
  Layout,
  Skeleton,
  SkeletonGroup,
  Typography,
} from "components";
import { Col, Container, Form, Row } from "react-bootstrap";
import withContainer from "./Container";
import { Link } from "react-router-dom";
import DynamicFields from "pages/Service/Details/components/DynamicFields";
import { Controller } from "react-hook-form";
import { STORAGE_URL } from "constants";
import classNames from "classnames";

const PackageDetailsPage = ({
  loading,
  data,
  getExtraPrices,
  registerField,
  control,
  setFieldValue,
  errors,
  handleSubmit,
}) => {
  const language = useLanguage();

  return (
    <Layout footerMargin>
      {!loading && data && errors && (
        <React.Fragment>
          <BreadCrumb
            title={data.information.name}
            path={[
              { title: language.packages, link: "/packages" },
              {
                title: data.information.name,
              },
            ]}
            src="/images/about_bg.png"
            titleClassName="text-black fs-1 fw-light"
            lastWordClassName="text-secondary fw-bolder fw-bolder"
          />

          <Container className="mt-5">
            <div className="bg-gray p-4 rounded-xs">
              <Row className="justify-content-center">
                <Col xs={12} xl={12}>
                  <Row className="justify-content-center">
                    <Col xs={12} lg={4}>
                      <Image
                        src={data.information.main_image}
                        className="w-100 h-100 rounded-md"
                        style={{
                          objectFit: "cover",
                        }}
                        fromApi
                      />
                    </Col>
                    <Col xs={12} lg={4} className="mt-4 mt-lg-0">
                      <Row>
                        <Col xs={12}>
                          <Typography
                            tag="h1"
                            variant="h4"
                            color="secondary"
                            weight="bold"
                            text={data.information.name}
                          />
                        </Col>

                        <Col xs={12}>
                          <Typography
                            tag="h1"
                            variant="h4"
                            color="primary"
                            weight="bold"
                            text={`${language.total}:  ${
                              parseFloat(data.information.max_price) +
                                parseFloat(getExtraPrices) >
                              0
                                ? language.website_currency +
                                  " " +
                                  (parseFloat(data.information.max_price) +
                                    parseFloat(getExtraPrices))
                                : language.basedOnSelection
                            }`}
                          />
                        </Col>
                        <Col xs={12}>
                          <Typography
                            tag="h1"
                            variant="h4"
                            color="secondary"
                            weight="bold"
                            text={`${language.duration}: ${data.information.duration_in_months} ${language.months}`}
                          />
                        </Col>
                        <Col xs={12}>
                          <ul className="features list-unstyled text-capitalize mt-4">
                            {data.information.features &&
                              data.information.features.length > 0 &&
                              data.information.features.map((item, idx) => (
                                <li
                                  key={item.id}
                                  className={classNames("", {
                                    "mt-2": idx > 0,
                                  })}
                                >
                                  <Typography
                                    tag="p"
                                    variant="body1"
                                    weight="normal"
                                    color="black"
                                  >
                                    - {item.text}
                                  </Typography>
                                </li>
                              ))}
                          </ul>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12} lg={4}>
                      <DynamicFields
                        fields={data.fields}
                        registerField={registerField}
                        control={control}
                        setFieldValue={setFieldValue}
                        errors={errors}
                      />
                    </Col>
                  </Row>

                  <Row className="mt-4 justify-content-center">
                    <Col xs={12} lg={8} className="offset-lg-4">
                      <Row>
                        <Col xs={12}>
                          <Controller
                            control={control}
                            name="agree"
                            defaultValue={false}
                            render={({
                              field: { value, onChange },
                              fieldState: { error },
                            }) => (
                              <React.Fragment>
                                <Form.Check
                                  id="termsAndConditions"
                                  type="checkbox"
                                  onChange={onChange}
                                  checked={value}
                                  label={
                                    <Typography
                                      tag="span"
                                      variant="body1"
                                      color="gray7"
                                      text={language.byContinuingYouAgreeToOur}
                                    />
                                  }
                                  className="d-inline-block me-1"
                                />
                                <Link
                                  className="text-blue"
                                  to="/page/termsAndConditions"
                                >
                                  {language.termsAndConditions}
                                </Link>
                                {errors && errors.agree && (
                                  <Form.Text className="d-block text-danger">
                                    {errors.agree.message}
                                  </Form.Text>
                                )}
                              </React.Fragment>
                            )}
                          />
                        </Col>

                        <Col xs={6} lg={8} className="mt-2">
                          <Button
                            onClick={handleSubmit}
                            variant="outline-primary"
                            text={language.subscribe}
                            className="w-100"
                          />
                        </Col>
                        <Col xs={6} lg={4} className="mt-2">
                          <Button
                            href={
                              STORAGE_URL +
                              data.information.sample_contract_link
                            }
                            target="_blank"
                            rel="noreferrer"
                            variant="secondary"
                            text={language.readContract}
                            className="w-100"
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Container>
        </React.Fragment>
      )}

      {loading && (
        <React.Fragment>
          <Skeleton height={320} />
          <Container className="mt-5">
            <Row>
              <Col xs={12}>
                <Skeleton height={40} width="30%" />
              </Col>
            </Row>
            <Row className="mt-5">
              <Col xs={12}>
                <SkeletonGroup height={40} rows={4} />
              </Col>
            </Row>
          </Container>
        </React.Fragment>
      )}
    </Layout>
  );
};

export default withContainer(PackageDetailsPage);

import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_ROUTES } from "constants";
import { client } from "helpers";

export const getHomeSlides = createAsyncThunk(
  "home/getHomeSlides",
  async (_, thunkAPI) => {
    const { getState, rejectWithValue } = thunkAPI;
    const { language } = getState().app;
    try {
      const response = await client().get(`${API_ROUTES.getHomeSlides}`, {
        headers: {
          Language: language,
        },
      });
      const data = response.data;
      if (data.status === 0) {
        return rejectWithValue(data.message);
      }
      return data;
    } catch (error) {
      const { response } = error;
      return rejectWithValue(response.data.message);
    }
  }
);

export const getCompanyServices = createAsyncThunk(
  "home/getCompanyServices",
  async (_, thunkAPI) => {
    const { getState, rejectWithValue } = thunkAPI;
    const { language } = getState().app;
    try {
      const response = await client().get(`${API_ROUTES.getCompanyServices}`, {
        headers: {
          Language: language,
        },
      });
      const data = response.data;
      if (data.status === 0) {
        return rejectWithValue(data.message);
      }
      return data;
    } catch (error) {
      const { response } = error;
      return rejectWithValue(response.data.message);
    }
  }
);

export const getHomeVideo = createAsyncThunk(
  "home/getHomeVideo",
  async (_, thunkAPI) => {
    const { getState, rejectWithValue } = thunkAPI;
    const { language } = getState().app;
    try {
      const response = await client().get(`${API_ROUTES.getHomeVideo}`, {
        headers: {
          Language: language,
        },
      });
      const data = response.data;
      if (data.status === 0) {
        return rejectWithValue(data.message);
      }
      return data;
    } catch (error) {
      const { response } = error;
      return rejectWithValue(response.data.message);
    }
  }
);

export const getHomeCategories = createAsyncThunk(
  "home/getHomeCategories",
  async (_, thunkAPI) => {
    const { getState, rejectWithValue } = thunkAPI;
    const { language } = getState().app;
    try {
      const response = await client().get(`${API_ROUTES.getHomeCategories}`, {
        headers: {
          Language: language,
        },
      });
      const data = response.data;
      if (data.status === 0) {
        return rejectWithValue(data.message);
      }
      return data;
    } catch (error) {
      const { response } = error;
      return rejectWithValue(response.data.message);
    }
  }
);

export const getHomeTopRatedServices = createAsyncThunk(
  "home/getHomeTopRatedServices",
  async (_, thunkAPI) => {
    const { getState, rejectWithValue } = thunkAPI;
    const { language } = getState().app;
    try {
      const response = await client().get(
        `${API_ROUTES.getHomeTopRatedServices}`,
        {
          headers: {
            Language: language,
          },
        }
      );
      const data = response.data;
      if (data.status === 0) {
        return rejectWithValue(data.message);
      }
      return data;
    } catch (error) {
      const { response } = error;
      return rejectWithValue(response.data.message);
    }
  }
);

export const getHomeFacts = createAsyncThunk(
  "home/getHomeFacts",
  async (_, thunkAPI) => {
    const { getState, rejectWithValue } = thunkAPI;
    const { language } = getState().app;
    try {
      const response = await client().get(`${API_ROUTES.getHomeFacts}`, {
        headers: {
          Language: language,
        },
      });
      const data = response.data;
      if (data.status === 0) {
        return rejectWithValue(data.message);
      }
      return data;
    } catch (error) {
      const { response } = error;
      return rejectWithValue(response.data.message);
    }
  }
);

export const getHomePackages = createAsyncThunk(
  "home/getHomePackages",
  async (_, thunkAPI) => {
    const { getState, rejectWithValue } = thunkAPI;
    const { language } = getState().app;
    try {
      const response = await client().get(`${API_ROUTES.getHomePackages}`, {
        headers: {
          Language: language,
        },
      });
      const data = response.data;
      if (data.status === 0) {
        return rejectWithValue(data.message);
      }
      return data;
    } catch (error) {
      const { response } = error;
      return rejectWithValue(response.data.message);
    }
  }
);

export const getHomeLatestOffers = createAsyncThunk(
  "home/getHomeLatestOffers",
  async (_, thunkAPI) => {
    const { getState, rejectWithValue } = thunkAPI;
    const { language } = getState().app;
    try {
      const response = await client().get(`${API_ROUTES.getHomeLatestOffers}`, {
        headers: {
          Language: language,
        },
      });
      const data = response.data;
      if (data.status === 0) {
        return rejectWithValue(data.message);
      }
      return data;
    } catch (error) {
      const { response } = error;
      return rejectWithValue(response.data.message);
    }
  }
);

export const getHomeTestimonials = createAsyncThunk(
  "home/getHomeTestimonials",
  async (_, thunkAPI) => {
    const { getState, rejectWithValue } = thunkAPI;
    const { language } = getState().app;
    try {
      const response = await client().get(`${API_ROUTES.getHomeTestimonials}`, {
        headers: {
          Language: language,
        },
      });
      const data = response.data;
      if (data.status === 0) {
        return rejectWithValue(data.message);
      }
      return data;
    } catch (error) {
      const { response } = error;
      return rejectWithValue(response.data.message);
    }
  }
);

export const getHomeAdvices = createAsyncThunk(
  "home/getHomeAdvices",
  async (_, thunkAPI) => {
    const { getState, rejectWithValue } = thunkAPI;
    const { language } = getState().app;
    try {
      const response = await client().get(`${API_ROUTES.getHomeAdvices}`, {
        headers: {
          Language: language,
        },
      });
      const data = response.data;
      if (data.status === 0) {
        return rejectWithValue(data.message);
      }
      return data;
    } catch (error) {
      const { response } = error;
      return rejectWithValue(response.data.message);
    }
  }
);

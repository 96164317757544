import AddressListPage from "./List";
import AddressCreatePage from "./Create";
import AddressEditPage from "./Edit";

const Address = {
  List: AddressListPage,
  Create: AddressCreatePage,
  Edit: AddressEditPage,
};

export default Address;

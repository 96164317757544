import React, { forwardRef, Fragment, useEffect, useState } from "react";
import { Alert, Col, Form, Row } from "react-bootstrap";
import { API_ROUTES } from "constants";
import { Controller } from "react-hook-form";
import {
  useFetch,
  useLanguage,
  useReactHookForm,
  useSkipFirstRender,
} from "hooks";
import { getAncestors } from "helpers";
import { DEFAULT_LATLNG } from "constants";
import {
  AttachField,
  Button,
  InputField,
  Map,
  SelectMultiLevel,
  Typography,
} from "components";
import { useDispatch, useSelector } from "react-redux";
import { basicLogin } from "store/auth";
import { setGuestInfo } from "store/cart";
import SelectChegoe from "../SelectChegoe";

const DynamicField = ({
  control,
  id,
  label,
  type,
  is_required,
  dropdown_values,
  defaultValue = "",
}) => {
  const renderField = () => {
    switch (type) {
      case 1:
        return (
          <Controller
            name={`extra_data-text-${id}`}
            control={control}
            defaultValue={defaultValue}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <InputField
                id={id}
                label={label}
                placeholder={label}
                value={value}
                onChange={onChange}
                error={error && error.message}
              />
            )}
          />
        );
      case 2:
        return (
          <Controller
            name={`extra_data-id-${id}`}
            control={control}
            defaultValue={defaultValue}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <SelectChegoe
                id={id}
                label={label}
                options={dropdown_values.map((item) => ({
                  label: item.value,
                  value: item.id,
                }))}
                value={value}
                onChange={onChange}
                error={error && error.message}
              />
            )}
          />
        );
      case 3:
        return (
          <Controller
            name={`extra_data-text-${id}`}
            control={control}
            defaultValue={defaultValue}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <InputField
                id={id}
                label={label}
                placeholder={label}
                value={value}
                onChange={onChange}
                error={error && error.message}
              />
            )}
          />
        );
      case 4:
        return (
          <Controller
            name={`extra_data-text-${id}`}
            control={control}
            defaultValue={[]}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Fragment>
                <AttachField
                  label={label}
                  error={error && error.message}
                  value={value}
                  onChange={(val) => {
                    onChange(val);
                  }}
                />
              </Fragment>
            )}
          />
        );
      default:
        return null;
    }
  };

  return <div>{renderField()}</div>;
};

const AddressForm = forwardRef(
  (
    { createAccountOption, onAddressCreated, locationId, hideButton = false },
    ref
  ) => {
    const translation = useLanguage();
    const dispatch = useDispatch();
    const { placesWithSubLevels } = useSelector((state) => state.common);
    const { isLoggedIn } = useSelector((state) => state.auth);
    const { guestInfo } = useSelector((state) => state.cart);

    // Fetch Specific Address
    const [_, fetchAddress] = useFetch(
      {
        endpoint: API_ROUTES.getUserLocationDetails,
        params: `?location_id=${locationId}`,
        initialLoadingState: false,
      },
      [],
      false
    );

    const [{ error: serverSideError1 }, createEditAddress] = useFetch(
      {
        method: "POST",
        endpoint: locationId
          ? API_ROUTES.editUserLocation
          : API_ROUTES.addLocation,
      },
      [],
      false
    );

    // Create Account
    const [{ error: serverSideError2 }, createAccount] = useFetch(
      {
        method: "POST",
        endpoint: API_ROUTES.createAccount,
      },
      [],
      false
    );

    const [extraFields, setExtraFields] = useState([]);

    const schema = [
      {
        name: "address_nickname",
        type: "string",
        validations: [
          {
            type: "required",
            params: [translation.thisFieldIsRequired],
          },
        ],
      },
      {
        name: "first_name",
        type: "string",
        validations: [
          {
            type: "required",
            params: [translation.thisFieldIsRequired],
          },
        ],
      },
      {
        name: "middle_name",
        type: "string",
        validations: [
          {
            type: "required",
            params: [translation.thisFieldIsRequired],
          },
        ],
      },
      {
        name: "last_name",
        type: "string",
        validations: [
          {
            type: "required",
            params: [translation.thisFieldIsRequired],
          },
        ],
      },
      {
        name: "email_address",
        type: "string",
        validations: [
          {
            type: "required",
            params: [translation.thisFieldIsRequired],
          },
          {
            type: "email",
            params: [translation.emailIsNotValid],
          },
        ],
      },
      {
        name: "phone_number",
        type: "string",
        validations: [
          {
            type: "required",
            params: [translation.thisFieldIsRequired],
          },
          {
            type: "matches",
            params: [
              new RegExp(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/),
              translation.phoneNumberIsNotValid,
            ],
          },
        ],
      },
      {
        name: "place_id",
        type: "string",
        validations: [
          {
            type: "required",
            params: [translation.thisFieldIsRequired],
          },
        ],
      },
      {
        name: "building_number",
        type: "string",
        validations: [
          {
            type: "required",
            params: [translation.thisFieldIsRequired],
          },
        ],
      },
      {
        name: "floor_number",
        type: "string",
        validations: [
          {
            type: "required",
            params: [translation.thisFieldIsRequired],
          },
        ],
      },
      {
        name: "apartment_number",
        type: "string",
        validations: [
          {
            type: "required",
            params: [translation.thisFieldIsRequired],
          },
        ],
      },
      {
        name: "address",
        type: "string",
        validations: [
          {
            type: "required",
            params: [translation.thisFieldIsRequired],
          },
        ],
      },
      {
        name: "coords.lat",
        type: "string",
        validations: [
          {
            type: "min",
            params: [4, translation.youMustChooseLocation],
          },
        ],
      },
      {
        name: "isCreateAccount",
        type: "boolean",
        validations: [],
      },
      {
        name: "password",
        type: "string",
        validations: [
          {
            type: "when",
            params: [
              "isCreateAccount",
              {
                is: true,
                then: (schema) =>
                  schema
                    .min(8, translation.passwordMustBeAtLeast8Characters)
                    .max(30, translation.passwordMustBeAtMost30Characters),
              },
            ],
          },
        ],
      },
      {
        name: "confirm_password",
        type: "string",
        validations: [
          {
            type: "test",
            params: [
              "passwords-match",
              translation.passwordsDoNotMatch,
              (a, b) => {
                if (b.parent.isCreateAccount) {
                  return a === b.parent.password;
                }
                return true;
              },
            ],
          },
        ],
      },
    ];
    const {
      control,
      handleSubmit,
      updateSchema,
      updateValues,
      setValue,
      watch,
    } = useReactHookForm({
      schema,
    });
    const isCreateAccount = watch("isCreateAccount");

    const onPlaceIdChangeHandler = (placeId, cb) => {
      if (cb) {
        cb();
      }
      const ancestorTree = getAncestors(
        placeId,
        placesWithSubLevels,
        "sub_levels"
      );
      const firstAncestor = placesWithSubLevels.find(
        (place) => place.id === ancestorTree[0]
      );
      setExtraFields(firstAncestor.extra_fields);
    };

    const handleCreateEditAddress = async (data, accessToken) => {
      const accessTokenToSend =
        typeof accessToken === "string" && accessToken !== ""
          ? accessToken
          : "";
      const extraFields = Object.keys(data)
        .filter((key) => key.startsWith("extra_data-"))
        .map((field) => {
          if (field.startsWith("extra_data-id-")) {
            return {
              place_field_id: +field.replace("extra_data-id-", ""),
              place_field_value_id: data[field],
              value_text: "",
            };
          }
          if (field.startsWith("extra_data-text-")) {
            return {
              place_field_id: +field.replace("extra_data-text-", ""),
              place_field_value_id: "",
              value_text: Array.isArray(data[field])
                ? data[field].join(",")
                : data[field],
            };
          }
        });
      if (!isLoggedIn) {
        // console.log("asd", data);
        await Promise.all([
          dispatch(
            setGuestInfo({
              firstName: data["first_name"],
              middleName: data["middle_name"],
              lastName: data["last_name"],
              phone: data["phone_number"],
              email: data["email_address"],
              place_id: data["place_id"],
              address: data["address"],
              building: data["building_number"],
              floor: data["floor_number"],
              apartment: data["apartment_number"],
              coords: data["coords"],
              createAccount: data["isCreateAccount"],
              password: data["password"],
              password_confirmation: data["confirm_password"],
              extra_data: extraFields,
            })
          ),
        ]);
      }
      createEditAddress({
        bodyReq: {
          ...(typeof locationId !== "undefined" && {
            user_location_id: locationId,
          }),
          nickname: data["address_nickname"],
          first_name: data["first_name"],
          middle_name: data["middle_name"],
          last_name: data["last_name"],
          phone: data["phone_number"],
          email: data["email_address"],
          place_id: data["place_id"],
          extra_data: extraFields,
          building: data["building_number"],
          floor: data["floor_number"],
          appartment: data["apartment_number"],
          address: data["address"],
          latitude: data["coords"]["lat"],
          longitude: data["coords"]["lng"],
        },
        ...(accessTokenToSend && {
          headers: {
            Authorization: `Bearer ${accessTokenToSend}`,
          },
        }),
        onSuccessReq: () => {
          if (onAddressCreated)
            onAddressCreated(accessTokenToSend ? accessTokenToSend : "");
        },
      });
    };

    const handleCreateAccountWithAddress = async (data) => {
      createAccount({
        bodyReq: {
          name: `${data["first_name"]} ${data["middle_name"]} ${data["last_name"]}`,
          email: data["email_address"],
          phone: data["phone_number"],
          place_id: data["place_id"],
          password: data["password"],
          password_confirmation: data["confirm_password"],
        },
        onSuccessReq: () => {
          dispatch(
            basicLogin({
              email: data["email_address"],
              password: data["password"],
              onSuccess: ({ accessToken }) => {
                handleCreateEditAddress(data, accessToken);
              },
            })
          );
        },
      });
    };

    useSkipFirstRender(() => {
      if (extraFields.length > 0) {
        let newSchema = [];
        extraFields.forEach(
          ({ is_required, min_length, max_length, type, label, id }) => {
            let validations = [];
            let fieldValidationType = "string";

            if (type === 1) {
              fieldValidationType = "string";
            } else if (type === 2) {
              fieldValidationType = "string";
            } else if (type === 3) {
              fieldValidationType = "number";
            } else if (type === 4) {
              fieldValidationType = "array";
            }

            if (is_required) {
              if (fieldValidationType === "array") {
                validations.push({
                  type: "min",
                  params: [1, translation.thisFieldIsRequired],
                });
              } else {
                validations.push({
                  type: "required",
                  params: [translation.thisFieldIsRequired],
                });
              }
            }
            if (min_length) {
              if (type === 1) {
                validations.push({
                  type: "min",
                  params: [
                    min_length,
                    `${label} ${translation.mustBeAtLeast} ${min_length} ${translation.digits}`,
                  ],
                });
              } else if (type === 3) {
                validations.push({
                  type: "min",
                  params: [
                    min_length,
                    `${label} ${translation.mustBeAtLeast} ${min_length}`,
                  ],
                });
              } else if (type === 4) {
                validations.push({
                  type: "min",
                  params: [
                    min_length,
                    `${label} ${translation.mustBeAtLeast} ${min_length}`,
                  ],
                });
              }
            }

            if (max_length) {
              if (type === 1) {
                validations.push({
                  type: "max",
                  params: [
                    max_length,
                    `${label} ${translation.mustBeAtMost} ${max_length} ${translation.digits}`,
                  ],
                });
              } else if (type === 3) {
                validations.push({
                  type: "max",
                  params: [
                    max_length,
                    `${label} ${translation.mustBeAtMost} ${max_length}`,
                  ],
                });
              } else if (type === 4) {
                validations.push({
                  type: "max",
                  params: [
                    max_length,
                    `${label} ${translation.mustBeAtMost} ${max_length}`,
                  ],
                });
              }
            }

            newSchema.push({
              name: `extra_data-${type === 2 ? "id" : "text"}-${id}`,
              type: fieldValidationType,
              validations,
            });
          }
        );
        updateSchema(newSchema);
      } else {
        updateSchema(schema, true);
      }
    }, [extraFields, updateSchema]);

    useEffect(() => {
      if (locationId) {
        fetchAddress({
          onSuccessReq: ({ data }) => {
            // console.log("data", data);
            // in data, convert null to ""
            data = Object.keys(data).reduce((acc, key) => {
              acc[key] = data[key] === null ? "" : data[key];
              return acc;
            }, {});
            updateValues({
              address_nickname: data.nickname,
              first_name: data.first_name,
              middle_name: data.middle_name,
              last_name: data.last_name,
              email_address: data.email,
              phone_number: data.phone,
              place_id: data.place.id,
              building_number: data.building,
              floor_number: data.floor,
              apartment_number: data.appartment,
              address: data.address,
              coords: {
                lat: data.latitude,
                lng: data.longitude,
              },
              ...extraFields.reduce((acc, { id, type }) => {
                const extraData = data.extra_fields.find(
                  (data) => data.place_field_id === id
                );
                if (extraData) {
                  acc[`extra_data-${type === 2 ? "id" : "text"}-${id}`] =
                    type === 2
                      ? extraData.place_field_value_id
                      : type === 4
                      ? extraData.value_text.split(",")
                      : extraData.value_text;
                }
                return acc;
              }, {}),
            });
          },
        });
      }
    }, [locationId, fetchAddress, updateValues, extraFields]);

    useEffect(() => {
      if (guestInfo) {
        updateValues({
          address_nickname: guestInfo.nickname,
          first_name: guestInfo.firstName,
          middle_name: guestInfo.middleName,
          last_name: guestInfo.lastName,
          email_address: guestInfo.email,
          phone_number: guestInfo.phone,
          place_id: +guestInfo.place_id,
          building_number: guestInfo.building,
          floor_number: guestInfo.floor,
          apartment_number: guestInfo.apartment,
          address: guestInfo.address,
          coords: guestInfo.coords,
          ...extraFields.reduce((acc, { id, type }) => {
            const extraData = guestInfo.extra_data.find(
              (data) => data.place_field_id === id
            );
            if (extraData) {
              acc[`extra_data-${type === 2 ? "id" : "text"}-${id}`] =
                type === 2
                  ? extraData.place_field_value_id
                  : extraData.value_text;
            }
            return acc;
          }, {}),
        });
      }
    }, [updateValues, extraFields, guestInfo]);

    return (
      <Fragment>
        {serverSideError1 && (
          <div className="form-error mb-3">
            <Alert variant="critical">
              <nl>
                {Object.values(serverSideError1.errors).map((err, index) => (
                  <li key={index}>{err[0]}</li>
                ))}
              </nl>
            </Alert>
          </div>
        )}
        {serverSideError2 && (
          <div className="form-error mb-3">
            <Alert variant="critical">
              <nl>
                {Object.values(serverSideError2.errors).map((err, index) => (
                  <li key={index}>{err[0]}</li>
                ))}
              </nl>
            </Alert>
          </div>
        )}
        <form
          ref={ref}
          onSubmit={handleSubmit(
            !isCreateAccount
              ? handleCreateEditAddress
              : handleCreateAccountWithAddress
          )}
          name="billing-info-form"
        >
          <Row className="gy-4">
            <Col xs={12} sm={6}>
              <Controller
                control={control}
                name="address_nickname"
                defaultValue=""
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <InputField
                    type="text"
                    id="address-nickname"
                    label={translation.locationName}
                    placeholder={translation.locationName}
                    value={value}
                    onChange={onChange}
                    error={error && error.message}
                    className="py-3"
                  />
                )}
              />
            </Col>
            <Col xs={12} sm={6}>
              <Controller
                control={control}
                name="first_name"
                defaultValue=""
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <InputField
                    type="text"
                    id="first-name"
                    label={translation.first_name}
                    placeholder={translation.first_name}
                    value={value}
                    onChange={onChange}
                    error={error && error.message}
                    className="py-3"
                  />
                )}
              />
            </Col>
            <Col xs={12} sm={6}>
              <Controller
                control={control}
                name="middle_name"
                defaultValue=""
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <InputField
                    type="text"
                    id="middle-name"
                    label={translation.middle_name}
                    placeholder={translation.middle_name}
                    value={value}
                    onChange={onChange}
                    error={error && error.message}
                    className="py-3"
                  />
                )}
              />
            </Col>
            <Col xs={12} sm={6}>
              <Controller
                control={control}
                name="last_name"
                defaultValue=""
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <InputField
                    type="text"
                    id="last-name"
                    label={translation.last_name}
                    placeholder={translation.last_name}
                    value={value}
                    onChange={onChange}
                    error={error && error.message}
                    className="py-3"
                  />
                )}
              />
            </Col>
            <Col xs={12} sm={6}>
              <Controller
                control={control}
                name="email_address"
                defaultValue=""
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <InputField
                    type="text"
                    id="email-address"
                    label={translation.emailAddress}
                    placeholder={translation.emailAddress}
                    value={value}
                    onChange={onChange}
                    error={error && error.message}
                    className="py-3"
                  />
                )}
              />
            </Col>
            <Col xs={12} sm={6}>
              <Controller
                control={control}
                name="phone_number"
                defaultValue=""
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <InputField
                    type="text"
                    id="phone-number"
                    label={translation.phoneNumber}
                    placeholder={translation.phoneNumber}
                    value={value}
                    onChange={onChange}
                    error={error && error.message}
                    className="py-3"
                  />
                )}
              />
            </Col>
            <Col xs={12}>
              <Controller
                name="place_id"
                control={control}
                defaultValue=""
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <>
                    {placesWithSubLevels.length > 0 && (
                      <SelectMultiLevel
                        id="select-place"
                        label={translation.selectPlace}
                        options={placesWithSubLevels.slice()}
                        onChange={(val) => onPlaceIdChangeHandler(val)}
                        onLastLevelSelected={(val) => onChange(val)}
                        value={value}
                        error={error && error.message}
                        labelSelector="name"
                        valueSelector="id"
                      />
                    )}
                  </>
                )}
              />
            </Col>
            {extraFields.map((field) => (
              <Col key={field.id} xs={12} sm={6}>
                <DynamicField control={control} {...field} />
              </Col>
            ))}
            <Col xs={12} sm={6}>
              <Controller
                control={control}
                name="building_number"
                defaultValue=""
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <InputField
                    type="text"
                    id="building-number"
                    label={translation.building_number}
                    placeholder={translation.building_number}
                    value={value}
                    onChange={onChange}
                    error={error && error.message}
                    className="py-3"
                  />
                )}
              />
            </Col>
            <Col xs={12} sm={6}>
              <Controller
                control={control}
                name="floor_number"
                defaultValue=""
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <InputField
                    type="text"
                    id="floor-number"
                    label={translation.floor_number}
                    placeholder={translation.floor_number}
                    value={value}
                    onChange={onChange}
                    error={error && error.message}
                    className="py-3"
                  />
                )}
              />
            </Col>
            <Col xs={12} sm={6}>
              <Controller
                control={control}
                name="apartment_number"
                defaultValue=""
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <InputField
                    type="text"
                    id="apartment-number"
                    label={translation.apartment_number}
                    placeholder={translation.apartment_number}
                    value={value}
                    onChange={onChange}
                    error={error && error.message}
                    className="py-3"
                  />
                )}
              />
            </Col>
            <Col xs={12}>
              <Row className="gy-4">
                <Col xs={12} sm={6}>
                  <Controller
                    control={control}
                    name="address"
                    defaultValue=""
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <InputField
                        type="text"
                        id="address"
                        label={translation.address}
                        placeholder={translation.address}
                        value={value}
                        onChange={onChange}
                        error={error && error.message}
                        className="py-3"
                      />
                    )}
                  />
                </Col>
                <Col xs={12}>
                  <Controller
                    control={control}
                    name="coords"
                    defaultValue={{
                      lat: DEFAULT_LATLNG.lat,
                      lng: DEFAULT_LATLNG.lng,
                    }}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <Fragment>
                        <Map
                          centerOnUserLocation={!locationId}
                          zoom={16}
                          mapTypeControl={false}
                          panControl={false}
                          zoomControl={false}
                          fullscreenControl={false}
                          streetViewControl={false}
                          center={{
                            lat: value.lat,
                            lng: value.lng,
                          }}
                          onSuggestionChosen={(suggestion) => {
                            setValue("address", suggestion.formatted_address);
                          }}
                          onCenterChanged={(res) => {
                            onChange(res.latlng);
                            setValue("address", res.formatted_address);
                          }}
                          getCenterDetails
                          marker
                          autoComplete
                        />
                        {error && (
                          <span className="mt-1 text-critical">
                            {error.lat.message}
                          </span>
                        )}
                      </Fragment>
                    )}
                  />
                </Col>

                {createAccountOption && (
                  <Col xs={12}>
                    <Controller
                      control={control}
                      name="isCreateAccount"
                      defaultValue={false}
                      render={({ field: { value, onChange } }) => (
                        <Form.Check
                          id="billing-create-account"
                          className="d-inline-block"
                          label={
                            <Typography
                              className="me-2"
                              text={translation.createAccount}
                            />
                          }
                          onChange={(e) => {
                            onChange(e.target.checked);
                          }}
                        />
                      )}
                    />
                  </Col>
                )}

                {createAccountOption && isCreateAccount && (
                  <Col xs={12}>
                    <Row className="gy-4">
                      <Col xs={12} sm={6}>
                        <Controller
                          control={control}
                          name="password"
                          defaultValue=""
                          render={({
                            field: { value, onChange },
                            fieldState: { error },
                          }) => (
                            <InputField
                              id="password"
                              label={translation.password}
                              placeholder={translation.password}
                              value={value}
                              onChange={onChange}
                              error={error && error.message}
                              secure
                              className="py-3"
                            />
                          )}
                        />
                      </Col>
                      <Col xs={12} sm={6}>
                        <Controller
                          control={control}
                          name="confirm_password"
                          defaultValue=""
                          render={({
                            field: { value, onChange },
                            fieldState: { error },
                          }) => (
                            <InputField
                              id="confirm-password"
                              label={translation.confirmPassword}
                              placeholder={translation.confirmPassword}
                              value={value}
                              onChange={onChange}
                              error={error && error.message}
                              secure
                              className="py-3"
                            />
                          )}
                        />
                      </Col>
                    </Row>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
          {!hideButton && (
            <Row className="mt-4">
              <Col xs={12}>
                <Button
                  type="submit"
                  variant="primary"
                  textVariant="buttonText3"
                  className="w-100"
                  text={translation.save}
                />
              </Col>
            </Row>
          )}
        </form>
      </Fragment>
    );
  }
);

AddressForm.displayName = "AddressForm";

export default AddressForm;

import React from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useLanguage } from "hooks";
import { Heading } from "components";
import { ProfileTabs } from "./components";
import withContainer from "./Container";

function ProfileLayout({
  notificationsOn,
  handleTurnOnOffNotifications,
  title,
  description,
  children,
}) {
  const translation = useLanguage();

  const links = [
    {
      path: "/account/edit",
      icon: "account",
      title: translation.personalInfo,
    },
    {
      path: "/account/orders",
      icon: "order",
      title: translation.myOrders,
    },
    {
      path: "/account/subscriptions",
      icon: "subscription",
      title: translation.mySubscriptions,
    },
    {
      path: "/account/points",
      icon: "points",
      title: translation.myPoints,
    },
    {
      path: "/account/addresses",
      icon: "location",
      title: translation.addresses,
    },
    {
      path: "/account/wallet",
      icon: "wallet",
      title: translation.myWallet,
    },
    {
      path: "/account/change-password",
      icon: "lockUnlocked",
      title: translation.changePassword,
    },
    {
      path: "/account/notifications",
      icon: "ring",
      title: translation.notifications,
      renderRightContent: () => (
        <Form.Check
          onChange={handleTurnOnOffNotifications}
          checked={notificationsOn ? true : false}
          type="switch"
          id="custom-switch"
          className="text-primary"
          style={{
            zIndex: 10,
          }}
        />
      ),
    },
  ];

  return (
    <div id="ProfileLayout">
      <section className="mt-4">
        <Container>
          {title && (
            <Row className="mb-4">
              <Col xs={12}>
                <Heading title={title} />
              </Col>
            </Row>
          )}

          <Row>
            <Col xs={12} lg={4} xl={3} className="d-none d-lg-block">
              <ProfileTabs links={links} showName />
            </Col>

            {/* Content */}
            <Col xs={12} lg={8} xl={9}>
              <Row>
                <Col xs={12}>{children}</Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}

export default withContainer(React.memo(ProfileLayout));

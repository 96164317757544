import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { API_ROUTES } from "constants";
import { useFetch, useLanguage } from "hooks";
import { addRemoveCart } from "store/cart";
import { useAlert } from "context";
import debounce from "lodash.debounce";

function withContainer(WrappedCompoent) {
  return (props) => {
    const dispatch = useDispatch();
    const language = useLanguage();
    const [_, dispatchAlert] = useAlert();
    const { isLoggedIn } = useSelector((state) => state.auth);
    const { placeId } = useSelector((state) => state.app);
    const cartIds = useSelector((state) => state.cart.ids);
    const [dataCopy, setDataCopy] = React.useState([]);

    const [{ loading }] = useFetch({
      initialLoadingState: true,
      endpoint: API_ROUTES.getCart,
      onSuccess: (res) => {
        setDataCopy([...res.data]);
      },
    });

    const [{ data: amountsData, loading: amountsLoading }, requestAmounts] =
      useFetch(
        {
          method: "POST",
          endpoint: API_ROUTES.getCheckoutAmounts,
          initialLoadingState: true,
          body: {
            place_id: placeId,
            coupons: [],
          },
        },
        []
      );

    const debouncedIncrementDecrementCart = React.useCallback(
      debounce((optionId, qty) => {
        dispatch(
          addRemoveCart({
            optionId,
            qty,
            onSuccess: () => {
              // const newData = [...dataCopy];
              // const index = newData.findIndex(
              //   (item) => item.product_option.id === optionId
              // );
              // if (index !== -1) {
              //   newData[index].qty = qty;
              //   setDataCopy(newData);
              // }
            },
          })
        );
      }, 500),
      []
    );

    const handleIncrementDecrementCart = (optionId, qty) => {
      debouncedIncrementDecrementCart(optionId, qty);
      if (dataCopy.length > 0) {
        const newData = [...dataCopy];
        const index = newData.findIndex((item) => item.product.id === optionId);

        if (index !== -1) {
          if (qty > newData[index].product.qty || qty < 1) return;
          newData[index].qty = qty;
          setDataCopy(newData);
        }
      }
    };

    const handleRemoveProductFromCart = (optionId) => {
      return dispatchAlert({
        type: "OPEN",
        payload: {
          icon: "attention",
          title: language.attention,
          text: language.doYouWantToRemoveFromCart,
          canDismiss: true,
          buttons: [
            {
              variant: "danger",
              text: language.yes,
              onClick: () =>
                dispatch(
                  addRemoveCart({
                    optionId,
                    qty: 0,
                    onSuccess: () => {
                      requestAmounts();
                    },
                  })
                ),
            },
            {
              variant: "secondary",
              text: language.no,
            },
          ],
        },
      });
    };

    return (
      <WrappedCompoent
        isLoggedIn={isLoggedIn}
        loading={loading}
        data={
          dataCopy && [...dataCopy.filter((item) => cartIds.includes(item.id))]
        }
        amountsLoading={amountsLoading}
        amountsData={amountsData}
        handleIncrementDecrementCart={handleIncrementDecrementCart}
        handleRemoveProductFromCart={handleRemoveProductFromCart}
      />
    );
  };
}
export default withContainer;

import React from "react";
import { Card, Col, Container, Offcanvas, Row } from "react-bootstrap";
import { useLanguage } from "hooks";
import {
  BreadCrumb,
  Button,
  GridList,
  Heading,
  Image,
  Layout,
  Typography,
} from "components";
import { ServiceCard } from "components/cards";
import { RequestCalloutModal } from "components/Header/components";
import { Filters } from "../List/components";
import withContainer from "./Container";
import "./style.scss";

function ServiceListPage({
  loading,
  data,
  meta,
  infoAndFilters,
  infoAndFiltersLoading,
  appliedFilters,
  selectedFilters,
  mobileFilterVisible,
  handleOpenMobileFilter,
  handleCloseMobileFilter,
  toggleFilter,
  handleApplyFilters,
  handleResetFilters,
}) {
  const language = useLanguage();
  const [requestModalShown, setRequestModalShown] = React.useState(false);
  const handleOpenRequestModal = () => {
    setRequestModalShown(true);
  };

  const handleCloseRequestModal = () => {
    setRequestModalShown(false);
  };

  return (
    <Layout footerMargin>
      <RequestCalloutModal
        visible={requestModalShown}
        handleClose={handleCloseRequestModal}
      />
      <div id="ServiceListPage">
        <BreadCrumb
          loading={infoAndFiltersLoading}
          title={language.topRatedServices}
          path={[
            { title: language.services, link: "/our-services" },
            { title: language.topRatedServices },
          ]}
          src="/images/about_bg.png"
          titleClassName="text-black fs-1 fw-light"
          lastWordClassName="text-secondary fw-bolder fw-bolder"
        />

        <div
          onClick={handleOpenMobileFilter}
          className="sideSwipe invert d-block d-lg-none position-absolute top-50 left-0"
        >
          <Image src="/images/sideSwipe.png" alt="filterbar" />
        </div>

        <Offcanvas
          show={mobileFilterVisible}
          onHide={handleCloseMobileFilter}
          className="d-lg-none"
          backdropClassName="d-lg-none"
        >
          <Offcanvas.Header closeButton></Offcanvas.Header>
          <Offcanvas.Body>
            <Filters
              loading={infoAndFiltersLoading}
              infoAndFilters={infoAndFilters}
              selectedFilters={selectedFilters}
              toggleFilter={toggleFilter}
              handleApplyFilters={handleApplyFilters}
              handleResetFilters={handleResetFilters}
            />
          </Offcanvas.Body>
        </Offcanvas>

        <Container className="mt-5">
          <Row className="justify-content-end">
            <Col lg={9} xs={12}>
              <Heading title={language.topRatedServices} tag="h2" />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col lg={3} xs={12} className="d-none d-lg-block">
              <Filters
                loading={infoAndFiltersLoading}
                infoAndFilters={infoAndFilters}
                selectedFilters={selectedFilters}
                toggleFilter={toggleFilter}
                handleApplyFilters={handleApplyFilters}
                handleResetFilters={handleResetFilters}
              />
            </Col>
            <Col xs={12} lg={9}>
              <GridList
                as={ServiceCard}
                items={data && data.length > 0 ? data : []}
                loading={loading}
                appliedFilters={appliedFilters}
                meta={meta}
                noDataImage="/images/no-services.png"
                noDataText={language.noServices}
                skeletonHeight={440}
                xs={6}
                sm={6}
                md={6}
                lg={4}
                xl={4}
              />
              <hr />
              <div>
                <Card className="border-secondary bg-gray3">
                  <Card.Body className="text-center">
                    <Card.Title>
                      <Typography
                        tag="h6"
                        variant="h4"
                        color="black"
                        weight="bold"
                        text={language.didntFindService}
                      />
                    </Card.Title>
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        handleOpenRequestModal();
                      }}
                      variant="outline-primary"
                      className="mt-2"
                      text={language.clickHere}
                    />
                  </Card.Body>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  );
}

export default withContainer(React.memo(ServiceListPage));

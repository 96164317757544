import React from "react";
import { useForm, useLanguage } from "hooks";
import { useDispatch, useSelector } from "react-redux";
import { basicLogin } from "store/auth";
import { useAlert } from "context";

export default function withContainer(WrappedComponent) {
  return (props) => {
    const dispatch = useDispatch();
    const language = useLanguage();
    const { loading } = useSelector((state) => state.auth);
    const [_, dispatchAlert] = useAlert();
    const [form, setForm] = useForm({ email: "", password: "" });
    const [errors, setErrors] = React.useState(null);

    const handleBasicLogin = (e) => {
      e.preventDefault();
      if (loading) return;
      dispatch(
        basicLogin({
          body: form,
          onSuccess: (err) => {
            dispatchAlert({
              type: "OPEN",
              payload: {
                icon: "success",
                title: language.success,
                text: language.loginSuccess,
                canDismiss: true,
              },
            });
            setErrors(null);
          },
          onError: (err) => setErrors(err),
        })
      );
    };

    return (
      <WrappedComponent
        form={form}
        setForm={setForm}
        loading={loading}
        errors={errors}
        handleBasicLogin={handleBasicLogin}
      />
    );
  };
}

import React from "react";
import { Link } from "react-router-dom";
import { Card, Col, Row } from "react-bootstrap";
import classNames from "classnames";
import { useLanguage } from "hooks";
import { Button, Icon, Typography } from "components";
import "./style.scss";

function AddressCard({
  id,
  nickname: name,
  email,
  phone,
  place,
  address,
  is_selected: isDefault = false,
  handleMakeAddressDefault,
  handleDeleteAddress,
}) {
  const translation = useLanguage();

  return (
    <div className="address-card">
      <Card className="bg-gray border-0">
        <Card.Header
          className={classNames("py-2 px-md-4 border-0 rounded-sm-top", {
            "bg-secondary": isDefault,
            "bg-gray6": !isDefault,
          })}
        >
          <Row className="align-items-center">
            {name && (
              <Col xs={8}>
                <Typography
                  tag="h6"
                  variant="subtitle3"
                  color="white"
                  text={
                    <React.Fragment>
                      {name} {isDefault ? `(${translation.default})` : ""}
                    </React.Fragment>
                  }
                />
              </Col>
            )}
            <Col xs={4} className="text-end">
              {!isDefault && (
                <Button
                  onClick={handleDeleteAddress}
                  variant=""
                  className="text-white p-0 me-3"
                  icon="trash"
                />
              )}
              <Button
                as={Link}
                to={`/account/address/edit/${id}`}
                variant=""
                className="text-white p-0"
                icon="edit"
              />
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Row>
            {email && (
              <Col xs={12}>
                <Row className="align-items-center">
                  <Col xs={12}>
                    <Icon className="text-secondary me-2" name="email" />
                    <Typography
                      tag="span"
                      variant="body1"
                      color="black"
                      text={email}
                    />
                  </Col>
                </Row>
              </Col>
            )}
            {phone && (
              <Col xs={12}>
                <Row className="align-items-center">
                  <Col xs={12}>
                    <Icon className="text-secondary me-2" name="phone" />
                    <Typography
                      tag="span"
                      variant="body1"
                      color="black"
                      text={phone}
                      ltr
                    />
                  </Col>
                </Row>
              </Col>
            )}

            <hr style={{ height: "2px" }} className="text-gray5 my-2" />

            {place && (
              <Col xs={12}>
                <Typography
                  tag="p"
                  variant="body1"
                  color="gray7"
                  className="fw-bold"
                  text={place.name}
                />
              </Col>
            )}

            {address && (
              <Col xs={12}>
                <Typography
                  tag="p"
                  variant="body1"
                  color="gray7"
                  className="fw-bold"
                  text={address}
                />
              </Col>
            )}

            {!isDefault && (
              <Col xs={12} className="align-self-end mt-2 text-center">
                <Button
                  onClick={handleMakeAddressDefault}
                  variant="outline-primary"
                  textVariant="buttonText3"
                  text={translation.makeDefault}
                />
              </Col>
            )}
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
}

export default React.memo(AddressCard);

import React from "react";
import { useParams } from "react-router-dom";
import { API_ROUTES } from "constants";
import { useFetch, usePagination } from "hooks";
import { useEffect } from "react";

function withContainer(WrappedCompoent) {
  return (props) => {
    const { keyword } = useParams();
    const { page } = usePagination();
    const [{ loading, data, meta }] = useFetch(
      {
        initialLoadingState: true,
        endpoint: API_ROUTES.getSearchResults,
        params: `?key=${keyword}&page=${page}`,
      },
      [keyword, page],
      true
    );

    return (
      <WrappedCompoent
        keyword={keyword}
        loading={loading}
        data={data}
        meta={meta}
      />
    );
  };
}
export default withContainer;

import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import classNames from "classnames";
import { QuantityField, Typography } from "components";
import { useLanguage } from "hooks";

const SelectFieldWithQty = React.forwardRef(
  (
    {
      id,
      name,
      label,
      labelType = "formLabel",
      labelClassName,
      className,
      placeholder,
      value,
      onChange,
      defaultValue,
      error,
      labelColor = "gray7",
      iconColor,
      icon,
      children,
      min,
      max,
      ...props
    },
    ref
  ) => {
    const translation = useLanguage();
    const quantityChangeHandler = (val) => {
      if (value.value) {
        onChange({
          value: `${value.value}`,
          qty: val,
        });
      }
    };

    const onChangeHandler = (e) => {
      onChange({
        value: `${e.target.value}`,
        qty: value.qty,
      });
    };

    return (
      <Form.Group>
        {label && (
          <Typography
            htmlFor={id}
            tag="label"
            className={classNames("form-label text-capitalize", {
              [`${labelClassName}`]: labelClassName,
              [`text-${labelColor}`]: labelColor,
            })}
            variant="body2"
            text={label}
          />
        )}
        <Row className="g-2">
          <Col xs={12} sm={8}>
            <Form.Select
              ref={ref}
              id={id}
              name={name}
              onChange={onChangeHandler}
              {...(placeholder && {
                defaultValue: "",
              })}
              placeholder={placeholder}
              defaultValue={placeholder ? "" : defaultValue}
              className={classNames("", {
                [`${className}`]: className,
                "is-invalid": error,
              })}
              {...props}
            >
              <option value="">{translation.pleaseSelect}</option>
              {/* {placeholder && <option value="">{placeholder}</option>} */}
              {children}
            </Form.Select>
          </Col>
          <Col xs={12} sm={4}>
            <QuantityField
              onChange={quantityChangeHandler}
              value={value.qty}
              min={min}
              max={max}
              disabled={!value.value}
            />
            {error && <Form.Text className="text-danger">{error}</Form.Text>}
          </Col>
        </Row>
      </Form.Group>
    );
  }
);
export default React.memo(SelectFieldWithQty);

import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Layout } from "components";
import { StepOne, StepThree, StepTwo } from "./components";
import "./style.scss";

const ForgotPassword = () => {
  const [step, setStep] = React.useState(1);
  const handleNextStep = () => setStep((oldValue) => oldValue + 1);

  return (
    <Layout mainClassName="section-mb">
      <section className="mb-5">
        <Container fluid className="p-0">
          <Row>
            <Col xs={12}>
              <img src="/images/heroImg__ForgotPass.png" className="w-100" />
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="justify-content-center mt-5">
            <Col xs={12} md={8} lg={6} xl={6}>
              {step === 1 && <StepOne handleNextStep={handleNextStep} />}
              {step === 2 && <StepTwo handleNextStep={handleNextStep} />}
              {step === 3 && <StepThree />}
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};
export default React.memo(ForgotPassword);

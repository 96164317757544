import React from "react";
import classNames from "classnames";
import "./style.scss";

function Dot({ size = 10, color, ...props }) {
  return (
    <span
      className={classNames("dot-component", {
        [`${props.className}`]: props.className,
      })}
      style={{ width: size, height: size, backgroundColor: color }}
    ></span>
  );
}

export default React.memo(Dot);

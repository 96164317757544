import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_ROUTES } from "constants";
import { client } from "helpers";

export const generateGuestId = createAsyncThunk(
  "auth/generateGuestId",
  async (_, thunkAPI) => {
    const { getState, rejectWithValue } = thunkAPI;
    const { language } = getState().app;
    const device_id = Math.random().toString(36).substring(2, 15);

    try {
      const response = await client().post(
        API_ROUTES.generateGuestId,
        {
          device_id: device_id,
          ip_address: "",
        },
        {
          headers: {
            Language: language,
          },
        }
      );
      const data = response.data;
      if (data.status === 0) {
        return rejectWithValue(data.message);
      }
      return data.data.guest.id;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const register = createAsyncThunk(
  "auth/register",
  async ({ body, onError, onSuccess }, thunkAPI) => {
    const { getState, rejectWithValue } = thunkAPI;
    const { language } = getState().app;
    const { guestId } = getState().auth;
    const { firstName, lastName, placeId } = body;

    try {
      const response = await client().post(
        API_ROUTES.register,
        {
          name: `${firstName} ${lastName}`,
          place_id: placeId,
          ...body,
        },
        {
          headers: {
            Language: language,
            guestid: guestId,
          },
        }
      );
      const data = response.data;
      if (data.status === 0) {
        onError(data.data.errors);
        return rejectWithValue(data.message);
      }
      if (onSuccess) {
        onSuccess(response.data, response.data.message);
      }
      return data.data;
    } catch (err) {
      let error = err.response.data.errors
        ? err.response.data.errors
        : err.response.data.message;
      if (onError) {
        onError(error);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const basicLogin = createAsyncThunk(
  "auth/basicLogin",
  async ({ body, onError, onSuccess }, thunkAPI) => {
    const { getState, rejectWithValue } = thunkAPI;
    const { language } = getState().app;
    const { email, password } = body;

    try {
      const response = await client().post(
        API_ROUTES.basicLogin,
        {
          email,
          password,
        },
        {
          headers: {
            Language: language,
          },
        }
      );
      const data = response.data;
      if (data.status === 0) {
        onError(data.data.errors);
        return rejectWithValue(data.message);
      }
      if (onSuccess) {
        onSuccess(response.data, response.data.message);
      }
      return data.data;
    } catch (err) {
      let error = err.response.data.errors
        ? err.response.data.errors
        : err.response.data.message;
      if (onError) {
        onError(error);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const checkLogin = createAsyncThunk(
  "auth/checkLogin",
  async (_, thunkAPI) => {
    const { dispatch, getState, rejectWithValue } = thunkAPI;
    const { language } = getState().app;
    const { accessToken } = getState().auth;

    if (!accessToken) {
      return rejectWithValue("No access token");
    }

    try {
      const response = await client().get(API_ROUTES.getUserInformation, {
        headers: {
          language: language,
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const data = response.data;
      return data.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const verify = createAsyncThunk(
  "auth/verify",
  async ({ body, onError, onSuccess }, thunkAPI) => {
    const { getState, rejectWithValue } = thunkAPI;
    const { language } = getState().app;
    const { otp } = body;
    const { accessToken } = getState().auth;

    try {
      const response = await (API_ROUTES.verify,
      {
        otp,
      },
      {
        headers: {
          Language: language,
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const data = response.data;
      if (data.status === 0) {
        onError(data.data.errors);
        return rejectWithValue(data.message);
      }
      if (onSuccess) {
        onSuccess(response.data, response.data.message);
      }
      return data.data;
    } catch (err) {
      let error = err.response.data.errors
        ? err.response.data.errors
        : err.response.data.message;
      if (onError) {
        onError(error);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const editProfile = createAsyncThunk(
  "auth/editProfile",
  async ({ body, onError, onSuccess }, thunkAPI) => {
    const { getState, rejectWithValue } = thunkAPI;
    const { language } = getState().app;
    const { accessToken } = getState().auth;
    const { firstName, lastName, ...restBody } = body;

    try {
      const response = await client().post(
        API_ROUTES.editProfile,
        {
          name: `${firstName} ${lastName}`,
          ...restBody,
        },
        {
          headers: {
            Language: language,
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const data = response.data;
      if (data.status === 0) {
        onError(data.data.errors);
        return rejectWithValue(data.message);
      }
      if (onSuccess) {
        onSuccess(response.data, response.data.message);
      }
      return data.data;
    } catch (err) {
      let error = err.response.data.errors
        ? err.response.data.errors
        : err.response.data.message;
      if (onError) {
        onError(error);
      }
      return rejectWithValue(error.message);
    }
  }
);

import React from "react";
import { Col, Row } from "react-bootstrap";
import { useLanguage } from "hooks";
import { Button, Image, QuantityCounter, Typography } from "components";
import "./style.scss";
import DetailsModal from "../DetailsModal";
import { Link } from "react-router-dom";
import moment from "moment";
import classNames from "classnames";

function CartCard({
  id: productId,
  title,
  machine_name,
  main_image,
  extra_fields,
  timeslot_date,
  timeslot_time,
  price_after_discount,
  sub_total_price,
  variants,
  qty,
  maxQty,
  need_time_slots,
  need_qty,
  handleIncrementDecrementCart,
  handleRemoveProductFromCart,
}) {
  const language = useLanguage();
  const [detailsModalShown, setDetailsModalShown] = React.useState(false);

  const toggleDetailsModal = () => {
    setDetailsModalShown(!detailsModalShown);
  };

  return (
    <>
      <DetailsModal
        title={title}
        extra_fields={extra_fields}
        visible={detailsModalShown}
        handleClose={toggleDetailsModal}
      />
      <div className="cart-card-container p-1 p-lg-2 bg-gray3 rounded-xs border border-gray">
        <div className="delete_icon">
          <Button
            onClick={() => handleRemoveProductFromCart(productId)}
            variant=""
            icon="trash"
            className="text-gray7"
          />
        </div>
        <Row className="justify-content-center h-100">
          <Col xs={4} lg={3}>
            <Link to={`/service/${machine_name}`}>
              <div className="img_container">
                <Image src={main_image} alt="" className="rounded-xs" fromApi />
              </div>
            </Link>
          </Col>
          <Col xs={8} lg={9}>
            <Row className="h-100">
              <Col xs={12} lg={12}>
                <Row className="h-100">
                  <Col xs={12} className="">
                    <Typography
                      tag="h6"
                      variant="h6"
                      weight="bold"
                      overflow={1}
                      color="secondary"
                    >
                      <Link to={`/service/${machine_name}`}>{title}</Link>
                    </Typography>
                  </Col>
                  <Col xs={12} className="mt-1">
                    <Typography className="fw-light" overflow={5}>
                      {variants && variants.length > 0 && (
                        <>
                          Variation:{" "}
                          {variants.map((item, idx) => (
                            <span key={idx}>
                              {item}
                              {idx < variants.length - 1 && " | "}
                            </span>
                          ))}
                        </>
                      )}
                    </Typography>
                  </Col>
                  {timeslot_date && (
                    <Col xs={12} className="mt-1">
                      <Typography className="fw-light text-overflow-1">
                        {language.serviceDate}:{" "}
                        {moment(timeslot_date).format("DD/MM/YYYY")}
                      </Typography>
                    </Col>
                  )}
                  {timeslot_time && (
                    <Col xs={12} className="mt-1">
                      <Typography className="fw-light text-overflow-1">
                        {language.time}: {timeslot_time}
                      </Typography>
                    </Col>
                  )}
                  {extra_fields && extra_fields.length > 0 && (
                    <Col xs={12} className="mt-1">
                      <Button
                        onClick={toggleDetailsModal}
                        variant="secondary"
                        text={language.details}
                      />
                    </Col>
                  )}
                  <Col
                    xs={12}
                    sm={8}
                    lg={12}
                    className={classNames("mt-1", {
                      "align-self-end": extra_fields && extra_fields.length > 0,
                    })}
                  >
                    <Row>
                      {!need_time_slots && need_qty && (
                        <Col
                          xs={12}
                          lg={4}
                          className="order-2 order-lg-1 mt-2 mt-lg-0"
                        >
                          <QuantityCounter
                            initialCount={qty}
                            min={1}
                            max={maxQty}
                            onChange={(val) =>
                              handleIncrementDecrementCart(productId, val)
                            }
                          />
                        </Col>
                      )}
                      <Col
                        xs={12}
                        lg={8}
                        className={classNames(" order-1 order-lg-2", {
                          "text-lg-end": !need_time_slots && need_qty,
                        })}
                      >
                        <Typography className="fw-normal">
                          {language.price}
                        </Typography>
                        <Typography
                          tag="span"
                          className="fw-normal text-secondary"
                        >
                          {+sub_total_price * +qty} {language.website_currency}
                        </Typography>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default React.memo(CartCard);

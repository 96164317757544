import React, { Fragment } from "react";
import { Col, Row } from "react-bootstrap";
import classNames from "classnames";
import moment from "moment";
import { useLanguage } from "hooks";
import {
  Layout,
  ProfileLayout,
  Button,
  Typography,
  Skeleton,
  Image,
  Dot,
} from "components";
import withContainer from "./Container";
import "./style.scss";
import DetailsModal from "./components/DetailsModal";

function OrderDetailsPage({ loading, data, websiteInformation }) {
  const translation = useLanguage();

  const [detailsModal, setDetailsModal] = React.useState(null);

  return (
    <Layout footerMargin>
      <div id="EditProfilePage">
        <ProfileLayout title={translation.myAccount}>
          {!loading ? (
            <div className="bg-gray3 py-3 rounded-xs border border-gray">
              <Row className="px-4">
                <Col xs={6}>
                  <Typography
                    tag="h6"
                    variant="subtitle1"
                    color="secondary"
                    text={
                      <React.Fragment>
                        {translation.from}:{" "}
                        <Typography
                          tag="span"
                          variant="subtitle3"
                          color="gray7"
                          text={websiteInformation?.company_name}
                        />
                      </React.Fragment>
                    }
                  />
                </Col>
                <Col xs={6}>
                  <Typography
                    tag="h6"
                    variant="subtitle1"
                    color="secondary"
                    text={
                      <React.Fragment>
                        {translation.to}:{" "}
                        <Typography
                          tag="span"
                          variant="subtitle3"
                          color="gray7"
                          text={
                            data.order_information.receiver_details.person_name
                          }
                        />
                      </React.Fragment>
                    }
                  />
                </Col>
              </Row>

              <Row className="px-4">
                <Col xs={12}>
                  <Row>
                    <Col xs={6} className="mt-2">
                      <Row>
                        <Col xs={12}>
                          <Typography
                            tag="h6"
                            variant="subtitle3"
                            color="gray7"
                            text={websiteInformation?.company_phone}
                            ltr
                          />
                        </Col>
                        <Col xs={12} className="mt-2">
                          <Typography
                            tag="h6"
                            variant="subtitle3"
                            color="gray7"
                            text={websiteInformation?.company_email}
                            className="text-break"
                          />
                        </Col>
                        <Col xs={12} className="mt-2">
                          <Typography
                            tag="h6"
                            variant="subtitle3"
                            color="gray7"
                            text={websiteInformation?.company_address}
                          />
                        </Col>
                        <Col xs={12} className="mt-2">
                          <Typography
                            tag="h6"
                            variant="subtitle3"
                            color="gray7"
                            text={moment(
                              data.order_information.created_at
                            ).format("DD/MM/YYYY")}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={6} className="mt-2">
                      <Row>
                        <Col xs={12}>
                          <Typography
                            tag="h6"
                            variant="subtitle3"
                            color="gray7"
                            text={data.order_information.receiver_details.phone}
                            ltr
                          />
                        </Col>
                        <Col xs={12} className="mt-2">
                          <Typography
                            tag="h6"
                            variant="subtitle3"
                            color="gray7"
                            text={data.order_information.receiver_details.email}
                            className="text-break"
                          />
                        </Col>
                        <Col xs={12} className="mt-2">
                          <Typography
                            tag="h6"
                            variant="subtitle3"
                            color="gray7"
                            text={
                              data.order_information.receiver_details.address
                            }
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <hr style={{ height: "2px" }} className="text-gray5" />

              <Row className="px-4">
                <Col xs={12}>
                  {data.items.map((item, index) => (
                    <Fragment>
                      <DetailsModal
                        title={item.product.title}
                        extra_fields={item.extra_fields}
                        visible={detailsModal === item.id}
                        handleClose={() => setDetailsModal(null)}
                      />
                      <Row
                        key={item.id}
                        className={classNames("", {
                          "mt-3": index !== 0,
                          "border-bottom pb-3": index !== data.items.length - 1,
                        })}
                      >
                        <Col
                          xs={0}
                          sm={4}
                          md={4}
                          lg={4}
                          xl={3}
                          className="d-none d-sm-block"
                        >
                          <div className="item_img text-center">
                            <Image
                              src={item.product.main_image}
                              alt="order-item"
                              className="rounded-sm"
                              fromApi
                            />
                          </div>
                        </Col>
                        <Col xs={12} sm={8} md={8} lg={8} xl={9}>
                          <Row>
                            <Col xs={4} lg={4} xl={3}>
                              <Typography
                                tag="h6"
                                variant="subtitle3"
                                color="secondary"
                                text={translation.id}
                              />
                            </Col>
                            <Col xs={8} lg={8} xl={9}>
                              <Typography
                                tag="h6"
                                variant="subtitle3"
                                color="black"
                                text={`#${item.id}`}
                              />
                            </Col>
                          </Row>
                          <Row className="mt-sm-3">
                            <Col xs={4} lg={4} xl={3}>
                              <Typography
                                tag="h6"
                                variant="subtitle3"
                                color="secondary"
                                text={translation.name}
                              />
                            </Col>
                            <Col xs={8} lg={8} xl={9}>
                              <Typography
                                tag="h6"
                                variant="subtitle3"
                                color="black"
                                text={item.product.title}
                              />
                            </Col>
                          </Row>
                          <Row className="mt-sm-3">
                            <Col xs={4} lg={4} xl={3}>
                              <Typography
                                tag="h6"
                                variant="subtitle3"
                                color="secondary"
                                text={translation.bookingDate}
                              />
                            </Col>
                            <Col xs={8} lg={8} xl={9}>
                              <Typography
                                tag="h6"
                                variant="subtitle3"
                                color="black"
                                text={moment(item.timeslot_date).format(
                                  "DD/MM/YYYY"
                                )}
                              />
                            </Col>
                          </Row>
                          <Row className="mt-sm-3">
                            <Col xs={4} lg={4} xl={3}>
                              <Typography
                                tag="h6"
                                variant="subtitle3"
                                color="secondary"
                                text={translation.status}
                              />
                            </Col>
                            <Col xs={8} lg={8} xl={9}>
                              <div>
                                <Dot
                                  color={data.order_information.statusColor}
                                  className="me-1"
                                  size={12}
                                />
                                <Typography
                                  tag="span"
                                  variant="subtitle1"
                                  style={{
                                    color: data.order_information.statusColor,
                                  }}
                                  text={data.order_information.statusText}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row className="mt-sm-3">
                            <Col xs={12}>
                              <Button
                                className="btn btn-primary btn-sm"
                                onClick={() => setDetailsModal(item.id)}
                                text={translation.details}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Fragment>
                  ))}
                </Col>
              </Row>

              <hr style={{ height: "2px" }} className="text-gray5" />

              <Row className="px-4">
                <Col xs={12}>
                  {data.order_information.items_count > 0 && (
                    <Row>
                      <Col xs={5} sm={3} lg={4} xl={3}>
                        <Typography
                          tag="h6"
                          variant="subtitle3"
                          color="black"
                          text={
                            <React.Fragment>
                              {translation.subTotal}{" "}
                              <Typography
                                tag="span"
                                variant="caption"
                                color="black"
                                text={`${data.order_information.items_count} ${translation.items}`}
                              />
                            </React.Fragment>
                          }
                        />
                      </Col>
                      <Col xs={7} sm={9} lg={8} xl={9}>
                        <Typography
                          tag="h6"
                          variant="subtitle3"
                          color="black"
                          text={
                            <React.Fragment>
                              {translation.website_currency}{" "}
                              <Typography
                                tag="span"
                                variant="subtitle1"
                                color="black"
                                text={data.order_information.items_amount}
                              />
                            </React.Fragment>
                          }
                        />
                      </Col>
                    </Row>
                  )}

                  {data.order_information.delivery_amount > 0 && (
                    <Row className="mt-2">
                      <Col xs={5} sm={3} lg={4} xl={3}>
                        <Typography
                          tag="h6"
                          variant="subtitle3"
                          color="black"
                          text={translation.shippingFee}
                        />
                      </Col>
                      <Col xs={7} sm={9} lg={8} xl={9}>
                        <Typography
                          tag="h6"
                          variant="subtitle3"
                          color="black"
                          text={
                            <React.Fragment>
                              {translation.website_currency}{" "}
                              <Typography
                                tag="span"
                                variant="subtitle1"
                                color="black"
                                text={data.order_information.delivery_amount}
                              />
                            </React.Fragment>
                          }
                        />
                      </Col>
                    </Row>
                  )}

                  {data.order_information.discount_amount > 0 && (
                    <Row className="mt-2">
                      <Col xs={5} sm={3} lg={4} xl={3}>
                        <Typography
                          tag="h6"
                          variant="subtitle3"
                          color="black"
                          text={translation.voucherDiscount}
                        />
                      </Col>
                      <Col xs={7} sm={9} lg={8} xl={9}>
                        <Typography
                          tag="h6"
                          variant="subtitle3"
                          color="black"
                          text={
                            <React.Fragment>
                              {translation.website_currency}{" "}
                              <Typography
                                tag="span"
                                variant="subtitle1"
                                color="black"
                                text={data.order_information.discount_amount}
                              />
                            </React.Fragment>
                          }
                        />
                      </Col>
                    </Row>
                  )}

                  {data.order_information.fees_amount > 0 && (
                    <Row className="mt-2">
                      <Col xs={5} sm={3} lg={4} xl={3}>
                        <Typography
                          tag="h6"
                          variant="subtitle3"
                          color="black"
                          text={translation.fees}
                        />
                      </Col>
                      <Col xs={7} sm={9} lg={8} xl={9}>
                        <Typography
                          tag="h6"
                          variant="subtitle3"
                          color="black"
                          text={
                            <React.Fragment>
                              {translation.website_currency}{" "}
                              <Typography
                                tag="span"
                                variant="subtitle1"
                                color="black"
                                text={data.order_information.fees_amount}
                              />
                            </React.Fragment>
                          }
                        />
                      </Col>
                    </Row>
                  )}

                  {data.order_information.taxes_amount > 0 && (
                    <Row className="mt-2">
                      <Col xs={5} sm={3} lg={4} xl={3}>
                        <Typography
                          tag="h6"
                          variant="subtitle3"
                          color="black"
                          text={translation.tax}
                        />
                      </Col>
                      <Col xs={7} sm={9} lg={8} xl={9}>
                        <Typography
                          tag="h6"
                          variant="subtitle3"
                          color="black"
                          text={
                            <React.Fragment>
                              {translation.website_currency}{" "}
                              <Typography
                                tag="span"
                                variant="subtitle1"
                                color="black"
                                text={data.order_information.taxes_amount}
                              />
                            </React.Fragment>
                          }
                        />
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
              <hr style={{ height: "2px" }} className="text-gray5" />

              <Row className="px-4">
                <Col xs={12}>
                  <Row className="align-items-center">
                    <Col xs={5} sm={3} lg={4} xl={3}>
                      <Typography
                        tag="h6"
                        variant="h5"
                        color="black"
                        className="fw-normal"
                        text={translation.totalAmount}
                      />
                    </Col>
                    <Col xs={7} sm={6} lg={5} xl={7}>
                      <Typography className="text-primary fw-normal">
                        {translation.website_currency}{" "}
                        {data.order_information.total_amount}
                      </Typography>
                    </Col>
                    <Col
                      xs={12}
                      sm={12}
                      md={3}
                      lg={3}
                      xl={2}
                      className="mt-2 mt-lg-0"
                    >
                      <Button
                        variant="primary"
                        textVariant="buttonText3"
                        className="w-100"
                        text={translation.print}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          ) : (
            <Skeleton height={600} />
          )}
        </ProfileLayout>
      </div>
    </Layout>
  );
}

export default withContainer(React.memo(OrderDetailsPage));

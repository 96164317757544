import _ from "lodash";
import classNames from "classnames";
import React, { Fragment, useState, useRef } from "react";
import { useOnClickOutside } from "hooks";
import "./Autocomplete.scss";

const Autocomplete = ({
  as,
  suggestionBoxHeight = 100,
  fetcher = () => {},
  renderItem,
  keyExtractor,
  onChange,
  onSuggestionClick,
  labelSelector,
  classes = {
    input: {
      container: "",
      input: "",
      label: "",
    },
  },
  ...props
}) => {
  const Component = as;
  const ItemComponent = renderItem;
  const ref = useRef(null);
  const [open, setOpen] = useState(false);
  const [suggestions, setSuggestions] = useState([]);

  const onChangeHandler = (text) => {
    if (onChange) {
      onChange(text);
    }
    if (text.length >= 4) {
      fetcher(text, (data) => setSuggestions(data));
    } else {
      setSuggestions([]);
    }
  };

  const debouncedOnChangeHandler = _.debounce(onChangeHandler, 1000);

  const onSuggestionClickHandler = (suggestion) => {
    setSuggestions([]);
    if (onSuggestionClick) {
      onSuggestionClick(suggestion);
    }
  };

  const renderItemHandler = (item, index) => {
    if (ItemComponent) {
      return ItemComponent({ item, index });
    }
    return item[labelSelector];
  };

  const keyExtractorHandler = (item, index) => {
    if (keyExtractor) {
      return keyExtractor(item, index);
    }
    return index;
  };

  useOnClickOutside(ref, () => {
    setOpen(false);
  });

  return (
    <div
      ref={ref}
      className={classNames("text-autocomplete", {
        [classes.container]: classes.container,
      })}
    >
      {Component && (
        <Component
          classes={{
            container: classNames("", {
              [classes.input.container]: classes.input.container,
            }),
            input: classNames("", {
              [classes.input.input]: classes.input.input,
            }),
            label: classNames("", {
              [classes.input.label]: classes.input.label,
            }),
          }}
          onClick={() => setOpen(true)}
          onChange={debouncedOnChangeHandler}
          // value={text}
          {...props}
        />
      )}
      <div
        className={classNames("text-autocomplete__list", {
          [classes.list]: classes.list,
          open: open && suggestions.length > 0,
        })}
        style={{
          maxHeight: `${suggestionBoxHeight}px`,
        }}
      >
        <ul>
          {suggestions.length > 0 &&
            suggestions.map((item, idx) => (
              <Fragment
                key={
                  keyExtractorHandler(item, idx)
                    ? keyExtractorHandler(item, idx)
                    : idx
                }
              >
                <li onClick={() => onSuggestionClickHandler(item)}>
                  {renderItemHandler(item, idx)}
                </li>
                {idx !== suggestions.length - 1 && (
                  <hr className="mx-3 text-secondary" />
                )}
              </Fragment>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default Autocomplete;

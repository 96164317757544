import React, { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { getAncestors } from "helpers";
import Select from "../SelectChegoe";

const SelectMultiLevel = ({
  id,
  label,
  options,
  labelSelector,
  valueSelector,
  value,
  required = false,
  onChange,
  onLastLevelSelected,
  error,
}) => {
  const [selectedLevels, setSelectedLevels] = useState([]);
  const getData = useCallback(
    (level) => {
      let dataArr = [];
      const findTargetLevelSubLevelsRecrusivley = (level, searchArr) => {
        const targetLevelInOptions = searchArr.find(
          (item) => item.id === level
        );
        if (targetLevelInOptions) {
          dataArr = targetLevelInOptions;
          return;
        }
        // find it in every sub level
        searchArr.forEach((item) => {
          findTargetLevelSubLevelsRecrusivley(level, item.sub_levels);
        });
      };
      if (level) {
        findTargetLevelSubLevelsRecrusivley(level, options);
      }
      return dataArr;
    },
    [options]
  );

  const isLastLevel = useCallback(
    (level) => {
      const data = getData(level);
      return !data.sub_levels || data.sub_levels.length === 0;
    },
    [getData]
  );

  useEffect(() => {
    if (value) {
      const allIds = getAncestors(value, options, "sub_levels");
      setSelectedLevels(allIds ? [...allIds] : []);
    }
  }, [value, options]);

  useEffect(() => {
    if (onChange && selectedLevels.length > 0) {
      onChange(selectedLevels[selectedLevels.length - 1]);
    }
    if (selectedLevels.length > 0) {
      if (isLastLevel(selectedLevels[selectedLevels.length - 1])) {
        onLastLevelSelected(selectedLevels[selectedLevels.length - 1]);
      } else {
        onLastLevelSelected("");
      }
    }
  }, [selectedLevels, isLastLevel, onLastLevelSelected, onChange]);

  const selectChangeHandler = (value, index) => {
    if (typeof index !== "undefined") {
      setSelectedLevels((prev) => [...prev.slice(0, index + 1), value]);
    } else {
      setSelectedLevels([value]);
    }
  };

  return (
    <div className="select-multilevel">
      <Row className="gy-4">
        {/* Main Level */}
        <Col xs={12} sm={6}>
          <Select
            id={id}
            label={label}
            options={options}
            labelSelector={labelSelector}
            valueSelector={valueSelector}
            required={required}
            value={selectedLevels[0]}
            onChange={selectChangeHandler}
            error={isLastLevel(selectedLevels[0]) && error}
          />
        </Col>
        {/* Recrusive Sub Levels */}
        {selectedLevels.slice(0, selectedLevels.length).map(
          (_, index) =>
            getData(selectedLevels[index]).sub_levels.length > 0 && (
              <Col key={`${id}-${index}`} xs={12} sm={6}>
                <Select
                  id={`${id}-${index}`}
                  label={label}
                  options={getData(selectedLevels[index]).sub_levels}
                  labelSelector={labelSelector}
                  valueSelector={valueSelector}
                  required={required}
                  value={selectedLevels[index + 1]}
                  onChange={(val) => selectChangeHandler(val, index)}
                  error={isLastLevel(selectedLevels[index + 1]) && error}
                />
              </Col>
            )
        )}
      </Row>
    </div>
  );
};

export default SelectMultiLevel;

import React from "react";
import { NavLink } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { useLanguage } from "hooks";
import { Layout } from "components";

function NotFound() {
  const language = useLanguage();

  return (
    <Layout footerMargin>
      <div id="wishlist-page" className="page_container">
        <section>
          <Container>
            <Row>
              <Col
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="d-flex justify-content-center mb-5"
              ></Col>
              <Col
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="d-flex justify-content-center"
              >
                <NavLink
                  to="/"
                  className="btn btn-primary btn-outline-primary-hover"
                >
                  {language.BackToHome}
                </NavLink>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </Layout>
  );
}

export default React.memo(NotFound);

import React from "react";
import { useFetch, useLanguage } from "hooks";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { API_ROUTES } from "constants";
import { useAlert } from "context";
import { checkLogin } from "store/auth";
import { Layout, ProfileLayout, Button, Typography } from "components";
import withContainer from "./Container";

function MyPointsPage({ user }) {
  const translation = useLanguage();
  const dispatch = useDispatch();
  const { serverTranslations } = useSelector((state) => state.common);
  const [_, dispatchAlert] = useAlert();
  const [{ data }, request] = useFetch(
    {
      method: "GET",
      endpoint: API_ROUTES.convertMyPointsToWallet,
      onSuccess: () => {
        dispatch(checkLogin());
        dispatchAlert({
          type: "OPEN",
          payload: {
            icon: "success",
            title: translation.success,
            text: translation.pointsConvertedToWalletSuccess,
            canDismiss: true,
          },
        });
      },
    },
    [],
    false
  );
  const handleconvertToWallet = () => {
    request();
  };

  return (
    <Layout footerMargin>
      <div id="MyPointsPage">
        <ProfileLayout title={translation.myAccount}>
          <div className="items bg-gray3 px-2 py-5 rounded-xs border border-gray">
            <Row>
              <Col xs={12} className="text-center">
                <img src="/images/my-points.svg" alt="points" />
              </Col>
              <Col xs={12} className="mt-3">
                <Typography
                  tag="h3"
                  color="secondary"
                  className="text-center"
                  text={translation.currentPoints}
                />
              </Col>
              <Col xs={12} className="mt-2">
                {user && (
                  <Typography
                    tag="h3"
                    color="secondary"
                    className="text-center"
                    text={`${user.points} ${translation.pointsNum}`}
                  />
                )}
              </Col>
              <Col xs={12} className="mt-4 text-center">
                <Button
                  onClick={handleconvertToWallet}
                  variant="outline-primary"
                  text={translation.convertToWallet}
                />
              </Col>
            </Row>

            <hr style={{ height: "2px" }} className="text-gray5" />

            <Row className="px-5">
              <Col xs={12}>
                <Typography
                  tag="h3"
                  color="secondary"
                  text={translation.pointsPolicy}
                />
              </Col>
              <Col xs={12}>
                <Typography
                  tag="p"
                  color="black"
                  text={serverTranslations.text_in_my_points}
                />
              </Col>
            </Row>
          </div>
        </ProfileLayout>
      </div>
    </Layout>
  );
}

export default withContainer(React.memo(MyPointsPage));

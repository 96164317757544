import { createSlice } from "@reduxjs/toolkit";
import { addRemoveCart, getCartIds } from "./actions";

const initialState = {
  ids: [],
  guestInfo: null,
  extraFields: null,
  subscriptionId: null,
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setGuestInfo: (state, action) => {
      state.guestInfo = action.payload;
    },
    clearGuestInfo: (state) => {
      state.guestInfo = null;
    },
    setExtraFields: (state, action) => {
      state.extraFields = action.payload;
    },
    clearExtraFields: (state) => {
      state.extraFields = null;
    },
    setSubscriptionId: (state, action) => {
      state.subscriptionId = action.payload;
    },
    clearSubscriptionId: (state) => {
      state.subscriptionId = null;
    },
  },
  extraReducers: {
    [getCartIds.fulfilled]: (state, action) => {
      state.ids = action.payload;
    },
    // [addRemoveCart.fulfilled]: (state, action) => {
    //   state.ids = action.payload;
    // },
  },
});

export { getCartIds, addRemoveCart };
export const {
  setGuestInfo,
  clearGuestInfo,
  setExtraFields,
  clearExtraFields,
  setSubscriptionId,
  clearSubscriptionId,
} = cartSlice.actions;
export default cartSlice.reducer;

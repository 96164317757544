import React from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { API_ROUTES } from "constants";
import { useForm } from "react-hook-form";
import { useAlert } from "context";
import { useFetch, useLanguage } from "hooks";
import { useEffect } from "react";
import { useSelector } from "react-redux";

function withContainer(WrappedCompoent) {
  return ({ testimonials }) => {
    const language = useLanguage();
    const [_, dispatchAlert] = useAlert();
    const { isLoggedIn } = useSelector((state) => state.auth);

    // Fetch user orderes services
    const [
      { loading: userOrderedServicesLoading, data: userOrderedServices },
      requestUserOrderedProducts,
    ] = useFetch(
      {
        endpoint: API_ROUTES.getUserOrderedProducts,
      },
      [],
      false
    );

    // Testimonial Schema
    const schema = yup.object().shape(
      {
        product_id: yup.string().required(language.serviceRequired),
        rating: yup.number(),
        comment: yup.string().required(language.messageRequired),
        notARobot: yup.boolean().oneOf([true]),
      },
      []
    );

    // Testimonial form
    const {
      control,
      handleSubmit,
      reset,
      watch,
      formState: { errors },
    } = useForm({
      resolver: yupResolver(schema),
    });
    const values = watch();

    // Submit testimonial
    const [{ loading: createTestimonialLoading }, createTestimonial] = useFetch(
      {
        method: "POST",
        endpoint: API_ROUTES.addServiceReview,
        body: {
          product_id: values.product_id,
          rate: values.rating / 20,
          comment: values.comment,
        },
        onSuccess: () => {
          reset();
          dispatchAlert({
            type: "OPEN",
            payload: {
              variant: "standard",
              icon: "success",
              title: language.success,
              text: language.addTestimonialSuccess,
              canDismiss: true,
            },
          });
        },
      },
      [],
      false
    );

    const handleSubmitTestimonial = () => {
      createTestimonial();
    };

    useEffect(() => {
      if (isLoggedIn) {
        requestUserOrderedProducts();
      }
    }, [isLoggedIn, requestUserOrderedProducts]);

    return (
      <WrappedCompoent
        control={control}
        userOrderedServicesLoading={userOrderedServicesLoading}
        userOrderedServices={userOrderedServices}
        testimonials={testimonials}
        handleSubmitTestimonial={handleSubmit(handleSubmitTestimonial)}
        createTestimonialLoading={createTestimonialLoading}
      />
    );
  };
}
export default withContainer;

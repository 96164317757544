import React from "react";
import {
  Badge,
  Col,
  Dropdown as BootstrapDropdown,
  Row,
} from "react-bootstrap";
import { Icon, Typography } from "components";
import "./style.scss";
import { useEffect } from "react";

function Dropdown({ label, icon, value, options, onSelect }) {
  const [chosenValue, setChosenValue] = React.useState(null);

  const handleOnSelect = (val) => {
    if (onSelect) onSelect(val);
    setChosenValue(val);
  };

  useEffect(() => {
    if (options && options.length > 0) {
      setChosenValue(options[0].label);
    }
  }, [options]);

  return (
    <div className="custom-dropdown">
      <Row>
        <Col xs={3} md={4}>
          <Icon
            name={icon}
            size={46}
            className="text-white d-none d-md-block"
          />
          <Icon
            name={icon}
            size={24}
            className="text-white d-block d-md-none"
          />
        </Col>
        <Col xs={9} md={8}>
          <Row>
            {label && (
              <Col xs={12} className="d-none d-md-block">
                <Typography
                  tag="label"
                  htmlFor="selectCity"
                  variant="h6"
                  color="white"
                  className="d-block w-100"
                  text={label}
                />
              </Col>
            )}
            <Col xs={12}>
              <BootstrapDropdown className="w-100">
                <BootstrapDropdown.Toggle
                  variant=""
                  className="text-start text-white p-0 text-overflow-1"
                  id="selectCity"
                >
                  {value || chosenValue}
                </BootstrapDropdown.Toggle>
                {options && (
                  <div className="dropdown-menu-container">
                    <BootstrapDropdown.Menu>
                      {options.map((item, idx) => (
                        <BootstrapDropdown.Item
                          key={idx}
                          className="d-flex align-items-center justify-content-between"
                          // onClick={() => handleOnSelect(item)}
                        >
                          {item.label}{" "}
                          {item.value !== 0 && (
                            <Badge
                              pill
                              bg="primary"
                              style={{
                                fontSize: "9px",
                              }}
                            >
                              Coming Soon
                            </Badge>
                          )}
                        </BootstrapDropdown.Item>
                      ))}
                    </BootstrapDropdown.Menu>
                  </div>
                )}
              </BootstrapDropdown>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default React.memo(Dropdown);

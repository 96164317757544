import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAlert } from "context";
import { useFetch, useLanguage } from "hooks";
import { addRemoveWishlist } from "store/wishlist";
import { addRemoveCart } from "store/cart";
import { navigate } from "helpers";
import { API_ROUTES } from "constants";
import { useEffect } from "react";

function withContainer(WrappedCompoent) {
  return ({ data, loading }) => {
    const language = useLanguage();
    const dispatch = useDispatch();
    const [_, dispatchAlert] = useAlert();
    const [originalFields, setOriginalFields] = useState([]);
    const schema = yup.lazy(() => {
      const shapes = {};
      const newFields = [];
      data.extra_fields.forEach((field) => {
        if (field.type === 7) {
          newFields.push(field);
          field.group_fields.forEach((subField) => {
            if (subField.type === 7) {
              newFields.push(subField);

              subField.group_fields.forEach((subSubField) => {
                newFields.push(subSubField);
              });
            } else {
              newFields.push(subField);
            }
          });
        } else {
          newFields.push(field);
        }
      });

      newFields.forEach((field) => {
        shapes[field.id] = yup;

        if (field.type === 3 || field.type === 8) {
          shapes[field.id] = shapes[field.id].number();
        } else if (field.type === 4 || field.type === 5) {
          shapes[field.id] = shapes[field.id].array();
        } else if (field.type === 7) {
          shapes[field.id] = shapes[field.id].array();
          if (field.is_required) {
            shapes[field.id] = shapes[field.id].min(
              1,
              `\${label} ${language.isRequired}`
            );
          }
          if (field.min_length) {
            shapes[field.id] = shapes[field.id].min(
              field.min_length,
              `\${label} ${language.mustBeAtLeast} \${min}`
            );
          }

          if (field.max_length) {
            shapes[field.id] = shapes[field.id].max(
              field.max_length,
              `\${label} ${language.mustBeLessThan} \${max}`
            );
          }
        } else if ([9, 11].includes(field.type)) {
          shapes[field.id] = shapes[field.id]
            .object()
            .shape({
              value: yup
                .string()
                .min(
                  field.min_length || field.is_required ? 1 : 0,
                  `${field.label} is required`
                )
                .label(field.label),
              qty: yup
                .number()
                .min(field.min_length)
                .max(field.max_length)
                .label(field.label),
            })
            .required("This field is required.");
        } else {
          shapes[field.id] = shapes[field.id].string();
        }
        shapes[field.id] = shapes[field.id].label(field.label);

        if ([9, 11].includes(field.type)) return;
        if (field.type === 7) return;

        if (field.is_required) {
          shapes[field.id] = shapes[field.id].required(
            `\${label} ${language.isRequired}`
          );
        }

        if (field.min_length) {
          shapes[field.id] = shapes[field.id].min(
            field.min_length,
            `\${label} ${language.mustBeAtLeast} \${min}`
          );
        }

        if (field.max_length) {
          shapes[field.id] = shapes[field.id].max(
            field.max_length,
            `\${label} ${language.mustBeLessThan} \${max}`
          );
        }

        if (field.type === 1) {
          if (field.min_length) {
            shapes[field.id] = shapes[field.id].min(
              field.min_length,
              `\${label} ${language.mustBeAtLeast} \${min} ${language.characters}`
            );
          }

          if (field.max_length) {
            shapes[field.id] = shapes[field.id].max(
              field.max_length,
              `\${label} ${language.mustBeLessThan} \${max} ${language.characters}`
            );
          }
        }
      });

      return yup.object().shape(shapes);
    });
    const {
      register: registerField,
      control,
      setValue: setFieldValue,
      handleSubmit,
      formState: { errors },
      watch,
    } = useForm({
      resolver: yupResolver(schema),
      reValidateMode: "onChange",
    });
    const values = watch();

    // React.useEffect(() => {
    // console.log("errors", errors);
    // }, [errors]);

    const wishlistIds = useSelector((state) => state.wishlist.ids);
    const isInFavourite = wishlistIds.includes(data.id);
    const [currentOptionGroup, setCurrentOptionGroup] = React.useState(null);
    const [selectedValues, setSelectedValues] = React.useState([]);
    const [selectedOptions, setSelectedOptions] = React.useState([]);
    const [qty, setQty] = React.useState(1);
    const [selectedDate, setSelectedDate] = React.useState(null);
    const [selectedTimeslot, setSelectedTimeslot] = React.useState(null);
    const cartIds = useSelector((state) => state.cart.ids);
    const isInCart =
      currentOptionGroup && cartIds.includes(currentOptionGroup.id);

    // Get Timeslots by date
    const [{ data: timeSlotData, loading: timeSlotLoading }] = useFetch(
      {
        endpoint: API_ROUTES.getTimeslotsByDayDate,
        params: `?date=${selectedDate}`,
        onSuccess: (data) => {
          if (data.data.length === 0) {
            dispatchAlert({
              type: "OPEN",
              payload: {
                icon: "attention",
                title: language.error,
                text: language.noTimeslotsAvailable,
                canDismiss: true,
              },
            });
          } else {
            setSelectedTimeslot(data.data[0].id);
          }
        },
      },
      [selectedDate],
      false
    );

    // Dispatch add to wishlist by id
    const handleAddRemoveWishlist = () => {
      if (data.id) {
        dispatch(addRemoveWishlist({ productId: data.id }));
      }
    };

    const handleOptionClicked = (item) => {
      let childrens = data.childrens;
      let itemId = item.id;
      let itemOptionId = item.option_id;
      let selectedValuesLocal = selectedValues;
      let selectedOptionsLocal = selectedOptions;
      if (selectedValuesLocal.indexOf(itemId) < 0) {
        if (selectedOptionsLocal.indexOf(itemOptionId) < 0) {
          selectedValuesLocal.push(itemId);
          setSelectedValues(selectedValuesLocal);

          selectedOptionsLocal.push(itemOptionId);
          setSelectedOptions(selectedOptionsLocal);
        } else {
          let indexToReplace = selectedOptionsLocal.indexOf(itemOptionId);
          selectedValuesLocal.splice(indexToReplace, 1);
          setSelectedValues(selectedValuesLocal);

          selectedOptionsLocal.splice(indexToReplace, 1);
          setSelectedOptions(selectedOptionsLocal);

          selectedValuesLocal.push(itemId);
          setSelectedValues(selectedValuesLocal);

          selectedOptionsLocal.push(itemOptionId);
          setSelectedOptions(selectedOptionsLocal);
        }
      }

      let targetGroup = null;

      for (let i = 0; i < childrens.length; i++) {
        let optGroup = childrens[i];
        let doesAllValuesExist = selectedValuesLocal.every((val) =>
          optGroup.values.includes(val)
        );

        if (doesAllValuesExist && !targetGroup) {
          targetGroup = optGroup;
        }
      }

      while (!targetGroup) {
        selectedValuesLocal.shift();
        setSelectedValues(selectedValuesLocal);

        selectedOptionsLocal.shift();
        setSelectedOptions(selectedOptionsLocal);

        for (let i = 0; i < childrens.length; i++) {
          let optGroup = childrens[i];
          let doesAllValuesExist = selectedValuesLocal.every((val) =>
            optGroup.values.includes(val)
          );

          if (doesAllValuesExist && !targetGroup) {
            targetGroup = optGroup;
          }
        }
      }
      setCurrentOptionGroup(targetGroup);
    };

    const handleSetQty = (qty) => {
      setQty(qty);
    };

    const handleAddRemoveCart = () => {
      let extraFields = [];
      if (originalFields.length > 0) {
        const valueArr = Object.entries(values)
          .filter((item) => {
            if (typeof item[1] === "string" && item[1] === "") {
              return false;
            } else if (typeof item[1] === "object" && item[1].value === "") {
              return false;
            } else if (Array.isArray(item[1]) && item[1].length === 0) {
              return false;
            }
            if (
              originalFields.findIndex(
                (x) => String(x.id) === String(item[0])
              ) < 0
            ) {
              return false;
            }
            return true;
          })
          .map((item) => ({
            id: item[0],
            value: item[1],
          }));
        valueArr.forEach((item, idx) => {
          let { type, values } = originalFields.find(
            (field) => String(field.id) === String(item.id)
          );

          let value;
          if ([2, 6, 10].includes(type)) {
            value = values.find(
              (val) => String(val.id) === String(item.value)
            ).value;
          }

          if ([9, 11].includes(type)) {
            value = values.find(
              (val) => String(val.id) === String(item.value.value)
            ).value;
          }

          extraFields.push({
            field_id: item.id,
            ...([1, 3, 8].includes(type) && {
              value_id: "",
              value_text: item.value,
            }),
            ...([2, 6, 10].includes(type) && {
              value_id: item.value,
              value_text: value,
            }),
            ...([4, 5].includes(type) && {
              value_id: type === 5 ? item.value.map((el) => el.value) : "",
              value_text:
                type === 5
                  ? item.value.map((el) => el.label).join(",")
                  : item.value.join(","),
            }),
            ...([9, 11].includes(type) && {
              value_id: item.value.value,
              value_text: `${value} &#xd7; ${item.value.qty}`,
            }),
          });
        });
      }

      dispatch(
        addRemoveCart({
          optionId: currentOptionGroup.id,
          qty,
          extraFields,
          // ...(selectedDate !== null &&
          //   selectedTimeslot !== null && {
          timeslot: {
            id: selectedTimeslot !== null ? selectedTimeslot : "",
            date: selectedDate !== null ? selectedDate : "",
          },
          // }),
          onSuccess: () =>
            dispatchAlert({
              type: "OPEN",
              payload: {
                icon: "success",
                title: language.success,
                text: language.productAddedToCart,
                canDismiss: true,
                buttons: [
                  {
                    variant: "secondary",
                    text: language.stayHere,
                  },
                  {
                    variant: "primary",
                    text: language.goToCart,
                    onClick: () => navigate("/cart"),
                  },
                ],
              },
            }),
          onError: (err) =>
            dispatchAlert({
              type: "OPEN",
              payload: {
                icon: "attention",
                title: language.error,
                text: err,
                canDismiss: true,
                buttons: [
                  {
                    variant: "secondary",
                    text: language.ok,
                  },
                ],
              },
            }),
        })
      );
    };

    useEffect(() => {
      data.extra_fields.forEach((field) => {
        if (field.type === 7) {
          field.group_fields.forEach((subField) => {
            if (subField.type === 7) {
              subField.group_fields.forEach((subSubField) => {
                setOriginalFields((prev) => [...prev, subSubField]);
              });
            } else {
              setOriginalFields((prev) => [...prev, subField]);
            }
          });
        } else {
          setOriginalFields((prev) => [...prev, field]);
        }
      });
    }, [data.extra_fields]);

    React.useEffect(() => {
      let groupWithCheapestPrice = data.childrens.reduce((a, b) =>
        Number(a.price_after_discount) < Number(b.price_after_discount) ? a : b
      );
      setCurrentOptionGroup(groupWithCheapestPrice);
    }, [data.childrens]);

    return (
      <WrappedCompoent
        loading={loading}
        data={data}
        timeSlotData={timeSlotData}
        timeSlotLoading={timeSlotLoading}
        errors={errors}
        isFavourite={isInFavourite}
        currentOptionGroup={currentOptionGroup}
        isInCart={isInCart}
        selectedExtraFields={
          Object.keys(values).length > 0 ? Object.entries(values) : []
        }
        handleOptionClicked={handleOptionClicked}
        handleSetQty={handleSetQty}
        handleAddRemoveWishlist={handleAddRemoveWishlist}
        handleAddRemoveCart={handleSubmit(handleAddRemoveCart)}
        handleValidateSubmit={(fnc) => handleSubmit(fnc)}
        registerField={registerField}
        control={control}
        getValue={(id) => {
          return values[id];
        }}
        setFieldValue={setFieldValue}
        setSelectedDate={setSelectedDate}
        selectedTimeslot={selectedTimeslot}
        setSelectedTimeslot={setSelectedTimeslot}
      />
    );
  };
}
export default withContainer;

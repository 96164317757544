import React from "react";
import { NavLink } from "react-router-dom";
import { Col, ListGroup, Row } from "react-bootstrap";
import { useLanguage } from "hooks";
import { Icon, Layout, Typography } from "components";
import { ProfileLinks } from "./components";
import withContainer from "./Container";

function AccountMobile({
  isLoggedIn,
  user,
  links,
  publicPages,
  handleSwitchLanguage,
  ...props
}) {
  const translation = useLanguage();

  return (
    <Layout>
      <div id="AccountMobilePage">
        <div className="bg-gray py-4 px-4">
          <Row>
            <Col xs={12}>
              <Row>
                <Col xs={12}>
                  <h1 className="text-primary">{translation.hello}</h1>
                </Col>
                <Col xs={12}>
                  <h1 className="text-primary">
                    {isLoggedIn && `${translation.callingTitles} ${user.name}`}
                    {!isLoggedIn && `${translation.visitor}`}
                  </h1>
                </Col>
                <Col xs={12}>
                  {isLoggedIn && (
                    <p className="fw-bold text-darkgray">{user.email}</p>
                  )}
                </Col>
                <Col xs={12} className="mt-3">
                  <div
                    className="d-inline-flex justify-content-center align-items-center"
                    onClick={handleSwitchLanguage}
                  >
                    <Icon name="translate" className="me-2" />
                    <Typography
                      tag="span"
                      variant="h4"
                      weight="normal"
                      color="black"
                    >
                      {translation.oppositeLanguageName}
                    </Typography>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div className="">
          <Row>
            <Col xs={12}>
              <ProfileLinks links={links} />
            </Col>
          </Row>
        </div>

        <div className="mt-4">
          <Row>
            <Col xs={12}>
              <ListGroup
                horizontal
                className="justify-content-center flex-wrap"
              >
                {publicPages.length > 0 &&
                  publicPages.map((item) => (
                    <ListGroup.Item className="border-0">
                      <NavLink
                        className="text-primary"
                        to={`/page/${item.machine_name}`}
                      >
                        {item.name}
                      </NavLink>
                    </ListGroup.Item>
                  ))}
              </ListGroup>
            </Col>
          </Row>
        </div>
      </div>
    </Layout>
  );
}
export default withContainer(React.memo(AccountMobile));

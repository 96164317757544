import React, { Fragment } from "react";
import { Modal } from "react-bootstrap";
import ModalVideo from "react-modal-video";
import { STORAGE_URL } from "constants";
import { Image } from "components";
import { useState } from "react";

function VideoImage({ image, video, video_provider }) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Fragment>
      <div className="position-relative" onClick={handleOpen}>
        <Image src={image} className="video-image w-100 rounded-xs" fromApi />

        <div className="position-absolute top overlay h-100 w-100 d-flex align-items-center justify-content-center bg-overlay">
          <Image src="/images/play-circle.svg" role="button" className="w-25" />
        </div>
      </div>

      {video_provider === "local" ? (
        <Modal show={open} onHide={handleClose} size="lg" centered>
          <video controls autoPlay>
            <source src={`${STORAGE_URL}${video}`} type="video/mp4" />
          </video>
        </Modal>
      ) : (
        <ModalVideo
          channel={video_provider}
          videoId={video}
          autoplay
          isOpen={open}
          onClose={handleClose}
        />
      )}
    </Fragment>
  );
}

export default React.memo(VideoImage);

import React from "react";
import { Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { API_ROUTES } from "constants";
import { useAlert } from "context";
import { useFetch, useLanguage } from "hooks";
import { Button, SelectField, TextAreaField, Typography } from "components";

function ComplainModal({
  order_id,
  order_reference,
  visible,
  handleClose,
  ...props
}) {
  const translation = useLanguage();
  const { orderComplainReasons } = useSelector((state) => state.common);
  const [_, dispatchAlert] = useAlert();

  const schema = yup.object().shape({
    reason_id: yup.string().required(translation.orderReasonRequired),
    comment: yup
      .string()
      .required(translation.orderComplainDescriptionRequired),
  });

  const { control, handleSubmit, watch, reset } = useForm({
    resolver: yupResolver(schema),
    // reValidateMode: "onChange",
  });
  const values = watch();

  const [{ loading }, request] = useFetch(
    {
      method: "POST",
      endpoint: API_ROUTES.sendOrderComplain,
      body: {
        order_id: order_id,
        reason_id: values.reason_id,
        comment: values.comment,
      },
      onSuccess: () => {
        dispatchAlert({
          type: "OPEN",
          payload: {
            icon: "success",
            title: translation.success,
            text: translation.submitComplainSuccess,
            canDismiss: true,
          },
        });
        reset();
        handleClose();
      },
      onError: (err) => {
        dispatchAlert({
          type: "OPEN",
          payload: {
            icon: "attention",
            title: translation.error,
            text: err,
            canDismiss: true,
          },
        });
        reset();
        handleClose();
      },
    },
    [],
    false
  );

  const handleSubmitData = () => {
    request();
  };

  return (
    <div>
      <Modal
        show={visible}
        onHide={handleClose}
        contentClassName="bg-gray border-0 rounded-lg"
      >
        <Modal.Header className="border-0" closeButton />
        <Modal.Body className="show-grid px-4">
          <Row>
            <Col xs={12}>
              <Typography
                tag="h1"
                color="black"
                className="text-center fs-4 fw-normal"
                text={translation.complain}
              />
            </Col>
            <Col xs={12}>
              <Typography
                tag="h1"
                variant="h6"
                color="secondary"
                className="text-center"
                text={`${translation.order} #${order_reference}`}
              />
            </Col>
          </Row>
          <Form onSubmit={handleSubmit(handleSubmitData)}>
            <Row>
              <Col xs={12}>
                <Controller
                  control={control}
                  name={"reason_id"}
                  defaultValue={""}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <SelectField
                      label={translation.reason}
                      labelClassName="fs-5 fw-bold m-0"
                      labelColor="black"
                      placeholder={translation.selectReason}
                      onChange={onChange}
                      defaultValue={value}
                      error={error && error.message}
                    >
                      {orderComplainReasons.map((reason) => (
                        <option key={reason.id} value={reason.id}>
                          {reason.value}
                        </option>
                      ))}
                    </SelectField>
                  )}
                />
              </Col>
              <Col xs={12} className="mt-4">
                <Typography
                  tag="label"
                  className="fs-5 fw-bold m-0"
                  color="black"
                  text={translation.writeComplain}
                />
                <Controller
                  control={control}
                  name={"comment"}
                  defaultValue={""}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <TextAreaField
                      rows={6}
                      placeholder={translation.message}
                      value={value}
                      onChange={onChange}
                      error={error && error.message}
                    />
                  )}
                />
              </Col>
            </Row>

            <Row className="justify-content-end mt-4">
              <Col xs={4}>
                <Button
                  type="submit"
                  className="w-100"
                  text={
                    loading ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      translation.send
                    )
                  }
                />
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default React.memo(ComplainModal);

import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_ROUTES } from "constants";
import { client } from "helpers";

export const getFirstLevelPlaces = createAsyncThunk(
  "app/getFirstLevelPlaces",
  async (_, thunkAPI) => {
    const { getState, rejectWithValue } = thunkAPI;
    const { language } = getState().app;
    try {
      const response = await client().get(`${API_ROUTES.getFirstLevelPlaces}`, {
        headers: {
          Language: language,
        },
      });
      const data = response.data;
      if (data.status === 0) {
        return rejectWithValue(data.message);
      }
      return data;
    } catch (error) {
      const { response } = error;
      return rejectWithValue(response.data.message);
    }
  }
);

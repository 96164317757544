import React from "react";
import { Col, Form, Row, Spinner } from "react-bootstrap";
import { useLanguage } from "hooks";
import { Layout, ProfileLayout, Button, InputField } from "components";
import withContainer from "./Container";

function ChangePasswordPage({ loading, errors, registerField, handleSubmit }) {
  const translation = useLanguage();

  return (
    <Layout footerMargin>
      <div id="change-password-page">
        <ProfileLayout title={translation.myAccount}>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col xs={12} sm={6}>
                <InputField
                  type="text"
                  label={translation.oldPassword}
                  placeholder="••••••••"
                  error={errors.old_password && errors.old_password.message}
                  labelClassName="fw-bolder"
                  className="py-3"
                  {...registerField("old_password")}
                  secure
                />
              </Col>

              <Col xs={12} sm={6} className="mt-3 mt-sm-0">
                <InputField
                  type="text"
                  label={translation.newPassword}
                  placeholder="••••••••"
                  error={errors.password && errors.password.message}
                  labelClassName="fw-bolder"
                  className="py-3"
                  {...registerField("password")}
                  secure
                />
              </Col>
            </Row>

            <Row className="mt-4">
              <Col xs={12} sm={6}>
                <InputField
                  type="text"
                  label={translation.confirmPassword}
                  placeholder="••••••••"
                  error={
                    errors.password_confirmation &&
                    errors.password_confirmation.message
                  }
                  labelClassName="fw-bolder"
                  className="py-3"
                  {...registerField("password_confirmation")}
                  secure
                />
              </Col>
            </Row>

            <Row className="justify-content-center mt-4">
              <Col xs={6}>
                <Button
                  type="submit"
                  variant="primary"
                  textVariant="buttonText3"
                  className="w-100"
                  text={
                    loading ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      translation.save
                    )
                  }
                />
              </Col>
            </Row>
          </Form>
        </ProfileLayout>
      </div>
    </Layout>
  );
}

export default withContainer(React.memo(ChangePasswordPage));

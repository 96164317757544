import React from "react";
import "./style.scss";

function Layout({ children, footerMargin }) {
  return (
    <div id="layout">
      {/* <Header /> */}
      <main>{children}</main>
      {/* <MobileBottomNav /> */}
      {/* <Footer footerMargin={footerMargin} /> */}
    </div>
  );
}

export default React.memo(Layout);

import { createSlice } from "@reduxjs/toolkit";
import { DEFAULT_LANGUAGE } from "constants";
import { getFirstLevelPlaces } from "./actions";

const initialState = {
  language: DEFAULT_LANGUAGE,
  countries: [],
  countryId: null,
  placeId: null,
  loading: true,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    switchLanguage: (state, action) => {
      state.language = action.payload;
    },
    setAppLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
  extraReducers: {
    [getFirstLevelPlaces.fulfilled]: (state, action) => {
      state.countries = action.payload.data;
      state.countryId = action.payload.data[0].id;
      state.placeId = action.payload.data[0].id;
    },
  },
});

export { getFirstLevelPlaces };
export const { switchLanguage, setAppLoading } = appSlice.actions;
export default appSlice.reducer;

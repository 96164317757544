import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";
import { useLanguage } from "hooks";
import { Icon } from "components";
import "./style.scss";

function MobileBottomNav() {
  const translation = useLanguage();
  const { pathname } = useLocation();
  const pathArr = pathname.toString().split("/");

  const homeNotActivePaths = [
    "account",
    "categories",
    "category",
    "product",
    "cart",
    "checkout",
    "favourite",
  ];

  const categoriesActivePaths = ["categories", "category", "product"];
  const cartActivePaths = ["cart", "checkout"];

  return (
    <div className="mobile-bottom-nav bg-gray py-2 fixed-bottom d-block d-lg-none">
      <Container fluid>
        <Row className="">
          <Col className="p-0">
            <Link
              to="/"
              className={classNames("", {
                active:
                  pathname.startsWith("/") &&
                  !homeNotActivePaths.includes(pathArr[1])
                    ? 1
                    : 0,
              })}
            >
              <Row>
                <Col xs={12} className="text-center">
                  <Icon name="bottomNavHome" />
                </Col>
                <Col xs={12} className="text-center mt-2">
                  <span className="text-darkgray">{translation.home}</span>
                </Col>
              </Row>
            </Link>
          </Col>
          <Col className="p-0">
            <a
              href="/chat"
              onClick={(e) => {
                e.preventDefault();
                if (window.Tawk_API) {
                  window.Tawk_API.toggle();
                }
              }}
            >
              <Row>
                <Col xs={12} className="text-center">
                  <Icon name="bottomNavChat" />
                </Col>
                <Col xs={12} className="text-center mt-2">
                  <span className="text-darkgray">{translation.chat}</span>
                </Col>
              </Row>
            </a>
          </Col>
          <Col className="p-0">
            <Link
              to="/packages"
              className={classNames("", {
                active: pathname.startsWith("/packages"),
              })}
            >
              <Row>
                <Col xs={12} className="text-center">
                  <Icon name="bottomNavPackage" />
                </Col>
                <Col xs={12} className="text-center mt-2">
                  <span className="text-darkgray">{translation.packages}</span>
                </Col>
              </Row>
            </Link>
          </Col>
          <Col className="p-0">
            <Link
              to="/cart"
              className={classNames("", {
                active: pathname.startsWith("/cart"),
              })}
            >
              <Row>
                <Col xs={12} className="text-center">
                  <Icon name="bottomNavCart" />
                </Col>
                <Col xs={12} className="text-center mt-2">
                  <span className="text-darkgray">{translation.cart}</span>
                </Col>
              </Row>
            </Link>
          </Col>
          <Col className="p-0">
            <Link
              to="/account/mobile"
              className={classNames("", {
                active: pathname.startsWith("/account"),
              })}
            >
              <Row>
                <Col xs={12} className="text-center">
                  <Icon name="bottomNavMore" />
                </Col>
                <Col xs={12} className="text-center mt-2">
                  <span className="text-darkgray">{translation.more}</span>
                </Col>
              </Row>
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default React.memo(MobileBottomNav);

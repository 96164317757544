import React from "react";
import { useSelector } from "react-redux";
import { API_ROUTES } from "constants";
import { useFetch } from "hooks";

function withContainer(WrappedCompoent) {
  return (props) => {
    const { placeId } = useSelector((state) => state.app);
    const [{ loading, data }] = useFetch({
      endpoint: API_ROUTES.getCategories,
      params: `?parent_id=0&is_featured=0&in_top_bar=0&show_brands=0&show_subcategories=0&place_id=${placeId}`,
    });

    return <WrappedCompoent loading={loading} data={data} />;
  };
}
export default withContainer;

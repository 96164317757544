const IMAGES = {
  Logo: "/images/website-logo.svg",
  saleTriangle: "/images/saleT.png",
  loginImg: "/images/login_img.jpeg",
  bgLogo: "/images/bg-logo.png",
  facebook: "/images/facebook.png",
  google: "/images/google.png",
  clientBg: "/images/clients-bg.png",
  registerimg: "/images/Register_img.png",
};

export default IMAGES;

import { createSlice } from "@reduxjs/toolkit";
import {
  getHomeSlides,
  getCompanyServices,
  getHomeVideo,
  getHomeCategories,
  getHomeTopRatedServices,
  getHomeFacts,
  getHomePackages,
  getHomeLatestOffers,
  getHomeTestimonials,
  getHomeAdvices,
} from "./actions";

const initialState = {
  slides: {
    loading: true,
    data: [],
  },
  companyServices: {
    loading: true,
    data: [],
  },
  video: {
    loading: true,
    data: null,
  },
  categories: {
    loading: true,
    data: [],
  },
  topRatedServices: {
    loading: true,
    data: [],
  },
  facts: {
    loading: true,
    data: [],
  },
  packages: {
    loading: true,
    data: [],
  },
  latestOffers: {
    loading: true,
    data: [],
  },
  testimonials: {
    loading: true,
    data: [],
  },
  advices: {
    loading: true,
    data: [],
  },
  loaded: false,
};

const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    setHomeLoaded: (state, action) => {
      state.loaded = action.payload;
    },
  },
  extraReducers: {
    // Slides
    [getHomeSlides.pending]: (state) => {
      state.slides.loading = true;
    },
    [getHomeSlides.fulfilled]: (state, action) => {
      state.slides.data = action.payload.data;
      state.slides.loading = false;
    },
    [getHomeSlides.rejected]: (state) => {
      state.slides.loading = false;
    },
    // Company Services
    [getCompanyServices.pending]: (state) => {
      state.companyServices.loading = true;
    },
    [getCompanyServices.fulfilled]: (state, action) => {
      state.companyServices.data = action.payload.data;
      state.companyServices.loading = false;
    },
    [getCompanyServices.rejected]: (state) => {
      state.companyServices.loading = false;
    },
    // Video
    [getHomeVideo.pending]: (state) => {
      state.video.loading = true;
    },
    [getHomeVideo.fulfilled]: (state, action) => {
      state.video.data = action.payload.data;
      state.video.loading = false;
    },
    [getHomeVideo.rejected]: (state) => {
      state.video.loading = false;
    },
    // Categories
    [getHomeCategories.pending]: (state) => {
      state.categories.loading = true;
    },
    [getHomeCategories.fulfilled]: (state, action) => {
      state.categories.data = action.payload.data;
      state.categories.loading = false;
    },
    [getHomeCategories.rejected]: (state) => {
      state.categories.loading = false;
    },
    // Top Rated Services
    [getHomeTopRatedServices.pending]: (state) => {
      state.topRatedServices.loading = true;
    },
    [getHomeTopRatedServices.fulfilled]: (state, action) => {
      state.topRatedServices.data = action.payload.data;
      state.topRatedServices.loading = false;
    },
    [getHomeTopRatedServices.rejected]: (state) => {
      state.topRatedServices.loading = false;
    },
    // Facts
    [getHomeFacts.pending]: (state) => {
      state.facts.loading = true;
    },
    [getHomeFacts.fulfilled]: (state, action) => {
      state.facts.data = action.payload.data;
      state.facts.loading = false;
    },
    [getHomeFacts.rejected]: (state) => {
      state.facts.loading = false;
    },
    // Packages
    [getHomePackages.pending]: (state) => {
      state.packages.loading = true;
    },
    [getHomePackages.fulfilled]: (state, action) => {
      state.packages.data = action.payload.data;
      state.packages.loading = false;
    },
    [getHomePackages.rejected]: (state) => {
      state.packages.loading = false;
    },
    // Latest Offers
    [getHomeLatestOffers.pending]: (state) => {
      state.latestOffers.loading = true;
    },
    [getHomeLatestOffers.fulfilled]: (state, action) => {
      state.latestOffers.data = action.payload.data;
      state.latestOffers.loading = false;
    },
    [getHomeLatestOffers.rejected]: (state) => {
      state.latestOffers.loading = false;
    },
    // Testimonials
    [getHomeTestimonials.pending]: (state) => {
      state.testimonials.loading = true;
    },
    [getHomeTestimonials.fulfilled]: (state, action) => {
      state.testimonials.data = action.payload.data;
      state.testimonials.loading = false;
    },
    [getHomeTestimonials.rejected]: (state) => {
      state.testimonials.loading = false;
    },
    // Advices
    [getHomeAdvices.pending]: (state) => {
      state.advices.loading = true;
    },
    [getHomeAdvices.fulfilled]: (state, action) => {
      state.advices.data = action.payload.data;
      state.advices.loading = false;
    },
    [getHomeAdvices.rejected]: (state) => {
      state.advices.loading = false;
    },
  },
});
export {
  getHomeSlides,
  getCompanyServices,
  getHomeVideo,
  getHomeCategories,
  getHomeTopRatedServices,
  getHomeFacts,
  getHomePackages,
  getHomeLatestOffers,
  getHomeTestimonials,
  getHomeAdvices,
};
export const { setHomeLoaded } = homeSlice.actions;
export default homeSlice.reducer;

import React from "react";
import { QRCodeSVG } from "qrcode.react";

function QRCode({
  value,
  size = 128,
  bgColor = "#ffffff",
  fgColor = "#000000",
}) {
  return (
    <div className="qr-code d-inline">
      <QRCodeSVG
        value={value}
        size={size}
        bgColor={bgColor}
        fgColor={fgColor}
        level={"L"}
      />
    </div>
  );
}

export default React.memo(QRCode);

import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLanguage } from "hooks";
import { BreadCrumb, GridList, Heading, Layout } from "components";
import { SubServiceCategoryCard } from "components/cards";
import withContainer from "./Container";

function CategoriesPage({ loading, data, meta }) {
  const language = useLanguage();
  return (
    <Layout footerMargin>
      <div id="ServiceListPage">
        <BreadCrumb
          title="Ac Services"
          path={[{ title: language.services }]}
          src="/images/about_bg.png"
          titleClassName="text-black fs-1 fw-light"
          lastWordClassName="text-secondary fw-bolder fw-bolder"
        />

        <Container className="mt-5">
          <Row>
            <Col xs={12}>
              <Heading title="Ac Services" tag="h2" />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col xs={12}>
              <GridList
                as={SubServiceCategoryCard}
                items={data && data.length > 0 ? data : []}
                loading={loading}
                meta={meta}
                noDataImage="/images/no-categories.png"
                noDataText={language.noCategories}
                skeletonHeight={307}
                xs={6}
                sm={6}
                md={6}
                lg={3}
                xl={3}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  );
}
export default withContainer(React.memo(CategoriesPage));

import React, { useMemo, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import classNames from "classnames";
import { useLanguage } from "hooks";
import {
  Button,
  DatePicker,
  Heading,
  OptionGroup,
  TimePicker,
  Typography,
  RatingStars,
  Image,
  Icon,
  SelectField,
} from "components";
import "./style.scss";
import DynamicFields from "../DynamicFields";
import withContainer from "./Container";
import moment from "moment";
import RequestCalloutModal from "components/RequestCalloutModal";

function Details({
  loading,
  data,
  errors,
  timeSlotData,
  timeSlotLoading,
  isFavourite,
  currentOptionGroup,
  isInCart,
  handleOptionClicked,
  handleSetQty,
  handleAddRemoveWishlist,
  handleAddRemoveCart,
  handleValidateSubmit,
  registerField,
  control,
  getValue,
  setFieldValue,
  setSelectedDate,
  selectedTimeslot,
  setSelectedTimeslot,
  selectedExtraFields,
}) {
  const language = useLanguage();
  const [isCalloutModalOpen, setIsCalloutModalOpen] = useState(false);

  const getExtraPrices = useMemo(() => {
    let allFields = [];
    data.extra_fields.forEach((field) => {
      if (field.type === 7) {
        field.group_fields.forEach((subField) => {
          allFields.push(subField);
        });
      } else {
        allFields.push(field);
      }
    });
    if (selectedExtraFields.length === 0) return 0;
    return [...selectedExtraFields]
      .filter((item) => {
        if (typeof item[1] === "string" && item[1] === "") {
          return false;
        } else if (typeof item[1] === "object" && item[1].value === "") {
          return false;
        } else if (Array.isArray(item[1]) && item[1].length === 0) {
          return false;
        }
        return true;
      })
      .map((item) => {
        // if doesnt include in all fields then return 0
        if (!allFields.find((field) => String(field.id) === String(item[0])))
          return 0;

        let fieldType = allFields.find(
          (field) => String(field.id) === String(item[0])
        ).type;

        if ([1, 4].includes(fieldType)) {
          return 0;
        }

        // Current Extra Field Data
        let currentExtraField = allFields.find(
          (field) => String(field.id) === String(item[0])
        );
        let extraFieldValues = currentExtraField.values;
        let extraFieldEquation = currentExtraField.equation;
        let extraFieldEquationTotal = 0;

        // Calculate the equation of the current extra field
        if (extraFieldEquation !== null) {
          // if equation is not empty
          // Equation string example: [327] * [328], where [327] and [328] are extra field ids
          let equationString = "";
          let equationStringArray = [];

          // if equation string contains OR operator
          if (extraFieldEquation.includes(" OR ")) {
            equationStringArray = extraFieldEquation.split(" OR ");

            // print all user selected fields
            let userSelectedFields = [...selectedExtraFields];
            // only get the fields that are filled by user
            userSelectedFields = userSelectedFields.filter((item) => {
              if (typeof item[1] === "string" && item[1] === "") {
                return false;
              } else if (typeof item[1] === "object" && item[1].value === "") {
                return false;
              } else if (Array.isArray(item[1]) && item[1].length === 0) {
                return false;
              }
              return true;
            });
            // I want to get the equation that has the most user selected fields
            let mostSelectedFields = 0;
            equationStringArray.forEach((equation) => {
              let equationFields = equation.match(/\[(.*?)\]/g);
              let selectedFields = 0;
              equationFields.forEach((field) => {
                if (
                  userSelectedFields.find(
                    (item) =>
                      String(item[0]) ===
                      String(field.replace("[", "").replace("]", ""))
                  )
                ) {
                  selectedFields++;
                }
              });
              if (selectedFields > mostSelectedFields) {
                mostSelectedFields = selectedFields;
                equationStringArray = [equation];
              }
            });

            // first occurence of the equation string
            equationStringArray = equationStringArray[0];
            equationString = equationStringArray;
          } else {
            equationString = extraFieldEquation;
          }

          console.log("equationString", equationString);
          equationStringArray = equationString.split(" ");
          equationStringArray.forEach((item, idx) => {
            if (item.includes("[")) {
              let extraFieldId = item
                .replace("[", "")
                .replace("]", "")
                .replace("(", "")
                .replace(")", "");

              // Get original extra field
              let originalExtraField = allFields.find(
                (field) => String(field.id) === String(extraFieldId)
              );

              // get selected value of the extra field
              let selectedValueData = selectedExtraFields.find(
                (field) => String(field[0]) === String(extraFieldId)
              )[1];
              let selectedValue = selectedValueData;
              let selectedValueQty = 1;

              if (typeof selectedValue === "object") {
                selectedValueQty = selectedValueData.qty;
                selectedValue = selectedValueData.value;
              }

              // Get Data of the selected value id
              let selectedExtraFieldValueData = originalExtraField.values.find(
                (value) => String(value.id) === String(selectedValue)
              );
              let selectedExtraFieldValueEquationValue = 0;

              if (
                typeof selectedValueData !== "object" ||
                (typeof selectedValueData === "object" &&
                  selectedValueData.value !== "")
              ) {
                if (typeof selectedExtraFieldValueData === "object") {
                  if (selectedExtraFieldValueData.equation_value !== null) {
                    selectedExtraFieldValueEquationValue =
                      Number(selectedExtraFieldValueData.equation_value) *
                      Number(selectedValueQty);
                  } else {
                    selectedExtraFieldValueEquationValue = 0;
                  }
                } else {
                  selectedExtraFieldValueEquationValue = selectedValue;
                }
              }

              if (originalExtraField.type === 8) {
                if (originalExtraField.price_per_unit) {
                  //  selectedExtraFieldValueEquationValue = qty
                  selectedExtraFieldValueEquationValue =
                    Number(selectedExtraFieldValueEquationValue) *
                    Number(originalExtraField.price_per_unit);
                }
              }

              if (typeof selectedExtraFieldValueEquationValue !== "number") {
                selectedExtraFieldValueEquationValue = 0;
              }
              if (
                String(selectedExtraFieldValueData?.equation_value) === "-99999"
              ) {
                selectedExtraFieldValueEquationValue = 0;
                equationString = "0";
              } else {
                equationString = equationString.replace(
                  item,
                  selectedExtraFieldValueEquationValue
                );
              }
            }
          });
          extraFieldEquationTotal = eval(equationString);
        }

        if (fieldType === 5) {
          let extraPrices = 0;
          item[1].forEach((subItem) => {
            extraPrices += allFields
              .find((field) => String(field.id) === String(item[0]))
              .values.find(
                (val) => String(val.id) === String(subItem.value)
              ).price;
          });
          return extraPrices;
        }

        if ([9, 11].includes(fieldType)) {
          let extraFieldValuePrice = extraFieldValues.find(
            (field) => String(field.id) === String(item[1].value)
          ).price;
          return extraFieldValuePrice * item[1].qty;
        }

        if (extraFieldValues.length > 0) {
          // find selected extra field value in extraFieldValues
          let extraFieldValuePrice = extraFieldValues.find(
            (field) => String(field.id) === String(item[1])
          ).price;
          return extraFieldValuePrice + extraFieldEquationTotal;
        }

        if (fieldType === 8) {
          let doesFieldExistinAnotherFieldEquation = allFields.find(
            (field) =>
              field.equation !== null && field.equation.includes(item[0])
          );
          if (!doesFieldExistinAnotherFieldEquation) {
            return item[1] * currentExtraField.price_per_unit;
          }
          return extraFieldEquationTotal;
        }

        return (
          allFields.find((field) => String(field.id) === String(item[0]))
            .price_per_unit * +item[1]
        );
      })
      .reduce((a, b) => a + +b, 0);
  }, [selectedExtraFields, data.extra_fields]);

  React.useEffect(() => {
    if (window.Tawk_API) {
      window.Tawk_API.hideWidget();
    }
  }, []);

  const renderDatePicker = () => {
    return (
      <Row>
        {Boolean(data.need_time_slots) && (
          <Col xs={12} className="mt-4 mt-lg-0">
            <Row>
              <Col xs={12}>
                <DatePicker
                  minDate={new Date()}
                  maxDate={moment(new Date()).add(2, "weeks").toDate()}
                  onChange={(val) => {
                    setSelectedDate(moment(val).format("YYYY-MM-DD"));
                    setSelectedTimeslot(null);
                  }}
                />
              </Col>
              {timeSlotData && timeSlotData.length > 0 && (
                <Col xs={12} sm={6} lg={12} className="mt-4">
                  <Typography
                    tag="h5"
                    variant="h5"
                    color="black"
                    text={
                      <React.Fragment>{language.chooseTime}</React.Fragment>
                    }
                  />
                  <SelectField
                    onChange={(e) => setSelectedTimeslot(e.target.value)}
                    placeholderDisabled
                  >
                    {timeSlotData.map((item, idx) => (
                      <option
                        key={idx}
                        value={item.id}
                        selected={selectedTimeslot === item.id}
                      >
                        {moment(item.from_time, "HH:mm").format("h:mm A")}
                      </option>
                    ))}
                  </SelectField>
                </Col>
              )}
            </Row>
          </Col>
        )}
      </Row>
    );
  };

  const renderPriceArea = (
    { split, hidePrice, hideAddToCart } = {
      split: false,
      hidePrice: false,
      hideAddToCart: false,
    }
  ) => {
    return (
      <div>
        {!data.is_callout && (
          <Row>
            <Col xs={12} sm={split ? 6 : 12}>
              {!hidePrice && (
                <>
                  <Typography
                    tag="h5"
                    variant="h4"
                    color="black"
                    text={<React.Fragment>{language.price}</React.Fragment>}
                  />
                  <Typography
                    tag="h6"
                    variant="h3"
                    color="secondary"
                    text={
                      <React.Fragment>
                        {currentOptionGroup &&
                        +currentOptionGroup.price_after_discount +
                          +getExtraPrices >
                          0
                          ? `${(
                              +currentOptionGroup.price_after_discount +
                              +getExtraPrices
                            ).toFixed(2)} ${language.website_currency}`
                          : language.basedOnSelection}{" "}
                        {currentOptionGroup?.discount_percentage !== null &&
                          currentOptionGroup?.discount_percentage !== 0 && (
                            <Typography
                              tag="span"
                              variant="h5"
                              color="gray6"
                              className="strikethrough ms-2"
                              text={`${
                                currentOptionGroup && currentOptionGroup.price
                              } ${language.website_currency}`}
                            />
                          )}
                      </React.Fragment>
                    }
                  />
                  {(
                    data.min_order -
                    (+data.price_after_discount + +getExtraPrices)
                  ).toFixed(2) > 0 && (
                    <span>
                      Inspection fees:{" "}
                      {(
                        data.min_order -
                        (+data.price_after_discount + +getExtraPrices)
                      ).toFixed(2)}{" "}
                      {language.website_currency}
                    </span>
                  )}
                </>
              )}
            </Col>
            {!hideAddToCart && (
              <Col
                xs={12}
                sm={split ? 6 : 12}
                className={classNames("", {
                  "mt-2": !split,
                })}
              >
                <Button
                  onClick={handleAddRemoveCart}
                  variant="primary"
                  textVariant="buttonText2"
                  className="w-100"
                  text={language.addToCart}
                />
                <p className="mt-3 body2">
                  {language.didntFindRequest}{" "}
                  <button
                    className="btn btn-link p-0 body2"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsCalloutModalOpen(true);
                    }}
                  >
                    {language.clickHere}
                  </button>
                </p>
              </Col>
            )}
          </Row>
        )}
        {!!data.is_callout && (
          <Button
            onClick={() =>
              handleValidateSubmit(() => setIsCalloutModalOpen(true))()
            }
            variant="primary"
            textVariant="buttonText2"
            className="w-100"
            text={language.requestCallout}
          />
        )}
      </div>
    );
  };

  return (
    <div className="service-details">
      <RequestCalloutModal
        visible={isCalloutModalOpen}
        handleClose={() => setIsCalloutModalOpen(false)}
        // Put selected extra fields title and value in description
        description={
          data.is_callout
            ? selectedExtraFields
                .map((item) => {
                  let currentExtraField = data.extra_fields.find(
                    (field) => String(field.id) === String(item[0])
                  );
                  if (currentExtraField) {
                    let extraFieldTitle = currentExtraField.label;
                    let extraFieldValueId = item[1];
                    if (typeof item[1] === "object") {
                      extraFieldValueId = item[1].value;
                    }

                    let extraFieldValueData = currentExtraField.values.find(
                      (val) => String(val.id) === String(extraFieldValueId)
                    );

                    if (extraFieldValueData) {
                      return `${extraFieldTitle}: ${extraFieldValueData.value}`;
                    }
                    return "";
                  }
                  return "";
                })
                .join("\n")
            : ""
        }
      />
      <Container>
        <Row>
          <Col xs={12}>{<Heading title={data.title} />}</Col>
        </Row>
        <Row className="mt-4">
          <Col xs={12}>
            <Typography
              tag="p"
              variant="body2"
              color="black"
              className="text-break"
              text={data.subtitle}
            />
            {/* <div
                  className="text-break"
                  dangerouslySetInnerHTML={{ __html: data.description }}
                /> */}
          </Col>
        </Row>
        <Row className="mt-2">
          <Col xs={12} lg={4} xxl={3} className="">
            <Row className="align-items-center">
              <Col xs={10}>
                <div className="d-flex">
                  <RatingStars rate={data.rate} showText />
                  <Typography
                    text={` | ${data.ordersCount} ${language.orders}`}
                    color="gray6"
                    className="ms-2"
                  />
                </div>
              </Col>
              <Col xs={2} className="text-end">
                <div
                  onClick={handleAddRemoveWishlist}
                  className="favourite"
                  role="button"
                >
                  {!isFavourite && (
                    <Icon name="favouriteOutline" className="text-primary" />
                  )}
                  {isFavourite && (
                    <Icon name="favourite" className="text-primary" />
                  )}
                </div>
              </Col>
            </Row>

            <Image
              className="w-100 mt-3"
              alt={data.title}
              src={data.main_image}
              fromApi
            />

            <Row className="mt-4 d-none d-lg-block d-xxl-none">
              <Col xs={12}>{renderDatePicker()}</Col>
            </Row>
          </Col>
          <Col xs={12} lg={8} xxl={6} className="">
            {data.options &&
              data.options.map((item, idx) => (
                <Row
                  key={item.id}
                  className={classNames({
                    "mt-4": idx === 0,
                    "mt-3": idx > 0,
                  })}
                >
                  <Col xs={12} lg={10} xl={12}>
                    <OptionGroup
                      group={item.group}
                      title={item.name}
                      options={item.values}
                      currentOptionGroup={currentOptionGroup}
                      handleOptionClicked={handleOptionClicked}
                    />
                  </Col>
                </Row>
              ))}
            {data.extra_fields.length > 0 && (
              <Row className="mt-4 mt-xxl-0">
                <Col xs={12}>
                  <DynamicFields
                    fields={data.extra_fields}
                    getValue={getValue}
                    registerField={registerField}
                    control={control}
                    setFieldValue={setFieldValue}
                    errors={errors}
                  />
                </Col>
              </Row>
            )}
          </Col>
          <Col xs={12} xxl={3} className="d-lg-none d-xxl-block">
            {!data.is_callout && (
              <div className="d-none d-xxl-block">
                {renderPriceArea({
                  hideAddToCart: true,
                })}
              </div>
            )}
            <div className="mt-3">{renderDatePicker()}</div>
            <div className="mt-4 d-none d-xxl-block">
              {renderPriceArea({
                hidePrice: true,
              })}
            </div>
          </Col>
        </Row>

        {/* Service Description */}
        <Row className="mt-4">
          <Col xs={12}>
            <Heading title={language.serviceDescription} />

            <div
              className="text-break mt-3"
              dangerouslySetInnerHTML={{ __html: data.description }}
            />
          </Col>
        </Row>
      </Container>

      {/* Floating Price + Add To Cart */}
      <div
        className="floating-price d-xxl-none"
        style={{
          zIndex: 1000,
        }}
      >
        <Container>
          <Row>
            <Col xs={12} lg={12}>
              {renderPriceArea({ split: true })}
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default withContainer(React.memo(Details));

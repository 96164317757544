import React, { Fragment } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Heading, Layout, Skeleton, SkeletonGroup } from "components";
import withContainer from "./Container";

function CustomPage({ loading, name, text }) {
  return (
    <Layout footerMargin>
      <div id="CustomPage">
        <section className="mt-4">
          <Container>
            {!loading ? (
              <Fragment>
                <Row className="mb-4">
                  <Col xs={12}>{name && <Heading title={name} tag="h2" />}</Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    {text && <div dangerouslySetInnerHTML={{ __html: text }} />}
                  </Col>
                </Row>
              </Fragment>
            ) : (
              <Row>
                <Col xs={12}>
                  <Skeleton height={40} width="30%" />
                </Col>
                <Col xs={12} className="mt-4">
                  <SkeletonGroup height={40} rows={4} width="100%" />
                </Col>
              </Row>
            )}
          </Container>
        </section>
      </div>
    </Layout>
  );
}

export default withContainer(React.memo(CustomPage));

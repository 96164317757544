import React from "react";
import {
  Button,
  DropZoneField,
  InputField,
  SelectField,
  TextAreaField,
  Typography,
} from "components";
import { useLanguage } from "hooks";
import { Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import withContainer from "./Container";
import "./style.scss";

function RequestCalloutModal({
  visible,
  handleClose,
  loading,
  registerField,
  control,
  errors,
  handleSubmit,
  description = "",
}) {
  const translation = useLanguage();
  const { calloutsTypes: types } = useSelector((state) => state.common);

  return (
    <div>
      <Modal
        show={visible}
        onHide={handleClose}
        dialogClassName="request-callout-modal"
        contentClassName="w-100 bg-gray border-0 rounded-xs"
      >
        <Modal.Header className="border-0" closeButton />
        <Modal.Body className="show-grid pt-0 px-4">
          <Row>
            <Col xs={12}>
              <Typography
                tag="h1"
                color="gray7"
                className="fs-5 fw-bold"
                text={translation.requestCallout}
              />
            </Col>
          </Row>

          <Form onSubmit={handleSubmit} className="mt-4">
            <Row>
              <Col xs={12} md={6}>
                <InputField
                  type="text"
                  label={translation.name}
                  placeholder={translation.name}
                  error={errors.name && errors.name.message}
                  {...registerField("name")}
                  className="py-3"
                />
              </Col>
              <Col xs={12} md={6}>
                <InputField
                  type="email"
                  label={translation.emailAddress}
                  placeholder={translation.emailAddress}
                  error={errors.email && errors.email.message}
                  {...registerField("email")}
                  className="py-3"
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs={12} md={6} className="mt-3 mt-md-0">
                <InputField
                  type="tel"
                  label={translation.phoneNumber}
                  placeholder={translation.phoneNumber}
                  error={errors.phone && errors.phone.message}
                  {...registerField("phone")}
                  className="py-3"
                />
              </Col>

              <Col xs={12} md={6}>
                <Controller
                  control={control}
                  name={"type"}
                  defaultValue={""}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <SelectField
                      label={translation.selectType}
                      labelColor="gray7"
                      placeholder={translation.selectType}
                      onChange={onChange}
                      defaultValue={value}
                      error={error && error.message}
                      className="py-3"
                    >
                      {types.map((type) => (
                        <option key={type.id} value={type.id}>
                          {type.name} - {type.general_price}{" "}
                          {translation.website_currency}
                        </option>
                      ))}
                    </SelectField>
                  )}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs={12} md={12} lg={12}>
                <Controller
                  control={control}
                  name="description"
                  defaultValue={description}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <TextAreaField
                      rows={4}
                      label={`${translation.description}`}
                      placeholder={translation.description}
                      error={error && error.message}
                      onChange={onChange}
                      value={value}
                      disabled={description !== ""}
                    />
                  )}
                />
              </Col>
            </Row>

            {/* Imaeg Upload */}
            <Row className="mt-3">
              <Col xs={12} md={12} lg={12}>
                <DropZoneField
                  label={`${translation.attachImage} (${translation.optional})`}
                />
              </Col>
            </Row>

            {/* Form check */}
            <Row className="mt-3">
              <Col xs={12}>
                <Controller
                  control={control}
                  name="agree"
                  defaultValue={false}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <React.Fragment>
                      <Form.Check
                        id="termsAndConditions"
                        type="checkbox"
                        onChange={onChange}
                        label={
                          <Typography
                            tag="span"
                            variant="body1"
                            color="gray7"
                            text={translation.byContinuingYouAgreeToOur}
                          />
                        }
                        className="d-inline-block me-1"
                      />
                      <a
                        href="/page/termsAndConditions"
                        target="_blank"
                        rel="noreferrer"
                        className="text-blue"
                      >
                        {translation.termsAndConditions}
                      </a>
                      {errors && errors.agree && (
                        <Form.Text className="d-block text-danger">
                          {errors.agree.message}
                        </Form.Text>
                      )}
                    </React.Fragment>
                  )}
                />
              </Col>
            </Row>

            <Row className="justify-content-center mt-4">
              <Col xs={6}>
                <Button
                  type="submit"
                  className="w-100"
                  variant="primary"
                  textVariant="buttonText3"
                  text={
                    loading ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      translation.send
                    )
                  }
                />
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default withContainer(React.memo(RequestCalloutModal));

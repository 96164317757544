import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { API_ROUTES } from "constants";
import { useSkipFirstRender, useUpload } from "hooks";
import { Button, Image } from "components";
import { useEffect } from "react";

function AttachField({ label, onChange, value, error }) {
  const [selectedFiles, setSelectedFiles] = React.useState([]);
  const fileInputRef = React.useRef(null);
  const [_, upload] = useUpload({
    endpoint: API_ROUTES.uploadFile,
  });

  const onAttachClick = () => {
    fileInputRef.current.click();
  };

  const onFileAttached = (e) => {
    if (!e.target.files || e.target.files.length === 0) return;

    // loop through all the files and upload them
    let uploadedFiles = [];
    for (let i = 0; i < e.target.files.length; i++) {
      const file = e.target.files[i];
      upload({
        body: {
          file,
        },
        onSuccess: (response) => {
          uploadedFiles.push(response.data);
        },
        onError: () => {},
      });
    }
    setSelectedFiles(uploadedFiles);
  };

  const onFileRemoveClicked = (indexToRemove) => {
    fileInputRef.current.value = "";
    setSelectedFiles((prevState) => [
      ...prevState.filter((item, idx) => idx !== indexToRemove),
    ]);
  };

  useSkipFirstRender(() => {
    const handleOnChange = () => {
      if (onChange) onChange(selectedFiles);
    };

    handleOnChange();
  }, [selectedFiles]);

  useEffect(() => {
    if (
      value &&
      selectedFiles.join(",") !==
        (typeof value === "string" ? value : value.join(","))
    ) {
      setSelectedFiles(typeof value === "string" ? value.split(",") : value);
    }
  }, [value]);

  return (
    <div className="mt-4">
      <Row>
        <Col xs={12}>
          <input
            ref={fileInputRef}
            type="file"
            onChangeCapture={onFileAttached}
            className="d-none"
            multiple={true}
          />
          <Button
            onClick={onAttachClick}
            variant=""
            icon="attach"
            textVariant="h6"
            text={label}
            className="p-0 fw-bolder"
          />
        </Col>
        <Col xs={12}>
          <ul className="d-flex flex-wrap border-0">
            {selectedFiles.length > 0 &&
              selectedFiles.map((item, idx) => (
                <li key={idx} className="border-0 px-0 pe-4">
                  <div className="attached-image">
                    <Button
                      onClick={() => onFileRemoveClicked(idx)}
                      variant=""
                      icon="trash"
                      iconClassName="delete mt-1 ms-2"
                    />
                    <Image
                      src={item}
                      alt={item}
                      fromApi
                      style={{
                        width: "48px",
                        height: "48px",
                      }}
                    />
                  </div>
                </li>
              ))}
          </ul>
        </Col>
        <Col xs={12}>
          {error && <Form.Text className="text-danger">{error}</Form.Text>}
        </Col>
      </Row>
    </div>
  );
}

export default AttachField;

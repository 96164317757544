import React from "react";
import { Col, Row, Offcanvas, Container } from "react-bootstrap";
import { useToggle } from "hooks";
import { Button } from "components";

function SideBar(props) {
  const [show, setShow] = useToggle(false);

  return (
    <div className="">
      <Row className={`d-${props.hiddenOn}-none`}>
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <Button
            variant="none"
            className="w-100 p-0 text-center"
            onClick={setShow}
            icon={props.icon}
          />

          <Offcanvas show={show} onHide={setShow} placement="end">
            <Container>
              <Row>
                <Col xs={12}>
                  <Offcanvas.Header
                    closeButton={props.closeButton ? false : true}
                    className="px-0 py-3"
                  >
                    <div className="text-end w-100">
                      {props.closeButton && (
                        <Button
                          variant="none"
                          className="p-0 text-center"
                          onClick={setShow}
                          icon={props.closeButton}
                        />
                      )}
                    </div>
                  </Offcanvas.Header>
                </Col>
                <Col xs={12}>
                  <Offcanvas.Title>{props.title}</Offcanvas.Title>
                </Col>
                <Col xs={12}>
                  <Offcanvas.Body>{props.children}</Offcanvas.Body>
                </Col>
              </Row>
            </Container>
          </Offcanvas>
        </Col>
      </Row>
    </div>
  );
}

export default React.memo(SideBar);

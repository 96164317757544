import { createSlice } from "@reduxjs/toolkit";
import { addRemoveWishlist, getWishlistIds } from "./actions";

const initialState = {
  ids: [],
};

const wishlistSlice = createSlice({
  name: "wishlist",
  initialState,
  reducers: {},
  extraReducers: {
    [getWishlistIds.fulfilled]: (state, action) => {
      state.ids = action.payload;
    },
    [addRemoveWishlist.fulfilled]: (state, action) => {
      state.ids = action.payload;
    },
  },
});

export { getWishlistIds, addRemoveWishlist };
export default wishlistSlice.reducer;

import React from "react";
import { Link } from "react-router-dom";
import { Dropdown as BootstrapDropdown } from "react-bootstrap";
import classNames from "classnames";
import { Button } from "components";
import "./style.scss";

function DropdownMenu({
  label,
  options,
  className,
  itemClassName,
  icon,
  textVariant,
  iconClassName,
  ...props
}) {
  return (
    <div
      className={classNames("custom-dropdown-menu w-100", {
        [`${className}`]: className,
      })}
    >
      <BootstrapDropdown className="d-inline-block">
        <BootstrapDropdown.Toggle
          as={Button}
          variant=""
          icon={icon}
          textVariant={textVariant}
          iconClassName={iconClassName}
          className={classNames(
            "text-start text-black p-0 text-overflow-1 w-100 text-end "
          )}
          text={label}
        />
        {options && (
          <BootstrapDropdown.Menu className="">
            {options.map(
              ({ label: itemLabel, link: itemLink, ...itemProps }, idx) => (
                <BootstrapDropdown.Item
                  key={idx}
                  as={(props) => (
                    <Button
                      variant=""
                      {...(itemLink && {
                        as: Link,
                        to: itemLink,
                      })}
                      {...props}
                      {...itemProps}
                    />
                  )}
                  iconClassName="me-2"
                  className={classNames(
                    "dropdown-item-button w-100 text-start py-2",
                    {
                      [`${itemClassName}`]: itemClassName,
                    }
                  )}
                  text={itemLabel}
                />
              )
            )}
          </BootstrapDropdown.Menu>
        )}
      </BootstrapDropdown>
    </div>
  );
}

export default React.memo(DropdownMenu);

import React from "react";
import Mobile from "./Mobile";
import Web from "./Web";

function GridList({
  noDataImage,
  noDataText,
  loading,
  items,
  meta,
  appliedFilters,
  skeletonHeight,
  as,
  xs,
  sm,
  md,
  lg,
  xl,
}) {
  const [matchMedia, setMatchMedia] = React.useState(null);

  React.useEffect(() => {
    function mediaQueryListener(mediaQueryList) {
      setMatchMedia(mediaQueryList.matches);
    }
    if (window.matchMedia) {
      var widthMediaQuery = window.matchMedia("(min-width: 992px)");

      setMatchMedia(widthMediaQuery.matches);

      widthMediaQuery.addEventListener("change", mediaQueryListener);
      return () => {
        widthMediaQuery.removeEventListener("change", mediaQueryListener);
      };
    }

    return setMatchMedia(true);
  }, []);

  if (matchMedia === null) return null;

  return (
    <div className="grid-list mt-4 mt-lg-0">
      {matchMedia && (
        <Web
          as={as}
          items={items && items.length > 0 ? items : []}
          skeletonHeight={skeletonHeight}
          meta={meta}
          loading={loading}
          noDataImage={noDataImage}
          noDataText={noDataText}
          lg={lg}
          xl={xl}
        />
      )}
      {!matchMedia && (
        <Mobile
          as={as}
          items={items && items.length > 0 ? items : []}
          meta={meta}
          loading={loading}
          appliedFilters={appliedFilters}
          noDataImage={noDataImage}
          noDataText={noDataText}
          xs={xs}
          sm={sm}
          md={md}
        />
      )}
    </div>
  );
}

export default React.memo(GridList);

import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import LoadingSkeleton from "react-loading-skeleton";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useLanguage } from "hooks";

function BaseSkeleton({ className, containerClassName, ...props }) {
  return (
    <div
      className={classNames({
        [`${containerClassName}`]: containerClassName,
      })}
    >
      <LoadingSkeleton
        className={classNames({
          [`${className}`]: className,
        })}
        {...props}
      />
    </div>
  );
}

function SkeletonGroup({
  rows = 1,
  count = 1,
  circle,
  color,
  width,
  height,
  xs,
  lg,
  className,
  containerClassName,
  container,
}) {
  const translation = useLanguage();

  const Component = container ? Container : React.Fragment;

  return (
    <Component>
      {!lg && !xs && (
        <React.Fragment>
          {Array.from({ length: rows }, (_, idx) => idx).map((_, idx) => (
            <Row
              key={idx}
              className={classNames("", {
                "mt-3": idx > 0,
              })}
            >
              {Array.from({ length: count }, (_, idx) => idx).map((_, idx) => (
                <Col key={idx} className="text-center">
                  <BaseSkeleton
                    baseColor={color}
                    circle={circle}
                    width={width}
                    height={height}
                    className={className}
                    containerClassName={classNames("", {
                      [`${containerClassName}`]: containerClassName,
                    })}
                    direction={translation.direction}
                  />
                </Col>
              ))}
            </Row>
          ))}
        </React.Fragment>
      )}
      {xs && (
        <div className="d-lg-none">
          {Array.from({ length: xs.rows || rows }, (_, idx) => idx).map(
            (_, idx) => (
              <Row
                key={idx}
                className={classNames("", {
                  "mt-3": idx > 0,
                })}
              >
                {Array.from({ length: xs.count || count }, (_, idx) => idx).map(
                  (_, idx) => (
                    <Col key={idx} className="text-center">
                      <BaseSkeleton
                        baseColor={color}
                        circle={circle}
                        width={xs.width || width}
                        height={xs.height || height}
                        className={className}
                        containerClassName={classNames("", {
                          [`${containerClassName}`]: containerClassName,
                        })}
                        direction={translation.direction}
                      />
                    </Col>
                  )
                )}
              </Row>
            )
          )}
        </div>
      )}
      {lg && (
        <div className="d-none d-lg-block">
          {Array.from({ length: lg.rows || rows }, (_, idx) => idx).map(
            (_, idx) => (
              <Row
                key={idx}
                className={classNames("", {
                  "mt-3": idx > 0,
                })}
              >
                {Array.from({ length: lg.count || count }, (_, idx) => idx).map(
                  (_, idx) => (
                    <Col key={idx} className="text-center">
                      <BaseSkeleton
                        baseColor={color}
                        circle={circle}
                        width={lg.width || width}
                        height={lg.height || height}
                        className={className}
                        containerClassName={classNames("", {
                          [`${containerClassName}`]: containerClassName,
                        })}
                        direction={translation.direction}
                      />
                    </Col>
                  )
                )}
              </Row>
            )
          )}
        </div>
      )}
    </Component>
  );
}

SkeletonGroup.propTypes = {
  circle: PropTypes.bool,
  color: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rows: PropTypes.number,
  count: PropTypes.number,
  xs: PropTypes.shape({
    rows: PropTypes.number,
    count: PropTypes.number,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  lg: PropTypes.shape({
    rows: PropTypes.number,
    count: PropTypes.number,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  className: PropTypes.string,
  containerClassName: PropTypes.string,
};

export default React.memo(SkeletonGroup);

import React from "react";
import { Col, Row } from "react-bootstrap";
import moment from "moment";
import classNames from "classnames";
import { Typography } from "components";

function NotificationItem({ headline, text, created_at, read }) {
  return (
    <div
      className={classNames(
        "notification bg-white px-4 py-2 border rounded-sm",
        {
          "border-gray": read,
          "border-secondary": !read,
        }
      )}
    >
      <Row className="align-items-center">
        <Col xs={8}>
          <Typography tag="h4" color="primary" text={headline} />
        </Col>
        <Col xs={4}>
          <Typography
            tag="p"
            color="gray7"
            className="text-end"
            text={moment(created_at).format("YYYY/MM/DD")}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Typography tag="p" color="black" text={text} />
        </Col>
      </Row>
    </div>
  );
}

export default React.memo(NotificationItem);

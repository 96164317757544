import RSelect from "react-select";
import classNames from "classnames";
import React, { useState, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useLanguage } from "hooks";

const Select = ({
  id,
  name,
  label,
  labelType,
  options = [],
  defaultValue,
  value,
  onChange,
  isSearchable = false,
  labelSelector = "label",
  valueSelector = "value",
  classes = {
    container: "",
    label: "",
    select: "",
  },
  required,
  error,
  showError = true,
}) => {
  const translation = useLanguage();
  const [selectedValue, setSelectedValue] = useState(undefined);
  const onChangeHandler = ({ value }) => {
    setSelectedValue(value);
    if (onChange) {
      onChange(value);
    }
  };

  useEffect(() => {
    setSelectedValue(defaultValue);
  }, [defaultValue]);

  return (
    <div
      className={classNames("select-component", {
        [classes.container]: classes.container,
      })}
    >
      <Row>
        <Form.Group
          as={Col}
          xs={12}
          className={classNames({
            "d-flex align-items-center gap-1": labelType === "horizontal",
          })}
        >
          {label && (
            <Form.Label
              htmlFor={id}
              className={classNames("select-component", {
                [classes.label]: classes.label,
                required: required,
              })}
            >
              {label}
            </Form.Label>
          )}
          <RSelect
            instanceId={`select-component-${id}`}
            inputId={id}
            name={name}
            options={options.map((option) => ({
              label: option[labelSelector],
              value: option[valueSelector],
            }))}
            placeholder={translation.pleaseSelect}
            defaultValue={
              defaultValue || (options.length > 0 ? options[0].value : null)
            }
            value={
              options
                .map((option) => ({
                  label: option[labelSelector],
                  value: option[valueSelector],
                }))
                .find(
                  (option) =>
                    String(option.value) === String(value || selectedValue)
                ) || ""
            }
            onChange={onChangeHandler}
            isSearchable={isSearchable}
            styles={{
              control: (styles) => ({
                ...styles,
                height: 48,
                borderColor: error ? "#df4949" : "#e5e5e5",
                boxShadow: "none",
                "&:hover": {
                  borderColor: error ? "#df4949" : "#e5e5e5",
                },
                "&:focus": {
                  borderColor: error ? "#df4949" : "#295ec6",
                },
                "&:active": {
                  borderColor: error ? "#df4949" : "#295ec6",
                },

                "&:focus-within": {
                  borderColor: error ? "#df4949" : "#295ec6",
                },
              }),
              option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                return {
                  ...styles,
                  height: 48,
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: isSelected ? "#000" : "#fff",
                  color: isSelected ? "#fff" : "#000",
                  ...(!isSelected && {
                    "&:hover": {
                      color: "#000",
                      backgroundColor: "#e5e5e5",
                    },
                  }),
                };
              },
            }}
            className={classNames("", {
              [classes.select]: classes.select,
            })}
          />
        </Form.Group>
        {showError && error && (
          <Form.Text className="mt-1 text-danger">{error}</Form.Text>
        )}
      </Row>
    </div>
  );
};

export default Select;

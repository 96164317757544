import { Button, InputField, Typography } from "components";
import { useLanguage } from "hooks";
import React from "react";
import { Col, Form, Row } from "react-bootstrap";

function StepOne({ handleNextStep }) {
  const langunge = useLanguage();

  return (
    <div className="step-one">
      <Row>
        <Col xs={12}>
          <Typography
            tag="h1"
            className="fs-2"
            color="black"
            text={langunge.forgotPassword1}
          />
        </Col>
        <Col xs={12}>
          <Typography
            tag="p"
            variant="body1"
            color="gray7"
            text={langunge.pleaseEnterYourPhoneNumberHereToChangePassword}
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col className="mt-4" xs={12}>
          <Form>
            <InputField
              type="text"
              label={langunge.emailAddress}
              labelClassName="fs-6"
              placeholder={langunge.emailAddress}
              className="py-3"
              // error={errors && errors.username}
            />
          </Form>
        </Col>
        <Col xs={12} className="mt-4">
          <Row className="justify-content-center">
            <Col xs={12} lg={5}>
              <Button
                type="submit"
                className="text-white py-3 w-100"
                variant="primary"
                onClick={handleNextStep}
                text={langunge.submit}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default StepOne;

import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useFetch, useLanguage } from "hooks";
import {
  Button,
  Dot,
  Layout,
  ProfileLayout,
  Skeleton,
  Typography,
} from "components";
import "./style.scss";
import withContainer from "./Container";
import moment from "moment";
import { STORAGE_URL } from "constants";
import { API_ROUTES } from "constants";
import { useAlert } from "context";
import { navigate } from "helpers";

function SubscriptionDetailsPage({ data, refetch }) {
  const language = useLanguage();

  const [_, dispatchAlert] = useAlert();

  const [{ loading: cancelSubscriptionLoading }, cancelSubscription] = useFetch(
    {
      method: "POST",
      endpoint: API_ROUTES.submitCancelSubscriptionOrder,
      body: {
        subscription_order_id: data.order_information.id,
      },
      onSuccess: () => {
        refetch();
        dispatchAlert({
          type: "OPEN",
          payload: {
            icon: "success",
            title: language.success,
            text: language.cancelSubscriptionSuccess,
            canDismiss: true,
          },
        });
      },
      onError: (err) => {
        dispatchAlert({
          type: "OPEN",
          payload: {
            icon: "attention",
            title: language.error,
            text: err.message,
            canDismiss: true,
          },
        });
      },
    },
    [],
    false
  );

  return (
    <Layout footerMargin>
      <div id="SubscriptionDetailsPage">
        <ProfileLayout title={language.myAccount}>
          <div className="bg-gray3 py-3 rounded-xs border border-gray">
            <div className="subscription-item bg-gray3 px-4">
              <Row>
                <Col xl={2} lg={3} md={3} sm={3} xs={4}>
                  <div className="subscription_img">
                    <img
                      src={"/images/package-gold.png"}
                      alt={data.order_information.name}
                      className="rounded-sm w-100"
                    />
                  </div>
                </Col>
                <Col
                  xl={8}
                  lg={6}
                  md={6}
                  sm={6}
                  xs={8}
                  className="mt-4 mt-sm-0"
                >
                  <Row>
                    <Col xs={12}>
                      <Typography
                        tag="h3"
                        variant="h4"
                        color="secondary"
                        text={data.order_information.subscription_info.name}
                      />
                    </Col>
                    {/* <Col xs={12}>
                        <Typography
                          tag="h3"
                          variant="subtitle1"
                          color="gray7"
                          text={item.duration}
                        />
                      </Col> */}
                    <Col xs={12} className="mt-1">
                      <Typography
                        tag="h3"
                        variant="subtitle1"
                        color="secondary"
                        text={`${data.order_information.total_amount} ${language.website_currency}`}
                      />
                    </Col>
                    <Col xs={12} className="mt-1">
                      <Typography
                        tag="h3"
                        variant="subtitle1"
                        color="gray7"
                        text={
                          <React.Fragment>
                            {moment(data.order_information.created_at).format(
                              "DD/MM/YYYY"
                            )}{" "}
                            {/* <Typography
                                tag="span"
                                variant="subtitle1"
                                color="secondary"
                                text={language.to}
                              />{" "}
                              {item.dateTo} */}
                          </React.Fragment>
                        }
                      />
                    </Col>
                    {data.order_information.duration_in_months && (
                      <Col xs={12} className="mt-1">
                        <Typography
                          tag="h1"
                          variant="h4"
                          color="secondary"
                          weight="bold"
                          text={`${language.duration}: ${data.order_information.duration_in_months} ${language.months}`}
                        />
                        {data.order_information.valid_until && (
                          <Typography
                            tag="h1"
                            variant="h4"
                            color="secondary"
                            weight="bold"
                            text={`${language.validUntil}: ${moment(
                              data.order_information.valid_until
                            ).format("DD/MM/YYYY")}`}
                          />
                        )}
                      </Col>
                    )}
                  </Row>
                </Col>
                <Col
                  xl={2}
                  lg={3}
                  md={3}
                  sm={3}
                  xs={12}
                  className="mt-4 mt-sm-0 text-end"
                >
                  <Row className="justify-content-center align-items-end">
                    <Col xs={12} className="text-start">
                      <div>
                        <Dot
                          color={data.order_information.statusColor}
                          className="me-1"
                          size={12}
                        />
                        <Typography
                          tag="span"
                          variant="subtitle1"
                          style={{
                            color: data.order_information.statusColor,
                          }}
                          text={data.order_information.statusText}
                        />
                      </div>
                    </Col>
                    <Col xs={12} className="text-end mt-2">
                      {data.order_information.status < 1 && (
                        <Button
                          onClick={() =>
                            navigate(
                              `/packages/${data.order_information.subscription_info.id}`
                            )
                          }
                          variant="outline-primary"
                          icon="refund"
                          className="w-100"
                          text={language.renew}
                        />
                      )}
                      {data.order_information.status >= 1 && (
                        <Button
                          onClick={cancelSubscription}
                          variant="outline-danger"
                          icon="cancel"
                          className="w-100"
                          text={language.cancel}
                        />
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>

            <hr style={{ height: "2px" }} className="text-gray5" />

            {data.callout_types.length > 0 && (
              <>
                <hr style={{ height: "2px" }} className="text-gray5" />
                <div className="px-4">
                  <Row>
                    <Col xs={12} className="mt-1">
                      <Typography
                        tag="h1"
                        variant="h4"
                        color="primary"
                        weight="bold"
                        text={language.callouts}
                      />
                      {data.callout_types.map((calloutType, idx) => (
                        <div>
                          <Typography
                            key={idx}
                            tag="h1"
                            variant="h4"
                            color="secondary"
                            weight="bold"
                            text={`${calloutType.callouts_type?.name}`}
                          />
                          <Typography
                            key={idx}
                            tag="h1"
                            variant="h6"
                            color="secondary"
                            weight="bold"
                            text={`Total: ${calloutType.available_qty}`}
                          />
                          <Typography
                            key={idx}
                            tag="h1"
                            variant="h6"
                            color="secondary"
                            weight="bold"
                            text={`Remaining: ${calloutType.reamin_qty}`}
                          />
                        </div>
                      ))}
                    </Col>
                  </Row>
                </div>
              </>
            )}

            <Row>
              <Col xs={12}>
                {data.order_information.subscription_info.features.map(
                  (item) => (
                    <Row key={item.id} className="px-4 mb-2">
                      <Col xs={2} className="d-none d-xl-block"></Col>
                      <Col xs={12} xl={10}>
                        <Typography
                          tag="h6"
                          variant="subtitle3"
                          color="gray7"
                          text={`- ${item.text}`}
                        />
                      </Col>
                    </Row>
                  )
                )}
              </Col>
              {data.order_information.contract_link && (
                <Col xs={12} className="mt-2">
                  <Typography
                    tag="a"
                    href={STORAGE_URL + data.order_information.contract_link}
                    target="_blank"
                    rel="noreferrer"
                    variant="body1"
                    color="secondary"
                    className="text-underline"
                    text={language.readContract}
                  />
                </Col>
              )}
            </Row>

            <Row className="mt-4 px-2">
              <Col xs={12}>
                <Row className="justify-content-center">
                  {data.order_information.status > 0 &&
                    ![-2, 2].includes(data.order_information.status) && (
                      <Col xs={12} lg={12} xl={4} className="mt-2 mt-xl-0">
                        <Button
                          as={Link}
                          to={`/account/subscription/request/${data.order_information.order_reference}`}
                          variant="outline-primary"
                          icon="requestCallout"
                          className="w-100"
                          text={language.requestCallout}
                        />
                      </Col>
                    )}
                  <Col xs={12} lg={12} xl={4} className="mt-2 mt-xl-0">
                    <Button
                      as={Link}
                      to={`/account/subscription/history/${data.order_information.order_reference}`}
                      variant="outline-primary"
                      icon="requestCallout"
                      className="w-100"
                      text={language.history}
                    />
                  </Col>
                  {/* <Col xs={12} lg={12} xl={4} className="mt-2 mt-xl-0">
                      <Button
                        as={Link}
                        to="/account/subscription/request/1"
                        variant="outline-primary"
                        icon="emergencyCallout"
                        className="w-100"
                        text={language.emergencyCallout}
                      />
                    </Col>
                    <Col xs={12} lg={12} xl={4} className="mt-2 mt-xl-0">
                      <Button
                        as={Link}
                        to="/account/subscription/request/1"
                        variant="outline-primary"
                        icon="normalCallout"
                        className="w-100"
                        text={language.normalCallout}
                      />
                    </Col> */}
                </Row>
              </Col>
            </Row>
          </div>
        </ProfileLayout>
      </div>
    </Layout>
  );
}

export default withContainer(SubscriptionDetailsPage);

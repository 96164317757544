import React from "react";
import { Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { API_ROUTES } from "constants";
import { useAlert } from "context";
import { useFetch, useLanguage } from "hooks";
import {
  Button,
  SelectField,
  SelectFieldMultiple,
  Skeleton,
  TextAreaField,
  Typography,
} from "components";

function RefundModal({
  order_id,
  order_reference,
  visible,
  handleClose,
  ...props
}) {
  const translation = useLanguage();
  const settings = useSelector((state) => state.common.websiteSettings);
  const { refund_reasons } = settings;
  const [_, dispatchAlert] = useAlert();
  const [{ loading: orderDetailsLoading, data: orderDetailsData }] = useFetch({
    endpoint: API_ROUTES.getOrderDetails,
    params: `?order_reference=${order_reference}`,
    initialLoadingState: true,
  });

  const schema = yup.object().shape({
    items_to_return: yup.array().min(1, translation.itemsToReturnRequired),
    order_refund_reason_id: yup
      .string()
      .required(translation.orderRefuneReasonRequired),
    refund_method_id: yup
      .string()
      .required(translation.orderRefundMethodIdRequired),
    notes: yup.string().required(translation.notesRequired),
  });

  const { control, handleSubmit, watch, reset } = useForm({
    resolver: yupResolver(schema),
    // reValidateMode: "onChange",
  });
  const values = watch();

  const [{ loading }, request] = useFetch(
    {
      method: "POST",
      endpoint: API_ROUTES.submitReturnRequest,
      body: {
        order_items: values.items_to_return,
        order_refund_reason_id: values.order_refund_reason_id,
        refund_method: values.refund_method_id,
        notes: values.notes,
      },
      onSuccess: () => {
        dispatchAlert({
          type: "OPEN",
          payload: {
            icon: "success",
            title: translation.success,
            text: translation.submitOrderRefundSuccess,
            canDismiss: true,
          },
        });
        reset();
        handleClose();
      },
      onError: (err) => {
        dispatchAlert({
          type: "OPEN",
          payload: {
            icon: "attention",
            title: translation.error,
            text: err,
            canDismiss: true,
          },
        });
        reset();
        handleClose();
      },
    },
    [],
    false
  );

  const handleSubmitData = () => {
    request();
  };

  return (
    <div>
      <Modal
        show={visible}
        onHide={handleClose}
        contentClassName="bg-gray border-0 rounded-lg"
      >
        <Modal.Header className="border-0" closeButton />
        <Modal.Body className="show-grid px-4">
          <Row>
            <Col xs={12}>
              <Typography
                tag="h1"
                color="black"
                className="text-center fs-4 fw-normal"
                text={translation.refund}
              />
            </Col>
            <Col xs={12}>
              <Typography
                tag="p"
                variant="h5"
                color="secondary"
                className="text-center"
                text={`${translation.order} #${order_reference}`}
              />
            </Col>
          </Row>
          <Form onSubmit={handleSubmit(handleSubmitData)} className="mt-2">
            <Row>
              {orderDetailsLoading && <Skeleton height={200} />}
              {!orderDetailsLoading &&
                orderDetailsData &&
                orderDetailsData.items && (
                  <Col xs={12}>
                    <Controller
                      control={control}
                      name={"items_to_return"}
                      defaultValue={[]}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => (
                        <SelectFieldMultiple
                          label={translation.itemsToReturn}
                          labelClassName="fs-5 fw-bold m-0"
                          labelColor="black"
                          placeholder={translation.itemsToReturn}
                          options={orderDetailsData.items
                            .slice()
                            .filter((item) => Boolean(item.can_return))
                            .map((item) => ({
                              value: item.id,
                              label: `${item.product.title} - ${item.total_price} ${translation.website_currency}`,
                            }))}
                          onChange={(item) =>
                            onChange(item.map((v) => v.value))
                          }
                          defaultValue={value}
                          error={error && error.message}
                        />
                      )}
                    />
                  </Col>
                )}
              {values.items_to_return?.length > 0 && (
                <>
                  <Col xs={12} className="mt-4">
                    {refund_reasons && (
                      <Controller
                        control={control}
                        name={"order_refund_reason_id"}
                        defaultValue={""}
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => (
                          <SelectField
                            label={translation.selectReason}
                            labelClassName="fs-5 fw-bold m-0"
                            labelColor="black"
                            placeholder={translation.selectReason}
                            onChange={onChange}
                            defaultValue={value}
                            error={error && error.message}
                          >
                            {refund_reasons.map((reason) => (
                              <option key={reason.id} value={reason.id}>
                                {reason.name}
                              </option>
                            ))}
                          </SelectField>
                        )}
                      />
                    )}
                  </Col>
                  <Col xs={12} className="mt-4">
                    {refund_reasons && (
                      <Controller
                        control={control}
                        name={"refund_method_id"}
                        defaultValue={""}
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => (
                          <SelectField
                            label={translation.refundMethod}
                            labelClassName="fs-5 fw-bold m-0"
                            labelColor="black"
                            placeholder={translation.refundMethod}
                            onChange={onChange}
                            defaultValue={value}
                            error={error && error.message}
                          >
                            <option value={1}>{translation.wallet}</option>
                          </SelectField>
                        )}
                      />
                    )}
                  </Col>
                  <Col xs={12} className="mt-4">
                    <Typography
                      tag="label"
                      className="fs-5 fw-bold m-0"
                      color="black"
                      text={translation.notes}
                    />
                    <Controller
                      control={control}
                      name={"notes"}
                      defaultValue={""}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => (
                        <TextAreaField
                          rows={6}
                          placeholder={translation.message}
                          value={value}
                          onChange={onChange}
                          error={error && error.message}
                        />
                      )}
                    />
                  </Col>
                </>
              )}
            </Row>

            <Row className="justify-content-end mt-4">
              <Col xs={4}>
                <Button
                  type="submit"
                  className="w-100"
                  text={
                    loading ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      translation.send
                    )
                  }
                />
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default React.memo(RefundModal);

import React from "react";
import { Col, Container, Row, Form, Alert, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useLanguage } from "hooks";
import { InputField, Layout, Typography, Button, Icon } from "components";
import withContainer from "./Container";
import "./style.scss";

const Login = ({ form, setForm, handleBasicLogin, loading, errors }) => {
  const langunge = useLanguage();

  return (
    <Layout footerMargin>
      <section id="Login__section">
        <Container className="py-5">
          <Row className="justify-content-end">
            <Col xs={12} lg={6}>
              <div className="px-4 px-md-5 px-lg-4 ">
                {/* Login header */}
                <Row>
                  <Col xs={12}>
                    <Typography
                      tag="h1"
                      variant="h2"
                      color="black"
                      text={langunge.login}
                    />
                  </Col>
                </Row>

                {/* form */}
                <Row className="mt-3">
                  <Col xs={12} lg={8}>
                    <Row>
                      <Col xs={12}>
                        {typeof errors === "string" && (
                          <Alert variant="danger">{errors}</Alert>
                        )}
                      </Col>
                    </Row>
                    <Form onSubmit={handleBasicLogin}>
                      {/* email */}
                      <Row>
                        <Col xs={12}>
                          <InputField
                            type="text"
                            label={langunge.email}
                            labelClassName="fs-6"
                            placeholder={langunge.email}
                            onChange={(e) => setForm("email", e.target.value)}
                            value={form.email}
                            error={errors && errors.email}
                            className="py-3"
                          />
                        </Col>
                      </Row>

                      {/* Password */}
                      <Row className="mt-3">
                        <Col xs={12}>
                          <InputField
                            type="password"
                            label={langunge.password}
                            labelClassName="fs-6"
                            placeholder={langunge.password}
                            onChange={(e) =>
                              setForm("password", e.target.value)
                            }
                            value={form.password}
                            error={errors && errors.password}
                            className="py-3"
                            secure
                          />
                        </Col>
                      </Row>

                      {/* forgot Password */}
                      <Row className="mt-3">
                        <Col xs={12}>
                          <Link to="/ForgotPassword">
                            <Typography
                              tag="span"
                              variant="body1"
                              color="gray7"
                              text={langunge.forgotPassword}
                            />
                          </Link>
                        </Col>
                      </Row>

                      {/* Login Button */}
                      <Row className="mt-2">
                        <Col
                          className="py-3"
                          xs={12}
                          sm={12}
                          md={12}
                          lg={6}
                          xl={5}
                        >
                          <Button
                            type="submit"
                            variant="primary"
                            textVariant="buttonText3"
                            className="py-3 w-100"
                            text={
                              loading ? (
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                              ) : (
                                langunge.login
                              )
                            }
                          />
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>

                {/* Under Form */}
                {/* register Button */}
                <Row className="">
                  <Col sm={12} md={10} lg={11} xl={12}>
                    <Typography
                      tag="p"
                      variant="body1"
                      color="gray7"
                      text={
                        <React.Fragment>
                          {langunge.donotHaveAnAccount}{" "}
                          <Link className="text-blue" to="/register">
                            {langunge.register}
                          </Link>
                        </React.Fragment>
                      }
                    />
                  </Col>
                </Row>

                {/* or Login With text */}
                <Row className="mt-5">
                  <Col sm={12} md={10} lg={11} xl={12}>
                    <Typography
                      tag="p"
                      variant="subtitle1"
                      color="gray7"
                      text={langunge.orLoginWith}
                    />
                  </Col>
                </Row>

                {/* facebook & google */}
                <Row className="mt-3">
                  <Col xs={6} lg={5} xl={4}>
                    <Button
                      variant="facebook"
                      className="w-100 py-3 h-100"
                      text={<Icon name="facebook" className="text-white" />}
                    />
                  </Col>
                  <Col xs={6} lg={5} xl={4}>
                    <Button
                      variant="google"
                      className="w-100 py-3 h-100"
                      text={<Icon name="google" className="text-white" />}
                    />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};
export default withContainer(React.memo(Login));

import React from "react";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { phoneNumberRegexp } from "constants";
import { checkLogin, editProfile } from "store/auth";
import { useLanguage } from "hooks";
import { useAlert } from "context";

function withContainer(WrappedCompoent) {
  return (props) => {
    const dispatch = useDispatch();
    const [_, dispatchAlert] = useAlert();
    const language = useLanguage();
    const { user, loading } = useSelector((state) => state.auth);
    const schema = yup.object().shape(
      {
        first_name: yup.string().required(language.firstNameRequired),
        last_name: yup.string().required(language.lastNameRequired),
        phone: yup
          .string()
          .required(language.phoneNumberRequired)
          .matches(phoneNumberRegexp, language.phoneNumberInvalid),
        email: yup
          .string()
          .required(language.emailRequired)
          .email(language.emailInvalid),
        date_of_birth: yup.string().required(language.dateOfBirthRequired),
        gender: yup.string().required(language.genderRequired),
      },
      []
    );
    const {
      register: registerField,
      handleSubmit,
      formState: { errors },
      watch,
      reset,
    } = useForm({
      resolver: yupResolver(schema),
    });
    const values = watch();

    const handleSubmitData = () => {
      if (loading) return;
      dispatch(
        editProfile({
          body: {
            name: `${values.first_name} ${values.last_name}`,
            phone: values.phone,
            email: values.email,
            date_of_birth: values.date_of_birth,
            gender: values.gender,
            place_id: user.place.id,
          },
          onSuccess: () => {
            dispatch(checkLogin());
            dispatchAlert({
              type: "OPEN",
              payload: {
                icon: "success",
                title: language.success,
                text: language.profileEditSuccess,
                canDismiss: true,
              },
            });
          },
          onError: (err) => {
            if (typeof err === "string") {
              dispatchAlert({
                type: "OPEN",
                payload: {
                  icon: "attention",
                  title: language.error,
                  text: err,
                  canDismiss: true,
                },
              });
            }
          },
        })
      );
    };

    React.useEffect(() => {
      reset({
        first_name: user.name.split(" ")[0] || "",
        last_name: user.name.split(" ")[1] || "",
        phone: user.phone || "",
        email: user.email || "",
        date_of_birth: user.date_of_birth || "",
        gender: user.gender || "",
        place_id: user.place.id || "",
      });
    }, [reset, user]);

    return (
      <WrappedCompoent
        loading={loading}
        registerField={registerField}
        values={values}
        handleSubmit={handleSubmit(handleSubmitData)}
        errors={errors}
      />
    );
  };
}
export default withContainer;

import React from "react";
import { ReactComponent as Account } from "assets/icons/account.svg";
import { ReactComponent as Attach } from "assets/icons/attach.svg";
import { ReactComponent as Attention } from "assets/icons/attention.svg";
import { ReactComponent as ArrowPrev } from "assets/icons/arrow-prev.svg";
import { ReactComponent as ArrowNext } from "assets/icons/arrow-next.svg";
import { ReactComponent as BottomNavCart } from "assets/icons/bottomnav-cart.svg";
import { ReactComponent as BottomNavChat } from "assets/icons/bottomnav-chat.svg";
import { ReactComponent as BottomNavHome } from "assets/icons/bottomnav-home.svg";
import { ReactComponent as BottomNavMore } from "assets/icons/bottomnav-more.svg";
import { ReactComponent as BottomNavPackage } from "assets/icons/bottomnav-package.svg";
import { ReactComponent as Cart } from "assets/icons/cart.svg";
import { ReactComponent as Cancel } from "assets/icons/cancel.svg";
import { ReactComponent as Complain } from "assets/icons/complain.svg";
import { ReactComponent as Cash } from "assets/icons/cash.svg";
import { ReactComponent as CreditCard } from "assets/icons/credit-card.svg";
import { ReactComponent as Details } from "assets/icons/details.svg";
import { ReactComponent as Edit } from "assets/icons/edit.svg";
import { ReactComponent as EmergencyCallout } from "assets/icons/emergency-callout.svg";
import { ReactComponent as Email } from "assets/icons/email.svg";
import { ReactComponent as EmailOutline } from "assets/icons/email-outline.svg";
import { ReactComponent as Eye } from "assets/icons/eye.svg";
import { ReactComponent as EyeSlash } from "assets/icons/eye-slash.svg";
import { ReactComponent as Facebook } from "assets/icons/facebook.svg";
import { ReactComponent as FacebookOutline } from "assets/icons/facebook-outline.svg";
import { ReactComponent as Favourite } from "assets/icons/favourite.svg";
import { ReactComponent as FavouriteOutline } from "assets/icons/favourite-outline.svg";
import { ReactComponent as Google } from "assets/icons/google.svg";
import { ReactComponent as InstagramOutline } from "assets/icons/instagram-outline.svg";
import { ReactComponent as Location } from "assets/icons/location.svg";
import { ReactComponent as LockUnlocked } from "assets/icons/lock-unlocked.svg";
import { ReactComponent as Logout } from "assets/icons/logout.svg";
import { ReactComponent as MarkerOutline } from "assets/icons/marker-outline.svg";
import { ReactComponent as Next } from "assets/icons/next.svg";
import { ReactComponent as NormalCallout } from "assets/icons/normal-callout.svg";
import { ReactComponent as Order } from "assets/icons/order.svg";
import { ReactComponent as Phone } from "assets/icons/phone.svg";
import { ReactComponent as Points } from "assets/icons/points.svg";
import { ReactComponent as QrCode } from "assets/icons/qr-code.svg";
import { ReactComponent as PhoneOutline } from "assets/icons/phone-outline.svg";
import { ReactComponent as Prev } from "assets/icons/prev.svg";
import { ReactComponent as Refund } from "assets/icons/refund.svg";
import { ReactComponent as RequestCallout } from "assets/icons/request-callout.svg";
import { ReactComponent as Ring } from "assets/icons/ring.svg";
import { ReactComponent as Search } from "assets/icons/search.svg";
import { ReactComponent as Star } from "assets/icons/star.svg";
import { ReactComponent as StarEmpty } from "assets/icons/star-empty.svg";
import { ReactComponent as Step1 } from "assets/icons/step-1.svg";
import { ReactComponent as Step2 } from "assets/icons/step-2.svg";
import { ReactComponent as Step3 } from "assets/icons/step-3.svg";
import { ReactComponent as StepArrow } from "assets/icons/step-arrow.svg";
import { ReactComponent as StepArrow2 } from "assets/icons/step-arrow-2.svg";
import { ReactComponent as Subscription } from "assets/icons/subscription.svg";
import { ReactComponent as Translate } from "assets/icons/translate.svg";
import { ReactComponent as Trash } from "assets/icons/trash.svg";
import { ReactComponent as TwitterOutline } from "assets/icons/twitter-outline.svg";
import { ReactComponent as User } from "assets/icons/user.svg";
import { ReactComponent as Wallet } from "assets/icons/wallet.svg";
import { ReactComponent as WhatsappOutline } from "assets/icons/whatsapp-outline.svg";
import { ReactComponent as YoutubeOutline } from "assets/icons/youtube-outline.svg";
import { ReactComponent as Success } from "assets/icons/success.svg";
import { ReactComponent as Failed } from "assets/icons/failed.svg";
import PropTypes from "prop-types";

function Icon({ name, className, size }) {
  const iconList = {
    account: Account,
    attach: Attach,
    attention: Attention,
    arrowPrev: ArrowPrev,
    arrowNext: ArrowNext,
    bottomNavCart: BottomNavCart,
    bottomNavChat: BottomNavChat,
    bottomNavHome: BottomNavHome,
    bottomNavMore: BottomNavMore,
    bottomNavPackage: BottomNavPackage,
    cart: Cart,
    cancel: Cancel,
    complain: Complain,
    cash: Cash,
    creditCard: CreditCard,
    details: Details,
    edit: Edit,
    emergencyCallout: EmergencyCallout,
    email: Email,
    emailOutline: EmailOutline,
    eye: Eye,
    eyeSlash: EyeSlash,
    facebook: Facebook,
    facebookOutline: FacebookOutline,
    favourite: Favourite,
    favouriteOutline: FavouriteOutline,
    google: Google,
    instagramOutline: InstagramOutline,
    location: Location,
    lockUnlocked: LockUnlocked,
    logout: Logout,
    markerOutline: MarkerOutline,
    next: Next,
    normalCallout: NormalCallout,
    order: Order,
    phone: Phone,
    points: Points,
    phoneOutline: PhoneOutline,
    qrCode: QrCode,
    prev: Prev,
    refund: Refund,
    requestCallout: RequestCallout,
    ring: Ring,
    search: Search,
    star: Star,
    starEmpty: StarEmpty,
    step1: Step1,
    step2: Step2,
    step3: Step3,
    stepArrow: StepArrow,
    stepArrow2: StepArrow2,
    subscription: Subscription,
    translate: Translate,
    trash: Trash,
    twitterOutline: TwitterOutline,
    user: User,
    wallet: Wallet,
    whatsappOutline: WhatsappOutline,
    youtubeOutline: YoutubeOutline,
    success: Success,
    failed: Failed,
  };
  const IconComponent = iconList[name];

  if (!name) return null;
  if (!iconList[name]) return <span className={className}>??</span>;
  return (
    <IconComponent
      className={className}
      style={{
        width: size,
        height: size,
      }}
    />
  );
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  size: PropTypes.number,
  className: PropTypes.string,
};

export default React.memo(Icon);

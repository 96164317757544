import React from "react";
import { Col, Row } from "react-bootstrap";
import { Rating } from "react-simple-star-rating";
import classNames from "classnames";
import {
  Button,
  Icon,
  RatingStars,
  TextAreaField,
  Typography,
} from "components";
import { useLanguage } from "hooks";
import withContainer from "./Container";

function RatingForm({
  ratings,
  rate,
  comment,
  loading,
  handleSetRate,
  handleSetComment,
  handleSubmit,
}) {
  const translation = useLanguage();

  return (
    <div
      id="rating-form"
      className="py-4 px-4 bg-gray3 border border-gray5 rounded-sm"
    >
      <Row>
        <Col xs={12} md={5} lg={12} xl={5}>
          <Row className="h-100">
            {Array.from({ length: 5 }, (_, idx) => Math.abs(idx - 5)).map(
              (item, idx) => (
                <Col
                  key={item}
                  xs={12}
                  className={classNames({
                    "mb-1": idx < ratings.length - 1,
                  })}
                >
                  <RatingStars
                    rate={item}
                    starsContainerClassName="me-4"
                    starClassName="me-1"
                    className="d-inline fs-6 fw-bolder me-1"
                    showText
                  />
                  <Typography
                    tag="span"
                    variant="subtitle2"
                    color="secondary"
                    text={`(${
                      ratings.find((elm) => elm.rate === item).rate_count
                    })`}
                  />
                </Col>
              )
            )}
            <hr style={{ height: "2px" }} className="text-gray5 my-2" />
            <Col xs={12}>
              <Typography
                tag="h4"
                variant="h4"
                color="black"
                text={translation.totalReviews}
              />
            </Col>
            <Col xs={12}>
              <div className="d-flex align-items-center">
                <Icon name="star" className="text-star me-2" />{" "}
                <span className="fs-5">0</span>
              </div>
            </Col>
            <Col xs={12} className="mt-2 align-self-end">
              <Rating
                onClick={(val) => handleSetRate(val)}
                ratingValue={rate}
                rtl={translation.direction === "rtl"}
                fullIcon={<Icon name="star" className="text-star me-1" />}
                emptyIcon={<Icon name="starEmpty" className="text-star me-1" />}
                tooltipClassName="bg-transparent text-secondary fs-6 fw-bolder"
                showTooltip
              />
            </Col>
          </Row>
        </Col>
        <Col
          xs={12}
          md={7}
          lg={12}
          xl={7}
          className="mt-4 mt-md-0 mt-lg-4 mt-xl-0"
        >
          <Row className="justify-content-center">
            <Col xs={12}>
              <TextAreaField
                rows={17}
                onChange={(e) => handleSetComment(e.target.value)}
                value={comment}
              />
            </Col>
            <Col xs={8} className="mt-3">
              <Button
                onClick={handleSubmit}
                variant="primary"
                textVariant="buttonText3"
                className="w-100"
                text={translation.submit}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default withContainer(React.memo(RatingForm));

import React from "react";
import { useSkipFirstRender } from "hooks";

export default function withContainer(WrappedComponent) {
  return ({
    id,
    label,
    labelClassName,
    labelColor,
    min = 0,
    max = Math.pow(10, 5),
    value,
    onChange,
    error,
  }) => {
    // const [count, setCount] = React.useState(defaultValue || min);
    const inputRef = React.useRef(null);
    const handleIncrement = () => {
      if (value + 1 <= max) {
        return onChange(value + 1);
      }
      onChange(value);
    };

    const handleDecrement = () => {
      if (+value - 1 >= min) {
        return onChange(+value - 1);
      }
      onChange(+value);
    };

    const handleOnInputBlur = React.useCallback(
      (val) => {
        let rounded = Math.round(val);
        inputRef.current.value = value;
        if (rounded === null || isNaN(rounded))
          return (inputRef.current.value = value);
        if (typeof rounded !== "number") return;
        if (rounded < min || rounded > max)
          return onChange(rounded < min ? min : max);
        onChange(rounded);
      },
      [value, max, min]
    );

    useSkipFirstRender(() => {
      // console.log("val", value);
      // if (onChange) {
      //   onChange(value);
      // }
      // handleOnInputBlur(value);
    }, [value]);

    return (
      <WrappedComponent
        id={id}
        label={label}
        labelClassName={labelClassName}
        labelColor={labelColor}
        value={value}
        handleDecrement={handleDecrement}
        inputRef={inputRef}
        handleOnInputBlur={handleOnInputBlur}
        handleIncrement={handleIncrement}
        error={error}
      />
    );
  };
}

import React from "react";
import { useSelector } from "react-redux";

function withContainer(WrappedCompoent) {
  return (props) => {
    const { user, loading } = useSelector((state) => state.auth);

    return <WrappedCompoent user={user} loading={loading} />;
  };
}
export default withContainer;

import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { IMAGES } from "constants";
import { useLanguage } from "hooks";
import { Icon, Image, Typography } from "components";
import withContainer from "./Container";
import "./style.scss";

function ServiceCard({
  id,
  machine_name,
  title,
  subtitle,
  main_image,
  price,
  price_after_discount,
  rate,
  label,
  is_favourite,
  percent,
  isFavourite,
  handleAddRemoveWishlist,
}) {
  const language = useLanguage();

  return (
    <div className="service-card p-2 bg-gray3 rounded-xs border border-gray border-1">
      <Link to={`/service/${machine_name}`}>
        <div
          onClick={handleAddRemoveWishlist}
          className="favourite"
          role="button"
        >
          {!isFavourite && (
            <Icon name="favouriteOutline" className="text-primary" />
          )}
          {isFavourite && <Icon name="favourite" className="text-primary" />}
        </div>
        <Row>
          <Col xs={12} className="position-relative">
            <Image
              src={main_image}
              alt={title}
              className="service_img rounded-xs"
              fromApi
            />
            {percent && percent > 0 && (
              <div className="position-absolute left right bottom text-center">
                <div className="position-relative d-flex justify-content-center">
                  <Image className="" src={IMAGES.saleTriangle} alt="sale" />
                  <div className="text-center position-absolute bottom w-100 h-100 d-flex align-items-end justify-content-center">
                    <Typography
                      tag="h4"
                      variant="h5"
                      weight="bolder"
                      color="primary"
                    >
                      {percent}%
                    </Typography>
                  </div>
                </div>
              </div>
            )}
          </Col>
          <Col xs={12} className="mt-2">
            <Row>
              <Col xs={12}>
                <div className="service-title">
                  <Typography
                    tag="h3"
                    variant="h4"
                    weight="bolder"
                    align="center"
                    overflow={2}
                    color="secondary"
                    className="text-capitalize"
                  >
                    {title}
                  </Typography>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <div className="service-description">
                  <Typography
                    tag="h4"
                    variant="body3"
                    weight="normal"
                    align="center"
                    color="black"
                    overflow={2}
                  >
                    {subtitle}
                  </Typography>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={12} className="mt-2">
            <Row className="">
              <Col xs={12}>
                <Typography tag="h4" variant="h6" weight="bold" color="primary">
                  {language.from}
                </Typography>
              </Col>
              <Col xs={12} className="align-self-end">
                <div className="price-container d-block d-xl-flex align-items-end">
                  <div>
                    <Typography
                      tag="h4"
                      variant="h5"
                      weight="normal"
                      color="secondary"
                      overflow={1}
                      className="me-2"
                      text={
                        parseFloat(price_after_discount) > 0
                          ? `${parseFloat(price_after_discount)} ${
                              language.website_currency
                            }`
                          : language.basedOnSelection
                      }
                    />
                  </div>
                  {parseFloat(price_after_discount) !== parseFloat(price) && (
                    <div className="d-inline-block">
                      <Typography
                        tag="span"
                        variant="h6"
                        weight="normal"
                        color="gray5"
                        overflow={1}
                        className="strikethrough"
                      >
                        {parseFloat(price)} {language.website_currency}
                      </Typography>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Link>
    </div>
  );
}

export default withContainer(React.memo(ServiceCard));

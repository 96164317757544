import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  Layout,
  BreadCrumb,
  Typography,
  Skeleton,
  SkeletonGroup,
} from "components";
import "./style.scss";
import { useLanguage } from "hooks";
import withContainer from "./Container";

function AboutPage({ loading, name, text }) {
  const language = useLanguage();
  return (
    <Layout mainClassName="section-mb">
      <div id="AboutPage" className="page_container">
        <BreadCrumb
          title={language.aboutUs}
          path={[{ title: language.aboutUs }]}
          src="./images/about_bg.png"
          lastWordClassName="text-secondary fw-bolder"
          titleClassName="text-black fs-1 fw-light"
        />
        <Container className="mt-4">
          {!loading ? (
            <Row>
              <Col xs={12}>
                <Typography
                  weight="bolder"
                  color="secondary"
                  tag="h1"
                  variant="h2"
                >
                  {name}
                </Typography>
              </Col>
              <Col xs={12} className="mt-2">
                {text && (
                  <div
                    className="content"
                    dangerouslySetInnerHTML={{ __html: text }}
                  />
                )}
              </Col>
            </Row>
          ) : (
            <Row>
              <Col xs={12}>
                <Skeleton height={40} width="30%" />
              </Col>
              <Col xs={12} className="mt-2">
                <SkeletonGroup height={40} rows={4} width="100%" />
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </Layout>
  );
}

export default withContainer(React.memo(AboutPage));

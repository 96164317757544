import ServiceListPage from "./List";
import TopRatedServicesPage from "./TopRated";
import ServiceDetailsPage from "./Details";

const Service = {
  List: ServiceListPage,
  TopRated: TopRatedServicesPage,
  Details: ServiceDetailsPage,
};

export default Service;

import React from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { API_ROUTES } from "constants";
import { useFetch } from "hooks";

function withContainer(WrappedCompoent) {
  return (props) => {
    const { orderReference } = useParams();
    const { websiteInformation } = useSelector((state) => state.common);
    const [{ loading, data }] = useFetch({
      endpoint: API_ROUTES.getOrderDetails,
      params: `?order_reference=${orderReference}`,
      initialLoadingState: true,
    });

    return (
      <WrappedCompoent
        loading={loading}
        data={data}
        websiteInformation={websiteInformation}
      />
    );
  };
}
export default withContainer;

import React from "react";
import { useAlert } from "context";

function withContainer(WrappedCompoent) {
  return (props) => {
    const [
      { variant, canDismiss, visible, icon, title, text, buttons },
      dispatch,
    ] = useAlert();

    const handleButtonClick = (onClick) => {
      if (onClick) {
        onClick();
      }
      dispatch({ type: "CLOSE" });
    };

    return (
      <WrappedCompoent
        variant={variant}
        canDismiss={canDismiss}
        visible={visible}
        icon={icon}
        title={title}
        text={text}
        buttons={buttons}
        handleButtonClick={handleButtonClick}
        handleClose={() => dispatch({ type: "CLOSE" })}
      />
    );
  };
}
export default withContainer;

import React from "react";
import { API_ROUTES } from "constants";
import { useFetch, useLanguage } from "hooks";
import { useAlert } from "context";

function withContainer(WrappedCompoent) {
  return (props) => {
    const language = useLanguage();
    const [defaultId, setDefaultId] = React.useState(null);
    const [deleteId, setDeleteId] = React.useState(null);
    const [_, dispatchAlert] = useAlert();
    const [{ loading, data }, refetchLocations] = useFetch({
      endpoint: API_ROUTES.getUserLocations,
      initialLoadingState: true,
    });

    useFetch(
      {
        method: "POST",
        endpoint: API_ROUTES.markUserLocationAsDefault,
        initialLoadingState: true,
        body: {
          user_location_id: defaultId,
        },
        onSuccess: (response) => {
          refetchLocations();
          dispatchAlert({
            type: "OPEN",
            payload: {
              icon: "success",
              title: language.success,
              text: language.locationMadeDefaultSuccess,
              canDismiss: true,
            },
          });
        },
      },
      [defaultId],
      false
    );

    useFetch(
      {
        method: "POST",
        endpoint: API_ROUTES.deleteUserLocation,
        initialLoadingState: true,
        body: {
          user_location_id: deleteId,
        },
        onSuccess: (response) => {
          refetchLocations();
          dispatchAlert({
            type: "OPEN",
            payload: {
              icon: "success",
              title: language.success,
              text: language.locationDeleteSuccess,
              canDismiss: true,
            },
          });
        },
      },
      [deleteId],
      false
    );

    return (
      <WrappedCompoent
        loading={loading}
        data={data}
        handleMakeAddressDefault={(id) => setDefaultId(id)}
        handleDeleteAddress={(id) => {
          dispatchAlert({
            type: "OPEN",
            payload: {
              icon: "attention",
              title: "Attention",
              text: language.doYouWantToDeleteAddress,
              canDismiss: true,
              buttons: [
                {
                  variant: "danger",
                  text: language.yes,
                  onClick: () => setDeleteId(id),
                },
                {
                  variant: "secondary",
                  text: language.no,
                },
              ],
            },
          });
        }}
      />
    );
  };
}
export default withContainer;

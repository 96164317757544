import React from "react";
import classNames from "classnames";

import { DynamicField } from "./component";

function DynamicFields({ fields, registerField, control, getValue, type = 1 }) {
  return (
    <div className="dynamice-fields">
      {fields &&
        fields.length > 0 &&
        fields.map((field, idx) => (
          <div
            key={field.id}
            className={classNames("", {
              "mt-3 ": idx > 0,
            })}
          >
            <DynamicField
              fieldsVariant={type}
              registerField={registerField}
              control={control}
              getValue={getValue}
              {...field}
            />
          </div>
        ))}
    </div>
  );
}

export default DynamicFields;

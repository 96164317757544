import {
  AttachField,
  DropZoneField,
  GroupField,
  InputField,
  QuantityField,
  RadioSelectField,
  HorizontalOptionsField,
  SelectField,
  SelectFieldMultiple,
  SelectFieldWithQty,
  TextAreaField,
  SelectFieldWithQtyAsSelect,
} from "components";
import { useLanguage } from "hooks";
import React, { Fragment } from "react";
import { Controller } from "react-hook-form";
import NumberField from "./NumberField";
import { useAlert } from "context";

function DynamicField({
  id,
  label,
  extra_label_1,
  extra_label_2,
  hideLabel,
  type,
  is_required,
  min_length,
  max_length,
  values,
  dropdown_values,
  group_fields,
  registerField,
  onSubFieldChangedHandler,
  control,
  getValue,
  fieldsVariant,
  equation,
}) {
  const language = useLanguage();
  const [_, dispatchAlert] = useAlert();

  if (fieldsVariant === 1) {
    switch (type) {
      case 1:
        return (
          <Controller
            control={control}
            name={`${id}`}
            defaultValue={""}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextAreaField
                id={`${id}`}
                type="text"
                label={hideLabel ? undefined : label}
                labelClassName="fw-bolder fs-7"
                labelColor="black"
                placeholder={label}
                error={error && error.message}
                onChange={(val) => {
                  onChange(val);
                  if (onSubFieldChangedHandler) {
                    if (val.length > 0) {
                      onSubFieldChangedHandler({
                        id: id,
                        value: val,
                      });
                    } else {
                      onSubFieldChangedHandler({
                        id: id,
                      });
                    }
                  }
                }}
                value={value}
              />
            )}
          />
        );
      case 2:
        return (
          <Controller
            name={`${id}`}
            control={control}
            defaultValue=""
            render={({ field: { onChange }, fieldState: { error } }) => (
              <SelectField
                id={`${id}`}
                type="text"
                label={hideLabel ? undefined : label}
                labelClassName="fw-bolder fs-7"
                labelColor="black"
                onChange={(e) => {
                  onChange(e.target.value);
                  if (onSubFieldChangedHandler) {
                    if (e.target.value.length > 0) {
                      onSubFieldChangedHandler({
                        id: id,
                        value: e.target.value,
                      });
                    } else {
                      onSubFieldChangedHandler({
                        id: id,
                      });
                    }
                  }
                }}
                placeholder={language.pleaseSelect}
                error={error && error.message}
              >
                {values.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.value}{" "}
                    {item.price > 0 &&
                      `| +${item.price} ${language.website_currency}`}
                  </option>
                ))}
              </SelectField>
            )}
          />
        );
      case 3:
        return (
          <Controller
            control={control}
            name={`${id}`}
            defaultValue={0}
            render={({
              field: { onChange, value, ref },
              fieldState: { error },
            }) => (
              <NumberField
                id={`${id}`}
                type="number"
                label={hideLabel ? undefined : label}
                labelClassName="fw-bolder fs-7"
                labelColor="black"
                placeholder={label}
                error={error && error.message}
                onChange={(val) => {
                  onChange(val);
                  if (onSubFieldChangedHandler) {
                    if (!isNaN(val) && val > 0) {
                      onSubFieldChangedHandler({
                        id: id,
                        value: val,
                      });
                    } else {
                      onSubFieldChangedHandler({
                        id: id,
                      });
                    }
                  }
                }}
                value={value}
              />
            )}
          />
        );
      case 4:
        return (
          <Controller
            control={control}
            name={`${id}`}
            defaultValue={[]}
            render={({
              field: { onChange, value, ref },
              fieldState: { error },
            }) => (
              <AttachField
                label={label}
                onChange={(val) => {
                  onChange(val);
                  if (onSubFieldChangedHandler) {
                    if (val) {
                      onSubFieldChangedHandler({
                        id: id,
                        value: val,
                      });
                    } else {
                      onSubFieldChangedHandler({
                        id: id,
                      });
                    }
                  }
                }}
              />
            )}
          />
        );

      case 5:
        return (
          <Controller
            control={control}
            name={`${id}`}
            defaultValue={[]}
            render={({
              field: { onChange, value, ref },
              fieldState: { error },
            }) => (
              <SelectFieldMultiple
                inputRef={ref}
                label={hideLabel ? undefined : label}
                labelClassName="fw-bolder fs-7"
                labelColor="black"
                placeholder={language.pleaseSelect}
                options={values.map((item) => {
                  return {
                    value: item.id,
                    label: `${item.value} | +${item.price} ${language.website_currency}`,
                    group_class: item.group_class ? item.group_class : null,
                  };
                })}
                value={value}
                onChange={(val) => {
      
                  //check if group_class is not null or undefined And if any of select values doesnt have a value has same group_class
                  let isValuePresent = false;
                  let conflictValueObj = null;
                  if(val.length > 0 && val[val.length - 1].group_class != null && val.length >= value.length){

                    value.some(obj => {
                      isValuePresent = typeof obj['group_class'] === 'string' && obj['group_class'].includes(val[val.length - 1].group_class);
                    
                      if (isValuePresent) {
                        conflictValueObj = obj;
                        return true; // Exit the loop if a match is found
                      }
                      return false; // Continue iterating
                    });

    
                
                  }

                  if (!isValuePresent) {
                    onChange(val.map((item) => item));
                    if (onSubFieldChangedHandler) {
                      if (val?.length > 0) {
                        onSubFieldChangedHandler({
                          id: id,
                          value: val.map((item) => item),
                        });
                      } else {
                        onSubFieldChangedHandler({
                          id: id,
                        });
                      }
                    }
                  } else {
                    console.log(conflictValueObj)
                    let messageOfConflict = language.theSelectedOptionIsConflictWithOneOfOtherOptions;
                    if(conflictValueObj) {
                      messageOfConflict = language.theSelectedOptionIsConflictWithOneOfOtherOptions + "   " + conflictValueObj.label;
                    }
                    dispatchAlert({
                      type: "OPEN",
                      payload: {
                        icon: "attention",
                        title: language.sorry,
                        text: messageOfConflict,
                        canDismiss: true,
                      },
                    });
                  }


                }}
                error={error && error.message}
              />
            )}
          />
        );
      case 6:
        return (
          <Controller
            control={control}
            name={`${id}`}
            defaultValue={""}
            render={({
              field: { onChange, value, ref },
              fieldState: { error },
            }) => {
              return (
                <HorizontalOptionsField
                  id={`${id}`}
                  label={hideLabel ? undefined : label}
                  labelClassName="fw-bolder fs-7"
                  labelColor="black"
                  options={values}
                  value={value}
                  error={error && error.message}
                  disabled={
                    equation &&
                    equation
                      .replace(/\D+/g, " ")
                      .split(" ")
                      .filter(
                        (item) =>
                          item !== "" &&
                          String(item) !== String(id) &&
                          getValue(String(item)) !== "" &&
                          getValue(String(item))?.value !== ""
                      ).length === 0
                  }
                  onChange={(val) => {
                    onChange(val);
                    if (onSubFieldChangedHandler) {
                      onSubFieldChangedHandler(val);
                      if (val) {
                        onSubFieldChangedHandler({
                          id: id,
                          value: val,
                        });
                      } else {
                        onSubFieldChangedHandler({
                          id: id,
                        });
                      }
                    }
                  }}
                />
              );
            }}
          />
        );

      case 7:
        return (
          <Controller
            control={control}
            name={`${id}`}
            defaultValue={[]}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <GroupField
                id={`${id}`}
                label={label}
                labelClassName="fw-bolder fs-7"
                labelColor="black"
                headers={values.map((item) => item.value)}
                group_fields={group_fields}
                registerField={registerField}
                error={error}
                onChange={(val) => {
                  onChange(val);
                  if (onSubFieldChangedHandler) {
                    if (val) {
                      onSubFieldChangedHandler({
                        id: id,
                        value: val,
                      });
                    } else {
                      onSubFieldChangedHandler({
                        id: id,
                      });
                    }
                  }
                }}
                value={value}
                control={control}
              />
            )}
          />
        );

      case 8:
        return (
          <Controller
            name={`${id}`}
            control={control}
            defaultValue={min_length}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <QuantityField
                id={`${id}`}
                type="number"
                label={hideLabel ? undefined : label}
                labelClassName="fw-bolder fs-7"
                labelColor="black"
                placeholder={label}
                min={min_length}
                max={max_length}
                error={error && error.message}
                onChange={(val) => {
                  onChange(val);
                  if (onSubFieldChangedHandler) {
                    if (val) {
                      onSubFieldChangedHandler({
                        id: id,
                        value: val,
                      });
                    } else {
                      onSubFieldChangedHandler({
                        id: id,
                      });
                    }
                  }
                }}
                value={value}
              />
            )}
          />
        );

      case 9:
        return (
          <Controller
            name={`${id}`}
            control={control}
            defaultValue={{
              value: "",
              qty: min_length,
            }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Fragment>
                <SelectFieldWithQty
                  id={`${id}`}
                  type="text"
                  label={hideLabel ? undefined : label}
                  labelClassName="fw-bolder fs-7"
                  labelColor="black"
                  min={min_length}
                  max={max_length}
                  value={value}
                  onChange={(val) => {
                    onChange(val);
                    if (onSubFieldChangedHandler) {
                      if (val && val.value && val.qty) {
                        onSubFieldChangedHandler({
                          id: id,
                          value: val,
                        });
                      } else {
                        onSubFieldChangedHandler({
                          id: id,
                        });
                      }
                    }
                  }}
                  error={
                    error &&
                    `${error.qty ? error.qty.message : ""} ${error.value ? error.value.message : ""
                    }`
                  }
                  placeholder={label}
                >
                  {values.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.value}
                    </option>
                  ))}
                </SelectFieldWithQty>
              </Fragment>
            )}
          />
        );

      case 10:
        return (
          <Controller
            control={control}
            name={`${id}`}
            defaultValue={""}
            render={({
              field: { onChange, value, ref },
              fieldState: { error },
            }) => (
              <RadioSelectField
                id={`${id}`}
                label={hideLabel ? undefined : label}
                labelClassName="fw-bolder fs-7"
                labelColor="black"
                options={values}
                onChange={(val) => {
                  onChange(val);
                  if (onSubFieldChangedHandler) {
                    if (val) {
                      onSubFieldChangedHandler({
                        id: id,
                        value: val,
                      });
                    } else {
                      onSubFieldChangedHandler({
                        id: id,
                      });
                    }
                  }
                }}
              />
            )}
          />
        );

      case 11:
        return (
          <Controller
            name={`${id}`}
            control={control}
            defaultValue={{
              value: "",
              qty: 0,
            }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Fragment>
                <SelectFieldWithQtyAsSelect
                  id={`${id}`}
                  type="text"
                  label={hideLabel ? undefined : label}
                  selectLabel={extra_label_1}
                  qtyLabel={extra_label_2}
                  labelClassName="fw-bolder fs-7"
                  labelColor="black"
                  min={min_length}
                  max={max_length}
                  value={value}
                  onChange={(val) => {
                    onChange(val);
                    if (onSubFieldChangedHandler) {
                      if (val) {
                        onSubFieldChangedHandler({
                          id: id,
                          value: val,
                        });
                      } else {
                        onSubFieldChangedHandler({
                          id: id,
                        });
                      }
                    }
                  }}
                  error={
                    error &&
                    `${error.qty ? error.qty.message : ""} ${error.value ? error.value.message : ""
                    }`
                  }
                  placeholder={label}
                >
                  {values.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.value}
                    </option>
                  ))}
                </SelectFieldWithQtyAsSelect>
              </Fragment>
            )}
          />
        );

      default:
        return null;
    }
  } else if (fieldsVariant === 2) {
    switch (type) {
      case 1:
        return (
          <Controller
            control={control}
            name={`${id}`}
            defaultValue={""}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputField
                id={`${id}`}
                type="text"
                label={label}
                labelClassName="fw-bolder fs-7"
                labelColor="black"
                placeholder={label}
                error={error && error.message}
                onChange={(val) => {
                  onChange(val);
                  if (onSubFieldChangedHandler) {
                    if (val) {
                      onSubFieldChangedHandler({
                        id: id,
                        value: val,
                      });
                    } else {
                      onSubFieldChangedHandler({
                        id: id,
                      });
                    }
                  }
                }}
                value={value}
              />
            )}
          />
        );
      case 2:
        return (
          <Controller
            name={`${id}`}
            control={control}
            defaultValue=""
            render={({ field: { onChange }, fieldState: { error } }) => (
              <SelectField
                id={`${id}`}
                type="text"
                label={hideLabel ? undefined : label}
                labelClassName="fw-bolder fs-7"
                labelColor="black"
                onChange={(e) => {
                  onChange(e.target.value);
                  if (onSubFieldChangedHandler) {
                    if (e.target.value) {
                      onSubFieldChangedHandler({
                        id: id,
                        value: e.target.value,
                      });
                    } else {
                      onSubFieldChangedHandler({
                        id: id,
                      });
                    }
                  }
                }}
                placeholder={language.pleaseSelect}
                error={error && error.message}
              >
                {dropdown_values.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.value}
                  </option>
                ))}
              </SelectField>
            )}
          />
        );
      case 3:
        return (
          <Controller
            control={control}
            name={`${id}`}
            defaultValue={0}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <NumberField
                id={`${id}`}
                type="number"
                label={label}
                labelClassName="fw-bolder fs-7"
                labelColor="black"
                placeholder={label}
                error={error && error.message}
                onChange={(val) => {
                  onChange(val);
                  if (onSubFieldChangedHandler) {
                    if (val) {
                      onSubFieldChangedHandler({
                        id: id,
                        value: val,
                      });
                    } else {
                      onSubFieldChangedHandler({
                        id: id,
                      });
                    }
                  }
                }}
                value={value}
              />
            )}
          />
        );
      case 4:
        return (
          <Controller
            control={control}
            name={`${id}`}
            defaultValue={[]}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <DropZoneField
                label={label}
                onChange={(val) => {
                  onChange(val);
                  if (onSubFieldChangedHandler) {
                    if (val) {
                      onSubFieldChangedHandler({
                        id: id,
                        value: val,
                      });
                    } else {
                      onSubFieldChangedHandler({
                        id: id,
                      });
                    }
                  }
                }}
                error={error}
              />
            )}
          />
        );
      default:
        return null;
    }
  } else {
    return null;
  }
}

export default DynamicField;

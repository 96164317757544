import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLanguage } from "hooks";
import { BreadCrumb, GridList, Title, Layout } from "components";
import withContainer from "./Container";
import { AdviceCard } from "components/cards";

function AdviceListPage({ loading, data, meta }) {
  const language = useLanguage();

  return (
    <Layout footerMargin>
      <div id="advice-list-page" className="page_container">
        <BreadCrumb
          title={language.advices}
          path={[{ title: language.advices }]}
          src="/images/blog.png"
          titleClassName="text-white fs-1 fw-light"
          lastWordClassName="text-secondary fw-bolder"
        />
        <Container className="mt-5">
          <Row>
            <Col xs={12}>
              <Title
                tag="h1"
                title={language.specialAdvicesforYourLovelyHome}
                description="Over 200,000 downloadable resources, with commercial license. Unlimited Downloads and more."
                titleClassName="fs-2 fw-normal"
                color="black"
                lastColor="secondary"
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col xs={12}>
              <GridList
                as={AdviceCard}
                items={data && data.length > 0 ? data : []}
                loading={loading}
                meta={meta}
                noDataImage="/images/no-blogs.png"
                noDataText={language.noAdvices}
                skeletonHeight={256}
                xs={12}
                sm={12}
                md={6}
                lg={4}
                xl={4}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  );
}

export default withContainer(React.memo(AdviceListPage));

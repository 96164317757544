import React from "react";
import { Link, NavLink } from "react-router-dom";
import { Col, Container, Row, ButtonGroup, Navbar, Nav } from "react-bootstrap";
import { IMAGES } from "constants";
import { useLanguage } from "hooks";
import Typography from "components/Typography";
import Button from "components/Button";
import DropdownMenu from "components/DropdownMenu";
import "./style.scss";

function SectionTwo({ isLoggedIn, user, handleLogout }) {
  const language = useLanguage();

  return (
    <div id="header-section-two">
      <Container className="h-100">
        <Row className="align-items-center h-100">
          <Col xl={2} lg={3} xs={12}>
            <NavLink to="/">
              <img src={IMAGES.Logo} alt="Expert Team" className="logo" />
            </NavLink>
          </Col>
          <Col xl={7} lg={4} className="d-none d-lg-block">
            <div className="header-navigation">
              <Navbar bg="transparent" expand="xl">
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="ms-auto">
                    <Nav.Link as={NavLink} to="/">
                      <Typography tag="span" variant="h6">
                        {language.home}
                      </Typography>
                    </Nav.Link>
                    <Nav.Link as={NavLink} to="/about">
                      <Typography tag="span" variant="h6">
                        {language.aboutUs}
                      </Typography>
                    </Nav.Link>
                    <Nav.Link as={NavLink} to="/our-services">
                      <Typography tag="span" variant="h6">
                        {language.services}
                      </Typography>
                    </Nav.Link>
                    <Nav.Link as={NavLink} to="/articles">
                      <Typography tag="span" variant="h6">
                        {language.blogs}
                      </Typography>
                    </Nav.Link>
                    <Nav.Link as={NavLink} to="/videos">
                      <Typography tag="span" variant="h6">
                        {language.videos}
                      </Typography>
                    </Nav.Link>
                    <Nav.Link as={NavLink} to="/contact">
                      <Typography tag="span" variant="h6">
                        {language.contactUs}
                      </Typography>
                    </Nav.Link>
                    <Nav.Link as={NavLink} to="/packages">
                      <Typography tag="span" variant="h6">
                        {language.maintenancePackages}
                      </Typography>
                    </Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
            </div>
          </Col>
          <Col
            xl={3}
            lg={5}
            xs={6}
            className="d-none d-lg-block text-end float-end"
          >
            {isLoggedIn ? (
              <DropdownMenu
                label={user.name}
                icon="user"
                textVariant="h1"
                iconClassName="user-icon me-2 text-secondary"
                className="user-dropdown float-end"
                options={[
                  {
                    label: language.myAccount,
                    link: "/account/edit",
                    icon: "user",
                  },
                  {
                    label: language.myOrders,
                    link: "/account/orders",
                    icon: "order",
                  },
                  {
                    label: language.mySubscriptions,
                    link: "/account/subscriptions",
                    icon: "subscription",
                  },
                  {
                    label: language.myPoints,
                    link: "/account/points",
                    icon: "points",
                  },
                  {
                    label: language.myWallet,
                    link: "/account/wallet",
                    icon: "wallet",
                  },
                  {
                    label: language.logout,
                    onClick: handleLogout,
                    icon: "logout",
                  },
                ]}
              />
            ) : (
              <ButtonGroup className="auth-btn-group">
                <Button
                  as={Link}
                  to="/register"
                  tabIndex="-1"
                  variant="outline-secondary"
                  text={language.register}
                />
                <Button
                  as={Link}
                  to="/login"
                  tabIndex="-1"
                  variant="outline-secondary"
                  text={language.login}
                />
              </ButtonGroup>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default React.memo(SectionTwo);

import React from "react";
import { Col, Row } from "react-bootstrap";
import classNames from "classnames";
import { useLanguage, usePagination } from "hooks";
import { Button } from "components";
import "./style.scss";

function Pagination({ count, perPage }) {
  const language = useLanguage();
  const { page, handleSetPage, onPrev, onNext } = usePagination();
  const siblingCount = 2;
  const totalCount = Math.ceil(count / perPage);

  const calculateRange = (start, end) => {
    let length = end - start + 1;
    /* Create an array of certain length and set the elements within it from
          start value to end value.
      */
    return Array.from({ length }, (_, idx) => idx + start);
  };

  const range = React.useMemo(() => {
    // Pages count is determined as siblingCount + firstPage + lastPage + currentPage
    const totalPageNumbers = siblingCount + 3;

    /*
        Case 1:
        If the number of pages is less than the page numbers we want to show in our
        paginationComponent, we return the range [1..totalCount]
      */
    if (totalPageNumbers >= totalCount) {
      return calculateRange(1, totalCount);
    }

    /*
        Calculate left and right sibling index and make sure they are within range 1 and totalCount
      */
    const leftSiblingIndex = Math.max(page - siblingCount, 1);
    const rightSiblingIndex = Math.min(page + siblingCount, totalCount);

    const firstPageIndex = 1;
    const lastPageIndex = totalCount;

    let middleRange = calculateRange(leftSiblingIndex, rightSiblingIndex);

    if (page - siblingCount <= firstPageIndex)
      return [...middleRange, lastPageIndex];
    else if (page + siblingCount >= totalCount)
      return [firstPageIndex, ...middleRange];
    return [firstPageIndex, ...middleRange, lastPageIndex];
  }, [totalCount, siblingCount, page]);

  if (page === 0 || range.length < 2) {
    return null;
  }

  if (totalCount < 2) return null;
  return (
    <div className="pagination-component mt-5 bg-gray3 py-3 px-2">
      <Row className="align-items-center">
        <Col xs={3}>
          {page > 1 && (
            <Button
              onClick={onPrev}
              variant=""
              icon="arrowPrev"
              iconClassName="flip-x text-primary me-4"
              className="page-prev text-secondary fw-bolder py-0"
              text={language.prev}
            />
          )}
        </Col>
        <Col xs={6} className="text-center">
          {range.map((pageNumber, idx) => (
            <Button
              key={pageNumber}
              onClick={() => handleSetPage(pageNumber)}
              variant={pageNumber === page ? "primary" : ""}
              className={classNames("page-btn fw-bolder text-secondary", {
                "active text-white": pageNumber === page,
                "me-1": idx + 1 < range.length,
              })}
              text={pageNumber}
            />
          ))}
        </Col>
        <Col xs={3} className="text-end">
          {page < totalCount && (
            <Button
              onClick={onNext}
              variant=""
              icon="arrowNext"
              iconClassName="flip-x text-primary ms-4"
              iconLocation="right"
              className="page-next text-secondary fw-bolder py-0"
              text={language.next}
            />
          )}
        </Col>
      </Row>
    </div>
  );
}

export default React.memo(Pagination);

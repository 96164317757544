import React from "react";
import { useParams } from "react-router-dom";
import { useOnScreen } from "hooks";

function withContainer(WrappedCompoent) {
  return ({ as, items, meta, horizontal, ...props }) => {
    const params = useParams();
    const listRef = React.useRef();
    const isListVisible = useOnScreen(listRef);

    React.useLayoutEffect(() => {
      setTimeout(() => {
        if (isListVisible) {
          window.scrollTo(0, 0);
        }
      }, 100);
    }, [isListVisible, params]);

    return (
      <WrappedCompoent
        {...{
          componentToRender: as,
          items,
          pagination: {
            count: meta && meta.total,
            perPage: meta && meta.per_page,
          },
          horizontal,
          listRef: listRef,
          ...props,
        }}
      />
    );
  };
}
export default withContainer;

import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Spinner } from "react-bootstrap";
import { NoData } from "components";
import withContainer from "./Container";

function GridListMobile({
  loading,
  noDataImage,
  noDataText,
  items,
  pagination,
  componentToRender,
  lastItemElement,
  xs,
  sm,
  md,
  lg,
  xl,
  ...props
}) {
  const Component = componentToRender.as
    ? componentToRender.as
    : componentToRender;
  return (
    <div className="gridlist-mobile" {...props}>
      {!loading && items.length === 0 && (
        <Col xs={12}>
          <NoData image={noDataImage} text={noDataText} />
        </Col>
      )}
      <Row className="g-2">
        {items.length > 0 &&
          items.map((item, idx) => (
            <Col
              key={`${item.id}`}
              {...(items.length - 1 === idx ? { ref: lastItemElement } : null)}
              xl={xl || 3}
              lg={lg || 4}
              md={md || 6}
              sm={sm || 6}
              xs={xs || 6}
              className="mt-0 mb-2"
            >
              <Component
                order={idx}
                {...item}
                {...(typeof componentToRender === "object"
                  ? componentToRender.props
                  : null)}
              />
            </Col>
          ))}
        {loading && (
          <Col xs={12} className="text-center">
            <Spinner animation="border" variant="primary" />
          </Col>
        )}
      </Row>
    </div>
  );
}

GridListMobile.propTypes = {
  noDataImage: PropTypes.string.isRequired,
  noDataText: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  items: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  componentToRender: PropTypes.oneOfType([
    PropTypes.elementType,
    PropTypes.object,
  ]).isRequired,
  listRef: PropTypes.object,
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
};

export default withContainer(React.memo(GridListMobile));

import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLanguage } from "hooks";
import { BreadCrumb, GridList, Layout, Title } from "components";
import { PackageCard } from "components/cards";
import withContainer from "./Container";

function PackageListPage({ loading, data, meta }) {
  const language = useLanguage();
  return (
    <Layout footerMargin>
      <div id="package-list-page">
        <BreadCrumb
          title={language.maintenancePackages}
          titleClassName="text-white fs-1 fw-light"
          path={[{ title: language.maintenancePackages }]}
          src="/images/packages_bg.png"
        />

        <Container className="mt-4">
          <Row>
            <Col xs={12}>
              <Title
                title={language.maintenancePackages}
                // description="Over 200,000 downloadable resources, with commercial license. Unlimited Downloads and more."
                color="black"
                lastColor="secondary"
              />
            </Col>
          </Row>

          <Row className="mt-5">
            <Col xs={12}>
              <GridList
                as={PackageCard}
                items={data && data.length > 0 ? data : []}
                meta={meta}
                loading={loading}
                noDataImage="/images/no-packages.png"
                noDataText={language.noPackages}
                skeletonHeight={480}
                xs={6}
                sm={6}
                md={4}
                lg={3}
                xl={3}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  );
}

export default withContainer(React.memo(PackageListPage));

import React from "react";
import { Col, Row } from "react-bootstrap";
import moment from "moment";
import classNames from "classnames";
import { Typography } from "components";
import { useLanguage } from "hooks";

function HistoryItem({ headline, calloutId, text, message, created_at }) {
  const language = useLanguage();

  return (
    <div
      className={classNames(
        "history-item bg-white px-4 py-2 border rounded-sm border-gray"
      )}
    >
      <Row className="align-items-center">
        <Col xs={8}>
          <Typography tag="h4" color="primary">
            {headline}
          </Typography>
        </Col>
        <Col xs={4}>
          <Typography
            tag="p"
            color="gray7"
            className="text-end"
            text={moment(created_at).format("YYYY/MM/DD")}
          />
        </Col>
      </Row>
      <Row>
        {text && (
          <Col xs={12}>
            {language.message}:{" "}
            <Typography tag="p" color="black">
              {text}
            </Typography>
          </Col>
        )}
        {message && (
          <Col xs={12}>
            {language.adminNotes}:
            <Typography tag="p" color="black">
              {message}
            </Typography>
          </Col>
        )}
      </Row>
    </div>
  );
}

export default React.memo(HistoryItem);

import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLanguage } from "hooks";
import { SkeletonGroup } from "components";
import { AdviceCard } from "components/cards";
import Title from "components/Title";

function Advices({ loading, advices }) {
  const language = useLanguage();

  return (
    <div id="packages">
      <Container>
        <Row>
          <Col xs={12}>
            <Title
              title={language.specialAdvicesforYourLovelyHome}
              // description="Over 200,000 downloadable resources, with commercial license. Unlimited Downloads and more."
              color="black"
              lastColor="secondary"
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col xs={12}>
            {!loading ? (
              <Row className="g-3">
                {advices.map((item, idx) => (
                  <Col key={idx} xs={12} sm={6} md={6} lg={6} xl={6}>
                    <AdviceCard {...item} />
                  </Col>
                ))}
              </Row>
            ) : (
              <SkeletonGroup
                rows={1}
                width={"100%"}
                xs={{
                  rows: 1,
                  count: 2,
                  height: 250,
                }}
                lg={{
                  count: 2,
                  rows: 2,
                  height: 320,
                }}
              />
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default React.memo(Advices);

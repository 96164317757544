import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import classNames from "classnames";
import { testStr } from "helpers";
import { useLanguage } from "hooks";
import Image from "components/Image";
import QRCode from "components/QRCode";
import Typography from "components/Typography";
import "./style.scss";

function SectionOne({ websiteSettings, socialMediaIcons, helpCenter }) {
  const language = useLanguage();

  return (
    <div id="header-section-one" className="bg-secondary d-none d-lg-block">
      <Container className="h-100">
        <Row className="align-items-center h-100">
          <Col lg={3} xl={4}>
            <ul className="m-0 d-flex flex-wrap">
              {socialMediaIcons &&
                socialMediaIcons.map((item, idx) => (
                  <li
                    key={item.id}
                    className={classNames("", {
                      "me-4": idx < socialMediaIcons.length - 1,
                    })}
                  >
                    <a href={item.link} target="_blank" rel="noreferrer">
                      <Image
                        src={item.icon}
                        className="social-icon"
                        alt={item.title}
                        fromApi
                      />
                    </a>
                  </li>
                ))}
            </ul>
          </Col>
          <Col lg={9} xl={8} className="float-end">
            <ul className="m-0 d-flex flex-wrap align-items-center float-end">
              {websiteSettings && websiteSettings.download_app_qr_link && (
                <li className="me-4">
                  <div className="d-inline me-2">
                    <QRCode
                      size={36}
                      value={websiteSettings.download_app_qr_link}
                      bgColor="transparent"
                      fgColor="#fff"
                    />
                  </div>
                  <Typography
                    tag="a"
                    target="_blank"
                    rel="noreferrer"
                    href={websiteSettings.download_app_qr_link}
                    variant="h6"
                    weight="normal"
                    color="white"
                    text={language.downloadApp}
                  />
                </li>
              )}
              {helpCenter &&
                helpCenter.map((item, idx) => (
                  <li
                    key={item.id}
                    className={classNames("", {
                      "me-4": idx < helpCenter.length - 1,
                    })}
                  >
                    <Image
                      src={item.icon}
                      className="social-icon me-2"
                      alt={item.name}
                      fromApi
                    />
                    {testStr(item.value, "link") && (
                      <Typography
                        tag="a"
                        target="_blank"
                        rel="noreferrer"
                        href={item.value}
                        variant="h6"
                        weight="normal"
                        color="white"
                        text={item.name}
                        {...(testStr(
                          item.name.replaceAll(" ", ""),
                          "phone"
                        ) && {
                          dir: "ltr",
                        })}
                      />
                    )}
                    {testStr(item.value, "email") && (
                      <Typography
                        tag="a"
                        href={`mailto:${item.value}`}
                        variant="h6"
                        weight="normal"
                        color="white"
                        text={item.name}
                      />
                    )}
                    {testStr(item.value, "phone") && (
                      <Typography
                        tag="a"
                        href={`tel:${item.value}`}
                        variant="h6"
                        weight="normal"
                        color="white"
                        text={item.name}
                        dir="ltr"
                      />
                    )}
                  </li>
                ))}
              {/* <li
                key={item.id}
                className={classNames("", {
                  "me-4": idx < socialMediaIcons.length - 1,
                })}
              >
                <a href={item.link} target="_blank" rel="noreferrer">
                  <Image
                    src={item.icon}
                    className="social-icon"
                    alt={item.title}
                    fromApi
                  />
                </a>
              </li> */}
              {/* <li className="me-4">
                <Icon name="whatsappOutline" className="text-white me-2" />
                <Typography
                  tag="a"
                  target="_blank"
                  rel="noreferrer"
                  href={`https://wa.me/${
                    websiteInformation && websiteInformation.phone
                  }`}
                  variant="h6"
                  weight="normal"
                  color="white"
                  text={websiteInformation && websiteInformation.phone}
                />
              </li>
              <li className="me-4">
                <Icon name="phoneOutline" className="text-white me-2" />
                <Typography
                  tag="a"
                  href={`tel://${
                    websiteInformation && websiteInformation.phone
                  }`}
                  variant="h6"
                  weight="normal"
                  color="white"
                  text={websiteInformation && websiteInformation.phone}
                />
              </li>
              <li className="">
                <Icon name="emailOutline" className="text-white me-2" />
                <Typography
                  tag="a"
                  href={`mailto://${
                    websiteInformation && websiteInformation.email
                  }`}
                  variant="h6"
                  weight="normal"
                  color="white"
                  text={websiteInformation && websiteInformation.email}
                />
              </li> */}
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default React.memo(SectionOne);

import React from "react";
import { useParams } from "react-router-dom";
import { API_ROUTES } from "constants";
import { useFetch } from "hooks";

function withContainer(WrappedCompoent) {
  return (props) => {
    const { id: parentId } = useParams();
    const [{ loading, data, meta }] = useFetch({
      endpoint: API_ROUTES.getCategories,
      params: `?parent_id=${parentId}&is_featured=0&in_top_bar=0&show_brands=0&show_subcategories=0&place_id=1`,
    });

    return <WrappedCompoent loading={loading} data={data} meta={meta} />;
  };
}
export default withContainer;

import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLanguage } from "hooks";
import { Skeleton, Slider } from "components";
import { ServiceCategoryCard } from "components/cards";
import Title from "components/Title";
import "./style.scss";

function OurServices({ loading, categories }) {
  const language = useLanguage();

  return (
    <div id="our-services-page">
      <Container>
        <Row>
          <Col xs={12}>
            <Title
              title={language.ourServices}
              // description="Over 200,000 downloadable resources, with commercial license. Unlimited Downloads and more."
              color="black"
              lastColor="secondary"
              link="/our-services"
            />
          </Col>
        </Row>
        {!loading ? (
          <React.Fragment>
            <Row className="d-none d-lg-block">
              <Col xs={12}>
                <div className="grid">
                  {!loading &&
                    categories &&
                    categories.map((item, idx) => (
                      <ServiceCategoryCard
                        key={item.id}
                        className="grid-item"
                        {...item}
                      />
                    ))}
                </div>
              </Col>
            </Row>
            <Row className="mt-4 d-block d-lg-none">
              <Col xs={12}>
                <Slider
                  dir={language.direction}
                  xs={{
                    slidesPerView: 3.5,
                    pagination: false,
                  }}
                  md={{
                    slidesPerView: 6.5,
                    pagination: false,
                  }}
                  items={categories}
                  itemAs={(props) => (
                    <ServiceCategoryCard
                      {...props}
                      className="constant-width"
                    />
                  )}
                />
              </Col>
            </Row>
          </React.Fragment>
        ) : (
          <Skeleton
            containerClassName="mt-4"
            xs={{
              height: 100,
            }}
            lg={{
              height: 400,
            }}
          />
        )}
      </Container>
    </div>
  );
}

export default React.memo(OurServices);

import React from "react";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import "./style.scss";

function TopProgressBar() {
  React.useEffect(() => {
    NProgress.configure({ showSpinner: false });
    NProgress.start();

    return () => {
      NProgress.done();
    };
  });

  return "";
}

export default React.memo(TopProgressBar);

import React from "react";
import { Col, Row } from "react-bootstrap";
import { Image, Typography } from "components";
import "./style.scss";

function NoData({ image, text }) {
  return (
    <div className="no-data">
      <Row className="align-items-center">
        <Col xs={12} className="text-center">
          <Image src={image} className="no-data-image" />
        </Col>
        <Col xs={12} className="text-center mt-4">
          <Typography
            tag="h1"
            variant="h3"
            weight="bolder"
            color="secondary"
            text={text}
          />
        </Col>
      </Row>
    </div>
  );
}

export default React.memo(NoData);

import React from "react";
import classNames from "classnames";
import { Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { useLanguage } from "hooks";
import { Typography } from "components";
import "./style.scss";

function SelectFieldMultiple({
  id,
  label,
  labelClassName,
  labelColor,
  inputRef,
  options,
  value,
  onChange,
  error,
  placeholder,
}) {
  const language = useLanguage();

  return (
    <Row>
      <Col xs={12}>
        <Typography
          htmlFor={id}
          tag="label"
          variant="body2"
          className={classNames("form-label text-capitalize", {
            [`${labelClassName}`]: labelClassName,
            [`text-${labelColor}`]: labelColor,
          })}
          text={label}
        />
      </Col>
      <Col xs={12}>
        <Select
          placeholder={placeholder}
          noOptionsMessage={() => language.noOptions}
          options={options}
          inputRef={inputRef}
          value={value}
          onChange={onChange}
          classNamePrefix="react-select"
          isMulti={true}
        />
      </Col>
      <Col xs={12}>
        {error && <Form.Text className="text-danger">{error}</Form.Text>}
      </Col>
    </Row>
  );
}

export default SelectFieldMultiple;

import Mobile from "./Mobile";
import EditProfilePage from "./EditProfile";
import Order from "./Order";
import Subscription from "./Subscription";
import Address from "./Address";
import MyPointsPage from "./Points";
import ChangePasswordPage from "./ChangePassword";
import NotificationsPage from "./Notifications";
import MyWalletPage from "./Wallet";

const Account = {
  Mobile: Mobile,
  EditProfile: EditProfilePage,
  Order: Order.Details,
  OrderList: Order.List,
  Subscription: Subscription,
  Address: Address,
  Points: MyPointsPage,
  Wallet: MyWalletPage,
  ChangePassword: ChangePasswordPage,
  Notifications: NotificationsPage,
};

export default Account;

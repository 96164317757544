import { navigate } from "helpers";
import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

function withContainer(Component) {
  return (props) => {
    const { pathname } = useLocation();
    const { keyword } = useParams();
    const [requestModalShown, setRequestModalShown] = React.useState(false);
    const [searchKey, setSearchKey] = React.useState(null);

    const handleOpenRequestModal = () => {
      setRequestModalShown(true);
    };

    const handleCloseRequestModal = () => {
      setRequestModalShown(false);
    };

    const handleChangeSearchKey = (val) => {
      setSearchKey(val);
    };

    const handleSearch = (e) => {
      e.preventDefault();
      if (searchKey === "") return;
      navigate(`/search/${searchKey}`);
    };

    useEffect(() => {
      if (!pathname.startsWith("/search")) {
        handleChangeSearchKey("");
      }
      return () => {};
    }, [pathname]);

    return (
      <Component
        {...props}
        requestModalShown={requestModalShown}
        handleOpenRequestModal={handleOpenRequestModal}
        handleCloseRequestModal={handleCloseRequestModal}
        handleChangeSearchKey={handleChangeSearchKey}
        searchKey={searchKey}
        keyword={keyword}
        handleSearch={handleSearch}
      />
    );
  };
}

export default withContainer;

import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import classNames from "classnames";
import { useLanguage } from "hooks";
import { Image, QRCode, Typography } from "components";
import "./style.scss";

const DetailsSection = ({
  websiteInformation,
  websiteSettings,
  socialMediaIcons,
  paymentMethods,
}) => {
  const language = useLanguage();

  return (
    <div className="footer-details-container py-5">
      <Container>
        <Row>
          <Col xs={5}>
            <Row className="justify-content-between h-100">
              <Col xs={12}>
                <img
                  src="/images/website-logo.svg"
                  className="logo"
                  alt="footer-logo"
                />
              </Col>
              <Col xs={7} className="mt-4">
                <div className="social-icons">
                  <ul className="list-unstyled d-flex justify-content-betweenx gap-3 align-items-center w-100 p-0 m-0">
                    {socialMediaIcons &&
                      socialMediaIcons.map((item, idx) => (
                        <li
                          key={item.id}
                          className={classNames("", {
                            "me-2": idx < socialMediaIcons.length - 1,
                          })}
                        >
                          <a href={item.link} target="_blank" rel="noreferrer">
                            <Image
                              src={item.icon}
                              alt={item.title}
                              fromApi
                              style={{
                                width: 24,
                                height: 24,
                              }}
                            />
                          </a>
                        </li>
                      ))}
                  </ul>
                </div>
              </Col>
              <Col xs={7} className="mt-4">
                <div className="payment-methods">
                  <ul className="list-unstyled d-flex align-items-center w-100 p-0 m-0">
                    {paymentMethods &&
                      paymentMethods.map((item, idx) => (
                        <li
                          key={item.id}
                          className={classNames("", {
                            "me-2": idx < paymentMethods.length - 1,
                          })}
                        >
                          <a href={item.link} target="_blank" rel="noreferrer">
                            <Image
                              src={item.image}
                              alt={item.name}
                              fromApi
                              style={{
                                width: 36,
                                height: 36,
                              }}
                            />
                          </a>
                        </li>
                      ))}
                  </ul>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={7}>
            <Row>
              <Col xs={4}>
                <Row>
                  <Col xs={12}>
                    <Typography
                      tag="h4"
                      variant="h6"
                      weight="bolder"
                      color="black"
                      className="title"
                    >
                      {language.quickLinks}
                    </Typography>
                  </Col>
                  <Col xs={12} className="mt-3">
                    <ul className="list-unstyled">
                      <li>
                        <Link to="/">
                          <Typography tag="span" variant="h6" color="black">
                            {language.home}
                          </Typography>
                        </Link>
                      </li>
                      <li className="mt-2">
                        <Link to="/our-services">
                          <Typography tag="span" variant="h6" color="black">
                            {language.services}
                          </Typography>
                        </Link>
                      </li>
                      <li className="mt-2">
                        <Link to="/articles">
                          <Typography tag="span" variant="h6" color="black">
                            {language.blogs}
                          </Typography>
                        </Link>
                      </li>
                      <li className="mt-2">
                        <Link to="/videos">
                          <Typography tag="span" variant="h6" color="black">
                            {language.videos}
                          </Typography>
                        </Link>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </Col>

              <Col xs={4}>
                <Row>
                  <Col xs={12}>
                    <Typography
                      tag="h4"
                      variant="h6"
                      weight="bolder"
                      color="black"
                      className="title"
                    >
                      {language.contactUs}
                    </Typography>
                  </Col>
                  <Col xs={12} className="mt-3">
                    <ul className="list-unstyled">
                      {websiteInformation &&
                        websiteInformation.company_phone && (
                          <li>
                            <Typography
                              tag="h6"
                              variant="h6"
                              weight="bolder"
                              color="black"
                            >
                              {language.phone}:{" "}
                              <a
                                href={`tel://${websiteInformation.company_phone}`}
                              >
                                <Typography
                                  tag="span"
                                  weight="normal"
                                  color="black"
                                >
                                  {websiteInformation.company_phone}
                                </Typography>
                              </a>
                            </Typography>
                          </li>
                        )}
                      {websiteInformation &&
                        websiteInformation.company_email && (
                          <li className="mt-1">
                            <Typography
                              tag="h6"
                              variant="h6"
                              weight="bolder"
                              color="black"
                            >
                              {language.email}:{" "}
                              <a
                                href={`mailto://${websiteInformation.company_email}`}
                              >
                                <Typography
                                  tag="span"
                                  weight="normal"
                                  color="black"
                                >
                                  {websiteInformation.company_email}
                                </Typography>
                              </a>
                            </Typography>
                          </li>
                        )}
                      {websiteInformation && websiteInformation.company_fax && (
                        <li className="mt-1">
                          <Typography
                            tag="h6"
                            variant="h6"
                            weight="bolder"
                            color="black"
                          >
                            {language.fax}:{" "}
                            <a href={`fax:${websiteInformation.company_fax}`}>
                              <Typography
                                tag="span"
                                weight="normal"
                                color="black"
                              >
                                {websiteInformation.company_fax}
                              </Typography>
                            </a>
                          </Typography>
                        </li>
                      )}
                      {websiteInformation &&
                        websiteInformation.company_address && (
                          <li className="mt-1">
                            <Typography
                              tag="h6"
                              variant="h6"
                              weight="bolder"
                              color="black"
                            >
                              {language.address}:{" "}
                              <Typography tag="p" weight="normal">
                                {websiteInformation.company_address}
                              </Typography>
                            </Typography>
                          </li>
                        )}
                    </ul>
                  </Col>
                </Row>
              </Col>

              <Col xs={4}>
                <Row>
                  <Col xs={12}>
                    <Typography
                      tag="h4"
                      variant="h6"
                      weight="bolder"
                      color="black"
                      className="title"
                    >
                      {language.downloadOurApp}
                    </Typography>
                  </Col>
                  {websiteSettings && websiteSettings.download_app_qr_link && (
                    <Col xs={12} className="text-center mt-3">
                      <QRCode
                        size={70}
                        value={websiteSettings.download_app_qr_link}
                      />
                    </Col>
                  )}
                  <Col xs={12} className="mt-4">
                    <Row>
                      {websiteSettings && websiteSettings.download_ios && (
                        <Col xs={12} className="text-center">
                          <a
                            href={websiteSettings.download_ios}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src="/images/Apple-Store.svg"
                              alt="Apple Store"
                              className="w-75"
                            />
                          </a>
                        </Col>
                      )}
                      {websiteSettings && websiteSettings.download_android && (
                        <Col xs={12} className="mt-2 text-center">
                          <a
                            href={websiteSettings.download_android}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src="/images/Google-Play.svg"
                              alt="google play"
                              className="w-75"
                            />
                          </a>
                        </Col>
                      )}
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default React.memo(DetailsSection);

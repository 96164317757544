import React from "react";
import * as ReactDOMClient from "react-dom/client";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { Wrapper as GoogleMapsWrapper } from "@googlemaps/react-wrapper";
import reportWebVitals from "./reportWebVitals";
import { GOOGLE_MAPS_API_KEY } from "constants";
import store, { persistor } from "store";
import App from "./App";
import { history } from "helpers";
import { AlertProvider } from "context";

const rootElement = document.getElementById("root");
const root = ReactDOMClient.createRoot(rootElement);
root.render(
  <HistoryRouter history={history}>
    <PersistGate loading={null} persistor={persistor}>
      <Provider store={store}>
        <GoogleMapsWrapper apiKey={GOOGLE_MAPS_API_KEY} libraries={["places"]}>
          <AlertProvider>
            <App />
          </AlertProvider>
        </GoogleMapsWrapper>
      </Provider>
    </PersistGate>
  </HistoryRouter>
);

reportWebVitals();

import { API_ROUTES } from "constants";
import { useAlert } from "context";
import { navigate } from "helpers";
import { useFetch, useLanguage } from "hooks";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkLogin } from "store/auth";
import { clearExtraFields, clearSubscriptionId, getCartIds } from "store/cart";
import { clearGuestInfo } from "store/cart";

function withContainer(WrappedCompoent) {
  return (props) => {
    const dispatch = useDispatch();
    const language = useLanguage();
    const [_, dispatchAlert] = useAlert();
    const { placeId } = useSelector((state) => state.app);
    const { isLoggedIn, user } = useSelector((state) => state.auth);
    const [defaultId, setDefaultId] = React.useState(null);
    const [useMyBalance, setUseMyBalance] = React.useState(false);
    const { guestInfo, extraFields, subscriptionId } = useSelector(
      (state) => state.cart
    );
    const { paymentMethods, hearAboutUs } = useSelector(
      (state) => state.common
    );
    const [paymentMethod, setPaymentMethod] = React.useState(
      paymentMethods[0].id
    );
    const [coupon, setCoupon] = React.useState("");
    const [hearAboutUsValue, setHearAboutUsValue] = React.useState("");

    const [{ data, loading }] = useFetch({
      endpoint: API_ROUTES.getCart,
      initialLoadingState: true,
    });

    const [
      { data: locationsData, loading: locationsLoading },
      refetchLocations,
    ] = useFetch({
      endpoint: API_ROUTES.getUserLocations,
      initialLoadingState: true,
    });

    useFetch(
      {
        method: "POST",
        endpoint: API_ROUTES.markUserLocationAsDefault,
        initialLoadingState: true,
        body: {
          user_location_id: defaultId,
        },
        onSuccess: (response) => {
          refetchLocations();
          dispatchAlert({
            type: "OPEN",
            payload: {
              icon: "success",
              title: language.success,
              text: language.locationMadeDefaultSuccess,
              canDismiss: true,
            },
          });
        },
      },
      [defaultId],
      false
    );

    const [{ data: amountsData, loading: amountsLoading }] = useFetch(
      {
        method: "POST",
        endpoint:
          props.type === "subscription"
            ? API_ROUTES.getSubscriptionCheckoutAmounts
            : API_ROUTES.getCheckoutAmounts,
        initialLoadingState: true,
        body: {
          ...(subscriptionId && { subscription_id: subscriptionId }),
          payment_id: paymentMethod,
          delivery_company_id: "",
          place_id: placeId,
          coupons: [coupon],
          fields: extraFields,
          use_my_balance: useMyBalance === true ? 1 : 0,
        },
        onSuccess: (response) => {
          if (response.data.coupons.length === 0 && coupon !== "") {
            dispatchAlert({
              type: "OPEN",
              payload: {
                icon: "attention",
                title: language.error,
                text: language.couponIsNotValid,
                canDismiss: true,
              },
            });
          }
        },
      },
      [coupon, paymentMethod, placeId, extraFields, useMyBalance]
    );

    useEffect(() => {
      //console log extraFields
      console.log("extraFields",extraFields);
    }, [extraFields]);

    const [
      { data: subscriptionDetailsData, loading: subscriptionDetailsLoading },
    ] = useFetch(
      {
        endpoint: API_ROUTES.getSubscriptionDetails,
        initialLoadingState: true,
        params: `?subscription_id=${subscriptionId}`,
      },
      [],
      props.type === "subscription"
    );

    const [{ loading: createOrderLoading }, createOrderRequest] = useFetch(
      {
        method: "POST",
        endpoint: API_ROUTES.createOrder,
        body: {
          use_my_balance: useMyBalance === true ? 1 : 0,
          payment_method_id: paymentMethod,
          coupons: [coupon],
          how_hear_about_us: hearAboutUsValue,
          // user_location_id: 106,
          ...(!isLoggedIn &&
            guestInfo && {
              guest_details: {
                place_id: guestInfo.place_id,
                person_name: `${guestInfo.firstName} ${guestInfo.lastName}`,
                latitude: guestInfo.coords.lat,
                longitude: guestInfo.coords.lng,
                building: guestInfo.building,
                floor: guestInfo.floor,
                appartment: guestInfo.appartment,
                phone: guestInfo.phone,
                email: guestInfo.email,
              },
            }),
        },
        onSuccess: (response) => {
          Promise.all([dispatch(clearGuestInfo())]).then(() => {
            if (!isLoggedIn) {
              return dispatchAlert({
                type: "OPEN",
                payload: {
                  variant: "fullscreen",
                  icon: "success",
                  title: language.orderSuccess,
                  text: language.orderCreaetdSuccessPleaseCheckEmailForDetails,
                  canDismiss: false,
                  buttons: [
                    {
                      variant: "primary",
                      text: language.continueShopping,
                      onClick: () => {
                        navigate("/");
                        dispatch(getCartIds());
                      },
                    },
                  ],
                },
              });
            }
            dispatch(checkLogin());
            dispatchAlert({
              type: "OPEN",
              payload: {
                variant: "fullscreen",
                icon: "success",
                title: language.orderSuccess,
                text: language.orderCreaetdSuccess,
                canDismiss: false,
                buttons: [
                  {
                    variant: "primary",
                    text: language.myOrders,
                    onClick: () => {
                      // navigate(
                      //   `/account/order/${response.data.order_reference}`
                      // );
                      // navigate to my orders page
                      navigate("/account/orders");
                      dispatch(getCartIds());
                    },
                  },
                  {
                    variant: "outline-primary",
                    text: language.continueShopping,
                    onClick: () => {
                      navigate("/");
                      dispatch(getCartIds());
                    },
                  },
                ],
              },
            });
          });
        },
      },
      [],
      false
    );

    const [{ loading: createSubscriptionLoading }, createSubscriptionRequest] =
      useFetch(
        {
          method: "POST",
          endpoint: API_ROUTES.createSubscriptionOrder,
          body: {
            use_my_balance: useMyBalance === true ? 1 : 0,
            payment_method_id: paymentMethod,
            subscription_id: subscriptionId,
            // user_location_id: 106,
            coupons: [coupon],
            how_hear_about_us: hearAboutUsValue,
            ...(!isLoggedIn &&
              guestInfo && {
                guest_details: {
                  place_id: guestInfo.place_id,
                  person_name: `${guestInfo.firstName} ${guestInfo.lastName}`,
                  latitude: guestInfo.coords.lat,
                  longitude: guestInfo.coords.lng,
                  building: guestInfo.building,
                  floor: guestInfo.floor,
                  appartment: guestInfo.appartment,
                  phone: guestInfo.phone,
                  email: guestInfo.email,
                },
              }),

            ...(extraFields && {
              fields: extraFields,
            }),
          },
          onSuccess: (response) => {
            Promise.all([
              dispatch(clearGuestInfo()),
              dispatch(clearSubscriptionId()),
              dispatch(clearExtraFields()),
            ]).then(() => {
              if (!isLoggedIn) {
                return dispatchAlert({
                  type: "OPEN",
                  payload: {
                    variant: "fullscreen",
                    icon: "success",
                    title: language.orderSuccess,
                    text: language.orderCreaetdSuccessPleaseCheckEmailForDetails,
                    canDismiss: false,
                    buttons: [
                      {
                        variant: "primary",
                        text: language.continueShopping,
                        onClick: () => {
                          navigate("/");
                        },
                      },
                    ],
                  },
                });
              }
              dispatchAlert({
                type: "OPEN",
                payload: {
                  variant: "fullscreen",
                  icon: "success",
                  title: language.orderSuccess,
                  text: language.orderCreaetdSuccess,
                  canDismiss: false,
                  buttons: [
                    {
                      variant: "primary",
                      text: language.subscriptionDetails,
                      onClick: () => {
                        navigate(
                          `/account/subscription/${response.data.order_reference}`
                        );
                      },
                    },
                    {
                      variant: "outline-primary",
                      text: language.continueShopping,
                      onClick: () => {
                        navigate("/");
                      },
                    },
                  ],
                },
              });
            });
          },
        },
        [],
        false
      );

    const handleApplyCoupon = (coupon) => {
      setCoupon(coupon);
    };

    const handleRemoveCoupon = () => {
      setCoupon("");
    };

    const handleChoosePaymentMethod = (id) => {
      setPaymentMethod(id);
    };

    const handleChooseHearAboutUsValue = (val) => {
      setHearAboutUsValue(val);
    };

    const handleCreateOrder = () => {
      if (props.type === "subscription") {
        return createSubscriptionRequest();
      }
      createOrderRequest();
    };

    return (
      <WrappedCompoent
        type={props.type}
        data={data}
        loading={loading || locationsLoading}
        user={user}
        createOrderLoading={createOrderLoading}
        createSubscriptionLoading={createSubscriptionLoading}
        amountsData={amountsData}
        amountsLoading={amountsLoading}
        subscriptionDetailsData={subscriptionDetailsData}
        subscriptionDetailsLoading={subscriptionDetailsLoading}
        locationsData={locationsData}
        isLoggedIn={isLoggedIn}
        guestInfo={guestInfo}
        chosenPaymentMethod={paymentMethod}
        paymentMethods={paymentMethods}
        handleChoosePaymentMethod={handleChoosePaymentMethod}
        handleApplyCoupon={handleApplyCoupon}
        handleRemoveCoupon={handleRemoveCoupon}
        handleCreateOrder={handleCreateOrder}
        handleChooseHearAboutUsValue={handleChooseHearAboutUsValue}
        handleMakeAddressDefault={(id) => setDefaultId(id)}
        hearAboutUsList={hearAboutUs}
        useMyBalance={useMyBalance}
        setUseMyBalance={setUseMyBalance}
        extraFieldsData={extraFields}
      />
    );
  };
}
export default withContainer;

import React from "react";
import { API_ROUTES } from "constants";
import { useFetch } from "hooks";
import { useParams } from "react-router-dom";

function withContainer(WrappedCompoent) {
  return (props) => {
    const { orderReference } = useParams();
    const [{ loading, data }] = useFetch({
      endpoint: API_ROUTES.getSubscriptionOrderHistory,
      params: `?order_reference=${orderReference}`,
      initialLoadingState: true,
    });

    return <WrappedCompoent loading={loading} data={data} />;
  };
}
export default withContainer;

import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row, Form, Spinner } from "react-bootstrap";
import { useLanguage } from "hooks";
import { Icon, InputField, Layout, Typography, Button } from "components";
import MultiLevelSelectInput from "components/MultiLevelSelectInput";
import withContainer from "./Container";
import "./style.scss";

const Register = ({ form, loading, errors, setForm, handleRegister }) => {
  const langunge = useLanguage();

  return (
    <Layout footerMargin>
      <section id="Register__section">
        <Container className="py-5">
          <Row className="justify-content-end">
            <Col xs={12} lg={6}>
              <div className="px-4 px-md-5 px-lg-4 ">
                {/* Login header */}
                <Row>
                  <Col xs={12}>
                    <Typography
                      tag="h1"
                      variant="h2"
                      color="black"
                      text={langunge.register}
                    />
                  </Col>
                </Row>

                {/* form */}
                <Row className="mt-3">
                  <Col xs={12}>
                    <Form onSubmit={handleRegister}>
                      {/* full name */}
                      <Row>
                        <Col xs={12} md={6}>
                          <InputField
                            type="text"
                            label={langunge.firstName}
                            labelClassName="fs-6"
                            placeholder={langunge.firstName}
                            onChange={(e) =>
                              setForm("firstName", e.target.value)
                            }
                            value={form.firstName}
                            error={errors && errors.name}
                            className="py-3"
                          />
                        </Col>
                        <Col xs={12} md={6} className="mt-3 mt-md-0">
                          <InputField
                            type="text"
                            label={langunge.lastName}
                            labelClassName="fs-6"
                            placeholder={langunge.lastName}
                            onChange={(e) =>
                              setForm("lastName", e.target.value)
                            }
                            value={form.lastName}
                            error={errors && errors.name}
                            className="py-3"
                          />
                        </Col>
                      </Row>

                      <Row className="mt-3">
                        <Col xs={12} md={6}>
                          <InputField
                            type="tel"
                            label={langunge.phoneNumber}
                            labelClassName="fs-6"
                            placeholder={langunge.phoneNumber}
                            onChange={(e) => setForm("phone", e.target.value)}
                            value={form.phone}
                            error={errors && errors.phone}
                            className="py-3"
                          />
                        </Col>
                        <Col xs={12} md={6} className="mt-3 mt-md-0">
                          <InputField
                            type="email"
                            label={langunge.emailAddress}
                            labelClassName="fs-6"
                            placeholder={langunge.emailAddress}
                            onChange={(e) => setForm("email", e.target.value)}
                            value={form.email}
                            error={errors && errors.email}
                            className="py-3"
                          />
                        </Col>
                      </Row>

                      <Row className="mt-3">
                        <Col xs={12} md={12}>
                          <MultiLevelSelectInput
                            label={langunge.area}
                            labelClassName="fs-6"
                            placeholder={langunge.area}
                            onSelected={(e) =>
                              setForm("placeId", e.target.value)
                            }
                            error={errors && errors.place_id}
                          />
                        </Col>
                      </Row>

                      {/* Password */}
                      <Row className="mt-3">
                        <Col xs={12} md={6}>
                          <InputField
                            type="password"
                            label={langunge.password}
                            labelClassName="fs-6"
                            placeholder={langunge.password}
                            onChange={(e) =>
                              setForm("password", e.target.value)
                            }
                            value={form.password}
                            error={errors && errors.password}
                            className="py-3"
                            secure
                          />
                        </Col>
                        <Col xs={12} md={6} className="mt-3 mt-md-0">
                          <InputField
                            type="password"
                            label={langunge.confirmPassword}
                            labelClassName="fs-6"
                            placeholder={langunge.confirmPassword}
                            onChange={(e) =>
                              setForm("password_confirmation", e.target.value)
                            }
                            value={form.password_confirmation}
                            error={errors && errors.password}
                            className="py-3"
                            secure
                          />
                        </Col>
                      </Row>

                      {/* Form check */}
                      <Row className="mt-3">
                        <Col xs={12}>
                          <Form.Check
                            id="termsAndConditions"
                            type="checkbox"
                            label={
                              <Typography
                                tag="span"
                                variant="body1"
                                color="gray7"
                                text={langunge.byContinuingYouAgreeToOur}
                              />
                            }
                            className="d-inline-block me-1"
                          />
                          <Link
                            className="text-blue"
                            to="/page/termsAndConditions"
                          >
                            {langunge.termsAndConditions}
                          </Link>
                          {errors && errors.checkbox && (
                            <Form.Text className="d-block text-danger">
                              {errors.checkbox}
                            </Form.Text>
                          )}
                        </Col>
                      </Row>

                      {/* Login Button */}
                      <Row className="mt-3">
                        <Col
                          className="py-3"
                          xs={12}
                          sm={12}
                          md={12}
                          lg={5}
                          xl={4}
                        >
                          <Button
                            type="submit"
                            variant="primary"
                            textVariant="buttonText3"
                            className="py-3 w-100"
                            text={
                              loading ? (
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                              ) : (
                                langunge.createAccount
                              )
                            }
                          />
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>

                {/* Under Form */}
                {/* register Button */}
                <Row className="">
                  <Col sm={12} md={10} lg={11} xl={12}>
                    <Typography
                      tag="p"
                      variant="body1"
                      color="gray7"
                      text={
                        <React.Fragment>
                          {langunge.haveAnAccount}{" "}
                          <Link className="text-blue" to="/login">
                            {langunge.login}
                          </Link>
                        </React.Fragment>
                      }
                    />
                  </Col>
                </Row>

                {/* or Login With text */}
                <Row className="mt-5">
                  <Col sm={12} md={10} lg={11} xl={12}>
                    <Typography
                      tag="p"
                      variant="subtitle1"
                      color="gray7"
                      text={langunge.orLoginWith}
                    />
                  </Col>
                </Row>

                {/* facebook & google */}
                <Row className="mt-3">
                  <Col xs={6} lg={5} xl={4}>
                    <Button
                      variant="facebook"
                      className="w-100 py-3 h-100"
                      text={<Icon name="facebook" className="text-white" />}
                    />
                  </Col>
                  <Col xs={6} lg={5} xl={4}>
                    <Button
                      variant="google"
                      className="w-100 py-3 h-100"
                      text={<Icon name="google" className="text-white" />}
                    />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default withContainer(React.memo(Register));

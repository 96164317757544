import { Layout, BreadCrumb, Heading, GridList } from "components";
import { OfferCard } from "components/cards";
import { Col, Container, Row } from "react-bootstrap";

const LatestOffers = () => {
  return (
    <Layout mainClassName="section-mb">
      {/* <div id="AboutPage" className="page_container">
        <section className="mb-5">
          <BreadCrumb
            title="Latest Offers"
            path={[{ title: "Latest Offers" }]}
            src="latest-offers.png"
            titleClassName="fs-1 fw-light"
          />
        </section>
        <section>
          <Container>
            <Row className="mb-4">
              <Col xs={12}>
                <Heading title="Latest Offers" />
              </Col>
            </Row>

            <GridList
              as={OfferCard}
              items={data && data.length > 0 ? data : []}
              loading={loading}
              noDataImage="/images/no-categories.png"
              noDataText={language.noOffers}
              xs={6}
              sm={6}
              md={6}
              lg={3}
              xl={3}
            />
          </Container>
        </section>
      </div> */}
    </Layout>
  );
};

export default LatestOffers;

import React from "react";
import classNames from "classnames";
import { Col, Form, Row } from "react-bootstrap";
import { Typography } from "components";

function RadioSelectField({
  id,
  label,
  labelClassName,
  labelColor,
  options,
  error,
  onChange,
}) {
  return (
    <Row>
      <Col xs={12}>
        <Typography
          htmlFor={id}
          tag="label"
          variant="body2"
          className={classNames("form-label text-capitalize", {
            [`${labelClassName}`]: labelClassName,
            [`text-${labelColor}`]: labelColor,
          })}
          text={label}
        />
      </Col>
      <Col xs={12}>
        {options.map((item, idx) => (
          <Form.Check
            key={idx}
            id={`inline-radio-${id}-${idx}`}
            name={`inline-radio-${id}`}
            type="radio"
            label={item.value}
            onChange={() => onChange(item.id)}
            inline
          />
        ))}
      </Col>
      <Col xs={12}>
        {error && <Form.Text className="text-danger">{error}</Form.Text>}
      </Col>
    </Row>
  );
}

export default RadioSelectField;

import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import classNames from "classnames";
import { Icon } from "components";
import "./index.scss";

function ProfileLinks({ links }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleLinkClicked = ({ path, func }) => {
    if (func) return func();
    navigate(path);
  };

  return (
    <div id="ProfileLinks">
      <div className="items">
        <Row>
          <Col xs={12}>
            {links.map((item, idx) => (
              <div
                key={idx}
                role="button"
                className={classNames("item user-select-none px-4 py-2", {
                  active: item.path === pathname,
                  "border-bottom border-gray2": idx !== links.length - 1,
                })}
                onClick={() => handleLinkClicked(item)}
              >
                <Row className="align-items-center">
                  <Col xs={8}>
                    <p className="title fs-6 fw-normal">{item.title}</p>
                  </Col>
                  <Col xs={4} className="text-end">
                    {item.renderRightContent ? (
                      item.renderRightContent()
                    ) : (
                      <Icon name="arrowNext" className="flip-x" />
                    )}
                  </Col>
                </Row>
              </div>
            ))}
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default React.memo(ProfileLinks);

import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Col, NavItem, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { useLanguage } from "hooks";
import { Icon, Typography } from "components";
import "./style.scss";

function ProfileTabs({ showName, links }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { user } = useSelector((state) => state.auth);
  const language = useLanguage();

  const handleLinkClicked = ({ path, func }) => {
    if (func) return func();
    navigate(path);
  };

  return (
    <div id="ProfileTabs">
      <div className="tabs-container bg-gray3 py-3 rounded-xs border border-gray">
        {showName && (
          <div className="user-info px-4">
            <Row className="align-items-center">
              <Col xs={12}>
                <Row>
                  <Col xs={12}>
                    <Typography
                      tag="h6"
                      variant="h6"
                      text={user.name}
                      color="black"
                    />
                  </Col>
                  <Col xs={12}>
                    <Typography
                      tag="h6"
                      variant="body2"
                      text={user.phone}
                      color="gray7"
                      ltr
                    />
                  </Col>
                  <Col xs={12}>
                    <div className="d-inline-block border border-secondary bg-white px-2 py-1 rounded-xs">
                      <Typography
                        tag="small"
                        variant="caption"
                        text={`${language.yourWallet}: ${user.wallet_balance} ${language.website_currency}`}
                        color="black"
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        )}
        <div className="profile_nav mt-4">
          <Row>
            <Col xs={12}>
              {links.map((item, idx) => (
                <div
                  key={idx}
                  className={classNames(
                    "profile_nav-item d-flex align-items-center justify-content-between px-4 py-2",
                    {
                      active: item.path === pathname,
                      "border-bottom border-light1": idx !== links.length - 1,
                    }
                  )}
                >
                  <NavItem
                    role="button"
                    to={item.path}
                    className="d-flex align-items-center"
                    onClick={() => handleLinkClicked(item)}
                  >
                    <Icon name={item.icon} className="me-3" />
                    <Typography tag="span" variant="h6" text={item.title} />
                  </NavItem>
                  {item.renderRightContent && item.renderRightContent()}
                </div>
              ))}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default React.memo(ProfileTabs);

import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import classNames from "classnames";
import { useAlert } from "context";
import { navigate } from "helpers";
import { useLanguage } from "hooks";
import {
  Heading,
  Layout,
  Button,
  Typography,
  NoData,
  Skeleton,
  SkeletonGroup,
} from "components";
import CartCard from "./components/CartCard";
import withContainer from "./Container";

function Cart({
  isLoggedIn,
  loading,
  data,
  amountsLoading,
  amountsData,
  handleIncrementDecrementCart,
  handleRemoveProductFromCart,
}) {
  const language = useLanguage();
  const [_, dispatchAlert] = useAlert();

  const handleGoToCheckout = () => {
    if (!isLoggedIn) {
      return dispatchAlert({
        type: "OPEN",
        payload: {
          icon: "attention",
          title: language.attention,
          text: language.doYouWantToContinueAsGuest,
          canDismiss: true,
          buttons: [
            {
              variant: "secondary",
              text: language.continueAsGuest,
              onClick: () => navigate("/pre-checkout"),
            },
            {
              variant: "primary",
              text: language.login,
              onClick: () => navigate("/login"),
            },
          ],
        },
      });
    }
    navigate("/checkout");
  };

  return (
    <Layout footerMargin>
      <div id="cart-page" className="page_container">
        <section id="cart" className="mt-4">
          <Container>
            <Row className="mb-4">
              <Col xs={12}>
                <Heading title={language.myCart} />
              </Col>
            </Row>
            {!loading && data && data.length > 0 && (
              <Row>
                <Col xs={12} lg={9} className="order-2 order-lg-1 mt-4 mt-lg-0">
                  {data.map((item, idx) => (
                    <div
                      key={item.id}
                      className={classNames({
                        "mt-4": idx !== 0,
                      })}
                    >
                      <CartCard
                        key={item.id}
                        {...item}
                        {...item.product}
                        extra_fields={item.extra_fields}
                        product_option={item.product_option}
                        maxQty={item.product.qty}
                        qty={item.qty}
                        timeslot_date={item.timeslot_date}
                        timeslot_time={
                          item.timeslot && `${item.timeslot.from_time}`
                        }
                        need_time_slots={item.product.need_time_slots}
                        need_qty={item.product.need_qty}
                        handleIncrementDecrementCart={
                          handleIncrementDecrementCart
                        }
                        handleRemoveProductFromCart={
                          handleRemoveProductFromCart
                        }
                      />
                    </div>
                  ))}
                </Col>
                <Col xs={12} lg={3} className="order-1 order-lg-2">
                  <div className="bg-gray3 rounded-xs border border-gray">
                    <Row className="p-4 p-lg-3 ">
                      <Col xs={12} className="mb-5">
                        <Typography className="text-secondary text-center fw-border">
                          {language.cartSummary}
                        </Typography>
                      </Col>
                      <Col xs={12}>
                        <Row>
                          {data.map((item, idx) => (
                            <Col
                              key={item.id}
                              xs={12}
                              className={classNames(
                                "border-bottom border-gray5 ",
                                {
                                  "mt-2": idx !== 0,
                                }
                              )}
                            >
                              <div className="pb-2">
                                <Row>
                                  <Col xs={10}>
                                    <Typography>
                                      {item.product.title}
                                    </Typography>
                                  </Col>
                                  <Col xs={2}>
                                    <Typography>x{item.qty}</Typography>
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                          ))}
                        </Row>
                      </Col>
                      {amountsData && (
                        <Col xs={12} className="mt-3">
                          <Row>
                            <Col xs={6}>
                              <Typography className="text-secondary">
                                {language.total}
                              </Typography>
                            </Col>
                            <Col xs={6}>
                              <Typography className="text-secondary text-end fw-bold">
                                {/* {data.reduce(
                                (acc, obj) =>
                                  acc +
                                  obj.product_option.price_after_discount *
                                    obj.qty,
                                0
                              )}{" "} */}
                                {amountsData.total_amount}
                                {language.website_currency}
                              </Typography>
                            </Col>
                          </Row>
                        </Col>
                      )}
                      <Col lg={12} xs={6} className="text-center mt-4">
                        <Button
                          variant="primary"
                          textVariant="buttonText3"
                          onClick={handleGoToCheckout}
                          className="w-100"
                          text={language.goToCheckout}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            )}
            {!loading && data && data.length === 0 && (
              <NoData text={language.cartIsEmpty} image="/images/no-cart.png" />
            )}
            {loading && (
              <Row>
                <Col xs={12} lg={9}>
                  <SkeletonGroup count={1} rows={4} height={200} />
                </Col>
                <Col xs={12} lg={3}>
                  <Skeleton height={400} />
                </Col>
              </Row>
            )}
          </Container>
        </section>
      </div>
    </Layout>
  );
}

export default withContainer(React.memo(Cart));

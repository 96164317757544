import React from "react";
import variants from "./Variants";
import withContainer from "./Container";

function Prompt({ variant, ...props }) {
  const Component = variants[variant] || variants["standard"];

  return <Component {...props} />;
}

export default withContainer(React.memo(Prompt));

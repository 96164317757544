import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { API_ROUTES, phoneNumberRegexp } from "constants";
import { useAlert } from "context";
import { useFetch, useLanguage } from "hooks";
import { useSelector } from "react-redux";
import { useEffect } from "react";

function withContainer(WrappedCompoent) {
  return (props) => {
    const { orderReference } = useParams();
    const navigate = useNavigate();
    const language = useLanguage();
    const { user } = useSelector((state) => state.auth);
    const [_, dispatchAlert] = useAlert();
    const schema = yup.object().shape(
      {
        name: yup.string().required(language.nameRequired),
        phone: yup
          .string()
          .required(language.phoneNumberRequired)
          .matches(phoneNumberRegexp, language.phoneNumberInvalid),
        email: yup
          .string()
          .email(language.emailInvalid)
          .required(language.emailRequired),
        description: yup
          .string()
          .required(language.descriptionRequired)
          .min(10, language.descriptionNotLessThan10Chars),
        type: yup.string().required(language.typeRequired),
      },
      []
    );
    const {
      register: registerField,
      control,
      handleSubmit,
      formState: { errors },
      watch,
      reset,
      setValue,
    } = useForm({
      resolver: yupResolver(schema),
    });
    const values = watch();

    // API_ROUTES.getCalloutsTypes

    const [
      { loading: fetchSubscriptionDetailsLoading, data: subscriptionDetails },
      fetchSubscriptionDetails,
    ] = useFetch({
      method: "GET",
      endpoint: API_ROUTES.getSubscriptionOrderDetails,
      params: `?order_reference=${orderReference}`,
      initialLoadingState: true,
    });

    const [{ loading }, request] = useFetch(
      {
        method: "POST",
        endpoint: API_ROUTES.sendCallout,
        body: {
          name: values.name,
          phone: values.phone,
          email: values.email,
          message: values.description,
          type: values.type,
          subscription_order_id: subscriptionDetails?.order_information?.id,
          files: [values?.file],
        },
        onSuccess: () => {
          reset();
          navigate("/account/subscriptions");
          dispatchAlert({
            type: "OPEN",
            payload: {
              icon: "success",
              title: language.success,
              text: language.calloutSendSuccess,
              canDismiss: true,
            },
          });
        },
      },
      [],
      false
    );

    const handleSubmitData = () => {
      request();
    };

    useEffect(() => {
      reset({
        name: user.name,
        email: user.email,
        phone: user.phone,
      });
    }, [user, reset]);

    return (
      <WrappedCompoent
        loading={loading}
        registerField={registerField}
        control={control}
        values={values}
        errors={errors}
        subscriptionDetails={subscriptionDetails}
        fetchSubscriptionDetailsLoading={fetchSubscriptionDetailsLoading}
        setValue={setValue}
        handleSubmit={handleSubmit(handleSubmitData)}
      />
    );
  };
}
export default withContainer;

import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLanguage } from "hooks";
import { GridList, Heading, Layout } from "components";
import { ServiceCard } from "components/cards";
import withContainer from "./Container";

function Wishlist({ loading, data, meta }) {
  const language = useLanguage();

  return (
    <Layout footerMargin>
      <div id="MyFavouritePage" className="page-container mt-4">
        <Container>
          <Row>
            <Col xs={12}>
              <Heading title={language.myFavourites} tag="h2" />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col xs={12}>
              <GridList
                as={ServiceCard}
                items={data && data.length > 0 ? data : []}
                meta={meta}
                loading={loading}
                noDataImage="/images/no-favourites.png"
                noDataText={language.favouriteIsEmpty}
                skeletonHeight={440}
                xs={6}
                sm={6}
                md={4}
                lg={3}
                xl={3}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  );
}
export default withContainer(React.memo(Wishlist));

import React from "react";
import debounce from "lodash.debounce";
import { API_ROUTES } from "constants";
import {
  useFetch,
  useFilter,
  useLanguage,
  usePagination,
  useSkipFirstRender,
} from "hooks";
import { useEffect } from "react";

function withContainer(WrappedCompoent) {
  return (props) => {
    const translation = useLanguage();
    const { page, handleSetPage } = usePagination();
    const [requestModalShown, setRequestModalShown] = React.useState(false);
    const [subscriptionOrderId, setSubscriptionOrderId] = React.useState(null);
    const { applyFilters, selectedFilters, toggleFilter, resetFilters } =
      useFilter({
        status: "",
        from_date: "",
        to_date: "",
        search_key: "",
      });
    const [{ loading, data, meta }, request] = useFetch(
      {
        initialLoadingState: true,
        method: "POST",
        endpoint: API_ROUTES.getSubscriptionsOrders,
        params: `?page=${page}`,
        body: {
          status: selectedFilters["status"],
          from_date: selectedFilters["from_date"],
          to_date: selectedFilters["to_date"],
          search_key: selectedFilters["search_key"],
        },
      },
      [page],
      false
    );

    const debouncedSearchKeyChange = React.useCallback(
      debounce((val) => toggleFilter("search_key", val, false), 2000),
      []
    );

    useSkipFirstRender(() => {
      handleSetPage(1);
    }, [selectedFilters]);

    useEffect(() => {
      request();
    }, [selectedFilters]);

    const handleResetFilters = () => {
      handleSetPage(1);
      resetFilters();
    };

    const handleOpenRequestModal = (id) => {
      console.log(id);
      setSubscriptionOrderId(id);
      setRequestModalShown(true);
    };

    const handleCloseRequestModal = () => {
      setSubscriptionOrderId(null);
      setRequestModalShown(false);
    };

    return (
      <WrappedCompoent
        loading={loading}
        debouncedSearchKeyChange={debouncedSearchKeyChange}
        refetch={request}
        toggleFilter={toggleFilter}
        handleResetFilters={handleResetFilters}
        handleOpenRequestModal={handleOpenRequestModal}
        handleCloseRequestModal={handleCloseRequestModal}
        requestModalShown={requestModalShown}
        subscriptionOrderId={subscriptionOrderId}
        data={data}
        meta={meta}
      />
    );
  };
}
export default withContainer;

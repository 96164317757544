import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { useLanguage } from "hooks";
import { Typography } from "components";
import "./style.scss";

function Title({
  title = "",
  tag = "h2",
  description = "",
  color,
  lastColor,
  link,
  ...props
}) {
  const language = useLanguage();
  return (
    <div className="title-component">
      <Row className="align-items-center">
        <Col xs={link ? 8 : 12} lg={12}>
          <Typography
            tag="h2"
            variant="h1"
            weight="bolder"
            color={color}
            className={classNames("title text-capitalize", {
              "text-center": !link,
            })}
          >
            {title.split(" ").length > 1 ? (
              <React.Fragment>
                {title.split(" ").map((item, idx) => (
                  <React.Fragment key={idx}>
                    <Typography
                      tag="span"
                      variant="h1"
                      {...(idx === title.split(" ").length - 1 && {
                        color: lastColor,
                      })}
                    >
                      {item}{" "}
                    </Typography>
                  </React.Fragment>
                ))}
              </React.Fragment>
            ) : (
              title
            )}
          </Typography>
        </Col>
        {link && (
          <Col xs={4} className="d-lg-none text-end">
            <Link to={link}>
              <Typography tag="span" color="primary" className="me-2">
                {language.viewAll}
              </Typography>
            </Link>
          </Col>
        )}
        <Col xs={12}>
          <Typography
            tag="p"
            variant="body2"
            weight="normal"
            color="black"
            className="description d-none d-lg-block text-capitalize"
          >
            {description}
          </Typography>
        </Col>
      </Row>
    </div>
  );
}

export default React.memo(Title);

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import LoadingSkeleton from "react-loading-skeleton";
import { useLanguage } from "hooks";

function BaseSkeleton({ className, containerClassName, ...props }) {
  return (
    <div
      className={classNames({
        [`${containerClassName}`]: containerClassName,
      })}
    >
      <LoadingSkeleton
        className={classNames({
          [`${className}`]: className,
        })}
        {...props}
      />
    </div>
  );
}

function Skeleton({
  circle,
  color,
  width,
  height,
  xs,
  lg,
  className,
  containerClassName,
  ...props
}) {
  const translation = useLanguage();

  return (
    <React.Fragment>
      {!xs && !lg && (
        <BaseSkeleton
          baseColor={color}
          circle={circle}
          width={width}
          height={height}
          className={className}
          containerClassName={classNames("", {
            [`${containerClassName}`]: containerClassName,
          })}
          direction={translation.direction}
          {...props}
        />
      )}
      {xs && (
        <BaseSkeleton
          baseColor={color}
          circle={circle}
          width={xs.width}
          height={xs.height}
          className={className}
          containerClassName={classNames("d-block d-lg-none", {
            [`${containerClassName}`]: containerClassName,
          })}
          direction={translation.direction}
          {...props}
        />
      )}
      {lg && (
        <BaseSkeleton
          baseColor={color}
          circle={circle}
          width={lg.width}
          height={lg.height}
          className={className}
          containerClassName={classNames("d-none d-lg-block", {
            [`${containerClassName}`]: containerClassName,
          })}
          direction={translation.direction}
          {...props}
        />
      )}
    </React.Fragment>
  );
}

Skeleton.propTypes = {
  circle: PropTypes.bool,
  color: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  xs: PropTypes.shape({
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  lg: PropTypes.shape({
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  className: PropTypes.string,
  containerClassName: PropTypes.string,
};

export default React.memo(Skeleton);

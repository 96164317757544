import React from "react";
import { Grid, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useLanguage } from "hooks";
import "./style.scss";

function Slider({
  loop,
  xs,
  sm,
  md,
  lg,
  xl,
  itemAs,
  items,
  autoplay = undefined,
  ...props
}) {
  const language = useLanguage();
  const Component = itemAs;
  const settings = {
    modules: [Autoplay, Pagination, Grid],
    ...(autoplay && { autoplay: { delay: 3000 } }),
    spaceBetween: 10,
    slidesPerView: 1,
    pagination: { clickable: true },
    loop: loop,
    breakpoints: {
      ...(xs && {
        0: xs,
      }),
      ...(sm && {
        576: sm,
      }),
      ...(md && {
        768: md,
      }),
      ...(lg && {
        992: lg,
      }),
      ...(xl && {
        1200: xl,
      }),
    },
  };
  return (
    <div className="custom-slider">
      {language.direction === "rtl" && (
        <Swiper {...settings} {...props} dir="rtl">
          {items &&
            itemAs &&
            items.map((item, idx) => (
              <SwiperSlide key={item.id}>
                <Component {...item} />
              </SwiperSlide>
            ))}
        </Swiper>
      )}

      {language.direction === "ltr" && (
        <Swiper {...settings} dir="ltr">
          {items &&
            itemAs &&
            items.map((item, idx) => (
              <SwiperSlide key={item.id}>
                <Component {...item} />
              </SwiperSlide>
            ))}
        </Swiper>
      )}
    </div>
  );
}

export default React.memo(Slider);

import React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import classNames from "classnames";
import { Image, Typography } from "components";

function DetailsModal({ title, extra_fields, visible, handleClose, ...props }) {
  return (
    <div>
      <Modal
        show={visible}
        onHide={handleClose}
        contentClassName="bg-gray border-0 rounded-lg"
      >
        <Modal.Header className="border-0" closeButton />
        <Modal.Body className="show-grid px-4">
          <Row>
            <Col xs={12}>
              <Typography
                tag="h1"
                variant="h4"
                weight="normal"
                align="center"
                color="secondary"
                text={title}
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col xs={12}>
              {extra_fields.length > 0 &&
                extra_fields.map((item, idx) => (
                  <Row
                    className={classNames("", {
                      "mt-3": idx > 0,
                      "pt-3 border-top border-gray5": idx > 0,
                    })}
                  >
                    {item.field.type !== 4 && (
                      <React.Fragment>
                        <Col xs={5}>
                          <Typography
                            tag="h6"
                            variant="body1"
                            weight="bolder"
                            color="black"
                          >
                            {item.field.label}
                          </Typography>
                        </Col>
                        <Col xs={7}>
                          <Typography
                            tag="span"
                            variant="body1"
                            weight="normal"
                            color="black"
                          >
                            {item.value}
                          </Typography>
                        </Col>
                      </React.Fragment>
                    )}
                    {item.field.type === 4 && (
                      <React.Fragment>
                        <Col xs={12}>
                          <Typography
                            tag="h6"
                            variant="body1"
                            weight="bolder"
                            color="black"
                          >
                            {item.field.label}
                          </Typography>
                        </Col>
                        <Col xs={12}>
                          <ul className="list-unstyled d-flex align-items-center flex-wrap">
                            {[...item.value.split(",")].map((item, idx) => (
                              <li
                                className={classNames("", {
                                  "ms-2": idx > 0,
                                })}
                              >
                                <div
                                  className="bg-gray3 border border-gray5 d-flex justify-content-center align-items-center"
                                  style={{
                                    width: "110px",
                                    height: "110px",
                                  }}
                                >
                                  <Image
                                    src={item}
                                    alt={item}
                                    style={{
                                      objecFit: "cover",
                                      maxWidth: "100%",
                                      maxHeight: "100%",
                                    }}
                                    fromApi
                                  />
                                </div>
                              </li>
                            ))}
                          </ul>
                        </Col>
                      </React.Fragment>
                    )}
                  </Row>
                ))}
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default React.memo(DetailsModal);

import React from "react";
import { Col, Form, Row, Table } from "react-bootstrap";
import classNames from "classnames";
import { Typography } from "components";
import { DynamicField } from "pages/Service/Details/components/DynamicFields/component";
import "./style.scss";

function GroupField({
  id,
  label,
  labelClassName,
  labelColor,
  headers,
  group_fields,
  registerField,
  control,
  errors,
  error,
  value,
  onChange,
}) {
  const onSubFieldChangedHandler = (item) => {
    // // let newValue = 0;
    // // if (value === null) newValue = 0;
    // // if (isInput) {
    // //   if (item > 0) {
    // //     onChange(value + 1);
    // //   }
    // // }
    // if (item === "") {
    //   // if (newValue - 1 === 0) newValue = null;
    //   return onChange(value - 1);
    // }
    // onChange(value + 1);

    if (item && !item.value) {
      return onChange(value.filter((v) => v.id !== item.id));
    }
    if (value) {
      let newValue = value.filter((v) => v.id !== item.id);
      newValue.push(item);
      return onChange(newValue);
    }
  };

  return (
    <Row>
      <Col xs={12}>
        <Typography
          htmlFor={id}
          tag="label"
          variant="body2"
          className={classNames("form-label text-capitalize", {
            [`${labelClassName}`]: labelClassName,
            [`text-${labelColor}`]: labelColor,
          })}
          text={label}
        />
      </Col>
      <Col xs={12}>
        {headers.length > 0 && (
          <div className="d-flex border border-bottom-0">
            {headers.map((header, idx) => {
              let colSize = {
                0: headers.length === 2 ? "60%" : "40%",
                1: headers.length === 2 ? "40%" : "40%",
                2: "20%",
              };
              return (
                <div
                  key={idx}
                  className={classNames("d-inline-block p-2", {
                    "border-end border-gray-1": idx !== headers.length - 1,
                  })}
                  style={{
                    width: colSize[idx],
                    minWidth: colSize[idx],
                  }}
                >
                  <b className="text-break">{header}</b>
                </div>
              );
            })}
          </div>
        )}
        <div className="">
          <table
            className="table table-bordered m-0"
            style={{
              tableLayout: "fixed",
            }}
          >
            <tbody
              style={{
                borderTopWidth: 0,
              }}
            >
              {group_fields.map(({ label, ...field }) => {
                let firstColSizeByFieldType;
                let secondColSizeByFieldType;
                switch (field.type) {
                  case 9:
                    firstColSizeByFieldType = "40%";
                    secondColSizeByFieldType = "60%";
                    break;
                  default:
                    firstColSizeByFieldType = "60%";
                    secondColSizeByFieldType = "40%";
                }
                return (
                  <tr key={field.id}>
                    <td
                      className="p-0 m-0 h-100"
                      style={{
                        width: firstColSizeByFieldType,
                        minWidth: firstColSizeByFieldType,
                        verticalAlign: "middle",
                      }}
                    >
                      <div className="d-flex align-items-center h-100 bg-primaryx">
                        <Typography
                          variant="body2"
                          weight="bold"
                          color="black"
                          text={label}
                        />
                      </div>
                    </td>
                    <td
                      style={{
                        width: secondColSizeByFieldType,
                        minWidth: secondColSizeByFieldType,
                      }}
                    >
                      <DynamicField
                        label={label}
                        hideLabel
                        fieldsVariant={1}
                        registerField={registerField}
                        control={control}
                        onSubFieldChangedHandler={(item) =>
                          onSubFieldChangedHandler(item)
                        }
                        {...field}
                        error={
                          errors && errors[field.id] && errors[field.id].message
                        }
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Col>
      <Col xs={12}>
        {error && (
          <Form.Text className="text-danger">{error.message}</Form.Text>
        )}
      </Col>
    </Row>
  );
}

export default GroupField;

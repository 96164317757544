import { useDispatch, useSelector } from "react-redux";
import { checkLogin, logout } from "store/auth";
import { useFetch, useLanguage } from "hooks";
import { Form } from "react-bootstrap";
import { API_ROUTES } from "constants";
import React from "react";
import { switchLanguage } from "store/app";

export default function withContainer(WrappedComponent) {
  return (props) => {
    const translation = useLanguage();
    const dispatch = useDispatch();
    const { user, isLoggedIn } = useSelector((state) => state.auth);
    const { publicPages } = useSelector((state) => state.common);
    const [notificationsOn, setNotificationsOn] = React.useState(
      user ? user.email_notifications : false
    );

    const [{ loading }, request] = useFetch(
      {
        initialLoadingState: true,
        method: "POST",
        endpoint: API_ROUTES.editProfile,
        body: {
          ...user,
          place_id: user ? user.place.id : null,
          email_notifications: notificationsOn ? true : false,
        },
        onSuccess: () => {
          dispatch(checkLogin());
        },
      },
      [],
      false
    );

    const links = [
      isLoggedIn && {
        path: "/account/edit",
        title: translation.profile,
      },
      isLoggedIn && {
        path: "/account/orders",
        title: translation.myOrders,
      },
      isLoggedIn && {
        path: "/account/subscriptions",
        title: translation.mySubscriptions,
      },
      isLoggedIn && {
        path: "/account/points",
        title: translation.myPoints,
      },
      isLoggedIn && {
        path: "/account/addresses",
        title: translation.addresses,
      },
      isLoggedIn && {
        path: "/account/wallet",
        title: translation.myWallet,
      },
      isLoggedIn && {
        path: "/account/change-password",
        title: translation.changePassword,
      },
      isLoggedIn && {
        path: "/account/notifications",
        title: translation.notifications,
        renderRightContent: () => (
          <Form.Check
            onClick={handleTurnOnOffNotifications}
            checked={notificationsOn ? true : false}
            type="switch"
            id="custom-switch"
            className="text-primary"
            style={{
              zIndex: 9999,
            }}
          />
        ),
      },
      {
        path: "/favourite",
        title: translation.favourite,
      },
      isLoggedIn && {
        title: translation.logout,
        func: () => handleLogout(),
      },
      !isLoggedIn && {
        path: "/login",
        title: translation.login,
      },
    ];

    const handleTurnOnOffNotifications = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setNotificationsOn(!notificationsOn);
    };

    const handleSwitchLanguage = () => {
      if (translation.lang === "ar") {
        return dispatch(switchLanguage("en"));
      }
      dispatch(switchLanguage("ar"));
    };

    const handleLogout = () => dispatch(logout());

    React.useEffect(() => {
      request();
    }, [notificationsOn]);

    return (
      <WrappedComponent
        publicPages={publicPages}
        isLoggedIn={isLoggedIn}
        user={user}
        links={links.filter((item) => Object.keys(item).length > 0)}
        handleSwitchLanguage={handleSwitchLanguage}
        {...props}
      />
    );
  };
}

import React from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import classNames from "classnames";
import { Button, InputField, Typography } from "components";
import withContainer from "./Container";
import "./style.scss";

function QuantityField({
  id,
  label,
  labelClassName,
  labelColor,
  value,
  handleDecrement,
  inputRef,
  handleOnInputBlur,
  handleIncrement,
  error,
  disabled = false,
}) {
  return (
    <Row>
      <Col xs={12}>
        {label && (
          <Typography
            htmlFor={id}
            tag="label"
            className={classNames("form-label text-capitalize", {
              [`${labelClassName}`]: labelClassName,
              [`text-${labelColor}`]: labelColor,
            })}
            variant="body2"
            text={label}
          />
        )}
        <div className="quantity-counter bg-gray rounded-sm">
          <InputGroup className="flex-nowrap">
            <Button
              variant=""
              textVariant="buttonText1"
              className="text-primary py-0"
              onClick={handleDecrement}
              text="-"
              disabled={disabled}
            />
            <InputField
              id={id}
              type="number"
              className="bg-transparent form-control p-0 border-0 text-center text-secondary fs-5 fw-bolder"
              ref={inputRef}
              value={value}
              onChange={(e) => handleOnInputBlur(+e.target.value)}
              onBlur={(e) => handleOnInputBlur(+e.target.value)}
              disabled={disabled}
            />
            <Button
              variant=""
              textVariant="buttonText1"
              className="text-primary py-0"
              onClick={handleIncrement}
              text="+"
              disabled={disabled}
            />
          </InputGroup>
        </div>
        {error && <Form.Text className="text-danger">{error}</Form.Text>}
      </Col>
    </Row>
  );
}

export default withContainer(React.memo(QuantityField));

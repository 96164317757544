import React from "react";
import { useParams } from "react-router-dom";
import { API_ROUTES } from "constants";
import { useFetch, useLanguage } from "hooks";
import { Layout, ProfileLayout, Skeleton } from "components";

function withContainer(WrappedCompoent) {
  return (props) => {
    const language = useLanguage();
    const { orderReference } = useParams();
    const [{ loading, data }, refetch] = useFetch({
      endpoint: API_ROUTES.getSubscriptionOrderDetails,
      params: `?order_reference=${orderReference}`,
      initialLoadingState: true,
    });

    if (loading) {
      return (
        <Layout footerMargin>
          <div id="SubscriptionDetailsPage">
            <ProfileLayout title={language.myAccount}>
              <Skeleton height={600} />
            </ProfileLayout>
          </div>
        </Layout>
      );
    }
    return <WrappedCompoent loading={loading} data={data} refetch={refetch} />;
  };
}
export default withContainer;

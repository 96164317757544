import { createSlice } from "@reduxjs/toolkit";
import {
  getWebsiteInformation,
  getSocialMediaIcons,
  getPaymentMethodsByPlaceID,
  getPublicPages,
  getWebsiteSettings,
  getHelpCenter,
  getOrderComplainReasons,
  getHearAboutUS,
  getCalloutsTypes,
  getCities,
  getPlacesWithSubLevels,
  getTranslations,
  getAdvertisements,
} from "./actions";

const initialState = {
  websiteInformation: null,
  websiteSettings: null,
  helpCenter: [],
  socialMediaIcons: [],
  calloutsTypes: [],
  paymentMethods: [],
  publicPages: [],
  orderComplainReasons: [],
  hearAboutUs: [],
  cities: [],
  placesWithSubLevels: [],
  serverTranslations: [],
  advertisements: null,
};

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {},
  extraReducers: {
    [getWebsiteInformation.fulfilled]: (state, action) => {
      state.websiteInformation = action.payload.data;
    },
    [getWebsiteSettings.fulfilled]: (state, action) => {
      state.websiteSettings = action.payload.data;
    },
    [getCities.fulfilled]: (state, action) => {
      state.cities = action.payload.data;
    },
    [getHelpCenter.fulfilled]: (state, action) => {
      state.helpCenter = action.payload.data;
    },
    [getSocialMediaIcons.fulfilled]: (state, action) => {
      state.socialMediaIcons = action.payload.data;
    },
    [getCalloutsTypes.fulfilled]: (state, action) => {
      state.calloutsTypes = action.payload.data;
    },
    [getPublicPages.fulfilled]: (state, action) => {
      state.publicPages = action.payload.data;
    },
    [getPaymentMethodsByPlaceID.fulfilled]: (state, action) => {
      state.paymentMethods = action.payload.data;
    },
    [getOrderComplainReasons.fulfilled]: (state, action) => {
      state.orderComplainReasons = action.payload.data;
    },
    [getHearAboutUS.fulfilled]: (state, action) => {
      state.hearAboutUs = action.payload.data;
    },
    [getPlacesWithSubLevels.fulfilled]: (state, action) => {
      state.placesWithSubLevels = action.payload.data;
    },
    [getTranslations.fulfilled]: (state, action) => {
      state.serverTranslations = action.payload.data;
    },
    [getAdvertisements.fulfilled]: (state, action) => {
      state.advertisements = action.payload.data;
    },
  },
});

export {
  getWebsiteInformation,
  getWebsiteSettings,
  getHelpCenter,
  getSocialMediaIcons,
  getPaymentMethodsByPlaceID,
  getPublicPages,
  getOrderComplainReasons,
  getHearAboutUS,
  getCities,
  getPlacesWithSubLevels,
  getTranslations,
  getAdvertisements,
};
export default commonSlice.reducer;

import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLanguage } from "hooks";
import { ReadMore, Slider } from "components";
import { OfferCard } from "components/cards";
import Title from "../../../../components/Title";
import "./style.scss";

function LastOffers({ latestOffers }) {
  const language = useLanguage();

  return (
    <div id="offers">
      <Container>
        <Row>
          <Col xs={12}>
            <Title
              title={language.checkOurLatestOffers}
              // description="Over 200,000 downloadable resources, with commercial license. Unlimited Downloads and more."
              color="black"
              lastColor="secondary"
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col xs={12}>
            <Slider
              dir={language.direction}
              xs={{
                slidesPerView: 1,
              }}
              lg={{
                slidesPerView: 2,
                pagination: { clickable: true },
              }}
              items={latestOffers}
              itemAs={OfferCard}
            />
          </Col>
        </Row>
        {/* <Row className="mt-4 d-none d-lg-block">
          <Col xs={12}>
            <ReadMore />
          </Col>
        </Row> */}
      </Container>
    </div>
  );
}

export default React.memo(LastOffers);

import React from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import ModalVideo from "react-modal-video";
import { STORAGE_URL } from "constants";
import { useToggle } from "hooks";
import { Image, Typography } from "components";
import Title from "components/Title";
import "./style.scss";

function HowItWorks({
  title,
  text,
  image,
  video_provider,
  video,
  steps_image,
}) {
  const [open, toggleOpen] = useToggle();

  return (
    <div id="how-it-works">
      {video_provider === "local" ? (
        <Modal show={open} onHide={toggleOpen} size="lg" centered>
          <video controls autoPlay width="100%" height="100%">
            <source src={`${STORAGE_URL}${video}`} type="video/mp4" />
          </video>
        </Modal>
      ) : (
        <ModalVideo
          channel={video_provider}
          videoId={video}
          autoplay
          isOpen={open}
          onClose={toggleOpen}
        />
      )}
      <Container>
        <Row>
          <Col xs={12} className="mb-lg-4">
            <Title
              title={title}
              description={text}
              color="black"
              lastColor="secondary"
              link="/videos"
            />
          </Col>
        </Row>
      </Container>
      <div className="how-it-works-body position-relative">
        <Container className="h-100">
          <Row className="d-none d-lg-block h-100">
            <Col xs={12} lg={6} className="h-100">
              <div className="left-side d-flex flex-column justify-content-center py-2 pe-5 h-100">
                <div>
                  <Typography
                    tag="h3"
                    variant="h3"
                    weight="bolder"
                    color="white"
                  >
                    {title}
                  </Typography>
                  <Typography tag="p" weight="normal" color="white">
                    {text}
                  </Typography>
                </div>
                <div className="mt-3">
                  <Image
                    src={steps_image}
                    className="steps-image"
                    alt="thumbnail"
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mt-4 mt-lg-0">
            <Col xs={12} lg={6}>
              <div role="button" onClick={toggleOpen} className="right-side">
                <div className="video-content h-100 position-relative">
                  <Image
                    src={image}
                    className="video_image h-100 w-100"
                    alt="thumbnail"
                    fromApi
                  />
                  <div className="d-flex align-items-center justify-content-center h-100 w-100 position-absolute top-0">
                    <Image
                      src="/images/play-circle.svg"
                      className="w-25"
                      role="button"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default React.memo(HowItWorks);

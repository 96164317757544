import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_ROUTES } from "constants";
import { client } from "helpers";

export const addRemoveWishlist = createAsyncThunk(
  "home/addRemoveWishlist",
  async ({ productId }, thunkAPI) => {
    const { getState, rejectWithValue } = thunkAPI;
    const { language } = getState().app;
    const { guestId, accessToken } = getState().auth;
    const { ids } = getState().wishlist;

    try {
      const response = await client().post(
        API_ROUTES.addRemoveWishlist,
        {
          product_id: productId,
        },
        {
          headers: {
            Language: language,
            Authorization: `Bearer ${accessToken}`,
            guestid: guestId,
          },
        }
      );

      if (response.data.status === 0) {
        return rejectWithValue(response.data.message);
      }
      // find if the product is already in the wishlist
      let newIds = [...ids];
      const index = ids.findIndex((id) => id === productId);
      // if it exists, remove it
      if (index > -1) {
        newIds.splice(index, 1);
        return newIds;
      } else {
        // if it doesn't exist, add it to newIds
        newIds = [...ids, productId];
      }
      return newIds;
    } catch (error) {
      const { response } = error;
      return rejectWithValue(response.data.message);
    }
  }
);

export const getWishlistIds = createAsyncThunk(
  "home/getWishlistIds",
  async (_, thunkAPI) => {
    const { getState, rejectWithValue } = thunkAPI;
    const { language } = getState().app;
    const { guestId, accessToken } = getState().auth;

    try {
      const response = await client().get(`${API_ROUTES.getWishlistIds}`, {
        headers: {
          language: language,
          Authorization: `Bearer ${accessToken}`,
          guestid: guestId,
        },
      });
      const data = response.data.data;
      if (data.status === 0) {
        return rejectWithValue(data.message);
      }
      return data;
    } catch (error) {
      const { response } = error;
      return rejectWithValue(response.data.message);
    }
  }
);

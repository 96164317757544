import React from "react";
import { Col, Row } from "react-bootstrap";
import { Image, RatingStars, Typography } from "components";
import "./style.scss";

function ReviewItem({ user, rate, comment, created_at }) {
  return (
    <div className="rating-item">
      <Row>
        <Col xs={4} lg={3}>
          <Row>
            <Col xs={12} className="text-center">
              <div className="rating-item__review-image">
                <Image
                  src={"/images/user-default.webp"}
                  // src={user?.image || "/images/user-default.webp"}
                  className="rounded-circle"
                  alt="user"
                  // {...(user?.image && {
                  //   fromApi: true,
                  // })}
                />
              </div>
            </Col>
            <Col xs={12} className="mt-2">
              <Typography
                tag="h6"
                variant="subtitle1"
                className="text-center"
                text={user ? user.name : "Guest"}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={8} lg={9}>
          <Row className="h-100">
            <Col xs={12} lg={12}>
              <Typography tag="p" variant="body1">
                {comment}
              </Typography>
            </Col>
            <Col xs={12} lg={12} className="align-self-end">
              <Row className="align-items-center">
                <Col xs={12} sm={6} lg={6}>
                  <RatingStars rate={rate} showText width={14} />
                </Col>
                <Col xs={12} sm={6} lg={6} className="">
                  <Typography
                    tag="p"
                    color="secondary"
                    className="text-sm-end"
                    text={new Date(created_at).toLocaleDateString()}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default React.memo(ReviewItem);

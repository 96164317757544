import React from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { Controller } from "react-hook-form";
import classNames from "classnames";
import { Rating } from "react-simple-star-rating";
import { useLanguage } from "hooks";
import {
  Button,
  Icon,
  SelectField,
  Slider,
  TextAreaField,
  Title,
  Typography,
} from "components";
import { ReviewCard } from "components/cards";
import withContainer from "./Container";
import "./style.scss";
import { useSelector } from "react-redux";

function ClientsTestimonials({
  control,
  userOrderedServicesLoading,
  userOrderedServices,
  testimonials,
  handleSubmitTestimonial,
  createTestimonialLoading,
}) {
  const language = useLanguage();
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { serverTranslations } = useSelector((state) => state.common);

  return (
    <div id="clients-testimonials" className="pb-5">
      <Title
        title={language.clientsTestimonials}
        color="black"
        lastColor="secondary"
      />
      <div className="clipped-bg"></div>
      <Container className="mt-5">
        <Row className="align-items-endd">
          <Col
            xs={12}
            lg={4}
            xl={4}
            className={classNames("mb-4 mb-lg-0", {
              "d-none": !isLoggedIn,
            })}
          >
            <div className="clients-testimonials-form position-relative ">
              <Row>
                <Col xs={12}>
                  <Typography
                    tag="h2"
                    variant="h1"
                    color="black"
                    weight="normal"
                  >
                    {language.clientsTestimonials.split(" ")[0]}{" "}
                    <Typography tag="span" color="secondary">
                      {language.clientsTestimonials.split(" ")[1]}
                    </Typography>
                  </Typography>
                  <Typography
                    tag="p"
                    variant="body1"
                    color="black"
                    weight="normal"
                  >
                    {serverTranslations.text_in_customer_testimonials}
                  </Typography>
                </Col>
                <Col xs={12} className="mt-4">
                  <div className="rounded-sm bg-gray3 shadow-sm p-2">
                    <Form onSubmit={handleSubmitTestimonial} className="p-1">
                      <Typography
                        tag="h4"
                        variant="h5"
                        color="black"
                        weight="normal"
                      >
                        {language.writeReview}
                      </Typography>
                      <div className="mt-4">
                        <div>
                          <Controller
                            name="product_id"
                            control={control}
                            defaultValue=""
                            render={({
                              field: { value, onChange },
                              fieldState: { error },
                            }) => (
                              <SelectField
                                label={language.service}
                                labelColor="gray7"
                                placeholder={language.service}
                                onChange={onChange}
                                value={value}
                                // value={value}
                                error={error && error.message}
                                className="py-3"
                              >
                                {userOrderedServices &&
                                  userOrderedServices.length > 0 &&
                                  userOrderedServices.map((item) => (
                                    <option key={item.id} value={item.id}>
                                      {item.title}
                                    </option>
                                  ))}
                              </SelectField>
                            )}
                          />
                        </div>
                        <div className="mt-3">
                          <Controller
                            name="comment"
                            control={control}
                            defaultValue=""
                            render={({
                              field: { value, onChange },
                              fieldState: { error },
                            }) => (
                              <TextAreaField
                                label={language.message}
                                labelColor="gray7"
                                placeholder={language.message}
                                onChange={onChange}
                                value={value}
                                // value={value}
                                error={error && error.message}
                                className="py-3"
                              />
                            )}
                          />
                        </div>
                        <div className="mt-3">
                          <Controller
                            name="rating"
                            control={control}
                            defaultValue={20}
                            render={({ field: { onChange, value } }) => (
                              <Rating
                                ratingValue={value}
                                initialValue={1}
                                onClick={onChange}
                                rtl={language.direction === "rtl"}
                                fullIcon={
                                  <Icon
                                    name="star"
                                    className="text-star me-1"
                                  />
                                }
                                emptyIcon={
                                  <Icon
                                    name="starEmpty"
                                    className="text-star me-1"
                                  />
                                }
                              />
                            )}
                          />
                        </div>
                      </div>
                      <Row className="mt-3">
                        <Col xs={6}>
                          <Button
                            type="submit"
                            className="text-white rounded-lg"
                            text={language.submit}
                          />
                        </Col>
                        <Col xs={6}>
                          <Controller
                            control={control}
                            name="notARobot"
                            defaultValue={false}
                            render={({
                              field: { value, onChange },
                              fieldState: { error },
                            }) => (
                              <React.Fragment>
                                <Form.Check
                                  id="notARobot"
                                  type="checkbox"
                                  onChange={onChange}
                                  checked={value}
                                  label={
                                    <Typography
                                      tag="span"
                                      variant="body1"
                                      color="gray7"
                                      className="text-uppercase"
                                      text={language.IamNotARobot}
                                    />
                                  }
                                  className={classNames("d-inline-block me-1")}
                                  isInvalid={error}
                                  isValid={typeof error === "undefined"}
                                />
                              </React.Fragment>
                            )}
                          />
                        </Col>
                      </Row>
                      {/* </fieldset>  */}
                    </Form>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col xs={12} lg={!isLoggedIn ? 12 : 8} xl={!isLoggedIn ? 12 : 8}>
            <Slider
              dir={language.direction}
              xs={{
                slidesPerView: 1,
                grid: {
                  fill: "row",
                  rows: 1,
                },
              }}
              md={{
                slidesPerView: 2,
                pagination: { clickable: true },
                grid: {
                  fill: "row",
                  rows: 2,
                },
              }}
              lg={{
                slidesPerView: 2,
                pagination: { clickable: true },
                grid: {
                  fill: "row",
                  rows: 2,
                },
              }}
              xl={{
                slidesPerView: isLoggedIn ? 2 : 3,
                pagination: { clickable: true },
                grid: {
                  fill: "row",
                  rows: 2,
                },
              }}
              items={testimonials.filter((item) => item.user !== null)}
              itemAs={ReviewCard}
              spaceBetween={24}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default withContainer(React.memo(ClientsTestimonials));

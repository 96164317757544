import React from "react";
import { Accordion, Col, Form, Row } from "react-bootstrap";
import { getTrackBackground, Range } from "react-range";
import { useLanguage } from "hooks";
import { Button, Image, RatingStars, Skeleton, Typography } from "components";
import "./style.scss";
import { useSelector } from "react-redux";

function Filters({
  loading,
  infoAndFilters,
  selectedFilters,
  toggleFilter,
  handleApplyFilters,
  handleResetFilters,
}) {
  const language = useLanguage();
  const { advertisements } = useSelector((state) => state.common);

  return (
    <div className="filterbar shadow-sm h-100 py-2">
      {!loading ? (
        <Row className="justify-content-center">
          <Col xs={12}>
            <Typography
              tag="h5"
              variant="h5"
              weight="bolder"
              className="px-2 py-1"
            >
              {language.filters}
            </Typography>
          </Col>
          <Col xs={12}>
            {infoAndFilters && (
              <Accordion
                defaultActiveKey={[
                  ...infoAndFilters.options.map((item) => item.name),
                  "rate",
                  "price",
                ]}
                alwaysOpen={true}
              >
                {infoAndFilters.options.map((item, idx) => (
                  <Accordion.Item
                    key={item.id}
                    eventKey={item.name}
                    area-hi={item.name}
                  >
                    <Accordion.Header>{item.name}</Accordion.Header>
                    <Accordion.Body>
                      {item.values.map((subItem, subIdx) => (
                        <Form.Check
                          key={subItem.id}
                          type={"checkbox"}
                          id={`check-${item.id}-${subItem.id}`}
                          label={subItem.name}
                          onChange={() =>
                            toggleFilter("options_values", subItem.id)
                          }
                          checked={
                            selectedFilters["options_values"].findIndex(
                              (item) => item === subItem.id
                            ) >= 0
                          }
                        />
                      ))}
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
                <Accordion.Item eventKey="price">
                  <Accordion.Header>{language.price}</Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      <Col xs={6}>
                        <Typography tag="span" variant="body2">
                          {infoAndFilters.prices.min}{" "}
                          {language.website_currency}
                        </Typography>
                      </Col>
                      <Col xs={6} className="text-end">
                        <Typography tag="span" variant="body2">
                          {infoAndFilters.prices.max}{" "}
                          {language.website_currency}
                        </Typography>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <Range
                          values={[
                            +selectedFilters["min_max"].min,
                            +selectedFilters["min_max"].max,
                          ]}
                          {...(selectedFilters["min_max"].min !== null && {
                            min: infoAndFilters.prices.min,
                            max: infoAndFilters.prices.max,
                          })}
                          direction={`to ${
                            language.direction === "rtl" ? "left" : "right"
                          }`}
                          step={1}
                          onChange={(range) => {
                            toggleFilter(
                              "min_max",
                              {
                                min: range[0],
                                max: range[1],
                              },
                              false
                            );
                          }}
                          renderTrack={({ props, children }) => (
                            <div
                              onMouseDown={props.onMouseDown}
                              onTouchStart={props.onTouchStart}
                              style={{
                                ...props.style,
                                height: "30px",
                                display: "flex",
                                width: "100%",
                              }}
                              dir="ltr"
                            >
                              <div
                                ref={props.ref}
                                className="bg-primary"
                                style={{
                                  height: "5px",
                                  width: "100%",
                                  borderRadius: "4px",
                                  background: getTrackBackground({
                                    values: [
                                      selectedFilters["min_max"].min,
                                      selectedFilters["min_max"].max,
                                    ],
                                    colors: ["#548BF4", "#000"],
                                    min: 5,
                                    max: 10,
                                  }),
                                  alignSelf: "center",
                                }}
                              >
                                {children}
                              </div>
                            </div>
                          )}
                          renderThumb={({ props, isDragged }) => (
                            <div
                              {...props}
                              className="bg-secondary rounded-circle"
                              style={{
                                ...props.style,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "10px",
                                width: "10px",
                              }}
                            >
                              <div
                                className="bg-secondary text-white fw-bolder px-2 py-1 rounded-xs"
                                style={{
                                  position: "absolute",
                                  bottom: "-30px",
                                  fontSize: "12px",
                                }}
                              >
                                {props["aria-valuenow"]}
                              </div>
                            </div>
                          )}
                          renderMark={({ props }) => (
                            <div
                              {...props}
                              className="bg-white rounded-circle"
                            ></div>
                          )}
                        />
                      </Col>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            )}
          </Col>
          <Col xs={7} className="mt-4">
            <Button
              variant="primary"
              onClick={handleApplyFilters}
              className="text-white w-100"
              text={language.applyFilters}
            />
          </Col>
          <Col xs={7} className="mt-4">
            <Button
              variant="primary"
              onClick={handleResetFilters}
              className="text-white w-100"
              text={language.resetFilters}
            />
          </Col>

          <Col xs={12} className="mt-4">
            <div className="p-2">
              {advertisements && advertisements["products-under-filter"] && (
                <section className="section-mt">
                  <div className="home-advertisement">
                    {/* If Can Click */}
                    {advertisements["products-under-filter"].link &&
                    advertisements["products-under-filter"].can_click ? (
                      <a
                        href={advertisements["products-under-filter"].link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Image
                          src={advertisements["products-under-filter"].ad_image}
                          alt="advertisement"
                          style={{
                            width: "100%",
                          }}
                          fromApi
                        />
                      </a>
                    ) : (
                      <Image
                        src={advertisements["products-under-filter"].ad_image}
                        alt="advertisement"
                        style={{
                          width: "100%",
                        }}
                        fromApi
                      />
                    )}
                  </div>
                </section>
              )}
            </div>
          </Col>
        </Row>
      ) : (
        <Skeleton height={500} />
      )}
    </div>
  );
}

export default React.memo(Filters);

import React from "react";
import { Col, InputGroup, Row } from "react-bootstrap";
import { Button, InputField } from "components";
import withContainer from "./Container";
import "./style.scss";

function QuantityCounter({
  handleDecrement,
  inputRef,
  handleOnInputBlur,
  handleIncrement,
}) {
  return (
    <div className="quantity-counter bg-gray rounded-sm">
      <Row>
        <Col xs={12}>
          <InputGroup className="w-100">
            <Button
              variant=""
              textVariant="buttonText1"
              className="text-primary"
              onClick={handleDecrement}
              text="-"
            />
            <InputField
              type="number"
              className="bg-transparent form-control border-0 text-center text-secondary fs-5 fw-bolder"
              ref={inputRef}
              onBlur={(e) => handleOnInputBlur(+e.target.value)}
            />
            <Button
              variant=""
              textVariant="buttonText1"
              className="text-primary"
              onClick={handleIncrement}
              text="+"
            />
          </InputGroup>
        </Col>
      </Row>
    </div>
  );
}

export default withContainer(React.memo(QuantityCounter));

import React from "react";
import { useSelector } from "react-redux";
import { API_ROUTES } from "constants";
import { useFetch } from "hooks";

function withContainer(WrappedCompoent) {
  return (props) => {
    const wishlistIds = useSelector((state) => state.wishlist.ids);

    const [{ data, loading, meta }] = useFetch({
      initialLoadingState: true,
      endpoint: API_ROUTES.getWishList,
    });

    return (
      <WrappedCompoent
        loading={loading}
        data={data && data.filter((item) => wishlistIds.includes(item.id))}
        meta={meta}
      />
    );
  };
}
export default withContainer;

import React from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { API_ROUTES, phoneNumberRegexp } from "constants";
import { useAlert } from "context";
import { useFetch, useLanguage } from "hooks";
import { useSelector } from "react-redux";
import { useEffect } from "react";

function withContainer(WrappedCompoent) {
  return (props) => {
    const language = useLanguage();
    const { isLoggedIn, user } = useSelector((state) => state.auth);
    const [_, dispatchAlert] = useAlert();
    const schema = yup.object().shape(
      {
        cancel_comment: yup.string().required(language.cancelCommentRequired),
        agree: yup
          .boolean()
          .oneOf(
            [true],
            `${language.youMustAgreeToThisNote}`
          ),
      },
      []
    );
    const {
      register: registerField,
      control,
      handleSubmit,
      formState: { errors },
      watch,
      reset,
    } = useForm({
      resolver: yupResolver(schema),
    });
    const values = watch();

    const [{ loading }, request] = useFetch(
      {
        method: "POST",
        endpoint: API_ROUTES.submitCancelSubscriptionOrder,
        body: {
          subscription_order_id: props.subscription_order_id,
          cancel_comment: values.cancel_comment
        },
        onSuccess: () => {
          reset();
          props.handleClose();
          props.refetch();
          dispatchAlert({
            type: "OPEN",
            payload: {
              icon: "success",
              title: language.success,
              text: language.cancelsubscriptionOrderSuccess,
              canDismiss: true,
            },
          });
        },
      },
      [],
      false
    );

    const handleSubmitData = () => {
      request();
    };

    useEffect(() => {
      if (user && isLoggedIn) {
        reset({
          name: user.name,
          email: user.email,
          phone: user.phone,
        });
      }
    }, [user, isLoggedIn, reset]);

    return (
      <WrappedCompoent
        loading={loading}
        registerField={registerField}
        control={control}
        values={values}
        errors={errors}
        handleSubmit={handleSubmit(handleSubmitData)}
        {...props}
      />
    );
  };
}
export default withContainer;

import React from "react";
import { Col, Form, Row, Spinner } from "react-bootstrap";
import { useLanguage } from "hooks";
import {
  Layout,
  ProfileLayout,
  Button,
  InputField,
  SelectField,
} from "components";
import withContainer from "./Container";

function EditProfile({ loading, registerField, values, handleSubmit, errors }) {
  const translation = useLanguage();

  return (
    <Layout footerMargin>
      <div id="EditProfilePage">
        <ProfileLayout title={translation.myAccount}>
          <Form onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Col xs={12} sm={6}>
                <InputField
                  type="text"
                  label={translation.firstName}
                  labelClassName="fw-bolder"
                  className="py-3"
                  placeholder={translation.firstName}
                  {...registerField("first_name")}
                  error={errors.first_name && errors.first_name.message}
                />
              </Col>
              <Col xs={12} sm={6} className="mt-3 mt-sm-0">
                <InputField
                  type="text"
                  label={translation.lastName}
                  labelClassName="fw-bolder"
                  className="py-3"
                  placeholder={translation.lastName}
                  {...registerField("last_name")}
                  error={errors.last_name && errors.last_name.message}
                />
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xs={12} sm={6}>
                <InputField
                  type="email"
                  label={translation.emailAddress}
                  labelClassName="fw-bolder"
                  className="py-3"
                  placeholder={translation.emailAddress}
                  {...registerField("email")}
                  error={errors.email && errors.email.message}
                />
              </Col>
              <Col xs={12} sm={6} className="mt-3 mt-sm-0">
                <InputField
                  type="tel"
                  label={translation.phoneNumber}
                  labelClassName="fw-bolder"
                  className="py-3"
                  placeholder={translation.phoneNumber}
                  {...registerField("phone")}
                  error={errors.phone && errors.phone.message}
                />
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xs={12} sm={6}>
                <InputField
                  type="date"
                  label={translation.dateOfBirth}
                  labelClassName="fw-bolder"
                  className="py-3"
                  placeholder={translation.dateOfBirth}
                  {...registerField("date_of_birth")}
                  error={errors.date_of_birth && errors.date_of_birth.message}
                  max={new Date().toISOString().split("T")[0]}
                />
              </Col>
              <Col xs={12} sm={6} className="mt-3 mt-sm-0">
                <SelectField
                  label={translation.gender}
                  labelClassName="fw-bolder"
                  className="py-3"
                  {...registerField("gender")}
                  error={errors.gender && errors.gender.message}
                >
                  <option value="1">{translation.male}</option>
                  <option value="2">{translation.female}</option>
                </SelectField>
              </Col>
            </Row>

            <Row className="justify-content-center mt-4">
              <Col xs={6}>
                <Button
                  type="submit"
                  className="w-100"
                  variant="primary"
                  textVariant="buttonText3"
                  text={
                    loading ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      translation.send
                    )
                  }
                />
              </Col>
            </Row>
          </Form>
        </ProfileLayout>
      </div>
    </Layout>
  );
}

export default withContainer(React.memo(EditProfile));

import React from "react";
import classNames from "classnames";
import "./style.scss";

const Heading = ({ title = "", tag = "h2", ...props }) => {
  return (
    <div className="heading-component">
      {React.createElement(
        tag,
        {
          className: classNames("heading-tag fw-normal", {
            [`${props.className}`]: props.className,
          }),
        },
        title.split(" ").length > 1 ? (
          <React.Fragment>
            {title.split(" ").map((item, idx) => (
              <React.Fragment key={idx}>
                <span
                  className={classNames("", {
                    "fw-bolder text-secondary":
                      idx === title.split(" ").length - 1,
                  })}
                >
                  {item}
                </span>{" "}
              </React.Fragment>
            ))}
          </React.Fragment>
        ) : (
          title
        )
      )}
    </div>
  );
};

export default React.memo(Heading);

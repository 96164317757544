import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "store/auth";

function withContainer(Component) {
  return (props) => {
    const dispatch = useDispatch();
    const headerRef = React.useRef(null);
    const wishlistIds = useSelector((state) => state.wishlist.ids);
    const cartIds = useSelector((state) => state.cart.ids);
    const { websiteSettings, helpCenter, socialMediaIcons, cities } =
      useSelector((state) => state.common);
    const { isLoggedIn, user } = useSelector((state) => state.auth);

    const handleLogout = () => dispatch(logout());

    return (
      <Component
        headerRef={headerRef}
        websiteSettings={websiteSettings}
        helpCenter={helpCenter}
        cities={cities}
        socialMediaIcons={socialMediaIcons}
        isLoggedIn={isLoggedIn}
        user={user}
        wishlistCount={wishlistIds.length}
        cartCount={cartIds.length}
        handleLogout={handleLogout}
      />
    );
  };
}

export default withContainer;

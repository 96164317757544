import React from "react";
import { STORAGE_URL, DEFAULT_USER_IMAGE } from "constants";

function Avatar({ image }) {
  return (
    <img
      src={
        !image || image === "" ? DEFAULT_USER_IMAGE : `${STORAGE_URL}${image}`
      }
      alt={"user avatar"}
      className="avatar w-75 rounded-circle bg-gray5"
    />
  );
}

export default React.memo(Avatar);

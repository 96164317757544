import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { createYupSchema } from "helpers";
import { useCallback, useState } from "react";
import { useMemo } from "react";

export default function useReactHookForm({ defaultValues, schema }) {
  const [currentSchema, setCurrentSchema] = useState(schema ?? null);
  const {
    control,
    setValue,
    handleSubmit,
    reset,
    watch,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValues ? defaultValues : undefined,
    resolver: currentSchema
      ? yupResolver(createYupSchema(currentSchema))
      : undefined,
  });

  const updateSchema = useCallback((newSchema, replace = false) => {
    if (replace) {
      return setCurrentSchema(newSchema);
    }
    setCurrentSchema((prevSchema) => [
      ...(prevSchema ? prevSchema : []),
      ...newSchema,
    ]);
  }, []);

  const updateValues = useCallback(
    (newValues) => {
      Object.keys(newValues).forEach((key) => {
        setValue(key, newValues[key]);
      });
    },
    [setValue]
  );

  return useMemo(
    () => ({
      updateSchema,
      control,
      setValue,
      updateValues,
      handleSubmit,
      reset,
      watch,
      getValues,
      errors,
    }),
    [
      updateSchema,
      control,
      setValue,
      updateValues,
      handleSubmit,
      reset,
      watch,
      getValues,
      errors,
    ]
  );
}

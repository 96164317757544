import React from "react";
import classNames from "classnames";

function Typography({
  tag = "p",
  variant,
  weight,
  align,
  color,
  overflow,
  className,
  ltr,
  text,
  children,
  ...props
}) {
  const classList = {
    h1: "h1",
    h2: "h2",
    h3: "h3",
    h4: "h4",
    h5: "h5",
    h6: "h6",
    body1: "body1",
    body2: "body2",
    body3: "body3",
  };

  return React.createElement(
    tag,
    {
      className: classNames({
        [`${classList[variant]}`]: variant,
        [`${className}`]: className,
        [`text-${align}`]: align,
        [`text-${color}`]: color,
        [`fw-${weight}`]: weight,
        [`text-overflow-${overflow}`]: overflow,
        "text-start": ltr,
      }),
      ...props,
      ...(ltr && { dir: "ltr" }),
    },
    text || children
  );
}

export default React.memo(Typography);

import React from "react";
import { Col, Form, Row, Spinner } from "react-bootstrap";
import { useLanguage } from "hooks";
import {
  InputField,
  Layout,
  ProfileLayout,
  Button,
  TextAreaField,
  SelectField,
  DropZoneField,
} from "components";
import withContainer from "./Container";
import { Controller } from "react-hook-form";
import { useSelector } from "react-redux";

function SubscriptionRequesCalloutPage({
  loading,
  registerField,
  control,
  values,
  errors,
  setValue,
  handleSubmit,
  subscriptionDetails,
}) {
  const translation = useLanguage();

  return (
    <Layout footerMargin>
      <div id="EditProfilePage">
        <ProfileLayout title={translation.myAccount}>
          <div className="bg-gray3 border-gray pt-4 pb-5">
            <Form onSubmit={handleSubmit}>
              <Row className="mb-3 px-4">
                <Col xs={12} md={6}>
                  <InputField
                    type="text"
                    label={translation.name}
                    placeholder={translation.name}
                    error={errors.name && errors.name.message}
                    {...registerField("name")}
                    className="py-3"
                  />
                </Col>
                <Col xs={12} md={6}>
                  <InputField
                    type="email"
                    label={translation.emailAddress}
                    placeholder={translation.emailAddress}
                    error={errors.email && errors.email.message}
                    {...registerField("email")}
                    className="py-3"
                  />
                </Col>
              </Row>
              <Row className="mb-3 px-4">
                <Col xs={12} md={6} className="mt-3 mt-md-0">
                  <InputField
                    type="tel"
                    label={translation.phoneNumber}
                    placeholder={translation.phoneNumber}
                    error={errors.phone && errors.phone.message}
                    {...registerField("phone")}
                    className="py-3"
                  />
                </Col>
                <Col xs={12} md={6}>
                  <Controller
                    control={control}
                    name={"type"}
                    defaultValue={""}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <>
                        {subscriptionDetails?.callout_types && (
                          <SelectField
                            label={translation.selectType}
                            labelColor="gray7"
                            placeholder={translation.selectType}
                            onChange={onChange}
                            defaultValue={value}
                            error={error && error.message}
                            className="py-3"
                          >
                            {subscriptionDetails.callout_types.map((type) => (
                              <option
                                key={type.id}
                                value={type.callouts_type.id}
                              >
                                {type?.callouts_type.name} -{" "}
                                {type.reamin_qty > 0
                                  ? `${type.reamin_qty} ${translation.callouts} ${translation.free}`
                                  : `${type.general_price.price} ${translation.website_currency}`}
                              </option>
                            ))}
                          </SelectField>
                        )}
                      </>
                    )}
                  />
                </Col>
              </Row>
              <Row className="mb-3 px-4">
                <Col xs={12} md={12} lg={6}>
                  <TextAreaField
                    rows={4}
                    label={`${translation.description}`}
                    placeholder={translation.description}
                    error={errors.description && errors.description.message}
                    {...registerField("description")}
                  />
                </Col>
              </Row>

              {/* Image Upload */}
              <Row className="px-4">
                <Col xs={12} md={12} lg={12}>
                  <DropZoneField
                    label={`${translation.attachImage} (${translation.optional})`}
                    onChange={(files) => {
                      setValue("file", files[0]);
                      console.log("files[0]", files[0]);
                    }}
                  />
                </Col>
              </Row>

              <Row className="justify-content-center mt-4">
                <Col xs={6}>
                  <Button
                    type="submit"
                    className="w-100"
                    variant="primary"
                    textVariant="buttonText3"
                    text={
                      loading ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        translation.send
                      )
                    }
                  />
                </Col>
              </Row>
            </Form>
          </div>
        </ProfileLayout>
      </div>
    </Layout>
  );
}

export default withContainer(SubscriptionRequesCalloutPage);

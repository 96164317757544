import { useLanguage, useSkipFirstRender } from "hooks";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setHomeLoaded,
  getCompanyServices,
  getHomeSlides,
  getHomeVideo,
  getHomeCategories,
  getHomeTopRatedServices,
  getHomeFacts,
  getHomePackages,
  getHomeLatestOffers,
  getHomeTestimonials,
  getHomeAdvices,
} from "store/home";

function withContainer(Component) {
  return (props) => {
    const dispatch = useDispatch();
    const {
      loaded,
      slides,
      companyServices,
      video,
      categories,
      topRatedServices,
      facts,
      packages,
      latestOffers,
      testimonials,
      advices,
    } = useSelector((state) => state.home);
    const { websiteSettings, advertisements } = useSelector(
      (state) => state.common
    );
    const language = useLanguage();

    React.useEffect(() => {
      if (!loaded) {
        Promise.all([
          dispatch(getHomeSlides()),
          dispatch(getCompanyServices()),
          dispatch(getHomeVideo()),
          dispatch(getHomeCategories()),
          dispatch(getHomeTopRatedServices()),
          dispatch(getHomeFacts()),
          dispatch(getHomePackages()),
          dispatch(getHomeLatestOffers()),
          dispatch(getHomeTestimonials()),
          dispatch(getHomeAdvices()),
        ]).then(() => {
          dispatch(setHomeLoaded(true));
        });
      }
    }, [dispatch, loaded]);

    return (
      <Component
        slides={slides}
        companyServices={companyServices}
        video={video}
        categories={categories}
        topRatedServices={topRatedServices}
        facts={facts}
        packages={packages}
        latestOffers={latestOffers}
        testimonials={testimonials}
        advices={advices}
        websiteSettings={websiteSettings}
        advertisements={advertisements}
        {...props}
      />
    );
  };
}

export default withContainer;

import React, { Fragment } from "react";
import { Image, Layout, Skeleton, SkeletonGroup } from "components";
import HomeSlider from "./components/Hero";
import HowItWorks from "./components/HowItWorks";
import OurServices from "./components/OurServices";
import TopRatedServices from "./components/TopRatedServices";
import OurFacts from "./components/OurFacts";
import Packages from "./components/Packages";
import LastOffers from "./components/LastOffers";
import ClientsTestimonials from "./components/ClientsTestimonials";
import AppDownload from "./components/AppDownload";
import Advices from "./components/Advices";
import withContainer from "./Container";
import "./style.scss";
import { useLanguage } from "hooks";
import { useEffect } from "react";

function Home({
  slides,
  companyServices,
  video,
  categories,
  topRatedServices,
  facts,
  packages,
  latestOffers,
  testimonials,
  advices,
  websiteSettings,
  advertisements,
}) {
  const language = useLanguage();

  return (
    <Layout footerMargin>
      <div id="home-page" className="page_container">
        <section>
          {!slides.loading ? (
            <HomeSlider
              slides={slides.data}
              companyServices={companyServices.data}
            />
          ) : (
            <Skeleton
              xs={{
                width: "100%",
                height: 180,
              }}
              lg={{
                width: "100%",
                height: 480,
              }}
            />
          )}
        </section>
        <section className="section-mt">
          {!video.loading ? (
            <HowItWorks
              title={language.howItWorks}
              // description="Over 200,000 downloadable resources, with commercial license. Unlimited Downloads and more."
              {...video.data}
              steps_image={
                language.code === "ar"
                  ? "/images/how-it-works-ar.png"
                  : "/images/how-it-works.png"
              }
            />
          ) : (
            <Skeleton
              xs={{
                width: "100%",
                height: 365,
              }}
              lg={{
                width: "100%",
                height: 365,
              }}
            />
          )}
        </section>
        <section className="section-mt">
          <OurServices
            loading={categories.loading}
            categories={categories.data}
          />
        </section>
        <section className="section-mt">
          <TopRatedServices
            loading={topRatedServices.loading}
            services={topRatedServices.data}
          />
        </section>
        <section className="section-mt">
          <OurFacts
            loading={facts.loading}
            facts={facts.data}
            categories={categories.data}
          />
        </section>
        <section className="section-mt">
          <Packages loading={packages.loading} packages={packages.data} />
        </section>
        <section className="section-mt">
          {!latestOffers.loading ? (
            <Fragment>
              {latestOffers.data.length > 0 && (
                <LastOffers latestOffers={latestOffers.data} />
              )}
            </Fragment>
          ) : (
            <Skeleton
              xs={{
                width: "100%",
                height: 200,
              }}
              lg={{
                width: "100%",
                height: 450,
              }}
            />
          )}
        </section>
        <section className="section-mt">
          {!testimonials.loading ? (
            <ClientsTestimonials testimonials={testimonials.data} />
          ) : (
            <Skeleton
              xs={{
                width: "100%",
                height: 200,
              }}
              lg={{
                width: "100%",
                height: 450,
              }}
            />
          )}
        </section>
        <section className="section-mt">
          {!advices.loading ? (
            <Advices loading={advices.loading} advices={advices.data} />
          ) : (
            <Skeleton
              xs={{
                width: "100%",
                height: 200,
              }}
              lg={{
                width: "100%",
                height: 450,
              }}
            />
          )}
        </section>
        <section className="section-mt">
          <AppDownload websiteSettings={websiteSettings} />
        </section>
        {advertisements && advertisements["home-bottom-image"] && (
          <section className="section-mt">
            <div className="home-advertisement">
              {/* If Can Click */}
              {advertisements["home-bottom-image"].link &&
              advertisements["home-bottom-image"].can_click ? (
                <a
                  href={advertisements["home-bottom-image"].link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    src={advertisements["home-bottom-image"].ad_image}
                    alt="advertisement"
                    style={{
                      width: "100%",
                    }}
                    fromApi
                  />
                </a>
              ) : (
                <Image
                  src={advertisements["home-bottom-image"].ad_image}
                  alt="advertisement"
                  style={{
                    width: "100%",
                  }}
                  fromApi
                />
              )}
            </div>
          </section>
        )}
      </div>
    </Layout>
  );
}
export default withContainer(React.memo(Home));

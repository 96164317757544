import React from "react";
import { Col, Row } from "react-bootstrap";
import { Image, Typography } from "components";

function FactCard({ title, value, icon }) {
  return (
    <div className="p-2 rounded-md border border-primary">
      <Row className="justify-content-center">
        <Col xl={12} className="text-center">
          <Image
            src={icon}
            alt={title}
            className="rounded-xs"
            style={{
              width: "20%",
            }}
            fromApi
          />
        </Col>
        <Col xs={12} className="mt-2">
          <Row className="align-items-center">
            <Col xs={12}>
              <Typography
                tag="h3"
                variant="h6"
                weight="bolder"
                align="center"
                color="white"
                overflow={1}
              >
                {title}
              </Typography>
              <Typography
                tag="p"
                variant="h3"
                weight="bold"
                color="white"
                align="center"
                overflow={3}
              >
                {value && value}
              </Typography>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default React.memo(FactCard);

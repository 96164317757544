import React from "react";
import PropTypes from "prop-types";
import { Button as BootstrapButton } from "react-bootstrap";
import classNames from "classnames";
import { Icon } from "components";
import "./style.scss";

/**
 * ========== USAGE ==========
 * <Button
 *    variant="primary" values=> default is "primary" || ["primary","secondary", "color", "outline-primary", "outline-secondary", "outline-color"]
 *    textVariant="buttonText1" default is body text values=> ["buttonText1", "buttonText2"]
 *    text="your-text" string
 *    icon="your-icon-name" string
 *    className="your-classes" string
 * />
 */

const Button = React.forwardRef(
  (
    {
      variant = "primary",
      textVariant,
      text,
      icon,
      iconClassName,
      iconLocation,
      className,
      onClick,
      ...props
    },
    ref
  ) => {
    const classList = {
      buttonText1: "fs-4 fw-normal",
      buttonText2: "fs-5 fw-normal",
      buttonText3: "fs-6 fw-normal",
    };

    return (
      <BootstrapButton
        ref={ref}
        variant={variant}
        className={classNames("custom-button", {
          "icon-button": icon && text,
          [`${classList[textVariant]}`]: textVariant,
          [`${className}`]: className,
        })}
        onClick={onClick}
        {...props} // Rest of the props, such as onClick, ...etc
      >
        {!icon && text}
        {icon && (
          <React.Fragment>
            {iconLocation !== "right" && (
              <Icon
                name={icon}
                className={classNames({
                  "icon me-1": icon && text,
                  [`${iconClassName}`]: iconClassName,
                })}
              />
            )}
            <span className="text">{text}</span>
            {iconLocation === "right" && (
              <Icon
                name={icon}
                className={classNames({
                  "icon ms-1": icon && text,
                  [`${iconClassName}`]: iconClassName,
                })}
              />
            )}
          </React.Fragment>
        )}
      </BootstrapButton>
    );
  }
);

Button.propTypes = {
  // text: PropTypes.oneOfType([
  //   PropTypes.string,
  //   PropTypes.number,
  //   PropTypes.elementType,
  // ]),
  icon: PropTypes.string,
  className: PropTypes.string,
};

export default React.memo(Button);

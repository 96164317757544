import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import classNames from "classnames";
import { Heading, NoData, Skeleton, Typography } from "components";
import { ReviewItem } from "./components";
import RatingForm from "../RatingForm";
import "./style.scss";
import { useLanguage } from "hooks";
import { useSelector } from "react-redux";

function Reviews({ loading, data, didBuyService, serviceId, fetchReviews }) {
  const language = useLanguage();
  const { isLoggedIn } = useSelector((state) => state.auth);

  return (
    <Container>
      {!loading && data ? (
        <Row>
          <Col xs={12} lg={6}>
            <Row>
              <Col xs={12}>
                <Heading title={language.reviews} />
              </Col>
              <Col xs={12} className="mt-4">
                {data && data.length === 0 && (
                  <NoData
                    image={"/images/no-reviews.png"}
                    text={language.noReviews}
                  />
                )}
                {data && data.length > 0 && (
                  <div className="rating-list">
                    {data.map((item, idx) => (
                      <div
                        key={item.id}
                        className={classNames({
                          "mt-4": idx > 0,
                          "border-bottom border-gray-1 pb-4":
                            idx < data.length - 1,
                        })}
                      >
                        <ReviewItem {...item} />
                      </div>
                    ))}
                  </div>
                )}
              </Col>
            </Row>
          </Col>
          <Col xs={12} lg={6} className="mt-5 mt-lg-0">
            <Row className="h-100">
              <Col xs={12}>
                <Heading title={language.writeReview} />
              </Col>
              <Col xs={12} className="h-100 mt-4">
                {isLoggedIn && didBuyService && (
                  <RatingForm
                    serviceId={serviceId}
                    ratings={[
                      { rate: 1, rate_count: 2 },
                      { rate: 2, rate_count: 2 },
                      { rate: 3, rate_count: 2 },
                      { rate: 4, rate_count: 2 },
                      { rate: 5, rate_count: 2 },
                    ]}
                    fetchReviews={fetchReviews}
                  />
                )}
                <div className="h-100 d-flex justify-content-center align-items-center">
                  {!isLoggedIn && (
                    <Typography
                      tag="h1"
                      variant="h4"
                      weight="bold"
                      color="primary"
                      align="center"
                      text={language.loginToWriteReview}
                    />
                  )}
                  {isLoggedIn && !didBuyService && (
                    <Typography
                      tag="h1"
                      variant="h4"
                      weight="bold"
                      color="primary"
                      align="center"
                      text={language.youNeedToBuyServiceToAddReview}
                    />
                  )}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col xs={12} lg={6}>
            <Row>
              <Col xs={12}>
                <Skeleton height={40} />
              </Col>
              <Col xs={12} className="mt-4">
                <Skeleton height={400} />
              </Col>
            </Row>
          </Col>
          <Col xs={12} lg={6} className="mt-5 mt-lg-0">
            <Row>
              <Col xs={12}>
                <Skeleton height={40} />
              </Col>
              <Col xs={12} className="mt-4">
                <Skeleton height={400} />
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </Container>
  );
}

export default React.memo(Reviews);

import classNames from "classnames";
import { SelectField } from "components";
import React from "react";
import { Col, Row } from "react-bootstrap";
import withContainer from "./Container";

function MultiLevelSelectInput({
  levels,
  lastSelectedValue,
  handleSetLastSelectedValue,
  handleSetLevels,
  handleSetRoot,
  loading,
  data,
  label,
  labelClassName,
  labelColor,
  placeholder,
  onSelected,
  value,
  error,
  ...props
}) {
  return (
    <Row>
      {levels.length > 0 &&
        levels.map((item, idx) => (
          <Col
            key={`${item.level}-${item.data[0].id}`}
            xs={6}
            className={classNames({
              "mt-3": idx > 1,
            })}
          >
            <SelectField
              label={label}
              labelClassName={labelClassName}
              labelColor={labelColor}
              placeholder={placeholder}
              placeholderDisabled
              onChange={(e) => {
                handleSetLastSelectedValue(e.target.value, item.level);
                onSelected(e);
              }}
              className="py-3"
              error={error}
            >
              {item.data &&
                item.data.map((item, idx) => (
                  <option key={idx} value={item.id}>
                    {item.name}
                  </option>
                ))}
            </SelectField>
          </Col>
        ))}
    </Row>
  );
}

export default withContainer(MultiLevelSelectInput);

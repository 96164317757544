import React from "react";
import { Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import { NoData, Pagination, SkeletonGroup } from "components";
import withContainer from "./Container";

function GridListWeb({
  noDataImage,
  noDataText,
  loading,
  items,
  pagination,
  componentToRender,
  listRef,
  skeletonHeight,
  xs,
  sm,
  md,
  lg,
  xl,
  ...props
}) {
  const Component = componentToRender.as
    ? componentToRender.as
    : componentToRender;

  return (
    <div className="grid-list" ref={listRef} {...props}>
      <Row className="g-3">
        {loading && (
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <SkeletonGroup
              rounded
              rows={4}
              {...(xs && {
                count: Math.floor(12 / xs),
              })}
              {...(sm && {
                count: Math.floor(12 / sm),
              })}
              {...(md && {
                count: Math.floor(12 / md),
              })}
              {...(lg && {
                count: Math.floor(12 / lg),
              })}
              {...(xl && {
                count: Math.floor(12 / xl),
              })}
              width={"100%"}
              height={skeletonHeight}
            />
          </Col>
        )}
        {!loading && items.length === 0 && (
          <Col xs={12}>
            <NoData image={noDataImage} text={noDataText} />
          </Col>
        )}

        {!loading &&
          items.length > 0 &&
          items.map((item, idx) => (
            <Col
              key={`${item.id}-${idx}`}
              xl={xl || 3}
              lg={lg || 4}
              md={md || 6}
              sm={sm || 6}
              xs={xs || 12}
            >
              <Component
                order={idx}
                {...item}
                {...(typeof componentToRender === "object"
                  ? componentToRender.props
                  : null)}
              />
            </Col>
          ))}
      </Row>
      {items && items.length > 0 && pagination.count ? (
        <Row>
          <Col xs={12}>
            <Pagination count={pagination.count} perPage={pagination.perPage} />
          </Col>
        </Row>
      ) : null}
    </div>
  );
}

GridListWeb.propTypes = {
  noDataImage: PropTypes.string.isRequired,
  noDataText: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  items: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  componentToRender: PropTypes.oneOfType([
    PropTypes.elementType,
    PropTypes.object,
  ]).isRequired,
  listRef: PropTypes.object,
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
};

export default withContainer(React.memo(GridListWeb));

import React from "react";
import moment from "moment";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useFetch, useLanguage } from "hooks";
import { Button, Dot, Typography } from "components";
import "./style.scss";
import { useAlert } from "context";
import { API_ROUTES } from "constants";
import { navigate } from "helpers";
import { useRef } from "react";
import { Controller } from "react-hook-form";
import CancelSubscriptionOrderModal from "components/CancelSubscriptionOrderModal";

export default function SubscriptionItem({
  id,
  order_reference,
  subscription_info,
  total_amount,
  name,
  created_at,
  status = 0,
  statusText,
  statusColor,
  refetch,
  requestModalShown,
  handleCloseRequestModal,
  handleOpenRequestModal,
  subscriptionOrderId
}) {
  const language = useLanguage();


  return (
    <div className="subscription-item bg-gray3 px-2 py-2 rounded-xs border border-gray">
      <CancelSubscriptionOrderModal
        visible={requestModalShown}
        handleClose={handleCloseRequestModal}
        subscription_order_id={subscriptionOrderId}
        refetch={refetch}
      />
      <Row>
        <Col xl={2} lg={3} md={3} sm={4} xs={4}>
          <Link to={`/account/subscription/${order_reference}`}>
            <div className="item_img">
              <img
                src={"/images/package-gold.png"}
                alt={name}
                className="rounded-sm w-100"
              />
            </div>
          </Link>
        </Col>
        <Col xl={8} lg={6} md={9} sm={8} xs={8} className="">
          <Row>
            <Col xs={12}>
              <Link to={`/account/subscription/${order_reference}`}>
                <Typography
                  tag="h3"
                  variant="h4"
                  color="secondary"
                  overflow={1}
                  text={subscription_info.name + id}
                />
              </Link>
            </Col>
            <Col xs={12}>
              <Typography
                tag="h3"
                variant="h6"
                color="gray7"
                overflow={1}
                text={order_reference}
              />
            </Col>
            {/* <Col xs={12}>
              <Typography
                tag="h3"
                variant="subtitle1"
                color="gray7"
                text={duration}
              />
            </Col> */}
            <Col xs={12} className="mt-1">
              <Typography
                tag="h3"
                variant="h4"
                color="gray7"
                text={`${total_amount} ${language.website_currency}`}
              />
            </Col>
            <Col xs={12} className="mt-1">
              <Typography
                tag="h3"
                variant="h4"
                color="gray7"
                text={
                  <React.Fragment>
                    {moment(created_at).format("DD/MM/YYYY")}{" "}
                    {/* <Typography
                      tag="span"
                      variant="subtitle1"
                      color="secondary"
                      text={language.to}
                    />{" "}
                    {dateTo} */}
                  </React.Fragment>
                }
              />
            </Col>
          </Row>
        </Col>
        <Col
          xl={2}
          lg={3}
          md={12}
          sm={12}
          xs={12}
          className="mt-4 mt-lg-0 text-end"
        >
          <Row className="justify-content-center align-items-end">
            <Col xs={12} className="text-start">
              <div>
                <Dot color={statusColor} className="me-1" size={12} />
                <Typography
                  tag="span"
                  variant="subtitle1"
                  style={{
                    color: statusColor,
                  }}
                  text={statusText}
                />
              </div>
            </Col>
            <Col xs={6} lg={12} className="text-end mt-2">
              {status < 1 && (
                <Button
                  onClick={() => navigate(`/packages/${subscription_info.id}`)}
                  variant="outline-primary"
                  className="w-100"
                  icon="refund"
                  text={language.renew}
                />
              )}
              {status >= 1 && (
                <Button
                  onClick={() => handleOpenRequestModal(id)}
                  variant="outline-danger"
                  className="w-100"

                  text={language.cancel}
                />
              )}
            </Col>
            <Col xs={6} lg={12} className="text-end mt-2">
              <Button
                as={Link}
                to={`/account/subscription/${order_reference}`}
                variant="outline-info"
                className="w-100"
                icon="details"
                text={language.details}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLanguage } from "hooks";
import { Heading, Skeleton, Slider } from "components";
import { ServiceCard } from "components/cards";
import "./style.scss";

function RelatedServices({ loading, data }) {
  const language = useLanguage();

  return (
    <div id="top-rated-services">
      <Container>
        {!loading && data ? (
          <Row>
            <Col xs={12}>
              <Heading title={language.relatedServices} />
            </Col>
            <Col xs={12} className="mt-4">
              <Slider
                dir={language.direction}
                xs={{
                  slidesPerView: 2,
                  pagination: false,
                }}
                lg={{
                  slidesPerView: 4,
                  pagination: { clickable: true },
                }}
                items={data}
                itemAs={ServiceCard}
                spaceBetween={24}
              />
            </Col>
          </Row>
        ) : (
          <Row>
            <Col xs={12}>
              <Skeleton height={40} width="40%" />
            </Col>
            <Col xs={12} className="mt-4">
              <Skeleton height={300} />
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
}

export default React.memo(RelatedServices);

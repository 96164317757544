import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { BreadCrumb, Layout, Skeleton, SkeletonGroup } from "components";
import { Details, RelatedServices, Reviews } from "./components";
import withContainer from "./Container";
import "./style.scss";
import { useLanguage } from "hooks";

function ServiceDetailsPage({
  loading,
  data,
  reviewsData,
  relatedProductsLoading,
  relatedProductsData,
  fetchReviews,
}) {
  const language = useLanguage();
  return (
    <Layout footerMargin>
      <div id="service-details-page" className="page_container">
        <section id="page-header">
          <BreadCrumb
            loading={loading}
            titleClassName="text-black fs-1 fw-light"
            lastWordClassName="text-secondary fw-bolder fw-bold"
            {...(!loading &&
              data && {
                title: data.category.name,
                path: [
                  { title: language.services, link: "/our-services" },

                  {
                    title: data.category.name,
                    link: `/services/${data.category.machine_name}`,
                  },
                  { title: data.title },
                ],
              })}
            src="/images/ac.png"
          />
        </section>
        <section id="service-details" className="mt-3">
          {!loading && data && data.id ? (
            <Details loading={loading} data={data} />
          ) : (
            <Container>
              <Row>
                <Col xs={12}>
                  <Skeleton height={40} />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col xs={12} lg={6}>
                  <Skeleton height={600} />
                </Col>
                <Col xs={12} lg={6} className="mt-4 mt-lg-0">
                  <Row className="align-items-center">
                    <Col xs={12}>
                      <Skeleton height={40} width="80%" />
                    </Col>
                  </Row>
                  <Row className="mt-1">
                    <Col xs={12}>
                      <Skeleton height={40} width="40%" />
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col xs={12}>
                      <Skeleton height={20} />
                      <Skeleton height={20} className="mt-2" />
                      <Skeleton height={20} width="80%" className="mt-2" />
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col xs={12} lg={8} className="mt-2">
                      <SkeletonGroup count={4} rows={1} height={40} />
                    </Col>
                    <Col xs={12} lg={8} className="mt-4">
                      <Skeleton height={100} />
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col xs={12} sm={6} md={6}>
                      <Skeleton height={200} />
                    </Col>
                    <Col xs={12} sm={6} md={6} className="mt-2 mt-sm-0">
                      <Skeleton height={200} />
                    </Col>
                  </Row>
                  <Row className="mt-5">
                    <Col xs={12}>
                      <Skeleton height={40} />
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col xs={12}>
                      <Skeleton height={40} />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="mt-6">
                <Col xs={12}>
                  <Skeleton height={40} width="40%" />
                </Col>
                <Col xs={12} className="mt-3">
                  <Skeleton height={20} />
                  <Skeleton height={20} className="mt-2" />
                  <Skeleton height={20} width="80%" className="mt-2" />
                </Col>
              </Row>
            </Container>
          )}
        </section>
        <section id="service-reviews" className="mt-6">
          <Reviews
            loading={!reviewsData}
            data={reviewsData}
            didBuyService={data && data.is_buyed}
            serviceId={data ? data.id : 0}
            fetchReviews={fetchReviews}
          />
        </section>

        {relatedProductsData && relatedProductsData.length > 0 && (
          <section id="related-services" className="mt-6">
            <RelatedServices
              loading={relatedProductsLoading}
              data={relatedProductsData}
            />
          </section>
        )}
      </div>
    </Layout>
  );
}

export default withContainer(React.memo(ServiceDetailsPage));

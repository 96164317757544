import React from "react";
import { API_ROUTES } from "constants";
import { useFetch } from "hooks";
import { useParams } from "react-router-dom";

function withContainer(WrappedComponent) {
  return (props) => {
    const { machineName } = useParams();
    const [{ data, loading }] = useFetch(
      {
        endpoint: API_ROUTES.getPublicPageDetails,
        params: `?machine_name=${machineName}`,
      },
      [machineName],
      true
    );

    return (
      <WrappedComponent
        loading={loading}
        name={!loading && data && data.name}
        text={!loading && data && data.text}
      />
    );
  };
}

export default withContainer;

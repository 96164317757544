import React from "react";
import { Link } from "react-router-dom";
import { useLanguage } from "hooks";
import { Typography } from "components";
import "./style.scss";

const ReadMore = ({ link }) => {
  const language = useLanguage();

  return (
    <Link to={link}>
      <div className="text-center">
        <Typography tag="span" color="primary" className="me-2">
          {language.readMore}
        </Typography>
        <img
          src="./images/right-arrow.svg"
          alt="read more"
          className="flip-x"
        />
      </div>
    </Link>
  );
};

export default React.memo(ReadMore);

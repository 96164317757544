import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Image, Typography } from "components";
import "./style.scss";

function SubServiceCategoryCard({
  id,
  machine_name,
  name,
  description,
  subcategories,
  image,
}) {
  return (
    <div className="service-sub-category-card bg-gray3 px-4 py-2 h-100">
      <Link
        to={
          subcategories.length > 0
            ? `/category/${id}`
            : `/services/${machine_name}`
        }
      >
        <Row className="h-100">
          <Col xs={12}>
            <Image src={image} alt={name} className="category_img" fromApi />
          </Col>

          <Col xs={12} className="align-self-end">
            <Row>
              <Col xs={12} className="mt-3">
                <Typography
                  tag="h3"
                  variant="h5"
                  align="center"
                  color="black"
                  overflow={1}
                >
                  {name}
                </Typography>
              </Col>
              <Col xs={12} className="mt-3">
                <Typography
                  tag="h4"
                  variant="body1"
                  align="center"
                  color="black"
                  overflow={2}
                >
                  {description}
                </Typography>
              </Col>
            </Row>
          </Col>
        </Row>
      </Link>
    </div>
  );
}

export default React.memo(SubServiceCategoryCard);

import React from "react";
import { useParams } from "react-router-dom";
import { API_ROUTES } from "constants";
import { useFetch } from "hooks";

function withContainer(WrappedCompoent) {
  return (props) => {
    const { id: adviceId } = useParams();
    const [{ loading, data }] = useFetch({
      endpoint: API_ROUTES.getAdvicesDetails,
      params: `?advice_id=${adviceId}`,
      initialLoadingState: true,
    });

    return <WrappedCompoent loading={loading} data={data} />;
  };
}
export default withContainer;

import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Icon, Typography } from "components";
import { useLanguage } from "hooks";

const SelectField = React.forwardRef(
  (
    {
      id,
      name,
      label,
      labelType = "formLabel",
      labelClassName,
      className,
      placeholder,
      onChange,
      defaultValue,
      error,
      labelColor = "gray7",
      iconColor,
      icon,
      children,
      placeholderDisabled,
      ...props
    },
    ref
  ) => {
    const translation = useLanguage();
    const ContainerElement =
      label && labelType !== "group" ? Form.Group : React.Fragment;

    return (
      <ContainerElement>
        {label && labelType === "group" && (
          <Typography
            htmlFor={id}
            tag="label"
            variant="body2"
            className={classNames("bg-white input-group-text text-capitalize", {
              [`${labelClassName}`]: labelClassName,
              [`text-${labelColor}`]: labelColor,
            })}
            text={label}
          />
        )}
        {label && labelType === "formLabel" && (
          <Typography
            htmlFor={id}
            tag="label"
            variant="body2"
            className={classNames("form-label text-capitalize", {
              [`${labelClassName}`]: labelClassName,
              [`text-${labelColor}`]: labelColor,
            })}
            text={label}
          />
        )}
        {icon && (
          <InputGroup className="h-100 bg-white rounded-sm">
            <InputGroup.Text
              as={Form.Label}
              htmlFor={id}
              className={classNames("border-0 bg-transparent my-1 px-2", {
                [`text-${iconColor}`]: iconColor,
              })}
            >
              <Icon name={icon} />
            </InputGroup.Text>
            <Form.Select
              ref={ref}
              id={id}
              name={name}
              onChangeCapture={onChange}
              {...(placeholder && {
                defaultValue: "",
              })}
              defaultValue={placeholder ? "" : defaultValue}
              className={classNames("border-0 text-primary", {
                [`${className}`]: className,
                "is-invalid": error,
              })}
              {...props}
            >
              {/* {placeholder && <option value="">{placeholder}</option>} */}
              <option value="" disabled={placeholderDisabled}>
                {translation.pleaseSelect}
              </option>
              {children}
            </Form.Select>
          </InputGroup>
        )}
        {!icon && (
          <Form.Select
            ref={ref}
            id={id}
            name={name}
            onChangeCapture={onChange}
            {...(placeholder && {
              defaultValue: "",
            })}
            defaultValue={placeholder ? "" : defaultValue}
            className={classNames("", {
              [`${className}`]: className,
              "is-invalid": error,
            })}
            {...props}
          >
            {/* {placeholder && <option value="">{placeholder}</option>} */}
            <option value="" disabled={placeholderDisabled}>
              {translation.pleaseSelect}
            </option>
            {children}
          </Form.Select>
        )}
        {error && <Form.Text className="text-danger">{error}</Form.Text>}
      </ContainerElement>
    );
  }
);

SelectField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  erorr: PropTypes.string,
  icon: PropTypes.string,
};

export default React.memo(SelectField);

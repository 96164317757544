import React from "react";
import { Col, Row } from "react-bootstrap";
import classNames from "classnames";
import { useLanguage } from "hooks";
import { Button, Icon, Typography } from "components";

function LocationCard({
  nickname,
  email,
  phone,
  place,
  address,
  is_selected: isDefault = false,
  handleMakeAddressDefault,
}) {
  const language = useLanguage();

  return (
    <div className="location-card rounded-xs overflow-hidden border border-gray bg-gray3 h-100">
      <Row>
        <Col xs={12}>
          <div
            className={classNames("px-4 py-3", {
              "bg-secondary": isDefault,
              "bg-gray6": !isDefault,
            })}
          >
            <Typography tag="h4" variant="h6" className="text-white">
              {nickname} {isDefault ? `(${language.default})` : ""}
            </Typography>
          </div>
        </Col>

        <Col xs={12}>
          <div className="px-4 py-4">
            <Row>
              <Col xs={12}>
                <Icon className="text-secondary me-2" name="email" />
                <Typography tag="span" variant="body1">
                  {email}
                </Typography>
              </Col>
              <Col xs={12} className="mt-2">
                <Icon className="text-secondary me-2" name="phone" />
                <Typography tag="span" variant="body1">
                  {phone}
                </Typography>
              </Col>
              <Col xs={12}>
                <hr className="text-gray6" />
                {place && (
                  <Col xs={12}>
                    <Typography
                      variant="subtitle3"
                      className="text-gray7 text-overflow-2"
                      text={place.name}
                    />
                  </Col>
                )}
                {address && (
                  <Typography
                    variant="subtitle3"
                    className="text-gray7 text-overflow-2"
                  >
                    {address}
                  </Typography>
                )}
              </Col>
              {!isDefault && (
                <Col xs={12} className="text-end">
                  <Button
                    onClick={handleMakeAddressDefault}
                    variant="outline-primary"
                    className="text-secondary fs-7"
                    text={language.makeDefault}
                  />
                </Col>
              )}
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default React.memo(LocationCard);

import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { useHover } from "hooks";
import { Image, Typography } from "components";
import "./style.scss";

function ServiceCategoryCard({
  id,
  machine_name,
  has_sub_levels,
  name,
  image,
  className,
}) {
  const [ref, isHovered] = useHover();

  return (
    <div
      ref={ref}
      className={classNames("service-category-card", {
        [`${className}`]: className,
      })}
    >
      <Row className="h-100-lg">
        <Col xs={12} className="h-100">
          <Link
            to={
              has_sub_levels ? `/category/${id}` : `/services/${machine_name}`
            }
            className=""
          >
            <div
              className={classNames(
                // "image-container bg-secondary rounded-circle p-3 p-sm-3 p-md-3 p-lg-4 p-xl-5 d-flex justify-content-center align-items-center",
                "image-container bg-secondary rounded-circle d-flex justify-content-center align-items-center",
                {
                  "rounded-lg": isHovered,
                }
              )}
            >
              <Image src={image} alt={name} className="w-100" fromApi />
            </div>
          </Link>
        </Col>
        <Col xs={12} className="mt-3">
          <Link
            to={
              has_sub_levels ? `/category/${id}` : `/services/${machine_name}`
            }
          >
            <Typography
              tag="h3"
              variant="h5"
              align="center"
              color="black"
              overflow={2}
              className="text-capitalize"
            >
              {name}
            </Typography>
          </Link>
        </Col>
      </Row>
    </div>
  );
}

export default React.memo(ServiceCategoryCard);

import React from "react";
import { Col, Row } from "react-bootstrap";
import classNames from "classnames";
import { Typography } from "components";
import "./style.scss";

function OptionGroup({
  title,
  options,
  currentOptionGroup,
  handleOptionClicked,
}) {
  return (
    <div className="option-group">
      <Row>
        <Col xs={12}>
          <Typography
            tag="h6"
            variant="h6"
            weight="bolder"
            color="black"
            overflow={1}
            className="text-capitalize"
            text={title}
          />
        </Col>

        <Col xs={12} className="">
          <Row className="gx-1">
            {options.map((item, idx) => (
              <Col
                key={idx}
                xs={6}
                sm={3}
                lg={3}
                xl={3}
                className={classNames("", {
                  "mt-2": true,
                })}
              >
                <div
                  key={item.id}
                  role="button"
                  className={classNames("option-item py-2 px-1 text-center", {
                    chosen:
                      currentOptionGroup &&
                      currentOptionGroup.values.indexOf(item.id) > -1,
                  })}
                  onClick={() => handleOptionClicked(item)}
                >
                  <Typography
                    tag="span"
                    variant="body2"
                    color="black"
                    overflow={1}
                    className="text-capitalize"
                    text={item.name}
                  />
                </div>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default React.memo(OptionGroup);

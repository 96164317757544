import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { QRCode, Typography } from "components";
import "./style.scss";

function AppDownload({ websiteSettings }) {
  const { serverTranslations } = useSelector((state) => state.common);

  return (
    <div id="app-download">
      <div className="clipped-bg"></div>
      <Container>
        <Row className="py-4">
          <Col xs={12} lg={5} className="mt-sm-4">
            <Row className="align-items-lg-center mt-sm-4 px-lg-4">
              <Col xs={12}>
                <Typography
                  tag="h2"
                  variant="h3"
                  weight="bold"
                  color="white"
                  align="center"
                >
                  {serverTranslations.text_in_download_app}
                </Typography>
              </Col>
              <Col xs={12} className="text-center mt-3">
                {websiteSettings && websiteSettings.download_app_qr_link && (
                  <Col xs={12} className="text-center mt-2">
                    <div className="rounded-sm overflow-hidden d-inline-block p-2 bg-white">
                      <QRCode
                        size={110}
                        value={websiteSettings.download_app_qr_link}
                      />
                    </div>
                  </Col>
                )}
              </Col>
              <Col xs={12} className="mt-2">
                <div className="d-flex flex-wrap justify-content-center align-items-center gap-2">
                  {websiteSettings && websiteSettings.download_android && (
                    <a
                      href={websiteSettings.download_android}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src="./images/Google-Play.svg" alt="google play" />
                    </a>
                  )}
                  {websiteSettings && websiteSettings.download_ios && (
                    <a
                      href={websiteSettings.download_ios}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src="./images/Apple-Store.svg" alt="Apple Store" />
                    </a>
                  )}
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={12} sm={5} lg={7} className="d-none d-lg-block text-end">
            <img
              className="mobile-img d-none d-md-inline-block"
              src="/images/phone-app.png"
              alt="Mobile App"
            />
            <img
              className="w-100 d-block d-md-none"
              src="/images/mobile-phone-app.png"
              alt="Mobile App"
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default React.memo(AppDownload);

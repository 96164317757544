import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { useLanguage } from "hooks";
import {
  Layout,
  ProfileLayout,
  Button,
  SkeletonGroup,
  NoData,
} from "components";
import AddressCard from "./components/AddressCard";
import withContainer from "./Container";

function AddressListPage({
  loading,
  data,
  handleMakeAddressDefault,
  handleDeleteAddress,
}) {
  const translation = useLanguage();

  return (
    <Layout footerMargin>
      <div id="EditProfilePage">
        <ProfileLayout title={translation.myAccount}>
          <Row>
            <Col xs={12} className="text-end">
              <Button
                as={Link}
                to="/account/address/create"
                vairant="primary"
                text={translation.addNewAddress}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="mt-4">
              <div className="items">
                {!loading && data && data.length > 0 && (
                  <Row className="gy-3">
                    {data.map((item, idx) => (
                      <Col key={item.id} xs={12} md={6}>
                        <AddressCard
                          {...item}
                          handleMakeAddressDefault={() =>
                            handleMakeAddressDefault(item.id)
                          }
                          handleDeleteAddress={() =>
                            handleDeleteAddress(item.id)
                          }
                        />
                      </Col>
                    ))}
                  </Row>
                )}
                {loading && (
                  <SkeletonGroup
                    height={200}
                    xs={{
                      rows: 1,
                      count: 1,
                    }}
                    lg={{
                      rows: 1,
                      count: 2,
                    }}
                  />
                )}
                {!loading && data && data.length === 0 && (
                  <NoData
                    text={translation.noAddresses}
                    image="/images/no-addresses.png"
                  />
                )}
              </div>
            </Col>
          </Row>
        </ProfileLayout>
      </div>
    </Layout>
  );
}

export default withContainer(React.memo(AddressListPage));

import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLanguage } from "hooks";
import { BreadCrumb, GridList, Title, Layout } from "components";
import Card from "./components/Card";
import withContainer from "./Container";

function VideosPage({ loading, data, meta }) {
  const language = useLanguage();

  return (
    <Layout footerMargin>
      <div id="videos-page" className="page_container">
        <section>
          <BreadCrumb
            title={language.videos}
            path={[{ title: language.videos }]}
            src="/images/videos.png"
            titleClassName="text-black fs-1 fw-light"
            lastWordClassName="text-secondary fw-bolder fw-bolder"
          />
        </section>
        <section>
          <Container>
            <Row className="mt-5">
              <Col xs={12}>
                <Title
                  title={language.specialVideosForyou}
                  // text="Over 200,000 downloadable resources, with commercial license. Unlimited Downloads and more."
                  titleClassName="fs-2 fw-normal"
                  lastWordClassName="text-secondary fw-bolder fw-bolder"
                />
              </Col>
            </Row>
            <Row className="mt-5">
              <Col xs={12}>
                <GridList
                  as={Card}
                  items={data && data.length > 0 ? data : []}
                  meta={meta}
                  loading={loading}
                  noDataImage="/images/no-videos.png"
                  noDataText={language.noVideos}
                  skeletonHeight={316}
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                />
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </Layout>
  );
}

export default withContainer(React.memo(VideosPage));
